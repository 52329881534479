import errors from '../misc/errors.json'
import { mapValues } from 'lodash'
import { message } from "antd"



// API_URL fetched from environment
export const API_ROOT = process.env.REACT_APP_USER_API_URL

// Resources that will be accessible through api.xxx.get()
const resources = []



// Fetches an API response
export const callApi = async (options) => {

	// Check if array of calls
	if (options instanceof Array) {
		return Promise.all(options.map((item) => callApi(item)))
	}
	// Check endpoint
	const endpoint = options.endpoint;
	if (typeof endpoint !== 'string') {
		throw new Error('Specify a string endpoint URL.')
	}
	// Make full url
	const fullUrl = new URL((endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint)
	// Method
	options.method = options.method || 'GET'
	// Headers
	if (!options.headers) {
		options.headers = {}
	}
	// Body
	if (options.body) {
		options.body = JSON.stringify(options.body)
		options.headers['Content-Type'] = 'application/json'
	}
	// Token
	let token = sessionStorage.getItem('token') || localStorage.getItem('token')
	if (token) {
		options.headers["Authorization"] = `Bearer ${token}`
	}
	// Add url parameters
	if (options.method.toUpperCase() === 'GET' && options.params) {
		Object.keys(options.params).forEach(key => fullUrl.searchParams.append(key, options.params[key]))
	}
	// Loader
	if (options.loader) options.loader(true)
	// Fetch
	try {
		const response = await fetch(fullUrl.toString(), options)
		// Handle OK response
		if (response.ok) {
			// Parse body
			const contentType = response.headers.get("content-type")
			let body
			if (contentType && contentType.indexOf('application/json') !== -1) {
				body = await response.json()
			} else {
				body = await response.blob()
			}
			// Loader
			if (options.loader) options.loader(false)
			// Return parsed body
			return Promise.resolve(body)
		}
		// Handle error response
		const json = await response.json()
		if (json?.error && errors[json.error]) {
			const error = errors[json.error]
			if (!error.ignore && !options.noErrorMessage) {
				message[error.type](error.message)
			}
		} else {
			message.error("Une erreur inconnue est survenue. Si le problème persiste, contactez l'administrateur.")
		}
		response.json = json
		if (options.loader) options.loader(false)
		return Promise.reject(response)
	} catch (err) {
		console.error(err)
		message.error("Une erreur inconnue est survenue. Si le problème persiste, contactez l'administrateur.")
		if (options.loader) options.loader(false)
		return Promise.reject(err)
	}

}

/**
 * API description
 * 
 * @type {{
 * 	call: Function
 * 	get: Function
 * 	getById: Function
 * 	post: Function
 * 	put: Function
 * 	delete: Function
 * }}
 */
// @ts-ignore
const api = {
	call: (url, options) => { options = options || {}; options.endpoint = url; return callApi(options) },
	get: (url, options) => { options = options || {}; options.method = "GET"; options.endpoint = url; return callApi(options) },
	getById: (url, id, options) => { options = options || {}; options.method = "GET"; options.endpoint = `${url}/${id}`; return callApi(options) },
	post: (url, body, options) => { options = options || {}; options.method = "POST"; options.endpoint = url; options.body = body; return callApi(options) },
	put: (url, id, body, options) => { options = options || {}; options.method = "PUT"; options.endpoint = `${url}/${id}`; options.body = body; return callApi(options) },
	delete: (url, id, options) => { options = options || {}; options.method = "DELETE"; options.endpoint = `${url}/${id}`; return callApi(options) }
}

// API functions for resources
const apiFunctions = Object.assign({}, api)

// For each resource, map the functions with url pre-added
resources.forEach(resource => {
	api[resource] = mapValues(apiFunctions, (value, key) => apiFunctions[key].bind(undefined, '/' + resource))
})



export default api