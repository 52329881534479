import React from 'react'

import Icon from './Icon'

import { Tag, Tooltip } from 'antd'
import { DateTime } from 'luxon'
import { nfPercent } from 'misc/utils'




export const SimpleTrainingTag = ({ tag, small = false }) => {
  return small
    ? <Tooltip title={tag.text}><Tag color={tag.color}><Icon icon={tag.icon} fixedWidth /></Tag></Tooltip>
    : <Tag color={tag.color}><Icon icon={tag.icon} fixedWidth /> {tag.text}</Tag>
}

export const TrainingVisibility = ({ training, small = false }) => {
  let tag = training.isPublished
    ? { color: 'green', icon: 'eye', text: 'Publiée' }
    : { color: 'red', icon: 'eye-slash', text: 'Invisible' }
  return <SimpleTrainingTag tag={tag} small={small} />
}

export const TrainingStatus = ({ training, help = false, small = false }) => {
  if (training.status === 'project') return <SimpleTrainingTag tag={{ color: 'blue', icon: 'digging', text: <>Projet {help ? <Icon.Info style={{ marginLeft: 5 }} tooltip="La formation n'est pas encore confirmée, cela dépend sans doute du nombre d'inscrits." /> : ''}</> }} small={small} />
  if (training.status === 'confirmed') return <SimpleTrainingTag tag={{ color: 'green', icon: 'check', text: 'Confirmée' }} small={small} />
  if (training.status === 'cancelled') return <SimpleTrainingTag tag={{ color: 'red', icon: 'ban', text: 'Annulée' }} small={small} />
  return <>-</>
}

export const RegistrationsStatus = ({ training, small = false }) => {
  if (training.registrationsStatus === 'open') return <SimpleTrainingTag tag={{ color: 'green', icon: 'lock-open', text: 'Ouvertes' }} small={small} />
  if (training.registrationsStatus === 'closed') return <SimpleTrainingTag tag={{ color: 'red', icon: 'lock', text: 'Fermées' }} small={small} />
  const opening = DateTime.fromISO(training.registrationsStatus)
  if (opening.isValid) return <SimpleTrainingTag tag={{ color: 'blue', icon: 'clock', text: `Le ${opening.toLocaleString()}` }} small={small} />
  return <>-</>
}

export const TrainingStates = ({ training }) => {
  return <>
    <TrainingVisibility training={training} small={true} />
    <RegistrationsStatus training={training} small={true} />
    <TrainingStatus training={training} small={true} />
  </>
}

export const UserType = {
  Trainer: () => <Tag color="orange">Formateur</Tag>,
  Participant: () => <Tag color="green">Participant</Tag>,
  Prospect: () => <Tag >Prospect</Tag>
}

export const UserTypes = ({ user }) => {
  const tags = []
  if (user.isTrainer) tags.push(<UserType.Trainer key="trainer" />)
  if (user.isParticipant) tags.push(<UserType.Participant key="participant" />)
  if (!tags.length) tags.push(<UserType.Prospect key="prospect" />)
  return <>{tags}</>
}

export const FLinkSpecialAccessStatus = ({ registration }) => {
  if (registration.status === 'waiting-list' || registration.status === 'to-check') return <Tag>Pas encore inscrit</Tag>
  if (registration.status === 'cancelled') return <Tag>Désistement</Tag>
  if (registration.status === 'rejected') return <Tag>Inscription refusée</Tag>
  if (registration.status === 'to-transfer' || registration.status === 'transfered') return <Tag>Transfert d'inscription</Tag>
  if (registration.training.status === 'cancelled') return <Tag>Formation annulée</Tag>
  if (!registration.training.flinkID) return <Tag>Pas de module FLink</Tag>
  if (registration.flinkSpecialAccess) {
    const access = DateTime.fromISO(registration.flinkSpecialAccess)
    if (access > DateTime.now()) {
      return <Tag color="green">Jusqu'au {access.toFormat('dd/MM/yy')}</Tag>
    } else {
      return <Tag color="gold">Expiré depuis {access.toFormat('dd/MM/yy')}</Tag>
    }
  }
  return <>-</>
}

export const FLinkNormalAccessStatus = ({ registration }) => {
  if (registration.status === 'waiting-list' || registration.status === 'to-check') return <Tag>Pas encore inscrit</Tag>
  if (registration.status === 'cancelled') return <Tag>Désistement</Tag>
  if (registration.status === 'rejected') return <Tag>Inscription refusée</Tag>
  if (registration.status === 'to-transfer' || registration.status === 'transfered') return <Tag>Transfert d'inscription</Tag>
  if (registration.training.status === 'cancelled') return <Tag>Formation annulée</Tag>
  if (!registration.training.flinkID) return <Tag>Pas de module FLink</Tag>
  if (registration.balance < 0) return <Tag color="volcano">Pas en ordre de paiement</Tag>
  const start = DateTime.fromISO(registration.training.start).minus({ days: 10 })
  const end = DateTime.fromISO(registration.training.end).plus({ months: 1 })
  if (DateTime.now() < start) return <Tag color="gold">A partir du {start.toFormat('dd/MM/yy')}</Tag>
  if (DateTime.now() >= end) return <Tag color="gold">Expiré depuis {end.toFormat('dd/MM/yy')}</Tag>
  if (registration.flinkAccess) return <Tag color="green">Jusqu'au {end.toFormat('dd/MM/yy')}</Tag>
  return <Tag color="gold">Expiré</Tag>
}

export const FLinkSpecialAccessStatusTrainer = ({ trainer }) => {
  if (trainer.training.status === 'cancelled') return <Tag>Formation annulée</Tag>
  if (!trainer.training.flinkID) return <Tag>Pas de module FLink</Tag>
  if (trainer.flinkSpecialAccess) {
    const access = DateTime.fromISO(trainer.flinkSpecialAccess)
    if (access > DateTime.now()) {
      return <Tag color="green">Jusqu'au {access.toFormat('dd/MM/yy')}</Tag>
    } else {
      return <Tag color="gold">Expiré depuis {access.toFormat('dd/MM/yy')}</Tag>
    }
  }
  return <>-</>
}

export const FLinkNormalAccessStatusTrainer = ({ trainer }) => {
  if (trainer.training.status === 'cancelled') return <Tag>Formation annulée</Tag>
  if (!trainer.training.flinkID) return <Tag>Pas de module FLink</Tag>
  const start = DateTime.fromISO(trainer.training.start).minus({ days: 365 })
  const end = DateTime.fromISO(trainer.training.end).plus({ months: 1 })
  if (DateTime.now() < start) return <Tag color="gold">A partir du {start.toFormat('dd/MM/yy')}</Tag>
  if (DateTime.now() >= end) return <Tag color="gold">Expiré depuis {end.toFormat('dd/MM/yy')}</Tag>
  if (trainer.flinkAccess) return <Tag color="green">Jusqu'au {end.toFormat('dd/MM/yy')}</Tag>
  return <Tag color="gold">Expiré</Tag>
}

// Used for user information
export const FLinkUserAccessStatus = ({ registration }) => {
  if (registration.status === 'waiting-list' || registration.status === 'to-check') return <Tag>Pas encore inscrit</Tag>
  if (registration.status === 'cancelled') return <Tag>Désistement</Tag>
  if (registration.status === 'rejected') return <Tag>Inscription refusée</Tag>
  if (registration.status === 'to-transfer' || registration.status === 'transfered') return <Tag>Transfert d'inscription</Tag>
  if (!registration.training.flinkID) return <Tag>Pas de module FLink</Tag>
  const start = DateTime.fromISO(registration.training.start).minus({ days: 10 })
  const end = DateTime.fromISO(registration.training.end).plus({ months: 1 })
  if (registration.flinkAccess) {
    let max = end
    if (registration.flinkSpecialAccess) {
      max = DateTime.max(end, DateTime.fromISO(registration.flinkSpecialAccess))
    }
    return <Tag color="green">Jusqu'au {max.toFormat('dd/MM/yy')}</Tag>
  }
  if (registration.balance < 0) return <Tag color="volcano">Pas en ordre de paiement</Tag>
  if (DateTime.now() < start) return <Tag color="gold">A partir du {start.toFormat('dd/MM/yy')}</Tag>
  if (DateTime.now() >= end) return <Tag color="gold">Expiré depuis {end.toFormat('dd/MM/yy')}</Tag>

}

export const RegistrationStatus = ({ registration }) => {
  switch (registration.status) {
    case 'to-check': return <Tag color="default">En attente de validation</Tag>
    case 'waiting-list': return <Tag color="blue">Liste d'attente</Tag>
    case 'validated': return <Tag color="green">Validée</Tag>
    case 'cancelled': return <Tag color="red">Désistement</Tag>
    case 'rejected': return <Tag color="red">Refusée</Tag>
    case 'to-transfer': return <Tag color="gold">À transférer</Tag>
    case 'transfered': return <Tag color="green">Transférée</Tag>
    default: return null
  }
}

export const PublicRegistrationStatus = ({ registration }) => {
  if (registration.training?.status === 'cancelled') return <Tag color="red">Formation annulée</Tag>
  if (registration.status === 'to-transfer') return <Tag color="gold">À transférer</Tag>
  if (registration.status === 'transfered') return <Tag color="green">Transférée</Tag>
  if (registration.status === 'cancelled') return <Tag color="red">Désistement</Tag>
  if (registration.status === 'rejected') return <Tag color="red">Inscription refusée</Tag>
  if (registration.status === 'to-check') return <Tooltip title="Un peu de patience, un collaborateur va traiter votre demande d'inscription."><Tag color="blue">En attente de validation <Icon icon="info-circle" /></Tag></Tooltip>
  if (registration.isClosed) {
    let message = 'Terminé'
    if (registration.status === 'waiting-list') message += ' (non-admis)'
    if (registration.status === 'validated') message += ' (admis)'
    return <Tooltip title="Tout est en ordre, il n'y a plus rien à faire."><Tag color="green">{message} <Icon icon="info-circle" /></Tag></Tooltip>
  }
  if (registration.status === 'waiting-list') {
    if (DateTime.fromISO(registration.training.start) <= DateTime.now()) return <Tag color="volcano">Non-admis</Tag>
    return <>{registration.training.isFull && <Tag color="volcano">Formation complête</Tag>} <Tag color="blue">Liste d'attente</Tag></>
  }
  if (registration.status === 'validated') {
    return <Tag color="green">Admis</Tag>
  }
  return null
}

export const PaymentStatus = ({ payment }) => {
  if (payment.type === 'holdback') {
    return <Tooltip title={payment.note}><Tag color="gold">Retenue <Icon icon="info-circle" /></Tag></Tooltip>
  }
  if (payment.type === 'refund' || payment.type === 'cf-refund') {
    if (payment.isDone) {
      return <Tag color="green">Remboursement effectué</Tag>
    }
    return <Tag color="blue">Remboursement en cours</Tag>
  }
  if (payment.type === 'cf') {
    switch (payment.paymentCF.status) {
      case 'encoded': return <Tag color="blue">En attente de validation</Tag>
      case 'accepted': return <Tag color="green">Chèques acceptés</Tag>
      case 'rejected': return <Tooltip title={payment.paymentCF.notes}><Tag color="red">Chèques refusés <Icon icon="info-circle" /></Tag></Tooltip>
      case 'invoiced': return <Tag color="blue">En cours de paiement par Sodexo</Tag>
      case 'paid': return <Tag color="green">Paiement validé</Tag>
      case 'payment-rejected': return <Tooltip title={payment.paymentCF.notes}><Tag color="red">Paiement refusé <Icon icon="info-circle" /></Tag></Tooltip>
      default: return <Tag>Statut inconnu</Tag>
    }
  }
  return <Tag color="green">Paiement reçu</Tag>
}

export const PaymentMethod = ({ payment }) => {
  if (payment.type === 'holdback') return <>-</>
  if (payment.paymentCF) return <Tag>Chèques formation</Tag>
  return <Tag>Virement bancaire</Tag>
}

export const PriceStatus = ({ registration }) => {
  if (registration.status === 'cancelled' || registration.status === 'rejected' || registration.status === 'to-check' || registration.status === 'to-transfer' || registration.status === 'transfered' || registration.isClosed) return null
  // If training cancelled
  if (registration.training.status === 'cancelled') {
    if (registration.balance > 0) return <Tag color="blue">{registration.balance} € vous seront remboursés</Tag>
    return null
  }
  // Pay date
  const payDate = registration.training.start ? ` avant le ${DateTime.fromISO(registration.training.start).minus({ days: 20 }).toFormat("dd/MM/yy")}` : ''
  // Check if deposit not paid, must be payed, even if balance is good
  const CFPayments = registration.payments.filter(p => p.paymentCF?.status === "accepted" || p.paymentCF?.status === "invoiced" || p.paymentCF?.status === "paid")
  const CFBalance = CFPayments.reduce((acc, curr) => acc + curr.amount, 0)
  const paid = registration.price + registration.balance - CFBalance
  if (!registration.isDepositPaid && registration.status === 'waiting-list') return <Tag color="gold">
    Encore {Math.max(-registration.balance, Math.min(registration.training.deposit, registration.price) - paid)} € à payer
    {payDate}
    <Icon.Info tooltip="L'acompte doit être payé pour valider l'inscription, même en cas de paiement par Chèques Formation." style={{ marginLeft: 7 }} />
  </Tag>
  // Easy cases
  if (registration.balance === 0) return <Tag color="green" icon={<Icon icon="check" />}>Payé</Tag>
  if (registration.balance > 0) return <Tag color="blue">{registration.balance} € vous seront remboursés</Tag>
  if (registration.balance < 0) return <Tag color="gold">Encore {-registration.balance} € à payer{payDate}</Tag>
}

export const RegistrationCancelStatus = ({ status }) => {
  switch (status) {
    case 'registration-cancel-0-3': return <Tag color="volcano">0-3 semaines</Tag>
    case 'registration-cancel-3-6': return <Tag color="gold">3-6 semaines</Tag>
    case 'registration-cancel-6': return <Tag color="green">6+ semaines</Tag>
    case 'registration-cancel-nothing-paid': return <Tag>Aucun paiement</Tag>
    default: return null
  }
}

export const PresenceRate = ({ registration, cf = false, showDetails = false }) => {
  if (registration.status !== 'validated') return <>-</>
  let text
  if (cf) {
    text = nfPercent(registration.presenceRateCF)
    if (registration.presenceRateCF < 1 || showDetails) text += ` (${registration.presenceHoursCF}h / ${registration.training.totalCFHours}h)`
  } else {
    text = nfPercent(registration.presenceRate)
    if (registration.presenceRate < 1 || showDetails) text += ` (${registration.presenceHours}h / ${registration.training.totalHours}h)`
  }
  if (registration.isPresenceRateOK) return <Tag color="green"><Icon icon="check" /> {text}</Tag>
  return <Tag color="volcano"><Icon icon="times" /> {text}</Tag>
}

export const EmailValidatedStatus = ({ status }) => {
  return status ? <Tag color="green">Validée</Tag> : <Tag color="volcano">Non validée</Tag>
}
