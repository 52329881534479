import React, { useState } from 'react'

import { Link, useLocation } from 'react-router-dom'

import api from 'services/userApiService'
import Icon from 'components/Icon'
import ModalLayout from 'components/ModalLayout/ModalLayout'
import Error500 from './Error500'

import { Button, Form, Input, Result } from 'antd'



const ResetPassword = () => {

  const [isDone, setIsDone] = useState(false)

  const code = new URLSearchParams(useLocation().search).get("code")

  const send = (values) => {
    api.post('/auth/reset-password', { code, password: values.password })
      .then(() => { setIsDone(true) })
      .catch(console.error)
  }

  if (!code) {
    return <Error500 />
  }

  return (
    <ModalLayout title={isDone || "Changez votre mot de passe"}>

      {isDone

        ? <Result
          title="C'est fait !"
          subTitle="Votre mot de passe est modifié. Connectez-vous à présent avec votre nouveau mot de passe."
          status="success"
          style={{ paddingTop: 20 }}
          icon={<Icon icon="check-circle" />}
          extra={<Link to="/login"><Button type="primary" icon={<Icon icon="lock" />}>Aller à l'écran de connexion</Button></Link>} />

        : <>
          <p style={{ textAlign: 'center' }}>Entrez un nouveau mot de passe pour votre compte.</p>
          <Form onFinish={send} style={{ marginTop: 24 }}>
            <Form.Item name="password" rules={[
              { required: true, message: 'Veuillez entrer votre nouveau mot de passe' },
              { min: 8, message: 'Le mot de passe doit faire au minimum 8 carractères.' }
            ]}>
              <Input.Password prefix={<Icon icon="lock" />} placeholder="Nouveau mot de passe" />
            </Form.Item>
            <Form.Item name="confirm-password" dependencies={['password']} rules={[
              { required: true, message: 'Veuillez confirmer votre nouveau mot de passe' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) return Promise.resolve()
                  return Promise.reject(new Error('Les deux mots de passe ne correspondent pas.'));
                }
              })
            ]}>
              <Input.Password prefix={<Icon icon="lock" />} placeholder="Confirmez le mot de passe" />
            </Form.Item>
            <Form.Item style={{ textAlign: 'center' }}>
              <Button type="primary" htmlType="submit" icon={<Icon icon="paper-plane" />}>Envoyer</Button>
            </Form.Item>
          </Form>
        </>}

    </ModalLayout>
  )

}

export default ResetPassword