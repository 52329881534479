import React, { useEffect, useState } from 'react'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import { filters, sorters } from 'misc/utils'
import TableButton from 'components/TableButton'
import DraggableModal from 'components/DraggableModal'

import { PageHeader, Table, Button, Card, Input, Form, message } from 'antd'

const { Column } = Table



const Signatures = () => {

  const [signatures, setSignatures] = useState([])
  const [filteredSignatures, setFilteredSignatures] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [currentSignature, setCurrentSignature] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [form] = Form.useForm()

  const loadSignatures = () => {
    api.signatures.get({ loader: setIsLoading }).then(data => {
      setSignatures(data)
      setFilteredSignatures(data)
    }).catch(console.error)
  }

  useEffect(loadSignatures, [])

  const onRowHandlers = (record, rowIndex) => ({
    onClick: e => {
      setCurrentSignature(record)
      form.setFieldsValue(record)
      setIsModalVisible(true)
    }
  })

  const openAddModal = () => {
    setCurrentSignature(null)
    form.resetFields()
    setIsModalVisible(true)
  }

  const onSearch = (e) => {
    if (e.target.value) {
      setFilteredSignatures(signatures.filter(filters.string(['description', 'name', 'function', 'phone', 'email', 'website', 'from'], e.target.value)))
    } else {
      setFilteredSignatures(signatures)
    }
  }

  const save = () => {
    if (currentSignature) {
      api.signatures.put(currentSignature.id, form.getFieldsValue(), { loader: setIsSaving }).then(data => {
        message.success("Modifications sauvegardées.")
        setIsModalVisible(false)
        loadSignatures()
      }).catch(console.error)
    } else {
      api.signatures.post(form.getFieldsValue(), { loader: setIsSaving }).then(data => {
        message.success("Template d'email ajouté.")
        setIsModalVisible(false)
        loadSignatures()
      }).catch(console.error)
    }
  }

  const remove = signature => {
    api.signatures.delete(signature.id, { loader: setIsLoading }).then(data => {
      message.success("Signature supprimée.")
      loadSignatures()
    }).catch(console.error)
  }

  return (
    <>
      <PageHeader
        title={<><Icon icon="signature" fixedWidth /> Signatures d'e-mails</>}
        extra={<Button icon={<Icon icon="plus" />} onClick={openAddModal}>Ajouter une signature</Button>}
      />
      <Card>
        <Input.Search style={{ marginBottom: 15, maxWidth: 350 }} placeholder="Rechercher..." onChange={onSearch} allowClear />
        <Table dataSource={filteredSignatures} loading={isLoading} onRow={onRowHandlers} rowKey="id" rowClassName="row-clickable" size="small">
          <Column title="Description" dataIndex="description" sorter={sorters.string('description')} />
          <Column title="Nom" dataIndex="name" sorter={sorters.string('name')} />
          <Column title="Fonction" dataIndex="function" sorter={sorters.string('function')} />
          <Column title="Téléphone" dataIndex="phone" sorter={sorters.string('phone')} />
          <Column title="E-mail" dataIndex="email" sorter={sorters.string('email')} />
          <Column title="Site web" dataIndex="website" sorter={sorters.string('website')} />
          <Column title="De" dataIndex="from" sorter={sorters.string('from')} />
          <Column title="Actions" render={item => <TableButton icon="trash" danger confirmMessage="Êtes-vous sûr ?" onClick={e => remove(item)} />} />
        </Table>
      </Card>
      <DraggableModal
        title={<><Icon icon="signature" />{currentSignature ? "Modifier la signature" : "Nouvelle signature"}</>}
        open={isModalVisible}
        onOk={form.submit}
        okButtonProps={{ loading: isSaving }}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form} onFinish={save} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          <Form.Item name="description" label={<>Descripton<Icon.Info tooltip="Utilisé en interne" style={{ marginLeft: 7 }} /></>} rules={[{ required: true }]}><Input /></Form.Item>
          <Form.Item name="name" label="Nom"><Input /></Form.Item>
          <Form.Item name="function" label="Fonction"><Input /></Form.Item>
          <Form.Item name="phone" label="Téléphone"><Input /></Form.Item>
          <Form.Item name="email" label="E-mail"><Input /></Form.Item>
          <Form.Item name="website" label="Site web"><Input /></Form.Item>
          <Form.Item name="from" label={<>De<Icon.Info tooltip={<>L'adresse d'envoi des e-mails. Exemple (format à respecter) :<br />Laurent Leleux &lt;laurent@neoxis.be&gt;</>} style={{ marginLeft: 7 }} /></>} rules={[{ required: true }]}><Input /></Form.Item>
        </Form>
      </DraggableModal>
    </>
  )

}

export default Signatures