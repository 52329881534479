import React, { useEffect, useState } from 'react'

import moment from 'moment'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import DraggableModal from 'components/DraggableModal'
import { filterOnSelectLabel } from 'misc/utils'

import { DatePicker, Form, Input, Select, Switch } from 'antd'



const AddNoteModal = ({ user = null, isVisible, onOK, onCancel, note = null }) => {

  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    if (isVisible) {
      if (note) {
        const values = { ...note }
        if (values.doneDate) values.doneDate = moment(values.doneDate)
        values.data = JSON.parse(values.data)
        form.setFieldsValue(values)
      }
      else form.resetFields()
    }
  }, [isVisible, form, note])

  const save = values => {
    const data = Object.assign({}, values)
    data.type = 'note'
    if (!data.registrationId) data.userId = user ? user.id : note.userId
    // Update
    if (note) {
      api.actions.put(note.id, data, { loader: setIsLoading })
        .then(onOK)
        .catch(console.error)
    }
    // Insert new
    else {
      api.actions.post(data, { loader: setIsLoading })
        .then(onOK)
        .catch(console.error)
    }
  }

  return (
    <DraggableModal
      title={<><Icon icon="sticky-note" fixedWidth /> {note ? "Modifier" : "Ajouter"} une note</>}
      open={isVisible}
      maskClosable={false}
      onCancel={onCancel}
      onOk={form.submit}
      okText="Enregistrer"
      okButtonProps={{ icon: <Icon icon="save" />, loading: isLoading }}
    >
      <Form form={form} layout="vertical" onFinish={save}>
        <Form.Item name={["data", "title"]} label="Titre" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name={["data", "content"]} label="Contenu">
          <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
        </Form.Item>
        <Form.Item name="doneDate" label="Date" rules={[{ required: true }]} initialValue={moment()}>
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item name="registrationId" label="Inscription (pas obligatoire)">
          <Select
            options={
              user?.registrations?.map(item => ({ label: `${item.number} - ${item.training.code}`, value: item.id }))
              || [{ label: `${note?.registration?.number} - ${note?.registration?.training?.code}`, value: note?.registration?.id }]
            }
            style={{ width: '100%' }}
            allowClear
            showSearch
            filterOption={filterOnSelectLabel}
            disabled={!!note}
          />
        </Form.Item>
        <Form.Item name={["data", "important"]} label={<>Point d'attention<Icon.Info tooltip="Une icône sera affichée dans la liste des inscriptions." style={{ marginLeft: 7 }} /></>} valuePropName="checked">
          <Switch checkedChildren="Oui" unCheckedChildren="Non" />
        </Form.Item>
      </Form>

    </DraggableModal >
  )

}

export default AddNoteModal