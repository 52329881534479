import React from 'react'

import ModalLayout from 'components/ModalLayout/ModalLayout'
import Icon from './Icon'

import { Result } from 'antd'



const Loader = () => (
  <ModalLayout>
    <Result
      title="Chargement en cours..."
      subTitle="Veuillez patienter une petite seconde"
      status="info"
      style={{ paddingTop: 20 }}
      icon={<Icon icon="spinner-third" spin />}
    />
  </ModalLayout>
)
export default Loader