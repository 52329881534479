import React from 'react'

import Icon from 'components/Icon'

import { Button, Card, Input, PageHeader, message, Alert } from 'antd'

const ICS_URL = process.env.REACT_APP_ICS_URL



const Misc = () => {

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(ICS_URL)
    message.success('Lien copié.')
  }

  return <>
    <PageHeader title={<><Icon icon="lightbulb-on" fixedWidth /> Divers</>} />
    <Card title={<><Icon icon="calendar-alt" fixedWidth /> Calendrier des formations</>}>
      <Alert
        style={{ marginBottom: 15 }}
        showIcon
        message="Comment l'intégrer dans Outlook ?"
        description={<>
          Copiez le lien ci-dessous. Dans Outlook, ajoutez un calendrier, et sélectionnez "S'abonner à partir du web". Utilisez le lien ci-dessous pour vous abonner.<br />
          <strong>Remarques :</strong>
          <ul>
            <li>Le calendrier est en lecture-seule, vous ne pouvez pas en modifier le contenu dans Outlook.</li>
            <li>Seuls les événements vieux de moins de 6 mois y apparaissent.</li>
            <li>Les événements d'une même formation, le même jour, sur les mêmes sites sont "aggrégés" ensemble.</li>
            <li>Dans outlook, le calendrier est mis à jour +/- toutes les 3 heures.</li>
          </ul>
        </>} />
      <Input.Group compact style={{ display: 'flex', maxWidth: 750 }}>
        <Input prefix={<Icon icon="globe" />} value={ICS_URL} />
        <Button icon={<Icon icon="clipboard" />} onClick={copyLinkToClipboard}>Copier</Button>
      </Input.Group>
    </Card>

  </>
}

export default Misc