import React, { useEffect, useState } from 'react'

import templateFields from 'misc/template-fields.json'
import Icon from 'components/Icon'
import { isString, unionBy } from 'lodash'

import { Alert, Card, Table } from 'antd'



const { Column } = Table


const EmailTemplateFieldsCard = ({ email }) => {

  const [fields, setFields] = useState([])

  // Get fields list for entity
  const getEntityData = entityName => {
    const entity = templateFields.entities[entityName] || {}
    return Object.keys(entity).map(key => ({ name: key, description: entity[key] }))
  }

  const emailCode = email ? (isString(email) ? email : email.code) : null

  useEffect(() => {
    if (emailCode) {
      const newFields = templateFields.emails[emailCode]
      if (isString(newFields)) setFields(templateFields.emails[templateFields.emails[emailCode]])
      else setFields(newFields)
    } else {
      setFields(unionBy(templateFields.emails['generic-email-for-registration'], templateFields.emails['generic-email-for-trainer'], 'name'))
    }
  }, [emailCode])

  return (
    <Card title={<><Icon icon="chalkboard" fixedWidth /> Champs disponibles</>}>
      {!emailCode && <Alert style={{ marginBottom: 15 }} showIcon closable message={<>
        Si cet e-mail est envoyé à un formateur, il contiendra tous les champs, sauf la partie "registration".<br />
        S'il est envoyé à un participant, il contiendra tous les champs sauf la partie "trainer".
      </>} />}
      <Alert style={{ marginBottom: 15 }} showIcon closable message={<>
        Les champs de dates doivent être préfixées par "simpleDate" si vous souhaitez qu'elle soit formatée comme ceci : jj/mm/yyyy<br />
        Pour un affichage personnalisé, on peut utiliser "formatDate" suivi de la date, suivi du format comme décrit <a href="https://moment.github.io/luxon/#/formatting?id=table-of-tokens" target="_blank" rel="noopener noreferrer">ici</a>.<br />
        Exemples : &#123;&#123;simpleDate training.start&#125;&#125; ou &#123;&#123;formatDate training.start dd/MM/yy&#125;&#125;
      </>} />
      <Table
        dataSource={fields}
        rowKey="name"
        size="small"
        showHeader={false}
        expandable={{
          expandedRowRender: record => <Table
            dataSource={getEntityData(record.entity)}
            rowKey="name"
            size="small"
            showHeader={false}
            pagination={false}
          >
            <Column dataIndex="name" />
            <Column dataIndex="description" />
          </Table>,
          rowExpandable: record => !!record.entity,
        }}
      >
        <Column dataIndex="name" />
        <Column dataIndex="description" />
      </Table>
    </Card>
  )

}

export default EmailTemplateFieldsCard