import React, {useState} from 'react'

import { orderBy } from 'lodash'

import Icon from 'components/Icon'
import { formatPaymentCom, isoDateToDateShort, isoDateToDateTime, nfEur } from 'misc/utils'
import { PaymentMethod, PaymentStatus } from 'components/Tags'

import { Descriptions, List, Space, Table } from 'antd'
import FileButton from 'components/FileButton'
import PaymentModal from "../../components/PaymentModal";

const { Column } = Table



const RegistrationView = ({ registration, refresh }) => {

  // Prepare files list
  const orderedActions = orderBy(registration.actions, ['date'], ['desc'])
  const files = []
  if (registration.status !== 'to-check' && registration.status !== 'rejected') {
    files.push({ name: 'Facture', files: orderedActions.filter(a => a.type === 'invoice-sent'), type: 'invoice-sent' })
    files.push({ name: 'Reconnaissance de dette', files: orderedActions.filter(a => a.type === 'ack-of-debt-generated'), type: 'ack-of-debt-generated' })
    files.push({ name: 'Etalement de paiement', files: orderedActions.filter(a => a.type === 'staggered-payments-generated'), type: 'staggered-payments-generated' })
  }
  if (orderedActions.filter(a => a.type === 'pro-forma-invoice').length) {
    files.push({ name: 'Facture pro-forma', files: orderedActions.filter(a => a.type === 'pro-forma-invoice'), type: 'pro-forma-invoice' })
  }

  const [currentPayment, setCurrentPayment] = useState(null)

  const onRowHandlers = (record, rowIndex) => ({
    onClick: e => setCurrentPayment(record)
  })


  return <>
    <Descriptions bordered size="small" column={1} className="registration-descriptions">
      {registration.isPQK && <Descriptions.Item label={<><Icon icon="user-md" /> Numéro INAMI</>}>{registration.inamiNumber}</Descriptions.Item>}
      <Descriptions.Item label={<><Icon icon="money-check-edit-alt" /> Communication struct.</>}>{formatPaymentCom(registration.paymentCommunication)}</Descriptions.Item>
      <Descriptions.Item label={<><Icon icon="copy" /> Documents</>}>
        {files.length ? <List dataSource={files} size="small" renderItem={file => (
          <List.Item>
            <List.Item.Meta
              title={file.name}
              description={<Space direction="vertical">{file.files.map(a => <div key={a.id} className="file-item">
                <Icon icon={a.isDone ? "check" : "hourglass"} /> {isoDateToDateTime(a.date)} {JSON.parse(a.data)?.values?.invoice ? `[${nfEur(JSON.parse(a.data)?.values?.invoice?.amount)}]` : ''} <FileButton.Open file={JSON.parse(a.data)?.pdfFile} />
              </div>)}</Space>}
            />
          </List.Item>
        )} /> : "Aucun document jusqu'à présent..."}
      </Descriptions.Item>
      <Descriptions.Item label={<><Icon icon="credit-card" /> Paiements</>}>
        {registration.payments.length
          ? <Table
            dataSource={registration.payments}
            size="small"
            rowKey="id"
            pagination={false}
            onRow={onRowHandlers}
            rowClassName="row-clickable"
          >
            <Column title="Date d'encodage" render={item => isoDateToDateShort(item.date)} />
            <Column title="Date du paiement" render={item => isoDateToDateShort(item.paymentDate)} />
            <Column title="Montant" render={item => `${item.amount} €`} />
            <Column title="Moyen de paiement" render={item => <PaymentMethod payment={item} />} />
            <Column title="Statut" render={item => <PaymentStatus payment={item} />} />
            <Column title="Note" dataIndex="note" />
          </Table>
          : <>Aucun paiement jusqu'à présent...</>
        }
      </Descriptions.Item>
    </Descriptions>
    <PaymentModal payment={currentPayment} isVisible={!!currentPayment} onClose={() => setCurrentPayment(null)} />
  </>
}

export default RegistrationView