import React from 'react'

import ModalLayout from 'components/ModalLayout/ModalLayout'
import Icon from 'components/Icon'

import { Result } from 'antd'



const Maintenance = () => {

  return (
    <ModalLayout>
      <Result
        title="Maintenance"
        subTitle={<>
          <p>Nous vous informons que le Centre de formation Continue Vinci va prochainement basculer vers une nouvelle plateforme d’inscription en ligne.</p>
          <p>Pour des raisons techniques, il n'est pas possible de vous inscrire à une formation jusqu'au 04/11.</p>
          <p>Veuillez-nous excuser pour le désagrément occasionné.</p>
          <p><em>L’Equipe du Centre de Formation Continue HE Vinci</em></p>
        </>}
        style={{ paddingTop: 20 }}
        icon={<Icon icon="digging" />}
      />
    </ModalLayout>
  )
}

export default Maintenance