import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { omit } from 'lodash'
import { DateTime } from 'luxon'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import TableButton from 'components/TableButton'
import TableLink from 'components/TableLink'
import { isoDateToDateShort, nfEur } from 'misc/utils'
import { PaymentStatus, PresenceRate } from 'components/Tags'
import SodexoInvoiceModal from './SodexoInvoiceModal'
import SodexoRefusedModal from './SodexoRefusedModal'
import SodexoPaidModal from './SodexoPaidModal'

import { Button, Card, message, PageHeader, Popover, Table, Tabs } from 'antd'

const { Column } = Table



const SodexoInvoicing = () => {

  const { tab = 'to-invoice' } = useParams()

  const history = useHistory()

  const [payments, setPayments] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const loadPayments = () => {
    setSelectedRowKeys([])
    api.payments.get({ loader: setIsLoading, params: { type: `cf-${tab}` } }).then(setPayments).catch(console.error)
  }

  useEffect(loadPayments, [tab])

  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const [isSodexoInvoiceModalVisible, setIsSodexoInvoiceModalVisible] = useState(false)
  const [isSodexoRefusedModalVisible, setIsSodexoRefusedModalVisible] = useState(false)
  const [isSodexoPaidModalVisible, setIsSodexoPaidModalVisible] = useState(false)

  const invoice = (values) => {
    const data = selectedRowKeys.map(k => ({
      id: k,
      paymentCF: {
        ...values,
        status: 'invoiced'
      }
    }))
    api.payments.put('', data, { loader: setIsLoading }).then(() => {
      loadPayments()
      message.success("Facturation effectuée.")
      setIsSodexoInvoiceModalVisible(false)
    }).catch(console.error)
  }

  const paymentReceived = (values) => {
    const data = selectedRowKeys.map(k => ({
      id: k,
      paymentDate: DateTime.now().toISO(),
      paymentCF: {
        status: 'paid',
        ...values
      }
    }))
    api.payments.put('', data, { loader: setIsLoading }).then(() => {
      loadPayments()
      message.success("Paiements confirmés.")
      setIsSodexoPaidModalVisible(false)
    }).catch(console.error)
  }

  const paymentRejected = (values) => {
    const data = selectedRowKeys.map(k => ({
      id: k,
      amount: 0,
      paymentCF: {
        status: 'payment-rejected',
        ...values
      }
    }))
    api.payments.put('', data, { loader: setIsLoading }).then(() => {
      loadPayments()
      message.success("Modifications enregistrées.")
      setIsSodexoRefusedModalVisible(false)
    }).catch(console.error)
  }

  const duplicate = payment => {
    const values = {
      registrationId: payment.registrationId,
      amount: payment.amount,
      paymentDate: payment.paymentDate,
      type: 'cf',
      paymentCF: omit(payment.paymentCF, ['id', 'paymentId', 'payment', 'notes'])
    }
    values.paymentCF.status = 'accepted'
    api.payments.post(values, { loader: setIsLoading }).then(data => {
      message.success("Paiement dupliqué et ajouté dans la liste des paiement à facturer.")
    }).catch(console.error)
  }

  const getAmountToInvoice = payment => Math.min(
    payment.registration.presenceRateCF === 1 ? Number.MAX_VALUE : payment.registration.presenceHoursCF * 30,
    payment.registration.price,
    payment.registration.training.CFHours * 30,
    payment.paymentCF.nbChecks * 30
  )

  return (
    <>
      <PageHeader
        title={<><Icon icon="file-invoice-dollar" fixedWidth /> Facturation Sodexo</>}
        extra={<>
          {tab === 'to-invoice' ? <Button icon={<Icon icon="check" />} disabled={!selectedRowKeys.length} onClick={() => setIsSodexoInvoiceModalVisible(true)}>Facturer la sélection ({selectedRowKeys.length})</Button> : null}
          {tab === 'invoiced' ? <Button icon={<Icon icon="check" />} disabled={!selectedRowKeys.length} onClick={() => setIsSodexoPaidModalVisible(true)}>Paiement reçu ({selectedRowKeys.length})</Button> : null}
          {tab === 'invoiced' ? <Button icon={<Icon icon="times" />} disabled={!selectedRowKeys.length} onClick={() => setIsSodexoRefusedModalVisible(true)}>Paiement refusé ({selectedRowKeys.length})</Button> : null}
        </>}
      />

      <Tabs defaultActiveKey="current" activeKey={tab} onTabClick={key => history.push(`/admin/sodexo-invoicing/${key}`)} destroyInactiveTabPane={true}>

        <Tabs.TabPane tab={<><Icon icon="clipboard-list-check" />A facturer</>} key="to-invoice">
          <Card>
            <Table dataSource={payments} pagination={{ pageSize: 20 }} loading={isLoading} rowKey="id" size="small" rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys }}>
              <Column title="Participant" render={payment => payment.registration && <TableLink.User user={payment.registration.user} />} />
              <Column title="Formation" render={payment => payment.registration && <TableLink.Training training={payment.registration.training} />} />
              <Column title="N° d'agrément" render={payment => payment.registration && payment.registration.training.CFApprovalNumber} />
              <Column title="N° d'autorisation client" dataIndex={['paymentCF', 'authorizationNumber']} />
              <Column title="Code d'utilisation" dataIndex={['paymentCF', 'usageCode']} />
              <Column title="Présence" render={payment => <PresenceRate registration={payment.registration} />} />
              <Column title="Nb. Chèques" dataIndex={['paymentCF', 'nbChecks']} />
              <Column title="Validité" render={payment => isoDateToDateShort(payment.paymentCF.validityDate)} />
              <Column title="Montant" render={payment => <Popover content={<>
                Inscription : {nfEur(payment.registration.price)}<br />
                A facturer : {nfEur(getAmountToInvoice(payment))}<br />
                Portefeuille : {nfEur(payment.paymentCF.nbChecks * 30)}<br />
              </>}>
                {nfEur(getAmountToInvoice(payment))}
              </Popover>} />
              <Column title="Facture" render={payment => `${payment.registration.number}/${payment.registration.training.code}`} />
              <Column title="CP" render={payment => payment.registration.training.sites.map(s => s.site.zip).join(', ')} />
            </Table>
          </Card>
        </Tabs.TabPane>

        <Tabs.TabPane tab={<><Icon icon="hourglass" />En attente de confirmation</>} key="invoiced">
          <Card>
            <Table dataSource={payments} pagination={{ pageSize: 20 }} loading={isLoading} rowKey="id" size="small" rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys }}>
              <Column title="Participant" render={payment => payment.registration && <TableLink.User user={payment.registration.user} />} />
              <Column title="Formation" render={payment => payment.registration && <TableLink.Training training={payment.registration.training} />} />
              <Column title="Numéro d'autorisation client" dataIndex={['paymentCF', 'authorizationNumber']} />
              <Column title="Présence" render={payment => <PresenceRate registration={payment.registration} />} />
              <Column title="Nb. Chèques" dataIndex={['paymentCF', 'nbChecks']} />
              <Column title="Validité" render={payment => isoDateToDateShort(payment.paymentCF.validityDate)} />
              <Column title="Montant" render={payment => `${nfEur(payment.amount)} (inscr. ${nfEur(payment.registration.price)})`} />
              <Column title="No. remise" dataIndex={['paymentCF', 'remiseNumber']} />
              <Column title="Date remise" render={payment => isoDateToDateShort(payment.paymentCF.remiseDate)} />
              <Column title="CP" render={payment => payment.registration.training.sites.map(s => s.site.zip).join(', ')} />
            </Table>
          </Card>
        </Tabs.TabPane>

        <Tabs.TabPane tab={<><Icon icon="check-double" />Paiements terminés</>} key="done">
          <Card>
            <Table dataSource={payments} pagination={{ pageSize: 20 }} loading={isLoading} rowKey="id" size="small" className="row-clickable" expandable={{
              expandRowByClick: true,
              expandIconColumnIndex: -1,
              expandedRowRender: p => <>
                <strong>Raison : </strong>{p.paymentCF.notes}<br />
                <strong>Numéro d'autorisation client : </strong>{p.paymentCF.authorizationNumber}<br />
                <strong>Code d'utilisation : </strong>{p.paymentCF.usageCode}<br />
              </>
            }}>
              <Column title="Participant" render={payment => payment.registration && <TableLink.User user={payment.registration.user} />} />
              <Column title="Formation" render={payment => payment.registration && <TableLink.Training training={payment.registration.training} />} />
              <Column title="Présence" render={payment => <PresenceRate registration={payment.registration} />} />
              <Column title="Nb. Chèques" dataIndex={['paymentCF', 'nbChecks']} />
              <Column title="Validité" render={payment => isoDateToDateShort(payment.paymentCF.validityDate)} />
              <Column title="Montant" render={payment => nfEur(payment.registration.price)} />
              <Column title="No. remise" dataIndex={['paymentCF', 'remiseNumber']} />
              <Column title="Date remise" render={payment => isoDateToDateShort(payment.paymentCF.remiseDate)} />
              <Column title="Statut" render={payment => <PaymentStatus payment={payment} />} />
              <Column title="Actions" width={40} className="actions-column" render={payment =>
                <TableButton
                  icon="copy"
                  confirmMessage="Êtes-vous sûr ?"
                  tooltip="Dupliquer ce paiement"
                  disabled={payment.paymentCF.status !== 'payment-rejected'}
                  onClick={e => duplicate(payment)}
                />
              } />
            </Table>
          </Card>
        </Tabs.TabPane>

      </Tabs>

      <SodexoInvoiceModal
        isVisible={isSodexoInvoiceModalVisible}
        onOK={invoice}
        onCancel={() => setIsSodexoInvoiceModalVisible(false)}
      />

      <SodexoRefusedModal
        isVisible={isSodexoRefusedModalVisible}
        onOK={paymentRejected}
        onCancel={() => setIsSodexoRefusedModalVisible(false)}
      />

      <SodexoPaidModal
        isVisible={isSodexoPaidModalVisible}
        onOK={paymentReceived}
        onCancel={() => setIsSodexoPaidModalVisible(false)}
      />
    </>
  )

}

export default SodexoInvoicing