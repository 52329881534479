import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import api from 'services/adminApiService'
import Icon from 'components/Icon'
import TableLink from 'components/TableLink'
import { isoDateToDateShort } from 'misc/utils'

import { Card, Input, PageHeader, Table } from 'antd'

const { Column } = Table



const Participants = () => {

  const [participants, setParticipants] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    defaultPageSize: 20,
    position: ['bottomRight', 'topRight'],
    total: 0,
    showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} éléments`,
    showSizeChanger: true
  })
  const [search, setSearch] = useState()

  const history = useHistory()

  const loadParticipants = (pageSize, current) => {
    api.users.get({ loader: setLoading, params: { results: pageSize, page: current, search, type: 'participant' } }).then(result => {
      setParticipants(result.data)
      setPagination({ ...pagination, pageSize, current, total: result.totalCount })
    }).catch(console.error)
  }

  useEffect(() => {
    loadParticipants(pagination.pageSize, 1)
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  const onRowHandlers = (record, rowIndex) => ({
    onClick: e => history.push(`/compta/user/${record.id}`)
  })

  const onTableChange = (pagination, filters, sorter) => {
    loadParticipants(pagination.pageSize, pagination.current)
  }

  return (
    <>
      <PageHeader title={<><Icon icon="users-class" fixedWidth /> Participants</>} />
      <Card>
        <Input.Search
          placeholder="Rechercher..."
          onSearch={setSearch}
          style={{ maxWidth: 250, position: 'absolute', top: 32, zIndex: 10 }}
          enterButton
          allowClear
        />
        <Table
          dataSource={participants}
          loading={loading}
          onRow={onRowHandlers}
          rowKey="id"
          rowClassName="row-clickable"
          size="small"
          pagination={pagination}
          onChange={onTableChange}
          style={{ marginTop: participants?.length ? 0 : 56 }}
        >
          <Column title="N° client" dataIndex="clientNumber" />
          <Column title="N° client compta" dataIndex="invoicingClientNumber" />
          <Column title="Nom" render={user => `${user.lastname} ${user.firstname}`} />
          <Column title="Dernière inscription" render={user =>
            <>{isoDateToDateShort(user.registrations[0].date)} (<TableLink.Training training={user.registrations[0].training} hideDate />)</>
          } />
        </Table>
      </Card>
    </>
  )

}

export default Participants