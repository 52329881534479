import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import api from 'services/adminApiService'
import Icon from 'components/Icon'
import SitesCard from './SitesCard'
import SessionsCard from './SessionsCard'
import RichTextEditor from 'components/RichTextEditor'
import { serialize, deserialize, EMPTY_VALUE } from 'misc/slateSerialize'
import { SelectsContext } from 'contexts/SelectsContext'
import FilesCard from './FilesCard'

import { Button, Card, Form, Input, Switch, message, InputNumber, Row, Col, Divider, TreeSelect, Select } from 'antd'



const Info = ({ training, setTraining, refreshTraining }) => {

  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()

  const history = useHistory()

  const { categories, partners } = useContext(SelectsContext)

  // Put form values when refreshing training
  useEffect(() => {
    form.setFieldsValue({
      ...training,
      categories: training?.categories?.map(c => c.id) || [],
      partners: training?.partners?.map(p => p.id) || [],
      content: training ? deserialize(training.content) : EMPTY_VALUE,
      description: training ? deserialize(training.description) : EMPTY_VALUE
    })
  }, [training, form])

  const saveTraining = values => {
    values.content = serialize(values.content)
    values.description = serialize(values.description)
    if (training?.id) {
      api.trainings.put(training.id, values, { loader: setSaving }).then(data => {
        setTraining(data)
        message.success("Modifications sauvegardées.")
      }).catch(console.error)
    } else {
      values.parentId = training?.parentId
      api.trainings.post(values, { loader: setSaving }).then(data => {
        setTraining(data)
        message.success("Formation ajoutée.")
        history.push(`/admin/training/${data.id}`)
      }).catch(console.error)
    }
  }

  return (
    <>
      <Row gutter={16}>

        <Col span={training?.sites && training?.trainers ? 16 : 24}>
          <Card title={<><Icon icon="info-circle" /> Informations générales</>}>
            <Form form={form} layout="vertical" onFinish={saveTraining}>
              <Row justify="space-around">
                <Col span={11}>
                  <Form.Item label="Code" name="code" rules={[{ required: true, message: 'Veuillez entrer un code.' }]}>
                    <Input placeholder="Code" />
                  </Form.Item>
                  <Form.Item label="Nom" name="name" rules={[{ required: true, message: 'Veuillez entrer un nom.' }]}>
                    <Input placeholder="Nom" />
                  </Form.Item>
                  <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Veuillez entrer une description.' }]}>
                    <RichTextEditor />
                  </Form.Item>
                  <Form.Item label="Contenu" name="content" rules={[{ required: true, message: 'Veuillez entrer un contenu.' }]}>
                    <RichTextEditor />
                  </Form.Item>
                  <Form.Item label="Durée" name="duration" rules={[{ required: true, message: 'Veuillez entrer la durée.' }]}>
                    <Input placeholder="Durée affichée sur le site." />
                  </Form.Item>
                  <Form.Item label="Nombre d'heures" className="horizontal-12" name="hours" rules={[{ required: true, message: 'Veuillez entrer un nombre d\'heure.' }]}>
                    <InputNumber min={1} />
                  </Form.Item>
                  <Form.Item label="Participants min." className="horizontal-12" name="minParticipants" rules={[{ required: true, message: 'Veuillez entrer un nombre minimum de participant.' }]}>
                    <InputNumber min={1} />
                  </Form.Item>
                  <Form.Item label="Participants max." className="horizontal-12" name="maxParticipants" rules={[{ required: true, message: 'Veuillez entrer un nombre maximum de participant.' }]}>
                    <InputNumber min={1} />
                  </Form.Item>
                </Col>
                <Col><Divider type="vertical" style={{ height: '100%' }} /></Col>
                <Col span={11}>
                  <Form.Item label="Nouveau" className="horizontal-12" name="isNew" valuePropName="checked" initialValue={true}>
                    <Switch checkedChildren="Oui" unCheckedChildren="Non" />
                  </Form.Item>
                  <Form.Item label="Certification" className="horizontal-12" name="isCertifying" valuePropName="checked">
                    <Switch checkedChildren="Oui" unCheckedChildren="Non" />
                  </Form.Item>
                  <Form.Item label="Agréé CF" className="horizontal-12" name="isCFAccepted" valuePropName="checked">
                    <Switch checkedChildren="Oui" unCheckedChildren="Non" />
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate={(prev, curr) => prev.isCFAccepted !== curr.isCFAccepted}>
                    {({ getFieldValue }) => getFieldValue('isCFAccepted') && <>
                      <Form.Item label="Heures CF" className="horizontal-12" name="CFHours" rules={[{ required: true, message: 'Veuillez entrer les heures CF.' }]}>
                        <InputNumber min={1} />
                      </Form.Item>
                      <Form.Item label="Numéro d'agrément" className="horizontal-12" name="CFApprovalNumber" rules={[
                        { pattern: /^\d{4}-\d{4}-\d{4}$/, message: 'Le numéro d\'agrément doit être au format 0000-0000-0000' }
                      ]}>
                        <Input />
                      </Form.Item>
                    </>
                    }
                  </Form.Item>
                  <Form.Item label="Agréé CEP" className="horizontal-12" name="isCEP" valuePropName="checked">
                    <Switch checkedChildren="Oui" unCheckedChildren="Non" />
                  </Form.Item>
                  <Form.Item label="Agréé PQK" className="horizontal-12" name="isPQK" valuePropName="checked">
                    <Switch checkedChildren="Oui" unCheckedChildren="Non" />
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate={(prev, curr) => prev.isPQK !== curr.isPQK}>
                    {({ getFieldValue }) => getFieldValue('isPQK') &&
                      <Form.Item label="Points PQK" className="horizontal-12" name="PQKPoints" rules={[{ required: true, message: 'Veuillez entrer les points PQK.' }]}>
                        <InputNumber min={1} />
                      </Form.Item>
                    }
                  </Form.Item>
                  <Form.Item label="Conditions d'inscription" name="conditions">
                    <Input.TextArea placeholder="Conditions d'inscription" autoSize={{ minRows: 3 }} />
                  </Form.Item>
                  <Form.Item label="Matériel" name="requirements">
                    <Input.TextArea placeholder="Matériel" autoSize={{ minRows: 3 }} />
                  </Form.Item>
                  <Form.Item label="Catégories" name="categories">
                    <TreeSelect
                      showSearch
                      allowClear
                      multiple
                      treeDefaultExpandAll
                      treeData={categories.map(c => ({ value: c.id, title: c.name, children: c.children.map(ch => ({ value: ch.id, title: ch.name })) }))}
                    />
                  </Form.Item>
                  <Form.Item label="Partenaires" name="partners">
                    <Select
                      showSearch
                      allowClear
                      mode="multiple"
                      options={partners.map(p => ({ label: p.name, value: p.id }))}
                    />
                  </Form.Item>
                  <Form.Item label={<span>Code FLink <Icon.Info tooltip="Nom abrégé du cours dans FLink" /></span>} name="flinkID">
                    <Input placeholder="Code du cours FLink" />
                  </Form.Item>
                  <Form.Item label="Acompte" className="horizontal-12" name="deposit" rules={[{ required: true, message: "Veuillez entrer le montant de l'acompte" }]}>
                    <InputNumber min={0} precision={2} style={{ width: 100 }} className="input-number-euro" />
                  </Form.Item>
                  <Form.Item label="Prix général" className="horizontal-12" name="price" rules={[{ required: true, message: 'Veuillez entrer le prix général (grand public)' }]}>
                    <InputNumber min={0} precision={2} style={{ width: 100 }} className="input-number-euro" />
                  </Form.Item>
                  <Form.Item label="Prix réduit" className="horizontal-12" name="reducedPrice" rules={[{ required: true, message: 'Veuillez entrer le prix réduit (Vinci et partenaires)' }]}>
                    <InputNumber min={0} precision={2} style={{ width: 100 }} className="input-number-euro" />
                  </Form.Item>
                  <Form.Item label={<span>Conditions prix réduit <Icon.Info tooltip='Les conditions "Vinci" sont affichées par défaut lors de l&apos;inscription.' /></span>} name="reducedPriceConditions">
                    <Input.TextArea placeholder="Conditions d'octroi du prix réduit (partenaires)." autoSize={{ minRows: 3 }} />
                  </Form.Item>
                  <Form.Item label={<span>Lien d'inscription spécifique <Icon.Info tooltip="A ne remplir que si les inscription sont gérées par un partenaire." /></span>} name="registrationURL">
                    <Input placeholder="Lien d'inscription" />
                  </Form.Item>
                </Col>
              </Row>
              <p></p>
              <Form.Item>
                <Button type="primary" htmlType="submit" icon={<Icon icon="save" />} loading={saving} style={{ marginRight: 10 }}>Sauvegarder</Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>

        {training?.sites && training?.trainers &&
          <Col span={8}>
            <SitesCard training={training} refreshTraining={refreshTraining} />
            <SessionsCard training={training} style={{ marginTop: 16 }} />
            <FilesCard training={training} style={{ marginTop: 16 }} />
          </Col>
        }

      </Row>

    </>
  )

}

export default Info