import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { cloneDeep } from 'lodash'

import Icon from 'components/Icon'
import api from 'services/adminApiService'

import { Button, Card, Checkbox, Col, Form, Input, List, Modal, PageHeader, Row, Spin, message } from 'antd'



const Site = () => {

  // Site states (site data, loading the site, saving the site data, form)
  const [site, setSite] = useState()
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()

  // Classroom states (selection to delete, new classroom form, currently editing classroom)
  const [classroomSelection, setClassroomSelection] = useState([])
  const [newClassroom, setNewClassroom] = useState("")
  const [editingClassroom, setEditingClassroom] = useState()

  // Current site, if null it's a new one
  const { id } = useParams()

  const history = useHistory()

  const loadSite = (resetForm = true) => {
    if (id) {
      api.sites.getById(id, { loader: setLoading }).then(data => {
        setSite(data)
        if (resetForm) form.setFieldsValue(data)
      }).catch(console.error)
    }
  }

  useEffect(loadSite, [id, form])

  const saveSite = values => {
    if (id) {
      api.sites.put(id, values, { loader: setSaving }).then(data => {
        setSite(data)
        message.success("Modifications sauvegardées.")
      }).catch(console.error)
    } else {
      api.sites.post(values, { loader: setSaving }).then(data => {
        setSite(data)
        message.success("Site ajouté.")
        history.push(`/admin/site/${data.id}`)
      }).catch(console.error)
    }
  }

  const deleteSite = () => {
    Modal.confirm({
      title: "Êtes-vous sûr ?",
      content: "Si le site est utilisé pour une formation, le supprimer ne l'enlèvera pas de la formation. Il ne sera par contre plus proposé pour les formations futures. Il en va de même pour les locaux associés au site supprimé.",
      okText: "Supprimer",
      okButtonProps: { danger: true },
      onOk: () => {
        api.sites.delete(id, { loader: setSaving }).then(() => {
          message.success("Site supprimé.")
          history.push("/admin/sites")
        }).catch(console.error)
      }
    })
  }

  const deleteClassrooms = () => {
    Modal.confirm({
      title: "Êtes-vous sûr ?",
      content: "Si un local est utilisé dans un planning, le supprimer ne l'enlèvera pas du planning. Il ne sera par contre plus proposé pour les plannings des formations futures.",
      okText: "Supprimer",
      okButtonProps: { danger: true },
      onOk: () => {
        api.classrooms.call({ method: 'DELETE', body: classroomSelection }).then(() => {
          message.success("Locaux supprimés.")
          loadSite(false)
        }).catch(console.error)
      }
    })
  }

  const addNewClassroom = () => {
    api.classrooms.post({ name: newClassroom, siteId: site.id }).then(data => {
      message.success("Local ajouté.")
      loadSite(false)
      setNewClassroom("")
    }).catch(console.error)
  }

  const saveClassroom = () => {
    api.classrooms.put(editingClassroom.id, { name: editingClassroom.name }, { loader: setSaving }).then(data => {
      message.success("Modifications sauvegardées.")
      setEditingClassroom(null)
      loadSite(false)
    }).catch(console.error)
  }

  const onClassroomNameChange = e => {
    const classroom = cloneDeep(editingClassroom)
    classroom.name = e.target.value
    setEditingClassroom(classroom)
  }

  return (
    <Spin delay={500} spinning={loading}>

      <PageHeader
        title={<><Icon icon="map-marker-alt" fixedWidth /> {id ? `Lieu de formation : ${site ? site.name : ''}` : "Nouveau lieu de formation"}</>}
        extra={[
          id && <Button key="delete" danger icon={<Icon icon="trash" />} loading={saving} onClick={deleteSite}>Supprimer le lieu</Button>
        ]}
      />

      <Row gutter={16}>

        <Col span={16}>
          <Card title={<><Icon icon="info-circle" fixedWidth /> Informations</>}>
            <Form form={form} layout="vertical" onFinish={saveSite}>
              <Form.Item label="Nom" name="name" rules={[{ required: true, message: 'Veuillez entrer un nom.' }]}>
                <Input placeholder="Nom" />
              </Form.Item>
              <Form.Item label="Description" name="description">
                <Input.TextArea placeholder="Description" autoSize={{ minRows: 3 }} />
              </Form.Item>
              <Form.Item label="Adresse" required>
                <Row gutter={6} style={{ marginBottom: 6 }}>
                  <Col flex="4">
                    <Form.Item name="street" noStyle rules={[{ required: true, message: "Veuillez entrer la rue" }]}>
                      <Input placeholder="Rue" />
                    </Form.Item>
                  </Col>
                  <Col flex="1">
                    <Form.Item name="number" noStyle rules={[{ required: true, message: "Veuillez entrer le numéro" }]}>
                      <Input placeholder="Numéro" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={6} style={{ marginBottom: 6 }}>
                  <Col flex="2">
                    <Form.Item name="zip" noStyle rules={[{ required: true, message: "Veuillez entrer le code postal" }]}>
                      <Input placeholder="Code postal" />
                    </Form.Item>
                  </Col>
                  <Col flex="3">
                    <Form.Item name="city" noStyle rules={[{ required: true, message: "Veuillez entrer la ville" }]}>
                      <Input placeholder="Ville" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col flex="auto">
                    <Form.Item name="country" noStyle rules={[{ required: true, message: "Veuillez entrer le pays" }]}>
                      <Input placeholder="Pays" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Notes" name="notes">
                <Input.TextArea placeholder="Notes" autoSize={{ minRows: 3 }} />
              </Form.Item>
              <Form.Item label="Lien web" name="url">
                <Input placeholder="Url" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" icon={<Icon icon="save" />} loading={saving} style={{ marginRight: 10 }}>Sauvegarder</Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>

        <Col span={8}>
          <Card title={<><Icon icon="chalkboard" fixedWidth /> Locaux</>}>
            <Checkbox.Group style={{ width: '100%' }} value={classroomSelection} onChange={setClassroomSelection}>
              <List dataSource={site ? site.classrooms : []} size="small" renderItem={item => (
                <List.Item key={item.id} actions={editingClassroom?.id === item.id ? [
                  <Button key="save" shape="circle" icon={<Icon icon="check" />} size="small" className="button-success" onClick={saveClassroom} loading={saving} />,
                  <Button key="cancel" shape="circle" icon={<Icon icon="times" />} danger size="small" onClick={() => setEditingClassroom(null)} loading={saving} />
                ] : [
                  <Button key="edit" shape="circle" icon={<Icon icon="pencil" />} size="small" onClick={() => setEditingClassroom(cloneDeep(item))} />
                ]}>
                  <Checkbox value={item.id} style={{ marginRight: 15 }} />
                  {editingClassroom?.id === item.id ? <Input size="small" defaultValue={editingClassroom.name} onChange={onClassroomNameChange} /> : item.name}
                </List.Item>
              )} />
            </Checkbox.Group>
            <Row justify="space-between" style={{ marginTop: 15 }}>
              <Col>
                <Input.Group compact>
                  <Input placeholder="Ajouter un local" style={{ maxWidth: 150 }} value={newClassroom} onChange={e => setNewClassroom(e.target.value)} disabled={!id} />
                  <Button icon={<Icon icon="plus" />} type="primary" onClick={addNewClassroom} disabled={!id} loading={saving} />
                </Input.Group>
              </Col>
              <Col><Button icon={<Icon icon="trash" />} danger disabled={!classroomSelection.length} onClick={deleteClassrooms} loading={saving}>Supprimer</Button></Col>
            </Row>
          </Card>
        </Col>

      </Row>

    </Spin>
  )

}

export default Site