import React from 'react'

import { Link } from 'react-router-dom'

import ModalLayout from 'components/ModalLayout/ModalLayout'
import Icon from 'components/Icon'

import { Button, Result } from 'antd'



const Error500 = () => {

  return (
    <ModalLayout>
      <Result
        title="Oups !"
        subTitle={<><p>Quelque-chose s'est mal passé.</p><p>Si le problème persiste, prennez contact avec le Centre de Formation Continue.</p></>}
        status="500"
        style={{ paddingTop: 20 }}
        icon={<Icon icon="check-circle" />}
        extra={<Link to="/login"><Button type="primary" icon={<Icon icon="lock" />}>Aller à l'écran de connexion</Button></Link>} />
    </ModalLayout>
  )
}

export default Error500