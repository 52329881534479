import React, { useEffect, useState } from 'react'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import TableLink from 'components/TableLink'
import { isoDateToDateTime } from 'misc/utils'
import TableButton from 'components/TableButton'
import DraggableModal from 'components/DraggableModal'

import { Alert, Card, DatePicker, Form, Input, InputNumber, PageHeader, Table } from 'antd'
import { RegistrationStatus } from 'components/Tags'


const { Column } = Table


const ChecksToBlock = () => {

  const [payments, setPayments] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const loadPayments = () => {
    api.payments.get({ loader: setIsLoading, params: { type: 'checksToBlock' } }).then(setPayments).catch(console.error)
  }

  useEffect(loadPayments, [])

  /**
   * Accept modal
   */
  const [paymentToAccept, setPaymentToAccept] = useState(null)
  const [acceptForm] = Form.useForm()
  const hideAcceptModal = () => {
    setPaymentToAccept(null)
    acceptForm.resetFields()
  }
  const acceptPayment = values => {
    api.post(`/payments/${paymentToAccept.id}/paymentCF/accepted`, values, { loader: setIsLoading }).then(() => {
      loadPayments()
      setPaymentToAccept(null)
    }).catch(console.error)
  }

  /**
   * Reject modal
   */
  const [paymentToReject, setPaymentToReject] = useState(null)
  const [rejectForm] = Form.useForm()
  const hideRejectModal = () => {
    setPaymentToReject(null)
    rejectForm.resetFields()
  }
  const rejectPayment = values => {
    api.post(`/payments/${paymentToReject.id}/paymentCF/rejected`, values, { loader: setIsLoading }).then(() => {
      loadPayments()
      setPaymentToReject(null)
    }).catch(console.error)
  }

  /**
   * JSX
   */
  return (
    <>
      <PageHeader title={<><Icon icon="shield-check" fixedWidth /> Chèques formation à bloquer</>} />
      <Card>
        <Table
          dataSource={payments}
          loading={isLoading}
          rowKey="id"
          size="small"
          pagination={{ pageSize: 20 }}
        >
          <Column title="Date" render={payment => isoDateToDateTime(payment.date)} />
          <Column title="Participant" render={payment => <TableLink.User user={payment.registration.user} />} />
          <Column title="Formation" render={payment => <TableLink.Training training={payment.registration.training} />} />
          <Column title="Status d'inscription" render={payment => <RegistrationStatus registration={payment.registration} />} />
          <Column title="Heures CF" render={payment => payment.registration.training.CFHours} />
          <Column title="Numéro d'autorisation client" dataIndex={['paymentCF', 'authorizationNumber']} />
          <Column title="Code d'utilisation" dataIndex={['paymentCF', 'usageCode']} />
          <Column title="Actions" width={100} className="actions-column" render={item => <>
            <TableButton icon="check" success onClick={() => setPaymentToAccept(item)} />
            <TableButton icon="times" danger onClick={e => setPaymentToReject(item)} />
          </>} />
        </Table>
      </Card>

      <DraggableModal
        title={<><Icon icon="check" />Accepter le paiement (chèques formation)</>}
        open={paymentToAccept}
        onOk={acceptForm.submit}
        okButtonProps={{ loading: isLoading }}
        onCancel={hideAcceptModal}
      >
        <Form form={acceptForm} onFinish={acceptPayment} layout="horizontal">
          <Form.Item name="validityDate" label="Date de validité" rules={[{ required: true }]}>
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
          <Form.Item name="nbChecks" label="Nombre de chèques" rules={[{ required: true }]}>
            <InputNumber min={1} step={1} />
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, curr) => prev.nbChecks !== curr.nbChecks}>
            {({ getFieldValue }) => getFieldValue('nbChecks') > paymentToAccept?.registration.training.CFHours
              ? <Alert
                type="info"
                showIcon
                message={`La formation n'accepte que ${paymentToAccept?.registration.training.CFHours} chèques. Vous pouvez en encoder plus, mais le plafond de facturation sera borné au nombre maximum de chèques.`}
                style={{ marginBottom: 30 }}
              />
              : <></>
            }
          </Form.Item>
        </Form>
      </DraggableModal>

      <DraggableModal
        title={<><Icon icon="times" />Refuser le paiement (chèques formation)</>}
        open={paymentToReject}
        onOk={rejectForm.submit}
        okButtonProps={{ loading: isLoading }}
        onCancel={hideRejectModal}
      >
        <Form form={rejectForm} onFinish={rejectPayment} layout="vertical">
          <Form.Item name="note" label="Raison du refus" rules={[{ required: true }]}>
            <Input.TextArea placeholder="Raison du refus, mentionnée dans le portail du participant, et envoyée par mail." autoSize={{ minRows: 3 }} />
          </Form.Item>
        </Form>
      </DraggableModal>
    </>
  )
}

export default ChecksToBlock