import React, { useEffect, useState } from 'react'

import './Trainings.less'

import { Link, useHistory, useParams } from 'react-router-dom'
import { mapValues, isArray } from 'lodash'

import Icon from 'components/Icon'
import { RegistrationsStatus, TrainingStates, TrainingStatus, TrainingVisibility } from 'components/Tags'
import api from 'services/adminApiService'
import { isoDateToDateShort } from 'misc/utils'
import Badges from 'components/Badges'
import TableLink from 'components/TableLink'

import { Button, Card, Checkbox, Drawer, Form, Input, PageHeader, Switch, Table, Tabs, Tag, Tooltip, Typography } from 'antd'

const { Column } = Table
const { Text } = Typography



const filterTitles = {
  fli: <Tooltip title="Encodage de l'ID FLink" >FLi</Tooltip>,
  mat: <Tooltip title="Matériel préparé" >Mat</Tooltip>,
  pop: <Tooltip title="Participans en ordre de paiement" >POP</Tooltip>,
  cfb: <Tooltip title="Chèques formation bloqués" >CFB</Tooltip>,
  rdi: <Tooltip title="Reconnaissances de dette imprimées" >RDI</Tooltip>,
  lpi: <Tooltip title="Liste de présence imprimée" >LPI</Tooltip>,
  rla: <Tooltip title="remboursement des participants en liste d'attente" >RLA</Tooltip>,
  abs: <Tooltip title="Encodage des absences" >Abs</Tooltip>,
  fac: <Tooltip title="Envoi des factures" >Fac</Tooltip>,
  fso: <Tooltip title="Facturation Sodexo" >FSo</Tooltip>,
  ats: <Tooltip title="Envoi des attestations de suivi (auto)" >AtS</Tooltip>,
  pfo: <Tooltip title="Paiement des formateurs" >PFo</Tooltip>,
  pqk: <Tooltip title="Encodate des points PQK" >PQK</Tooltip>,
}

const BooleanFilter = ({ name, label, na = true }) => {
  const options = [{ label: 'Fait', value: 'true' }, { label: 'Pas fait', value: 'false' }]
  if (na) options.push({ label: 'N/A', value: 'null' })
  return <Form.Item name={name} label={label}>
    <Checkbox.Group options={options} />
  </Form.Item>
}


const TrainingsTable = ({ type }) => {

  const [trainings, setTrainings] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    defaultPageSize: 20,
    position: ['bottomRight', 'topRight'],
    total: 0,
    showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} éléments`,
    showSizeChanger: true
  })
  const [search, setSearch] = useState(null)
  const [tasksView, setTasksView] = useState(false)
  const [isFilterDrawerVisible, setIsFilterDrawerVisible] = useState(false)
  const [filters, setFilters] = useState({})

  const history = useHistory()

  const loadTrainings = (pageSize, current) => {
    api.trainings.get({ loader: setLoading, params: { results: pageSize, page: current, search, filters: JSON.stringify(filters), type } }).then(result => {
      setTrainings(result.data)
      setPagination({ ...pagination, pageSize, current, total: result.totalCount })
    }).catch(console.error)
  }

  useEffect(() => {
    loadTrainings(pagination.pageSize, 1)
  }, [search, filters]) // eslint-disable-line react-hooks/exhaustive-deps

  const onRowHandlers = (record, rowIndex) => ({
    onClick: e => history.push(`/admin/training/${record.id}`)
  })

  const onTableChange = (pagination, filters, sorter) => {
    loadTrainings(pagination.pageSize, pagination.current)
  }

  const onFilter = (changes, values) => {
    setFilters(mapValues(values, v => {
      if (isArray(v)) {
        return v.map(b => {
          switch (b) {
            case 'true': return true
            case 'false': return false
            case 'null': return null
            default: return b
          }
        })
      }
    }))
  }

  const onResetFilters = () => {
    form.resetFields()
    setFilters({})
  }

  const [form] = Form.useForm()

  return (
    <>
      <div style={{ position: 'absolute', top: 32, zIndex: 10, display: 'flex', alignItems: 'center' }}>
        <Input.Search
          placeholder="Rechercher..."
          onSearch={setSearch}
          style={{ maxWidth: 250 }}
          enterButton
          allowClear
        />
        <span style={{ marginLeft: 25 }}>Vue des tâches</span>
        <Switch checked={tasksView} onChange={setTasksView} style={{ marginLeft: 10 }} checkedChildren={<Icon icon="check" fixedWidth />} unCheckedChildren={<Icon icon="times" fixedWidth />} />
        <Button style={{ marginLeft: 25 }} icon={<Icon icon="filter" />} onClick={() => setIsFilterDrawerVisible(true)}>Filtres</Button>
      </div>
      <Table
        dataSource={trainings}
        loading={loading}
        onRow={onRowHandlers}
        rowKey="id"
        rowClassName="row-clickable"
        size="small"
        pagination={pagination}
        onChange={onTableChange}
        style={{ marginTop: trainings?.length ? 0 : 56 }}
      >
        <Column title="Code" render={t => <TableLink.Training training={t} hideDate newTab />} />
        {tasksView || <Column title="Nom" dataIndex="name" render={name => <Text ellipsis={{ tooltip: true }} style={{ maxWidth: 200 }}>{name}</Text>} />}
        <Column title="Date (début/fin)" render={item => item.start ? isoDateToDateShort(item.start) + ' - ' + isoDateToDateShort(item.end) : '-'} />
        {tasksView || <Column title="Lieu(x)" render={item => item.sites?.map(s => s.site.name).join(', ')} />}
        {tasksView && <>
          <Column title={filterTitles.fli} render={t => <Icon.Boolean boolean={!!t.flinkID} />} />
          <Column title={filterTitles.mat} render={t => <Icon.Boolean boolean={t.isMaterialPrepared} />} />
          <Column title={filterTitles.pop} render={t => <Icon.Boolean boolean={t.isParticipantsBalanceOK} />} />
          <Column title={filterTitles.cfb} render={t => <Icon.Boolean boolean={t.isPaymentsCFBlocked} />} />
          <Column title={filterTitles.rdi} render={t => <Icon.Boolean boolean={t.isAckOfDeptPrinted} />} />
          <Column title={filterTitles.lpi} render={t => <Icon.Boolean boolean={t.isTimesheetPrinted} />} />
        </>}
        {tasksView && type !== 'next' && <>
          <Column title={filterTitles.rla} render={t => <Icon.Boolean boolean={t.isWaitingListRefunded} />} />
          <Column title={filterTitles.abs} render={t => <Icon.Boolean boolean={t.isAbsencesEncoded} />} />
          <Column title={filterTitles.fso} render={t => <Icon.Boolean boolean={t.isSodexoInvoicingDone} />} />
          <Column title={filterTitles.ats} render={t => <Icon.Boolean boolean={t.isAttendanceCertSent} />} />
          <Column title={filterTitles.pfo} render={t => <Icon.Boolean boolean={t.isTrainersPaid} />} />
          <Column title={filterTitles.pqk} render={t => <Icon.Boolean boolean={t.isPQKEncoded} />} />
        </>}
        <Column title="Inscri." key="inscriptions" render={(record) => <Badges.Registrations training={record} />} />
        <Column title="États" render={training => <TrainingStates training={training} />} />
      </Table>
      <Drawer
        title={<><Icon icon="filter" fixedWidth /> Filtrer les formations</>}
        extra={<Button htmlType="button" onClick={onResetFilters} style={{ float: 'right' }}>Réinitialiser</Button>}
        placement="right"
        width={400}
        onClose={() => setIsFilterDrawerVisible(false)}
        visible={isFilterDrawerVisible}
        closable={false}
      >
        <Form form={form} onValuesChange={onFilter} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} className="filter-form">
          <h2>États</h2>
          <Form.Item name="isPublished" label="Pub. site">
            <Checkbox.Group className='vertical-checkbox-group' options={[
              { label: <TrainingVisibility training={{ isPublished: true }} />, value: 'true' },
              { label: <TrainingVisibility training={{ isPublished: false }} />, value: 'false' }
            ]} />
          </Form.Item>
          <Form.Item name="registrationsStatus" label="Inscr.">
            <Checkbox.Group className='vertical-checkbox-group' options={[
              { label: <RegistrationsStatus training={{ registrationsStatus: 'open' }} />, value: 'open' },
              { label: <RegistrationsStatus training={{ registrationsStatus: 'closed' }} />, value: 'closed' },
              { label: <Tag color="blue"><Icon icon="clock" fixedWidth /> Date spécifique</Tag>, value: 'date' }
            ]} />
          </Form.Item>
          <Form.Item name="status" label="Orga.">
            <Checkbox.Group className='vertical-checkbox-group' options={[
              { label: <TrainingStatus training={{ status: 'project' }} />, value: 'project' },
              { label: <TrainingStatus training={{ status: 'confirmed' }} />, value: 'confirmed' },
              { label: <TrainingStatus training={{ status: 'cancelled' }} />, value: 'cancelled' }
            ]} />
          </Form.Item>
          <h2 style={{ marginTop: 15 }}>Tâches</h2>
          <BooleanFilter name="flinkID" label={filterTitles.fli} na={false} />
          <BooleanFilter name="isMaterialPrepared" label={filterTitles.mat} />
          <BooleanFilter name="isParticipantsBalanceOK" label={filterTitles.pop} />
          <BooleanFilter name="isPaymentsCFBlocked" label={filterTitles.cfb} />
          <BooleanFilter name="isAckOfDeptPrinted" label={filterTitles.rdi} />
          <BooleanFilter name="isTimesheetPrinted" label={filterTitles.lpi} />
          <BooleanFilter name="isWaitingListRefunded" label={filterTitles.rla} />
          <BooleanFilter name="isAbsencesEncoded" label={filterTitles.abs} />
          <BooleanFilter name="isSodexoInvoicingDone" label={filterTitles.fso} />
          <BooleanFilter name="isAttendanceCertSent" label={filterTitles.ats} />
          <BooleanFilter name="isTrainersPaid" label={filterTitles.pfo} />
          <BooleanFilter name="isPQKEncoded" label={filterTitles.pqk} />
        </Form>
      </Drawer>
    </>
  )

}



const Trainings = () => {

  // @ts-ignore
  const { tab = 'current' } = useParams()

  const history = useHistory()

  return (
    <>
      <PageHeader
        title={<><Icon icon="book-open" fixedWidth /> Catalogue de formation</>}
        extra={[
          <Link to="/admin/training" key="1" ><Button icon={<Icon icon="plus" />}>Nouvelle formation</Button></Link>
        ]}
      />

      <Tabs defaultActiveKey="current" activeKey={tab} onTabClick={key => history.push(`/admin/trainings/${key}`)} destroyInactiveTabPane={true}>

        <Tabs.TabPane tab={<><Icon icon="history" />Formations passées</>} key="past">
          <Card>
            <TrainingsTable type="past" />
          </Card>
        </Tabs.TabPane>

        <Tabs.TabPane tab={<><Icon icon="calendar-check" />Formations en cours</>} key="current">
          <Card>
            <TrainingsTable type="current" />
          </Card>
        </Tabs.TabPane>

        <Tabs.TabPane tab={<><Icon icon="map-signs" />Formations à venir</>} key="next">
          <Card>
            <TrainingsTable type="next" />
          </Card>
        </Tabs.TabPane>

        <Tabs.TabPane tab={<><Icon icon="list" />Toutes les formations</>} key="all">
          <Card>
            <TrainingsTable type="all" />
          </Card>
        </Tabs.TabPane>

      </Tabs>
    </>
  )

}

export default Trainings