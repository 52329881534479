import React, { useContext, useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import { serialize, deserialize } from 'misc/slateSerialize'
import RichTextEditor from 'components/RichTextEditor'
import SelectsContext from 'contexts/SelectsContext'
import EmailTemplateFieldsCard from './EmailTemplateFieldsCard'

import { Button, Card, Col, Form, Input, PageHeader, Row, Spin, Popconfirm, message, Select } from 'antd'



const EmailTemplate = () => {

  // Email states (email data, loading the email, saving the email data, form)
  const [email, setEmail] = useState(null)
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()

  // Current email, if null it's a new one
  const { id } = useParams()

  const history = useHistory()

  const { signatures } = useContext(SelectsContext)

  const loadEmail = (resetForm = true) => {
    if (id) {
      api.emails.getById(id, { loader: setLoading }).then(data => {
        setEmail(data)
        const values = Object.assign({}, data)
        values.html = deserialize(values.html)
        if (resetForm) form.setFieldsValue(values)
      }).catch(console.error)
    }
  }

  useEffect(loadEmail, [id, form])

  const saveEmail = values => {
    values.html = serialize(values.html)
    if (id) {
      api.emails.put(id, values, { loader: setSaving }).then(data => {
        setEmail(data)
        message.success("Modifications sauvegardées.")
      }).catch(console.error)
    } else {
      api.emails.post(values, { loader: setSaving }).then(data => {
        setEmail(data)
        message.success("Template d'email ajouté.")
        history.push(`/admin/email-template/${data.id}`)
      }).catch(console.error)
    }
  }

  const deleteEmail = () => {
    api.emails.delete(id, { loader: setSaving }).then(() => {
      message.success("Template d'e-mail supprimé.")
      history.push("/admin/email-templates")
    }).catch(console.error)
  }

  const sendTestEmail = () => {
    const values = Object.assign({}, form.getFieldsValue())
    values.html = serialize(values.html)
    api.post('/emails/test', values, { loader: setSaving }).then(() => {
      message.success("Email de test envoyé.")
    }).catch(e => {
      setSaving(false)
      console.error(e)
    })
  }

  return (
    <Spin delay={500} spinning={loading}>

      <PageHeader
        title={<><Icon icon="envelope-open-text" fixedWidth /> {id ? `Template d'e-mail : ${email ? email.name : ''}` : "Nouveau template d'e-mail"}</>}
        subTitle={email ? email.code : ''}
        extra={[
          id && <Popconfirm key="delete" title="Êtes-vous sûr ?" onConfirm={deleteEmail} disabled={email?.code}>
            <Button danger icon={<Icon icon="trash" />} loading={saving} disabled={email?.code}>Supprimer l'email</Button>
          </Popconfirm>
        ]}
      />

      <Row gutter={16}>

        <Col span={16}>
          <Card title={<><Icon icon="info-circle" fixedWidth /> Informations</>}>
            <Form form={form} layout="vertical" onFinish={saveEmail}>
              <Form.Item label="Nom" name="name" rules={[{ required: true }]}>
                <Input placeholder="Nom" />
              </Form.Item>
              <Form.Item label="Signature" name="signatureId" rules={[{ required: true }]}>
                <Select options={signatures?.map(item => ({ label: item.description, value: item.id }))} style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item label="Objet" name="subject" rules={[{ required: true }]}>
                <Input placeholder="Objet" />
              </Form.Item>
              <Form.Item label="Contenu" name="html" rules={[{ required: true }]}>
                <RichTextEditor />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" icon={<Icon icon="save" />} loading={saving} style={{ marginRight: 10 }}>Sauvegarder</Button>
                <Button icon={<Icon icon="paper-plane" />} loading={saving} onClick={sendTestEmail}>M'envoyer un mail de test</Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>

        <Col span={8}>
          <EmailTemplateFieldsCard email={email} />
        </Col>

      </Row>

    </Spin>
  )

}

export default EmailTemplate