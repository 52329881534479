import React, { useEffect, useState } from 'react'

import './Registration.less'

import { useParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { map, uniqBy } from 'lodash'

import Icon from 'components/Icon'
import { address, isoDateToDateShort, nfEur } from 'misc/utils'
import api from 'services/userApiService'
import { FLinkUserAccessStatus, PaymentMethod, PaymentStatus, PriceStatus, PublicRegistrationStatus } from 'components/Tags'
import PaymentModal from './PaymentModal'
import DocumentStatus from './DocumentStatus'

import { Card, Col, Table, Row, Spin, Descriptions, Alert, PageHeader, Button, Modal } from 'antd'

const { Column } = Table



const Registration = () => {

  const { id } = useParams()

  const [registration, setRegistration] = useState(null)
  const [isLoading, setIsLoading] = useState(null)

  const loadRegistration = () => {
    api.getById('/registrations', id, { loader: setIsLoading }).then(data => {
      data.actions.forEach(a => a.data = JSON.parse(a.data))
      setRegistration(data)
    }).catch(console.error)
  }
  useEffect(loadRegistration, [id])

  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false)

  const showCancelModal = () => {
    Modal.confirm({
      title: `Désistement de ${registration.training.code}`,
      content: (
        <div>
          <p>Souhaitez-vous vraiment vous désister ?</p>
          <p>Nous vous rappelons que :</p>
          <ul>
            <li>pour un désistement moins de 3 semaines avant le début de la formation, aucun remboursement ne sera effectué</li>
            <li>pour un désistement entre 3 et 6 semaines avant le début de la formation, l'accompte n'est pas remboursé</li>
            <li>pour un désistement plus de 6 semaines avant le début de la formation, 25€ de frais administratifs ne seront pas remboursés.</li>
          </ul>
          <p>Dans le cas où vous souhaitez vraiment vous désister, envoyez un mail à <a href="mailto:formation.continue@vinci.be">formation.continue@vinci.be</a> avec votre nom, le code de la formation, ainsi que la raison du désistement.</p>
        </div>
      ),
      okButtonProps: { icon: <Icon icon="check" />, danger: true }
    })
  }

  if (!registration || isLoading) {
    return <Spin size="large" />
  }

  const paymentLimit = DateTime.fromISO(registration.training.start).minus({ days: 20 })

  let alerts = []
  // Show alert when registrations open, deposit not paid, and in waiting list (go pay to validate registration)
  if (registration.training.registrationsStatus === 'open'
    && !registration.isDepositPaid
    && registration.status === 'waiting-list'
    && registration.training.status !== 'cancelled') {
    alerts.push(<Alert key="1" type="warning" style={{ marginTop: 10 }} showIcon message="Votre inscription n'est pas encore validée, car vous n'avez pas payé l'accompte. Votre place ne sera réservée qu'une fois l'accompte reçu." />)
  }
  // Show alert when registration confirmed, but balance not OK and training will start soon (20days before) (go pay or cannot go to training)
  if (registration.balance < 0
    && registration.status === 'validated'
    && paymentLimit <= DateTime.now()
    && registration.training.status !== 'cancelled') {
    alerts.push(<Alert key="2" type="warning" style={{ marginTop: 10 }} showIcon message="Il semblerait que nous n'ayons pas encore reçu le paiement du solde. Sans cela, vous ne pourrez pas participer à la formation." />)
  }
  // Show alert when balance not OK and training will not start soon (more than 20days before) (go pay or cannot go to training)
  if (registration.balance < 0
    && registration.status === 'validated'
    && paymentLimit > DateTime.now()
    && registration.training.status !== 'cancelled') {
    alerts.push(<Alert key="3" type="info" style={{ marginTop: 10 }} showIcon message={`N'oubliez pas d'effectuer le paiement du solde au plus tard le ${paymentLimit.toFormat("dd/MM/yy")}`} />)
  }
  // Show alert with practical infos
  if (registration.status === 'validated'
    && DateTime.now().plus({ days: 20 }) > DateTime.fromISO(registration.training.start)
    && DateTime.now() < DateTime.fromISO(registration.training.end)
    && registration?.training?.sites?.length) {
    alerts.push(<Alert
      key="4"
      type="info"
      style={{ marginTop: 10 }}
      showIcon
      description="Toutes les informations pratiques pour votre formation sont consultables en cliquant sur les icônes des lieux de formation."
      message={`Informations pratiques`}
      closable
    />)
  }

  const documents = registration.actions.filter(a =>
    a.type === 'pro-forma-invoice'
    || a.type === 'attendance-certificate-sent'
    || a.type === 'invoice-sent'
    || (a.type === 'file' && a.data.showInPortal)
  )

  const mayNotPay = registration.status === 'cancelled' || registration.training.status === 'cancelled' || registration.status === "to-check" || registration.status === "rejected" || registration.isClosed

  return (
    <>
      <PageHeader
        title={<><Icon icon="clipboard-list-check" /> Mon inscription : {registration.training.code}</>}
        tags={<PublicRegistrationStatus registration={registration} />}
        extra={registration.status !== 'cancelled' && registration.status !== "rejected" && [
          <Button key="1" icon={<Icon icon="money-check-alt" />} onClick={() => setIsPaymentModalVisible(true)} hidden={mayNotPay}>Payer</Button>,
          <Button key="2" icon={<Icon icon="times-circle" />} onClick={() => showCancelModal()} danger>Me désister</Button>
        ]}
      />

      {alerts}

      <Row gutter={[16, 16]} style={{ marginTop: 30 }}>

        <Col span={24}>
          <Card title={<><Icon icon="info-circle" /> Informations générales</>}>
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label="Formation">{registration.training.name}</Descriptions.Item>
              <Descriptions.Item label="Matériel">{registration.training.requirements}</Descriptions.Item>
              {registration.training.isPQK && <Descriptions.Item label="Affilié PQK"><Icon.Boolean boolean={registration.isPQK} /></Descriptions.Item>}
              {registration.training.isPQK && registration.isPQK && <Descriptions.Item label="Numéro INAMI">{registration.inamiNumber}</Descriptions.Item>}
              {registration.training.isCEP && <Descriptions.Item label="Bénéficiaire CEP"><Icon.Boolean boolean={registration.isCEP} /></Descriptions.Item>}
              <Descriptions.Item label="Acompte">{Math.min(registration.price, registration.training.deposit)} €</Descriptions.Item>
              <Descriptions.Item label="Prix total">
                {registration.price} € &nbsp;&nbsp;<PriceStatus registration={registration} />
              </Descriptions.Item>
              <Descriptions.Item label="État de l'inscription"><PublicRegistrationStatus registration={registration} /></Descriptions.Item>
              {registration.training.flinkID && <Descriptions.Item label="Accès FLink"><FLinkUserAccessStatus registration={registration} /></Descriptions.Item>}
            </Descriptions>
          </Card>
        </Col>

        {registration.status === 'validated' && <Col span={24}>
          <Card title={<><Icon icon="calendar-alt" /> Horaire</>}>
            {registration.training.events.length
              ? <Table dataSource={registration.training.events} size="small" rowKey="id" pagination={false}>
                <Column title="Date" render={event => DateTime.fromISO(event.start).toFormat("ccc D")} />
                <Column title="Heure" render={event => `${DateTime.fromISO(event.start).toFormat("T")} - ${DateTime.fromISO(event.end).toFormat("T")} ${event.tableHours ? `(${event.tableHours}h de table)` : ''}`} />
                <Column title="Lieu" render={event => uniqBy(map(event.classrooms, 'site'), 'id').map(s => s.name).join(', ')} />
                <Column title="Formateurs" render={event => event.trainers.map(t => `${t.firstname} ${t.lastname}`).join(', ')} />
              </Table>
              : <p>Informations à venir.</p>
            }
          </Card>
        </Col>}

        {registration.status === 'validated' && <Col span={24}>
          <Card title={<><Icon icon="map-marker-alt" /> Lieu(x) de formation</>}>
            {registration.training.sites.length
              ? <Row gutter={[16, 16]} justify="space-around">
                {registration.training.sites.map(site =>
                  <Col key={site.site.id}>
                    <Card
                      className="site-card"
                      cover={<></>}
                      actions={[
                        <a key="website" href={site.site.url} target="_blank" rel="noopener noreferrer nofollow">
                          <div><Icon icon="globe" /><br />Informations pratiques</div>
                        </a>,
                        <a key="map" href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(address(site.site))}`} target="_blank" rel="noopener noreferrer nofollow">
                          <div><Icon icon="map-marked-alt" /><br />Plan</div>
                        </a>
                      ]}
                    >
                      <Card.Meta title={site.site.name} />
                      <Card.Meta
                        avatar={<Icon icon="map-marker-alt" />}
                        description={address(site.site)}
                      />
                      <Card.Meta
                        avatar={<Icon icon="sticky-note" />}
                        description={<>
                          {site.notes ? <>{site.notes}<br /></> : ''}
                          {site.site.notes ? site.site.notes : ''}
                        </>}
                      />
                    </Card>
                  </Col>
                )}
              </Row>
              : <p>Informations à venir.</p>
            }

          </Card>
        </Col>}

        {registration.status !== 'rejected' && registration.status !== 'to-check' && <Col span={24}>
          <Card title={<><Icon icon="money-check-alt" /> Paiements effectués</>}>
            {registration.payments.length
              ? <Table dataSource={registration.payments} size="small" rowKey="id" pagination={false}>
                <Column title="Date" render={item => isoDateToDateShort(item.paymentDate || item.date)} />
                <Column title="Montant" render={item => item.amount ? item.amount + " €" : '-'} />
                <Column title="Moyen de paiement" render={item => <PaymentMethod payment={item} />} />
                <Column title="Statut" render={item => <PaymentStatus payment={item} />} />
              </Table>
              : <>
                <p>Aucun paiement reçu à ce jour.</p>
                <p>Notez que le délai de traitement pour un paiement par virement bancaire peut prendre plusieurs jours.</p>
              </>
            }
          </Card>
        </Col>}

        <Col span={24}>
          <Card title={<><Icon icon="file-alt" /> Mes documents</>}>
            {documents.length
              ? <Table dataSource={documents} size="small" rowKey="id" pagination={false}>
                <Column title="Date" render={item => isoDateToDateShort(item.doneDate || item.date)} />
                <Column title="Type" render={item => {
                  switch (item.type) {
                    case 'pro-forma-invoice': return 'Facture pro forma'
                    case 'attendance-certificate-sent': return 'Attestation de suivi'
                    case 'invoice-sent': return `${item.data?.values?.invoice?.amount > 0 ? 'Facture' : 'Note de crédit'} ${item.data?.values?.invoice?.invoiceNumber} (${nfEur(item.data?.values?.invoice?.amount)})`
                    case 'file': return item.data.title
                    default: return '-'
                  }
                }} />
                <Column title="Statut" render={item => <DocumentStatus document={item} />} />
              </Table>
              : <p>Aucun document disponible à ce jour.</p>
            }
          </Card>
        </Col>

      </Row>

      <PaymentModal
        isVisible={isPaymentModalVisible}
        onCancel={() => setIsPaymentModalVisible(false)}
        onOK={() => { loadRegistration(); setIsPaymentModalVisible(false) }}
        registration={registration}
      />
    </>
  )

}

export default Registration