import React, { useEffect, useState } from 'react'

import api from 'services/adminApiService'
import Icon from 'components/Icon'
import TableLink from 'components/TableLink'
import {isoDateToDateShort, nfEur} from 'misc/utils'

import {Card, DatePicker, Input, PageHeader, Space, Table} from 'antd'
import {PaymentMethod, PaymentStatus} from "./Tags";
import PaymentModal from "./PaymentModal";

const { Column } = Table



const Payments = () => {

  const [payments, setPayments] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    defaultPageSize: 20,
    position: ['bottomRight', 'topRight'],
    total: 0,
    showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} éléments`,
    showSizeChanger: true
  })
  const [search, setSearch] = useState()
  const [range, setRange] = useState([])

  const [currentPayment, setCurrentPayment] = useState(null)

  const loadPayments = (pageSize, current) => {
    const params = { results: pageSize, page: current, search }
    if (range) {
      params.start = range[0]?.toISOString()
      params.end = range[1]?.toISOString()
    }
    api.payments.get({ loader: setLoading, params }).then(result => {
      setPayments(result.data)
      setPagination({ ...pagination, pageSize, current, total: result.totalCount })
    }).catch(console.error)
  }

  useEffect(() => {
    loadPayments(pagination.pageSize, 1)
  }, [search, range]) // eslint-disable-line react-hooks/exhaustive-deps

  const onRowHandlers = (record, rowIndex) => ({
    onClick: e => setCurrentPayment(record)
  })

  const onTableChange = (pagination, filters, sorter) => {
    loadPayments(pagination.pageSize, pagination.current)
  }

  return (
    <>
      <PageHeader title={<><Icon icon="credit-card" fixedWidth /> Paiements</>} />
      <Card>
        <Space style={{ position: 'absolute', top: 32, zIndex: 10 }}>
          <Input.Search
            placeholder="Rechercher..."
            onSearch={setSearch}
            enterButton={<Icon icon="search" />}
            allowClear
            style={{ maxWidth: 250 }}
          />
          <Icon.Info popover={{title: "Filtres disponibles :", content: <>
              - Nom et prénom du participant<br />
              - Numéro du participant<br />
              - Montant<br />
              - Communication structurée (sans + et /)<br />
              - Données des coda
            </>}} />
          <DatePicker.RangePicker allowClear allowEmpty={[true, true]} onChange={setRange} style={{marginLeft: 25}} />
          <Icon.Info tooltip="Le filtre de date est appliqué sur la date de paiement. La date de paiement des CF est celle ou les CF ont été acceptés. Avant cela ils n'ont pas de date de paiement." />
        </Space>

        <Table
          dataSource={payments}
          loading={loading}
          onRow={onRowHandlers}
          rowKey="id"
          rowClassName="row-clickable"
          size="small"
          pagination={pagination}
          onChange={onTableChange}
          style={{ marginTop: payments?.length ? 0 : 56 }}
        >
          <Column title="Date d'encodage" dataIndex="date" render={isoDateToDateShort} />
          <Column title="Date du paiement" dataIndex="paymentDate" render={isoDateToDateShort} />
          <Column title="Montant" dataIndex="amount" render={nfEur} />
          <Column title="Moyen de paiement" render={item => <PaymentMethod payment={item} />} />
          <Column title="Statut" render={item => <PaymentStatus payment={item} />} />
          <Column title="Participant" render={payment => <TableLink.User user={payment?.registration?.user} />} />
          <Column title="Inscription" render={payment => <>{payment?.registration?.number} - <TableLink.Training training={payment?.registration?.training} hideDate /></>} />
          <Column title="Fait" render={payment => <Icon.Boolean boolean={payment.isDone}  />} />
          <Column title="Note" dataIndex="note" />
        </Table>
      </Card>
      <PaymentModal payment={currentPayment} isVisible={!!currentPayment} onClose={() => setCurrentPayment(null)} />
    </>
  )

}

export default Payments