import React, { useContext, useEffect, useMemo, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { sortBy, clone } from 'lodash'

import api from 'services/adminApiService'
import Icon from 'components/Icon'
import { UserTypes } from 'components/Tags'
import Trainings from './Trainings'
import RegistrationsList from 'admin-app/RegistrationsList/RegistrationsList'
import Profile from './Profile'
import FLinkAccess from './FLinkAccess'
import RecentsContext from 'contexts/RecentsContext'
import ActionsTimeline from './ActionsTimeline'
import { callGraph } from 'services/graphService'
import MergeUserModal from './MergeUserModal'
import AddNoteModal from './AddNoteModal'
import AddFileModal from './AddFileModal'

import { Badge, Button, Dropdown, Menu, PageHeader, Spin, Tabs, message, Modal, Card } from 'antd'




const User = () => {

  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isMergeUserModalVisible, setIsMergeUserModalVisible] = useState(false)
  const [isAddNoteModalVisible, setIsAddNoteModalVisible] = useState(false)
  const [isAddFileModalVisible, setIsAddFileModalVisible] = useState(false)

  // Current user
  // @ts-ignore
  const { id, tab = 'profile' } = useParams()

  const history = useHistory()

  const { addRecent } = useContext(RecentsContext)

  const loadUser = () => {
    if (id) api.users.getById(id, { loader: setLoading }).then(setUser).catch(console.error)
  }

  useEffect(loadUser, [id])

  useEffect(() => {
    if (user) addRecent(user, 'user')
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  const mergeDone = () => {
    setIsMergeUserModalVisible(false)
    message.success("Fusion effectuée.")
    loadUser()
  }

  const addNoteDone = () => {
    setIsAddNoteModalVisible(false)
    message.success("Note ajoutée.")
    loadUser()
  }

  const addFileDone = () => {
    setIsAddFileModalVisible(false)
    message.success("Fichier ajouté dans la timeline.")
    loadUser()
  }

  const handleActionsClick = ({ key }) => {
    switch (key) {
      case 'add-note':
        setIsAddNoteModalVisible(true)
        break
      case 'add-file':
        setIsAddFileModalVisible(true)
        break
      case 'open-folder':
        callGraph({ endpoint: `/drive/special/approot:/Utilisateurs/${id}` }).then((data) => {
          window.open(data.webUrl, '_blank');
        }).catch(console.error)
        break
      case 'go-portal':
        api.call(`/users/${user.id}/token`, { method: 'GET', loader: setLoading }).then(({ token }) => {
          const win = window.open("", "_blank")
          win.document.write(`<script>sessionStorage.setItem('token', '${token}'); window.location.replace('/portal')</script>`)
        }).catch(console.error)
        break
      case 'mark-teacher':
        api.users.put(user.id, { isTrainer: true }, { loader: setLoading }).then(user => {
          message.success("Modification effectuée.")
          setUser(user)
        }).catch(console.error)
        break
      case 'unmark-teacher':
        api.users.put(user.id, { isTrainer: false }, { loader: setLoading }).then(user => {
          message.success("Modification effectuée.")
          setUser(user)
        }).catch(console.error)
        break
      case 'merge':
        setIsMergeUserModalVisible(true)
        break
      case 'delete':
        Modal.confirm({
          title: "Êtes-vous sûr ?",
          content: <>Ceci supprimer l'utilisateur et toutes ses informations.<br /><strong>Cette opération est irréversible.</strong></>,
          okText: "Supprimer",
          okButtonProps: { icon: <Icon icon="trash" />, danger: true },
          onOk: () => {
            api.users.delete(user.id, { loader: setLoading }).then(user => {
              message.success("Utilisateur supprimé.")
              history.push("/admin/users")
            }).catch(console.error)
          }
        })
        break
      default:
        break
    }
  }

  const registrations = useMemo(() => {
    const registrations = sortBy(user?.registrations || [], 'start').map(r => clone(r))
    registrations.forEach(r => r.user = user)
    return registrations
  }, [user])

  // Training loading
  if (!user) {
    return <div><Spin size="large" /></div>
  }

  // Existing user view
  return (
    <>
      <PageHeader
        title={<><Icon icon="user" fixedWidth /> Utilisateur : {user.lastname} {user.firstname}</>}
        subTitle={user.clientNumber}
        tags={<UserTypes user={user} />}
        extra={<>
          <Button icon={<Icon icon="sync" spin={loading} />} onClick={loadUser} />
          <Dropdown arrow overlay={
            <Menu onClick={handleActionsClick}>
              <Menu.Item key="add-note" icon={<Icon icon="sticky-note" />}>Ajouter une note</Menu.Item>
              <Menu.Item key="add-file" icon={<Icon icon="file-alt" />}>Ajouter un fichier</Menu.Item>
            </Menu>
          }>
            <Button icon={<Icon icon="plus" />} />
          </Dropdown>
          <Dropdown arrow overlay={
            <Menu onClick={handleActionsClick}>
              <Menu.Item key="open-folder" icon={<Icon icon="folder-open" fixedWidth />}>Ouvrir le dossier OneDrive</Menu.Item>
              <Menu.Item key="go-portal" icon={<Icon icon="user-secret" fixedWidth />}>Se connecter au portail</Menu.Item>
              {user.isTrainer
                ? <Menu.Item key="unmark-teacher" icon={<Icon icon="chalkboard-teacher" fixedWidth />}>Supprimer le statut de formateur</Menu.Item>
                : <Menu.Item key="mark-teacher" icon={<Icon icon="chalkboard-teacher" fixedWidth />}>Marquer comme formateur</Menu.Item>
              }
              <Menu.Item key="merge" icon={<Icon icon="code-merge" fixedWidth />}>Fusionner avec un autre utilisateur</Menu.Item>
              <Menu.Divider />
              <Menu.Item key="delete" icon={<Icon icon="trash" fixedWidth />} danger disabled={user.registrations.length || user.trainings.length}>Supprimer l'utilisateur</Menu.Item>
            </Menu>
          }>
            <Button icon={<Icon icon="ellipsis-v" />} />
          </Dropdown>
        </>}
      />

      <Spin delay={500} spinning={loading}>

        <Tabs defaultActiveKey="profile" activeKey={tab} onTabClick={key => history.push(`/admin/user/${id}/${key}`)} destroyInactiveTabPane={true}>

          <Tabs.TabPane tab={<><Icon icon="clipboard-user" />Données personnelles</>} key="profile">
            <Profile user={user} setUser={setUser} />
          </Tabs.TabPane>

          <Tabs.TabPane tab={<><Icon icon="graduation-cap" />Formations <Badge count={user.trainings.length || 0} style={{ backgroundColor: "#1890ff" }} showZero /></>} key="trainings">
            <Trainings user={user} refreshUser={loadUser} />
          </Tabs.TabPane>

          <Tabs.TabPane tab={<><Icon icon="file-signature" />Inscriptions <Badge count={user.registrations.length || 0} style={{ backgroundColor: "#1890ff" }} showZero /></>} key="registrations">
            <Card title={<><Icon icon="file-signature" />Inscriptions <Badge count={user.registrations.length || 0} style={{ backgroundColor: "#1890ff" }} showZero /></>}>
              <RegistrationsList
                registrations={registrations}
                refresh={loadUser}
                from='user'
              />
            </Card>
          </Tabs.TabPane>

          <Tabs.TabPane tab={<><Icon icon="stream" />Timeline{user.hasImportantNote && <Icon.ImportantNote style={{ marginLeft: 7, marginRight: 0 }} />}</>} key="timeline">
            <ActionsTimeline user={user} refreshUser={loadUser} />
          </Tabs.TabPane>

          <Tabs.TabPane tab={<><Icon icon="graduation-cap" />Accès FLink</>} key="flink">
            <FLinkAccess user={user} refreshUser={loadUser} />
          </Tabs.TabPane>

        </Tabs>

      </Spin>

      <MergeUserModal user={user} isVisible={isMergeUserModalVisible} onOK={mergeDone} onCancel={() => setIsMergeUserModalVisible(false)} />
      <AddNoteModal user={user} isVisible={isAddNoteModalVisible} onOK={addNoteDone} onCancel={() => setIsAddNoteModalVisible(false)} />
      <AddFileModal user={user} isVisible={isAddFileModalVisible} onOK={addFileDone} onCancel={() => setIsAddFileModalVisible(false)} />

    </>
  )

}

export default User


