import React from 'react'

import { isString } from 'lodash'

import Icon from './Icon'

import { Button, Popconfirm, Tooltip } from "antd"



const TableButton = ({ onClick, confirmMessage = null, icon, danger = false, success = false, tooltip = null, loading = false, disabled = false }) => {

  const _onClick = (e) => {
    onClick()
    e.stopPropagation()
  }

  const onStop = e => e.stopPropagation()

  const _icon = isString(icon) ? <Icon icon={icon} fixedWidth /> : icon

  let button

  if (confirmMessage) {
    if (confirmMessage === true) confirmMessage = "Êtes-vous sûr ?"
    button = <Button shape="circle" size="small" icon={_icon} danger={danger} className={success ? "button-success" : ""} loading={loading} disabled={disabled} />
  } else {
    button = <Button shape="circle" size="small" icon={_icon} danger={danger} className={success ? "button-success" : ""} loading={loading} disabled={disabled} onClick={_onClick} />
  }

  if (tooltip) {
    button = <Tooltip title={tooltip}>{button}</Tooltip>
  }

  if (confirmMessage) {
    button = <span onClick={onStop}>
      <Popconfirm title={confirmMessage} onConfirm={_onClick}>
        {button}
      </Popconfirm>
    </span>
  }

  return button
}

export default TableButton