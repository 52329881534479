import React, { useEffect, useState } from 'react'

import { Link, useLocation } from 'react-router-dom'
import { useInterval } from 'react-use'

import api from 'services/userApiService'
import ModalLayout from 'components/ModalLayout/ModalLayout'
import Icon from 'components/Icon'
import Error500 from './Error500'

import { Button, Result } from 'antd'



const ValidateEmail = () => {

  const [isSent, setIsSent] = useState(false)
  const [isValidated, setIsValidated] = useState(false)
  const [error, setError] = useState(null)
  const [timeBeforeClose, setTimeBeforeClose] = useState(5)

  const params = new URLSearchParams(useLocation().search)
  const email = params.get("email")
  const code = params.get("code")

  useInterval(() => {
    if (timeBeforeClose <= 1) window.close()
    setTimeBeforeClose(timeBeforeClose - 1)
  }, isValidated ? 1000 : null)

  const send = () => {
    api.post('/auth/send-validation-email', { email })
      .then(() => { setIsSent(true) })
      .catch(console.error)
  }

  useEffect(() => {
    if (code) {
      api.post('/auth/validate-email', { code })
        .then(() => {
          setTimeout(() => setIsValidated(true), 1000)
        })
        .catch(response => {
          if (response?.json?.error === "auth.invalid_or_expired_code") {
            setTimeout(() => setError("invalid_or_expired_code"), 1000)
          } else {
            setTimeout(() => setError("unknown"), 1000)
          }
        })
    }
  }, [code])

  if ((!email && !code) || (email && code) || error === "unknown") {
    return <Error500 />
  }

  return (
    <ModalLayout>

      {error === "invalid_or_expired_code" &&
        <Result
          title="Oups..."
          subTitle="Le lien que vous avez utilisé semble périmé ou déjà utilisé. Essayez de vous connecter à nouveau pour obtenir un nouveau lien."
          status="warning"
          style={{ paddingTop: 20 }}
          icon={<Icon icon="exclamation-triangle" />}
          extra={<Link to="/login"><Button type="primary" icon={<Icon icon="lock" />}>Aller à l'écran de connexion</Button></Link>} />
      }

      {code && !error && (isValidated
        ? <Result
          title="L'adresse e-mail est validée !"
          subTitle={<>Cette page se fermera automatiquement dans <strong>{timeBeforeClose}</strong> secondes...</>}
          status="success"
          style={{ paddingTop: 20 }}
          icon={<Icon icon="check-circle" />} />

        : <Result
          title="Veuillez patienter..."
          subTitle="Nous vérifions que tout est en ordre..."
          status="info"
          style={{ paddingTop: 20 }}
          icon={<Icon icon="spinner-third" spin />}
          extra={<Button type="primary" icon={<Icon icon="paper-plane" />} onClick={send}>Renvoyer l'e-mail</Button>} />
      )}

      {email && (isSent
        ? <Result
          title="C'est fait !"
          subTitle="Consultez votre boite mail, et cliquez sur le lien que nous vous avons envoyé pour valider votre adresse e-mail."
          status="success"
          style={{ paddingTop: 20 }}
          icon={<Icon icon="check-circle" />}
          extra={<Link to="/login"><Button type="primary" icon={<Icon icon="lock" />}>Aller à l'écran de connexion</Button></Link>} />

        : <Result
          title="Vous y êtes presque !"
          subTitle={<><p>Il ne reste plus qu'à valider votre adresse e-mail. Pour cela, cliquez sur le lien dans le mail que vous avez reçu.</p><p>Vous ne l'avez pas reçu ? Utilisez le bouton ci-dessous pour renvoyer un nouveau mail.</p></>}
          status="info"
          style={{ paddingTop: 20 }}
          icon={<Icon icon="info-circle" />}
          extra={<Button type="primary" icon={<Icon icon="paper-plane" />} onClick={send}>Renvoyer l'e-mail</Button>} />
      )}

    </ModalLayout>
  )
}

export default ValidateEmail