import React, { useState } from 'react'

import Icon from 'components/Icon'
import { callGraph } from 'services/graphService'
import api from 'services/adminApiService'

import { Button, Tooltip, Modal } from 'antd'

import saveImage from 'static/images/save-doc.png'



const FileButton = {}

export default FileButton



const SimpleButton = ({ icon, onClick, tooltip = null, disabled = false, loading = false, shape = 'circle', children = null }) => {
  const [isLoading, setIsLoading] = useState(false)
  const _onClick = () => {
    setIsLoading(true)
    const res = onClick()
    if (res) res.then(() => setIsLoading(false))
    else setIsLoading(false)
  }
  let button = <Button icon={<Icon icon={icon} />} size="small" shape={shape} onClick={_onClick} loading={loading || isLoading} disabled={disabled}>{children}</Button>
  if (tooltip) button = <Tooltip title={tooltip}>{button}</Tooltip>
  return button
}
FileButton.Simple = SimpleButton



/**
 * Button to simply open a file
 */
FileButton.Open = ({ file, tooltip = null }) => {

  if (!file) return <></>

  const openFile = () => {
    return callGraph({ endpoint: `/drive/items/${file.id}` }).then((data) => {
      window.open(data.webUrl, '_blank')
    }).catch(console.error)
  }

  return <SimpleButton
    tooltip={tooltip || "Ouvrir le fichier"}
    icon={file.file?.mimeType === 'application/pdf' ? 'file-pdf' : 'file-word'}
    onClick={openFile}
  />
}


/**
 * Download a new file for a registration or a training
 */
FileButton.Download = ({ registration = null, trainerOnTraining = null, training = null, refresh = null, type, tooltip = "Télécharger un nouveau PDF", disabled = false, shape = 'circle', icon = 'download', children = null }) => {

  const url = registration
    ? `/registrations/${registration.id}/file`
    : trainerOnTraining
      ? `/trainings/${trainerOnTraining.trainingId}/trainers/${trainerOnTraining.userId}/file`
      : `/trainings/${training.id}/file`

  const download = () => {
    return api.post(url, { name: type, action: 'pdf', overwriteDoc: true }).then(data => {
      window.open(data.webUrl, '_blank')
      if (refresh) refresh()
    }).catch(console.error)
  }

  return <SimpleButton
    tooltip={tooltip}
    onClick={download}
    icon={icon}
    shape={shape}
    disabled={disabled}
  >
    {children}
  </SimpleButton>
}



/**
 * Edit a file for a registration/trainerOnTraining
 */
const Edit = ({ registration = null, trainerOnTraining = null, refresh, type, tooltip = null, disabled = false, onOK = null, overwriteDoc = false }) => {

  const [isLoading, setIsLoading] = useState(false)

  const url = registration
    ? `/registrations/${registration.id}/file`
    : `/trainings/${trainerOnTraining.trainingId}/trainers/${trainerOnTraining.userId}/file`

  // By default, download
  if (!onOK) {
    onOK = () => {
      return api.post(url, { name: type, action: 'pdf', overwriteDoc: false }).then(data => {
        window.open(data.webUrl, '_blank')
        refresh()
        setIsLoading(false)
      })
    }
  }

  const download = async () => {
    setIsLoading(true)
    api.post(url, { name: type, action: 'doc', overwriteDoc }).then(data => {
      window.open(data.webUrl, '_blank')
      Modal.info({
        title: 'Votre document est ouvert.',
        content: <>
          <p>Vous pouvez à présent modifier le document.</p>
          <p>Une fois vos modifications terminées, fermez le document et cliquez sur le bouton "J'ai terminé" pour lancer le téléchargement du fichier PDF.</p>
          <p>Attention ! Pour avant de fermer le document, sauvegardez vos modifications en cliquant sur le bouton "Affichage" : <img src={saveImage} style={{ maxWidth: '100%' }} alt="help" /></p>
        </>,
        okText: "J'ai terminé !",
        okButtonProps: { icon: <Icon icon="check" /> },
        onOk: async () => {
          await onOK()
          setIsLoading(false)
        }
      })
    }).catch(console.error)
  }
  return <SimpleButton
    tooltip={tooltip || "Modifier avant création du PDF"}
    onClick={download}
    icon="pencil"
    disabled={disabled}
    loading={isLoading}
  />
}

FileButton.Edit = Edit