import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import TableLink from 'components/TableLink'
import { formatExperience, formatGender, formatNationality, formatStudyLevel, formatWorkStatus, isoDateToDateShort, nfEur } from 'misc/utils'
import { PresenceRate } from 'components/Tags'

import { Button, Card, Descriptions, message, PageHeader, Popover, Table, Tabs } from 'antd'

const { Column } = Table



const SodexoForemEncoding = () => {

  const { tab = 'to-encode' } = useParams()

  const history = useHistory()

  const [payments, setPayments] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const loadPayments = () => {
    setSelectedRowKeys([])
    api.payments.get({ loader: setIsLoading, params: { type: `cf-${tab}` } }).then(setPayments).catch(console.error)
  }

  useEffect(loadPayments, [tab])

  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const encodeForem = () => {
    const data = selectedRowKeys.map(k => ({
      id: k,
      paymentCF: {
        encodedForem: true
      }
    }))
    api.payments.put('', data, { loader: setIsLoading }).then(() => {
      loadPayments()
      message.success("Paiements encodés.")
    }).catch(console.error)
  }

  const getAmountToInvoice = payment => Math.min(
    payment.registration.presenceRateCF === 1
      ? payment.registration.training.CFHours * 30
      : payment.registration.presenceHoursCF * 30,
    payment.registration.price,
    payment.registration.training.CFHours * 30
  )

  return (
    <>
      <PageHeader
        title={<><Icon icon="edit" fixedWidth /> Encodage Forem</>}
        extra={<>
          {tab === 'to-encode' ? <Button icon={<Icon icon="check" />} disabled={!selectedRowKeys.length} onClick={() => encodeForem()}>Encodé Forem ({selectedRowKeys.length})</Button> : null}
        </>}
      />

      <Tabs defaultActiveKey="current" activeKey={tab} onTabClick={key => history.push(`/admin/forem-encoding/${key}`)} destroyInactiveTabPane={true}>

        <Tabs.TabPane tab={<><Icon icon="clipboard-list-check" />A encoder</>} key="to-encode">
          <Card>
            <Table
              dataSource={payments}
              pagination={{ pageSize: 20 }}
              loading={isLoading}
              rowKey="id"
              size="small"
              rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys }}
              rowClassName="row-clickable"
              expandable={{
                expandRowByClick: true,
                expandIconColumnIndex: -1,
                expandedRowRender: r => <>
                  <Descriptions bordered size="small" column={1}>
                    <Descriptions.Item label="Date de naissance">{isoDateToDateShort(r.registration.user.birthDate)}</Descriptions.Item>
                    <Descriptions.Item label="Genre">{formatGender(r.registration.user.gender)}</Descriptions.Item>
                    <Descriptions.Item label="Numéro de registre national">{r.paymentCF.NRNumber}</Descriptions.Item>
                    <Descriptions.Item label="Nationalité">{formatNationality(r.paymentCF.nationality)}</Descriptions.Item>
                    <Descriptions.Item label="Statut">{formatWorkStatus(r.paymentCF.workStatus)}</Descriptions.Item>
                    <Descriptions.Item label="Expérience professionnelle">{formatExperience(r.paymentCF.experience)}</Descriptions.Item>
                    <Descriptions.Item label="Niveau d'études">{formatStudyLevel(r.paymentCF.studyLevel)}</Descriptions.Item>
                    <Descriptions.Item label="Numéro d'entreprise">{r.paymentCF.companyNumber}</Descriptions.Item>
                    <Descriptions.Item label="Nom de l'entreprise">{r.paymentCF.companyName}</Descriptions.Item>
                    <Descriptions.Item label="Code postal">{r.paymentCF.companyZip}</Descriptions.Item>
                    <Descriptions.Item label="Localité">{r.paymentCF.companyCity}</Descriptions.Item>

                  </Descriptions>
                </>
              }}
            >
              <Column title="Participant" render={payment => payment.registration && <TableLink.User user={payment.registration.user} />} />
              <Column title="Formation" render={payment => payment.registration && <TableLink.Training training={payment.registration.training} />} />
              <Column title="N° d'agrément" render={payment => payment.registration && payment.registration.training.CFApprovalNumber} />
              <Column title="N° d'autorisation client" dataIndex={['paymentCF', 'authorizationNumber']} />
              <Column title="Code d'utilisation" dataIndex={['paymentCF', 'usageCode']} />
              <Column title="Présence" render={payment => <PresenceRate registration={payment.registration} />} />
              <Column title="Nb. Chèques" dataIndex={['paymentCF', 'nbChecks']} />
              <Column title="Validité" render={payment => isoDateToDateShort(payment.paymentCF.validityDate)} />
              <Column title="Montant" render={payment => <Popover content={<>
                Inscription : {nfEur(payment.registration.price)}<br />
                A facturer : {nfEur(getAmountToInvoice(payment))}<br />
                Portefeuille : {nfEur(payment.paymentCF.nbChecks * 30)}<br />
              </>}>
                {nfEur(getAmountToInvoice(payment))}
              </Popover>} />
              <Column title="No. remise" dataIndex={['paymentCF', 'remiseNumber']} />
              <Column title="Date remise" render={payment => isoDateToDateShort(payment.paymentCF.remiseDate)} />
              <Column title="Facture" render={payment => `${payment.registration.number}/${payment.registration.training.code}`} />
              <Column title="CP" render={payment => payment.registration.training.sites.map(s => s.site.zip).join(', ')} />
            </Table>
          </Card>
        </Tabs.TabPane>

        <Tabs.TabPane tab={<><Icon icon="check-double" />Encodés</>} key="encoded">
          <Card>
            <Table dataSource={payments} pagination={{ pageSize: 20 }} loading={isLoading} rowKey="id" size="small" rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys }}>
              <Column title="Participant" render={payment => payment.registration && <TableLink.User user={payment.registration.user} />} />
              <Column title="Formation" render={payment => payment.registration && <TableLink.Training training={payment.registration.training} />} />
              <Column title="Numéro d'autorisation client" dataIndex={['paymentCF', 'authorizationNumber']} />
              <Column title="Présence" render={payment => <PresenceRate registration={payment.registration} />} />
              <Column title="Nb. Chèques" dataIndex={['paymentCF', 'nbChecks']} />
              <Column title="Validité" render={payment => isoDateToDateShort(payment.paymentCF.validityDate)} />
              <Column title="Montant" render={payment => nfEur(payment.registration.price)} />
              <Column title="No. remise" dataIndex={['paymentCF', 'remiseNumber']} />
              <Column title="Date remise" render={payment => isoDateToDateShort(payment.paymentCF.remiseDate)} />
              <Column title="CP" render={payment => payment.registration.training.sites.map(s => s.site.zip).join(', ')} />
            </Table>
          </Card>
        </Tabs.TabPane>

      </Tabs>
    </>
  )

}

export default SodexoForemEncoding