import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import {forIn, keys} from 'lodash'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import {downloadFile, formatPaymentCom, isoDateToDateTime, nfEur} from 'misc/utils'
import TableLink from 'components/TableLink'

import {Button, Card, Input, message, PageHeader, Space, Table, Tabs} from 'antd'
import {useMap} from "react-use";

const { Column } = Table



const InvoicesTable = ({ type }) => {

  const [invoices, setInvoices] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    defaultPageSize: 20,
    position: ['bottomRight', 'topRight'],
    total: 0,
    showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} éléments`,
    showSizeChanger: true
  })
  const [search, setSearch] = useState(null)

  const [cnUpdates, {set : setCNUpdates, remove : removeCNUpdate, reset : resetCNUpdates}] = useMap()

  const [saving, setSaving] = useState(false)

  const loadInvoices = (pageSize = pagination.pageSize, current = pagination.current) => {
    api.invoices.get({ loader: setLoading, params: { results: pageSize, page: current, search, type } }).then(result => {
      setInvoices(result.data)
      setPagination({ ...pagination, pageSize, current, total: result.totalCount })
    }).catch(console.error)
  }

  const downloadAdfinity = () => {
    api.get(`/reports/adfinity/companies-incomplete`, { loader: setSaving, rawResponse: true}).then(data => {
      downloadFile(data.body, data.response)
    })
  }

  const saveCNUpdates = () => {
    const updates = {companies: [], users: [], invoices: []}
    forIn(cnUpdates, (invoicingClientNumber, id) =>{
      const invoice = invoices.find(i => i.id === id)
      if (invoice.isBillingSpecific) {
        updates.invoices.push({id, invoicingClientNumber})
        updates.companies.push({companyNumber: invoice.companyNumber, invoicingClientNumber})
      } else {
        updates.users.push({id: invoice.registration.user.id, invoicingClientNumber})
      }
    })
    api.invoices.call({ method: "PUT", loader: setSaving, body: updates }).then(data => {
      loadInvoices()
      resetCNUpdates()
      message.success("Modifications sauvegardées.")
    }).catch(console.error)
  }

  const saveOneCNUpdate = (id) => {
    const updates = {companies: [], users: [], invoices: []}
    const invoice = invoices.find(i => i.id === id)
    const invoicingClientNumber = cnUpdates[id]
    if (invoice.isBillingSpecific) {
      updates.invoices.push({id, invoicingClientNumber})
      updates.companies.push({companyNumber: invoice.companyNumber, invoicingClientNumber})
    } else {
      updates.users.push({id: invoice.registration.user.id, invoicingClientNumber})
    }
    api.invoices.call({ method: "PUT", loader: setSaving, body: updates }).then(data => {
      loadInvoices()
      removeCNUpdate(id)
      message.success("Modification sauvegardée.")
    }).catch(console.error)
  }

  useEffect(() => {
    loadInvoices(pagination.pageSize, 1)
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  const onTableChange = (pagination, filters, sorter) => {
    loadInvoices(pagination.pageSize, pagination.current)
  }

  const getCN = (invoice) => invoice.isBillingSpecific ? invoice.invoicingClientNumber : invoice.registration.user.invoicingClientNumber
  
  return (
    <>
      <Space style={{ position: 'absolute', top: 32, zIndex: 10, display: 'flex', alignItems: 'center' }}>
        <Input.Search
          placeholder="Rechercher..."
          onSearch={setSearch}
          style={{ maxWidth: 250 }}
          enterButton
          allowClear
        />
        <Icon.Info popover={{title: "Filtres disponibles :", content: <>
            - Nom sur la facture (participant ou entreprise)<br />
            - Numéro du client<br />
            - Montant<br />
            - Communication structurée (sans + et /)<br />
            - N° entreprise<br />
            - Numéro de facture<br />
            - Code formation
          </>}} />
      </Space>
      <Table
        dataSource={invoices}
        loading={loading}
        rowKey="id"
        size="small"
        pagination={pagination}
        onChange={onTableChange}
        style={{ marginTop: invoices?.length ? 0 : 56 }}
      >
        <Column title={<Icon.Info iconName="file-export" tooltip="Exporté ?" />} render={i => <Icon.Boolean boolean={i.exported} noError />} />
        <Column title="N°" dataIndex="invoiceNumber" />
        <Column title="Date" dataIndex="date" render={isoDateToDateTime} />
        <Column title="Montant" dataIndex="amount" render={nfEur} />
        <Column title="Communic. struct." dataIndex="paymentCommunication" render={formatPaymentCom} />
        <Column title="Formation" render={i => <TableLink.Training training={i.registration.training} hideDate />} />
        <Column title="Nom" render={i => i.isBillingSpecific ? i.name : <TableLink.User user={i.registration.user} text={i.name} />} />
        <Column title="Entreprise" dataIndex="isBillingSpecific" render={v => <Icon.Boolean boolean={v} noError />} />
        <Column title="N° entrep." render={i => i.isBillingSpecific ? i.companyNumber : ''}  />
        <Column title="N° partic." render={i => <TableLink.User user={i.registration.user} text={i.registration.user.clientNumber} />} />
        <Column title="N° client" render={item => {
          if (!getCN(item) || cnUpdates[item.id] !== undefined) {
            return (
              <Space.Compact size="small">
                <Input placeholder="N° client" autoFocus value={cnUpdates[item.id]} onChange={e => setCNUpdates(item.id, e.target.value)} />
                <Button type="primary" htmlType="submit" icon={<Icon icon="save" />} style={{width: 30}} onClick={() => saveOneCNUpdate(item.id)}></Button>
                <Button icon={<Icon icon="undo" />} style={{width: 30}} onClick={() => removeCNUpdate(item.id)}></Button>
              </Space.Compact>
            )
          }
          return <Button type="text" size="small" onClick={e => setCNUpdates(item.id, getCN(item))}>{getCN(item)}</Button>
        }} />
      </Table>
      <Space>
        <Button type="primary" icon={<Icon icon="save" />} loading={saving} onClick={saveCNUpdates} disabled={!keys(cnUpdates).length}>Sauvegarder tout ({keys(cnUpdates).length})</Button>
        {type === 'company' && <Button type="primary" icon={<Icon icon="file-export" />} loading={saving} onClick={downloadAdfinity} disabled={!invoices.length}>Exporter la liste (format adfinity)</Button>}
      </Space>
    </>
  )

}



const Invoices = () => {

  // @ts-ignore
  const { tab = 'company' } = useParams()

  const history = useHistory()

  return (
    <>
      <PageHeader title={<><Icon icon="file-invoice-dollar" fixedWidth /> Factures et notes de crédit</>} />

      <Tabs defaultActiveKey="current" activeKey={tab} onTabClick={key => history.push(`/compta/invoices/${key}`)} destroyInactiveTabPane={true}>

        <Tabs.TabPane tab={<><Icon icon="building" />Factures avec N° client manquants (Entreprises)</>} key="company">
          <Card>
            <InvoicesTable type="company" />
          </Card>
        </Tabs.TabPane>

        <Tabs.TabPane tab={<><Icon icon="user" />Factures avec N° client manquants (Participants)</>} key="participant">
          <Card>
            <InvoicesTable type="participant" />
          </Card>
        </Tabs.TabPane>

        <Tabs.TabPane tab={<><Icon icon="list" />Toutes les factures</>} key="all">
          <Card>
            <InvoicesTable type="all" />
          </Card>
        </Tabs.TabPane>

      </Tabs>
    </>
  )

}

export default Invoices