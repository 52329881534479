import React, { useState } from 'react'

import './RegistrationView.less'

import { useHistory } from 'react-router-dom'

import Icon from 'components/Icon'
import { getColoredBalance, isoDateToDateTime, nfEur } from 'misc/utils'
import EditRegistrationModal from '../EditRegistrationModal'
import TableLink from 'components/TableLink'
import TableButton from 'components/TableButton'
import AddPaymentModal from '../AddPaymentModal'
import { PresenceRate, RegistrationStatus } from 'components/Tags'
import api from 'services/adminApiService'
import RegistrationView from './RegistrationView'
import CancelRegistrationModal from './CancelRegistrationModal'
import SendFileMailModal from 'admin-app/SendFileMailModal'
import AbsencesModal from './AbsencesModal'

import { Alert, Button, Dropdown, Menu, message, Modal, Space, Table } from 'antd'

const { Column } = Table



/**
 * from can be training or user.
 */
const RegistrationsList = ({ registrations, refresh, from, loading = false, pagination = false, onChange = null, showIsClosed = true, showStatus = true }) => {

  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false)
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false)
  const [isAcceptFileMailModalVisible, setIsAcceptFileMailModalVisible] = useState(false)
  const [isRejectFileMailModalVisible, setIsRejectFileMailModalVisible] = useState(false)
  const [isAbsenceModalVisible, setIsAbsenceModalVisible] = useState(false)
  const [currentRegistration, setCurrentRegistration] = useState(null)

  const history = useHistory()

  const updateDone = () => {
    setIsEditModalVisible(false)
    setIsPaymentModalVisible(false)
    setIsAcceptFileMailModalVisible(false)
    setIsRejectFileMailModalVisible(false)
    setIsCancelModalVisible(false)
    setIsAbsenceModalVisible(false)
    refresh()
  }

  const edit = registration => {
    setCurrentRegistration(registration)
    setIsEditModalVisible(true)
  }

  const addPayment = registration => {
    setCurrentRegistration(registration)
    setIsPaymentModalVisible(true)
  }

  const transfer = registration => {
    Modal.confirm({
      title: 'Êtes-vous sûr ?',
      content: <>
        <p>Transférer une inscription permet de clôturer une inscription avec un statut "A transférer". Plus tard, le participant devra créer une nouvelle inscription, et vous pourrez la modifier pour en diminuer le prix, en fonction du solde de l'inscription précédente. On pourra alors la marquer comme "Transférée". Merci de faire attention aux points suivants :</p>
        <ul>
          <li>Une <strong>note de crédit</strong> sera émise avec le montant pas encore perçu.</li>
          <li>Le solde de l'inscription sera équivalent au montant "trop perçu" qui pourra être déduit de la nouvelle inscription future.</li>
          <li><strong>Aucun e-mail automatique</strong> ne sera envoyé, il faut donc prévenir le participant si nécessaire.</li>
          <li><strong>Aucune nouvelle inscription</strong> ne sera créée. Le participant devra lui-même s'inscrire plus tard</li>
          <li>Le prix de la nouvelle inscription devra être modifié manuellement</li>
          <li>L'inscription "A transférer" devra <strong>manuellement être marquée comme "transférée"</strong> lorsque ce sera fait.</li>
        </ul>
      </>,
      maskClosable: true,
      closable: true,
      onOk() {
        api.post(`/registrations/${registration.id}/to-transfer`).then(data => {
          message.success("Inscription marquée comme étant \"à transférer\".")
          refresh()
        }).catch(console.error)
      },
    })
  }

  const transferDone = registration => {
    Modal.confirm({
      title: 'Êtes-vous sûr ?',
      content: <p>L'inscription sera définitivement marquée comme "Transférée".</p>,
      maskClosable: true,
      closable: true,
      onOk() {
        api.post(`/registrations/${registration.id}/transfered`).then(data => {
          message.success("Inscription marquée comme étant \"Transférée\".")
          refresh()
        }).catch(console.error)
      },
    })
  }

  const open = registration => {
    Modal.confirm({
      title: "Êtes-vous sûr ?",
      content: <>
        Ouvrir à nouveau une inscription ne doit être  fait que lorsqu'elle a été fermée à tort. Par exemple : le participant est finalement venu, malgré que son inscription n'ait pas été validée, le participant à payé après que son inscription ait été clôturée...<br /><br />
        N'oubliez pas qu'une inscription est fermée automatiquement si les conditions sont remplies. Si vous l'ouvrez à nouveau sans rien modifier, elle sera automatiquement clôturée à nouveau.
      </>,
      maskClosable: true,
      closable: true,
      onOk: () => {
        api.post(`/registrations/${registration.id}/open`).then(data => {
          message.success("Inscription ouverte.")
          refresh()
        }).catch(console.error)
      }
    })
  }

  const cancel = registration => {
    setCurrentRegistration(registration)
    setIsCancelModalVisible(true)
  }

  const addAbsence = registration => {
    setCurrentRegistration(registration)
    setIsAbsenceModalVisible(true)
  }

  const validate = registration => {
    Modal.confirm({
      title: "Êtes-vous sûr ?",
      content: <>
        Valider l'inscription enverra le mail de confirmation au participant, et réservera une place pour lui.
        {!registration.isDepositPaid && <Alert type="warning" showIcon message="Le participant n'a pas encore payé l'acompte !" style={{ marginTop: 15 }} />}
        {registration.training.isFull && <Alert type="warning" showIcon message="La formation est complète !" style={{ marginTop: 15 }} />}
      </>,
      maskClosable: true,
      closable: true,
      onOk: () => {
        api.post(`/registrations/${registration.id}/validate`).then(data => {
          message.success("Inscription validée.")
          refresh()
        }).catch(console.error)
      }
    })
  }

  const validatePresence = registration => {
    api.registrations.put(registration.id, { isPresenceRateOK: true }).then(data => {
      message.success("Taux de présence validé.")
      refresh()
    }).catch(console.error)
  }

  const accept = registration => {
    setCurrentRegistration(registration)
    setIsAcceptFileMailModalVisible(true)
  }

  const reject = registration => {
    setCurrentRegistration(registration)
    setIsRejectFileMailModalVisible(true)
  }

  const sendMail = registration => {
    history.push('/admin/send-mail?registration=' + JSON.stringify({
      id: registration.id,
      training: {
        id: registration.training.id,
        code: registration.training.code
      },
      user: {
        id: registration.user.id,
        name: `${registration.user.firstname} ${registration.user.lastname}`
      }
    }))
  }

  // Show the presence rate column ?
  const showPresence = registrations.length && !!registrations.find(r => r.status === 'validated')

  return (
    <>
      <Table
        dataSource={registrations}
        rowKey="id"
        size="small"
        rowClassName="row-clickable"
        expandable={{
          expandRowByClick: true,
          expandIconColumnIndex: -1,
          expandedRowRender: r => <RegistrationView registration={r} refresh={refresh} />
        }}
        loading={loading}
        pagination={pagination}
        onChange={onChange}
      >
        <Column title="Numéro" render={item => <>{item.number}{item.user.hasImportantNote && <Icon.ImportantNote style={{ marginLeft: 7 }} />}</>} />
        {from !== 'user' && <Column title="Participant" render={item => <TableLink.User user={item.user} />} />}
        {from !== 'training' && <Column title="Formation" render={item => <TableLink.Training training={item.training} hideDate />} />}
        <Column title="Date" render={item => isoDateToDateTime(item.date)} />
        <Column title="Prix" render={item => nfEur(item.price)} />
        <Column title={<>Solde <Icon.Info popover={{
          title: "Codes couleur",
          content: <>
            <span style={{ color: '#52c41a' }}>Vert</span> : solde payé<br />
            <span style={{ color: '#fa8c16' }}>Orange</span> : acompte payé<br />
            <span style={{ color: '#f5222d' }}>Rouge</span> : acompte non payé
          </>
        }} /></>} render={getColoredBalance} />
        <Column title="PQK" render={item => <Icon.Boolean boolean={item.isPQK} noError />} />
        <Column title="CEP" render={item => <Icon.Boolean boolean={item.isCEP} noError />} />
        <Column title="Étudiant" render={item => <Icon.Boolean boolean={item.isStudent} noError />} />
        {showPresence && <Column title="Présence" render={item => <PresenceRate registration={item} />} />}
        {showStatus && <Column title="Statut" render={item => <RegistrationStatus registration={item} />} />}
        {showIsClosed && <Column title="Clôturée" render={item => <Icon.Boolean boolean={item.isClosed} />} />}
        <Column title="Actions" width={1} render={item => <Space>
          {!item.isPresenceRateOK && <TableButton icon="check" success tooltip="Valider le taux de présence" confirmMessage onClick={e => validatePresence(item)} />}
          {item.status === "to-check" && <TableButton icon="check" success tooltip="Accepter l'inscription (passer en liste d'attente)" onClick={e => accept(item)} />}
          {item.status === "to-check" && <TableButton icon="times" danger tooltip="Refuser l'inscription" onClick={e => reject(item)} />}
          {item.status === 'waiting-list' && !item.isClosed && <TableButton icon="check" success tooltip="Valider l'inscription (passer en inscrit)" onClick={e => validate(item)} />}
          <div onClick={e => e.stopPropagation()}>
            <Dropdown arrow overlay={<Menu>
              {!item.isClosed && <Menu.Item key="edit" icon={<Icon icon="pencil" fixedWidth />} onClick={() => edit(item)}>Modifier l'inscription</Menu.Item>}
              {!item.isClosed && <Menu.Item key="add-payment" icon={<Icon icon="money-check-edit-alt" fixedWidth />} onClick={() => addPayment(item)}>Ajouter un paiement</Menu.Item>}
              <Menu.Item key="send" icon={<Icon icon="paper-plane" fixedWidth />} onClick={() => sendMail(item)}>Envoyer un e-mail</Menu.Item>
              {item.status === 'validated' && <Menu.Item key="absence" icon={<Icon icon="user-slash" fixedWidth />} onClick={() => addAbsence(item)}>Encoder des absences</Menu.Item>}
              <Menu.Divider />
              {!item.isClosed && <Menu.Item key="transfer" icon={<Icon icon="share-square" fixedWidth />} onClick={() => transfer(item)} danger>Transférer l'inscription</Menu.Item>}
              {item.status === 'to-transfer' && <Menu.Item key="transfered" icon={<Icon icon="share-square" fixedWidth />} onClick={() => transferDone(item)} danger>Transfert terminé</Menu.Item>}
              {item.isClosed && <Menu.Item key="open" icon={<Icon icon="lock-open" fixedWidth />} onClick={() => open(item)} danger>Ré-ouvrir l'inscription</Menu.Item>}
              {!item.isClosed && <Menu.Item key="cancel" icon={<Icon icon="ban" fixedWidth />} onClick={() => cancel(item)} danger>Désistement</Menu.Item>}
            </Menu>}>
              <Button shape="circle" icon={<Icon icon="ellipsis-v" />} size="small" />
            </Dropdown>
          </div>
        </Space>} />
      </Table>

      <EditRegistrationModal registration={currentRegistration} isVisible={isEditModalVisible} onOK={updateDone} onCancel={() => setIsEditModalVisible(false)} from={from} />
      <AddPaymentModal registration={currentRegistration} isVisible={isPaymentModalVisible} onOK={updateDone} onCancel={() => setIsPaymentModalVisible(false)} />
      <CancelRegistrationModal registration={currentRegistration} isVisible={isCancelModalVisible} onOK={updateDone} onCancel={() => setIsCancelModalVisible(false)} />
      <SendFileMailModal registration={currentRegistration} isVisible={isAcceptFileMailModalVisible} onOK={updateDone} onCancel={() => setIsAcceptFileMailModalVisible(false)} type="accept" />
      <SendFileMailModal registration={currentRegistration} isVisible={isRejectFileMailModalVisible} onOK={updateDone} onCancel={() => setIsRejectFileMailModalVisible(false)} type="reject" />
      <AbsencesModal registration={currentRegistration} isVisible={isAbsenceModalVisible} onOK={updateDone} onCancel={() => setIsAbsenceModalVisible(false)} />
    </>
  )

}

export default RegistrationsList