import React, { useState } from 'react'

import { orderBy } from 'lodash'
import { DateTime } from 'luxon'

import Icon from 'components/Icon'
import { formatPaymentCom, isoDateToDateShort, isoDateToDateTime, nfEur } from 'misc/utils'
import TableButton from 'components/TableButton'
import { PaymentMethod, PaymentStatus } from 'components/Tags'
import EditPaymentModal from '../Payments/EditPaymentModal'
import api from 'services/adminApiService'

import { Descriptions, List, message, Space, Table } from 'antd'
import FileButton from 'components/FileButton'
import SendFileMailModal from 'admin-app/SendFileMailModal'

const { Column } = Table



const RegistrationView = ({ registration, refresh }) => {

  const [isEditPaymentModalVisible, setIsEditPaymentModalVisible] = useState(false)
  const [fileMailModalData, setFileMailModalData] = useState({ visible: false, overwriteDoc: false, type: null })
  const [currentPayment, setCurrentPayment] = useState(null)

  const editPayment = payment => {
    setCurrentPayment(payment)
    setIsEditPaymentModalVisible(true)
  }

  const deletePayment = payment => {
    api.payments.delete(payment.id).then(data => {
      message.success("Paiement correctement supprimé.")
      refresh()
    }).catch(console.error)
  }

  const updateDone = () => {
    setIsEditPaymentModalVisible(false)
    setFileMailModalData({ visible: false, overwriteDoc: false, type: null })
    refresh()
  }

  // Prepare files list
  const orderedActions = orderBy(registration.actions, ['date'], ['desc'])
  const files = []
  if (registration.status !== 'to-check' && registration.status !== 'rejected') {
    files.push({ name: 'Facture', files: orderedActions.filter(a => a.type === 'invoice-sent'), type: 'invoice-sent' })
    files.push({ name: 'Reconnaissance de dette', files: orderedActions.filter(a => a.type === 'ack-of-debt-generated'), type: 'ack-of-debt-generated' })
    files.push({ name: 'Etalement de paiement', files: orderedActions.filter(a => a.type === 'staggered-payments-generated'), type: 'staggered-payments-generated' })
  }
  if (registration.status !== "waiting-list" && DateTime.fromISO(registration.training.start).plus({ days: 1 }) < DateTime.now()) {
    files.push({ name: 'Attestation de suivi', files: orderedActions.filter(a => a.type === 'attendance-certificate-sent'), type: 'attendance-certificate-sent' })
  }
  if (orderedActions.filter(a => a.type === 'pro-forma-invoice').length) {
    files.push({ name: 'Facture pro-forma', files: orderedActions.filter(a => a.type === 'pro-forma-invoice'), type: 'pro-forma-invoice' })
  }

  const getActions = (f) => {
    switch (f.type) {
      case 'ack-of-debt-generated': return [<Space>
        <FileButton.Edit overwriteDoc={true} registration={registration} refresh={refresh} type="ack-of-debt" disabled={registration.balance >= 0} />
        <FileButton.Download registration={registration} refresh={refresh} type="ack-of-debt" disabled={registration.balance >= 0} />
      </Space>]
      case 'staggered-payments-generated': return [<Space>
        <FileButton.Edit overwriteDoc={true} registration={registration} refresh={refresh} type="staggered-payments" disabled={registration.balance >= 0} />
      </Space>]
      case 'attendance-certificate-sent': return [<Space>
        <FileButton.Edit overwriteDoc={true} registration={registration} refresh={refresh} type="attendance-certificate" tooltip="Modifier avant envoi" onOK={() => setFileMailModalData({ visible: true, overwriteDoc: false, type: "attendance-certificate" })} />
        <FileButton.Simple tooltip="Envoyer" icon="paper-plane" onClick={() => setFileMailModalData({ visible: true, overwriteDoc: true, type: "attendance-certificate" })} />
      </Space>]
      default: return []
    }
  }

  return <>
    <Descriptions bordered size="small" column={1} className="registration-descriptions">
      <Descriptions.Item label={<><Icon icon="graduation-cap" /> Diplômes</>}>{registration.user.diplomas}</Descriptions.Item>
      <Descriptions.Item label={<><Icon icon="university" /> Etablissements</>}>{registration.user.diplomasInstitutions}</Descriptions.Item>
      {registration.isPQK && <Descriptions.Item label={<><Icon icon="user-md" /> Numéro INAMI</>}>{registration.inamiNumber}</Descriptions.Item>}
      <Descriptions.Item label={<><Icon icon="money-check-edit-alt" /> Communication struct.</>}>{formatPaymentCom(registration.paymentCommunication)}</Descriptions.Item>
      <Descriptions.Item label={<><Icon icon="copy" /> Documents</>}>
        {files.length ? <List dataSource={files} size="small" renderItem={file => (
          <List.Item actions={getActions(file)}>
            <List.Item.Meta
              title={file.name}
              description={<Space direction="vertical">{file.files.map(a => <div key={a.id} className="file-item">
                <Icon icon={a.isDone ? "check" : "hourglass"} /> {isoDateToDateTime(a.date)} {JSON.parse(a.data)?.values?.invoice ? `[${nfEur(JSON.parse(a.data)?.values?.invoice?.amount)}]` : ''} <FileButton.Open file={JSON.parse(a.data)?.pdfFile} />
              </div>)}</Space>}
            />
          </List.Item>
        )} /> : "Aucun document jusqu'à présent..."}
      </Descriptions.Item>
      <Descriptions.Item label={<><Icon icon="credit-card" /> Paiements</>}>
        {registration.payments.length
          ? <Table dataSource={registration.payments} size="small" rowKey="id" pagination={false}>
            <Column title="Date d'encodage" render={item => isoDateToDateShort(item.date)} />
            <Column title="Date du paiement" render={item => isoDateToDateShort(item.paymentDate)} />
            <Column title="Montant" render={item => `${item.amount} €`} />
            <Column title="Moyen de paiement" render={item => <PaymentMethod payment={item} />} />
            <Column title="Statut" render={item => <PaymentStatus payment={item} />} />
            <Column title="Note" dataIndex="note" />
            <Column title="Actions" width={40} render={item => <Space>
              <TableButton confirmMessage={<>Êtes-vous sûr ?<br />Modifier un paiement manuellement<br />n'est pas recommandé.</>} icon="pencil" onClick={() => editPayment(item)} />
              <TableButton confirmMessage={<>Êtes-vous sûr ?<br />Cette opération est irréversible.</>} icon="trash" onClick={() => deletePayment(item)} danger />
            </Space>} />
          </Table>
          : <>Aucun paiement jusqu'à présent...</>
        }
      </Descriptions.Item>
    </Descriptions>

    <EditPaymentModal payment={currentPayment} isVisible={isEditPaymentModalVisible} onOK={updateDone} onCancel={() => setIsEditPaymentModalVisible(false)} />
    <SendFileMailModal
      registration={registration}
      isVisible={fileMailModalData.visible}
      onOK={updateDone}
      onCancel={() => setFileMailModalData({ visible: false, overwriteDoc: false, type: null })}
      overwriteDoc={fileMailModalData.overwriteDoc}
      type={fileMailModalData.type}
    />
  </>
}

export default RegistrationView