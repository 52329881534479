import React, { useEffect, useState } from 'react'

import moment from 'moment'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import DraggableModal from 'components/DraggableModal'

import { DatePicker, Form, Input, InputNumber, Select, Switch } from 'antd'



const AddPaymentModal = ({ registration, isVisible, onOK, onCancel }) => {

  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    if (isVisible) {
      form.resetFields()
      form.setFieldValue('iban', registration?.payments?.find(p => p.iban)?.iban)
    }
  }, [isVisible, form, registration])

  const save = values => {
    if (values.type !== 'refund') delete values.isDone
    api.payments.post({ ...values, registrationId: registration.id }, { loader: setIsLoading })
      .then(onOK)
      .catch(console.error)
  }

  return (
    <DraggableModal
      title={<><Icon icon="money-check-edit-alt" fixedWidth /> Ajouter un paiement</>}
      open={isVisible}
      maskClosable={false}
      onCancel={onCancel}
      onOk={form.submit}
      okText="Enregistrer"
      okButtonProps={{ icon: <Icon icon="save" />, loading: isLoading }}
    >
      <Form form={form} layout="vertical" onFinish={save}>
        <Form.Item name="type" label="Type de paiement" rules={[{ required: true }]}>
          <Select>
            <Select.Option value="payment">Paiement</Select.Option>
            <Select.Option value="refund">Remboursement</Select.Option>
            <Select.Option value="holdback">Retenue</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="amount" label="Montant" rules={[{ required: true }]}>
          <InputNumber precision={2} style={{ width: 100 }} className="input-number-euro" />
        </Form.Item>
        <Form.Item name="paymentDate" label="Date du paiement" initialValue={moment()}>
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prev, curr) => prev.type !== curr.type}>
          {({ getFieldValue }) => getFieldValue('type') === "refund" &&
            <Form.Item name="isDone" label="Remboursement déjà effectué ?" valuePropName="checked" initialValue={false}>
              <Switch checkedChildren="Oui" unCheckedChildren="Non" />
            </Form.Item>
          }
        </Form.Item>
        <Form.Item name="iban" label="Compte bancaire">
          <Input />
        </Form.Item>
        <Form.Item name="note" label="Note (raison du remboursement...)">
          <Input />
        </Form.Item>
      </Form>

    </DraggableModal >
  )

}

export default AddPaymentModal