import React, { useEffect, useState } from 'react'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import { eventDateTime, fullname } from 'misc/utils'
import DraggableModal from 'components/DraggableModal'

import { Form, Table, Switch } from 'antd'

const { Column } = Table



const AbsencesModal = ({ registration, isVisible, onOK, onCancel }) => {

  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    // Convert the absences array into form values [{id: 1}] => {1: false, 2: true, 3: true}
    const values = registration?.training.events?.reduce((res, value) => {
      res[value.id] = !registration.absences.find(item => item.id === value.id)
      return res
    }, {})
    if (isVisible) form.setFieldsValue(values)
  }, [isVisible, form, registration])

  const save = values => {
    const absences = []
    for (const id in values) {
      if (!values[id]) absences.push({ id: Number(id) })
    }
    api.registrations.put(registration.id, { absences }, { loader: setIsLoading })
      .then(onOK)
      .catch(console.error)
  }

  if (!registration) return <></>

  return (
    <DraggableModal
      title={<><Icon icon="user-slash" fixedWidth /> Encoder les absences : {fullname(registration?.user)}</>}
      open={isVisible}
      maskClosable={false}
      onCancel={onCancel}
      onOk={form.submit}
      okText="Enregistrer"
      okButtonProps={{ icon: <Icon icon="save" />, loading: isLoading }}
    >
      <Form form={form} layout="vertical" onFinish={save}>
        <Table dataSource={registration.training.events} pagination={false} size="small" rowKey="id">
          <Column title="Date" render={event => eventDateTime(event.start, event.end)} />
          <Column title="Présence" width={50} render={event => (
            <Form.Item name={event.id} noStyle valuePropName="checked" initialValue={true}>
              <Switch checkedChildren="Oui" unCheckedChildren="Non" />
            </Form.Item>
          )} />
        </Table>
      </Form>

    </DraggableModal >
  )

}

export default AbsencesModal