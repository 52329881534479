import React from 'react'

import { Link } from 'react-router-dom'

import Icon from 'components/Icon'

import { Button, Result } from 'antd'



const NotFound = ({ backTo = "/login" }) => {

  return (
    <Result
      status="404"
      title="Oups !"
      subTitle="Je crois qu'on s'est perdus... La page n'existe pas..."
      extra={<Link to={backTo}><Button type="primary" icon={<Icon icon="home" />}>Revenir à l'accueil</Button></Link>}
    />
  )

}

export default NotFound