import React, { useContext, useState } from 'react'

import FullCalendar from '@fullcalendar/react'
import frLocale from '@fullcalendar/core/locales/fr'
import luxonPlugin from '@fullcalendar/luxon'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

import moment from 'moment'
import { DateTime } from 'luxon'
import { flatten, uniqBy } from "lodash"

import './Planning.css'

import Icon from 'components/Icon'
import TrainersCard from './TrainersCard'
import api from 'services/adminApiService'
import { isoDateToDateShort, jsDateToHours } from 'misc/utils'
import SelectsContext from 'contexts/SelectsContext'
import SitesCard from './SitesCard'
import DraggableModal from 'components/DraggableModal'

import { Card, DatePicker, Form, Select, Row, Col, Descriptions, InputNumber, Input, Button, Popconfirm, Spin, Badge } from 'antd'




const Planning = ({ training, refreshTraining }) => {

  const [saving, setSaving] = useState(false)

  const [form] = Form.useForm()

  const { getClassroom, getSiteClassroomsWithout } = useContext(SelectsContext)

  const [modalStatus, setModalStatus] = useState({ visible: false, new: false, id: null })

  const hideModal = () => setModalStatus({ visible: false, new: false, id: null })

  const copyEvent = () => {
    hideModal()
    setTimeout(() => setModalStatus({ visible: true, new: true, id: null }), 300)
  }

  const addEvent = () => {
    form.validateFields().then(values => {
      api.events.post({ ...values, training: training.id }, { loader: setSaving }).then(() => {
        hideModal()
        refreshTraining()
      }).catch(console.error)
    }).catch(e => { })
  }

  const updateEvent = () => {
    form.validateFields().then(values => {
      api.events.put(modalStatus.id, { ...values, training: training.id }, { loader: setSaving }).then(() => {
        hideModal()
        refreshTraining()
      }).catch(console.error)
    }).catch(e => { })
  }

  const deleteEvent = () => {
    api.events.delete(modalStatus.id, { loader: setSaving }).then(() => {
      hideModal()
      refreshTraining()
    }).catch(console.error)
  }

  const onSelect = info => {
    form.setFieldsValue({ start: moment(info.start), end: moment(info.end), tableHours: 0, trainers: [], classrooms: [], notes: null })
    setModalStatus({ visible: true, new: true, id: null })
  }

  const onEventClick = ({ event }) => {
    form.setFieldsValue({
      start: moment(event.start),
      end: moment(event.end),
      tableHours: event.extendedProps.tableHours,
      trainers: event.extendedProps.trainers.map(t => t.id),
      classrooms: event.extendedProps.classrooms.map(t => t.id),
      notes: event.extendedProps.notes
    })
    setModalStatus({ visible: true, new: false, id: event.id })
  }

  const weekEventContent = arg => <>
    <strong>{arg.timeText}</strong>
    {arg.event.extendedProps.tableHours ? <span> ({arg.event.extendedProps.tableHours}h de table)</span> : ''}
    <br />
    {arg.event.extendedProps.trainers?.map(t => t.firstname + ' ' + t.lastname).join(', ')}{/* Search trainer into select instead of receiving it from backend */}
    {arg.event.extendedProps.classrooms && <>{arg.event.extendedProps.classrooms.map(c => <div key={c.id}>{c.name} <small><i>{getClassroom(c.id)?.site.name}</i></small><br /></div>)}</>}
  </>

  const listEventContent = arg => <>
    <strong>{arg.event.extendedProps.trainers?.map(t => t.firstname + ' ' + t.lastname).join(', ') || '-'}</strong>
    {arg.event.extendedProps.classrooms && <>{arg.event.extendedProps.classrooms.map(c => <div key={c.id}>{c.name} <small><i>{getClassroom(c.id)?.site.name}</i></small><br /></div>)}</>}
  </>

  const monthEventContent = arg => <div>
    <div className="fc-daygrid-event-dot month-dot"></div>
    <strong>{jsDateToHours(arg.event.start)} - {jsDateToHours(arg.event.end)}</strong>
    {arg.event.extendedProps.tableHours ? <span> ({arg.event.extendedProps.tableHours}h de table)</span> : ''}
  </div>

  if (!training) return <Row justify="center" style={{ marginTop: 50 }}><Col><Spin size="large" /></Col></Row>

  const recentClassrooms = uniqBy(flatten(training?.events.map(e => e.classrooms) || []), 'id')

  return (
    <>
      <Row gutter={16}>

        <Col span={18}>
          <Card title={<><Icon icon="calendar-alt" /> Planning <Badge count={training?.events?.length || 0} style={{ backgroundColor: "#1890ff" }} showZero /></>}>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, luxonPlugin]}
              headerToolbar={{
                start: 'prev,next today',
                center: 'title',
                end: 'month,week,listAll'
              }}
              views={{
                listAll: {
                  type: 'list',
                  visibleRange: { start: training.start || DateTime.now().toJSDate(), end: training.end || DateTime.now().toJSDate() },
                  titleFormat: "'Tous les cours'",
                  eventContent: listEventContent
                },
                week: {
                  type: 'timeGridWeek',
                  eventContent: weekEventContent
                },
                month: {
                  type: 'dayGridMonth',
                  eventContent: monthEventContent
                }
              }}
              initialView="listAll"
              initialDate={training.start || DateTime.now().toJSDate()}
              allDaySlot={false}
              events={training.events}
              selectable={true}
              selectOverlap={false}
              selectAllow={({ start, end }) => moment(start).isSame(end, 'day')}
              height={700}
              locale={frLocale}
              select={onSelect}
              eventClick={onEventClick}
            />
          </Card>
        </Col>

        <Col span={6} >
          <Card title={<><Icon icon="info-circle" /> Informations</>}>
            <Descriptions column={1} bordered size="small">
              <Descriptions.Item label="Début">{training.start ? isoDateToDateShort(training.start) : '-'}</Descriptions.Item>
              <Descriptions.Item label="Fin">{training.end ? isoDateToDateShort(training.end) : '-'}</Descriptions.Item>
              <Descriptions.Item label="Heures prévues">{training.hours} h</Descriptions.Item>
              <Descriptions.Item label={<>Heures total <Icon.Info tooltip="Les heures sont calculées hors heures de table." /></>}>{training.totalHours} h</Descriptions.Item>
              {training.isCFAccepted && <>
                <Descriptions.Item label="Heures CF prévues">{training.CFHours} h</Descriptions.Item>
                <Descriptions.Item label={<>Heures CF total <Icon.Info tooltip="Les heures CF sont calculées sur une base de 7h par cours." /></>}>{training.totalCFHours} h</Descriptions.Item>
              </>}
            </Descriptions>
          </Card>
          <TrainersCard training={training} refreshTraining={refreshTraining} style={{ marginTop: 16 }} />
          <SitesCard training={training} refreshTraining={refreshTraining} style={{ marginTop: 16 }} />
        </Col>

      </Row>

      <DraggableModal
        title={modalStatus.new ? <><Icon icon={"calendar-plus"} /> Ajouter un cours</> : <><Icon icon={"calendar-edit"} /> Modifier le cours</>}
        open={modalStatus.visible}
        closable
        maskClosable={false}
        onCancel={hideModal}
        footer={<Row justify="space-between">
          <Col>
            <Popconfirm title="Êtes-vous sûr ?" onConfirm={deleteEvent}><Button key="delete" danger hidden={modalStatus.new} icon={<Icon icon="trash" />} loading={saving}>Supprimer</Button></Popconfirm>
            <Button key="copy" hidden={modalStatus.new} icon={<Icon icon="copy" />} onClick={copyEvent}>Dupliquer</Button>
          </Col>
          <Col>
            <Button key="cancel" onClick={hideModal}>Annuler</Button>
            {modalStatus.new
              ? <Button key="ok" type="primary" icon={<Icon icon="plus" />} onClick={addEvent} loading={saving}>Ajouter</Button>
              : <Button key="ok" type="primary" icon={<Icon icon="save" />} onClick={updateEvent} loading={saving}>Enregistrer</Button>}
          </Col>
        </Row>}
      >
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          <Form.Item label="Début" name="start" required rules={[{ required: true, message: 'Veuillez compléter la date de début' }]}>
            <DatePicker format="DD/MM/YYYY    HH:mm" showTime={true} minuteStep={5} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label="Fin" name="end" dependencies={['start']} rules={[
            { required: true, message: 'Veuillez compléter la date de fin' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!moment(getFieldValue('start')).isSame(value, 'day')) return Promise.reject(new Error('Un événement doit être sur une seule journée.'))
                if (!moment(getFieldValue('start')).isBefore(value)) return Promise.reject(new Error('La fin doit être avant le début.'))
                return Promise.resolve()
              }
            })
          ]}>
            <DatePicker format="DD/MM/YYYY    HH:mm" showTime={true} minuteStep={5} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label="Heures de table" name="tableHours">
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item label="Formateurs" name="trainers">
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              options={training.trainers.map(t => ({ label: `${t.user.firstname} ${t.user.lastname}`, value: t.user.id }))}
            />
          </Form.Item>
          <Form.Item label="Locaux" name="classrooms">
            <Select mode="multiple" allowClear style={{ width: '100%' }} >
              {recentClassrooms?.length && <Select.OptGroup key="recent" label="Locaux récents">
                {recentClassrooms.map(classroom =>
                  <Select.Option key={`recent${classroom.id}`} value={classroom.id}>{classroom.name} <small><i>{getClassroom(classroom.id)?.site.name}</i></small></Select.Option>
                )}
              </Select.OptGroup>}
              {training.sites.map(({ site }) =>
                <Select.OptGroup key={site.id} label={site.name}>
                  {getSiteClassroomsWithout(site.id, recentClassrooms).map(classroom =>
                    <Select.Option key={classroom.id} value={classroom.id}>{classroom.name} <small><i>{site.name}</i></small></Select.Option>
                  )}
                </Select.OptGroup>
              )}
            </Select>
          </Form.Item>
          <Form.Item label="Notes" name="notes">
            <Input.TextArea autoSize={{ minRows: 3 }} />
          </Form.Item>
        </Form>
      </DraggableModal>
    </>
  )

}

export default Planning