import React, { useState } from 'react'

import api from 'services/adminApiService'
import { useMap } from 'react-use'
import { partial } from 'lodash'

import { Button, Card, PageHeader, DatePicker, Divider, Typography } from 'antd'
import Icon from 'components/Icon'



const Reports = () => {

  const [isLoading, { set }] = useMap({ clientsCompta: false, registrations: false, cep: false, trainings: false, trainers: false, clients: false, events: false, 'trainings-calendar-quarters': false })
  const [range, setRange] = useState([])

  const download = (report) => {
    const params = { dates: [] }
    if (range?.[0]) params.dates[0] = range[0].toISOString()
    if (range?.[1]) params.dates[1] = range[1].toISOString()
    api.get(`/reports/${report}?query=${JSON.stringify(params)}`, { loader: partial(set, report) }).then(data => {
      const a = document.createElement('a')
      a.href = window.URL.createObjectURL(data)
      a.download = `${report}.xlsx`
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
  }

  return (
    <>
      <PageHeader title={<><Icon icon="file-alt" fixedWidth /> Rapports</>} />
      <Card>
        Dates pour les rapports (facultatif) :
        <DatePicker.RangePicker allowClear allowEmpty={[true, true]} onChange={setRange} style={{ marginLeft: 10, marginRight: 15 }} />
        Si vous ne spécifiez pas de dates, toutes les données seront téléchargées.
        <Divider />
        <Button icon={<Icon icon="users" />} onClick={() => download('clients-compta')} loading={isLoading.clientsCompta}>Comptabilité - Listing Clients</Button>
        <Typography.Text disabled italic style={{ marginLeft: 10 }}>Pas de filtre possible</Typography.Text>
        <br /><br />
        <Button icon={<Icon icon="file-signature" />} onClick={() => download('registrations')} loading={isLoading.registrations}>Comptabilité - Inscriptions</Button>
        <Typography.Text disabled italic style={{ marginLeft: 10 }}>Filtrable selon la date d'inscription.</Typography.Text>
        <br /><br />
        <Button icon={<Icon icon="file-user" />} onClick={() => download('cep')} loading={isLoading.cep}>Attestations CEP</Button>
        <Typography.Text disabled italic style={{ marginLeft: 10 }}>Filtrable selon la date d'inscription.</Typography.Text>
        <br /><br />
        <Button icon={<Icon icon="users" />} onClick={() => download('clients')} loading={isLoading.clients}>Liste des Clients</Button>
        <Typography.Text disabled italic style={{ marginLeft: 10 }}>Pas de filtre possible</Typography.Text>
        <br /><br />
        <Button icon={<Icon icon="graduation-cap" />} onClick={() => download('trainings')} loading={isLoading.trainings}>Liste des formations</Button>
        <Typography.Text disabled italic style={{ marginLeft: 10 }}>Filtrable selon la date de début de la formation.</Typography.Text>
        <br /><br />
        <Button icon={<Icon icon="chalkboard-teacher" />} onClick={() => download('trainers')} loading={isLoading.trainers}>Liste des formateurs</Button>
        <Typography.Text disabled italic style={{ marginLeft: 10 }}>Filtrable selon la date de début de la formation.</Typography.Text>
        <br /><br />
        <Button icon={<Icon icon="calendar" />} onClick={() => download('events')} loading={isLoading.events}>Liste des événemenets (calendrier)</Button>
        <Typography.Text disabled italic style={{ marginLeft: 10 }}>Filtrable selon la date de début de l'événement.</Typography.Text>
        <br /><br />
        <Button icon={<Icon icon="calendar-day" />} onClick={() => download('trainings-calendar-quarters')} loading={isLoading['trainings-calendar-quarters']}>Répartition du temps de formation par trimestre</Button>
        <Typography.Text disabled italic style={{ marginLeft: 10 }}>Pas de filtre possible</Typography.Text>
      </Card>

    </>
  )

}

export default Reports