import React from 'react'

import {Link, useLocation} from 'react-router-dom'

import { Typography } from 'antd'
import {fullname, getAppPath, isoDateToDateShort} from 'misc/utils'
import Icon from './Icon'

const { Text } = Typography



const TableLink = ({ to, children, newTab = false }) => (
  newTab
    ? <Link to={to} onClick={e => e.stopPropagation()} target="_blank" rel="noopener noreferrer">
      {children} <Icon icon="external-link" size="xs" />
    </Link>
    : <Link to={to} onClick={e => e.stopPropagation()}>
      {children}
    </Link>
)

export default TableLink



const Training = ({ training, hideDate = false, newTab = false }) => {
  const location= useLocation()
  const appPath = getAppPath(location.pathname)
  return (
    training ? <>
      <TableLink to={`${appPath}/training/${training.id}`} newTab={newTab}>
        {training.code}
      </TableLink>
      {training.start && !hideDate ? <Text type="secondary" italic style={{ marginLeft: 7, fontSize: '80%' }}>
        {isoDateToDateShort(training.start)} - {isoDateToDateShort(training.end)}
      </Text> : null}
    </> : null
  )
}

const User = ({ user, text }) => {
  const location= useLocation()
  const appPath = getAppPath(location.pathname)
  const name = text ? text : fullname(user)
  return user ? <TableLink to={`${appPath}/user/${user.id}`}>{name}</TableLink> : null
}


TableLink.Training = Training
TableLink.User = User