import React, { useState } from 'react'

import moment from 'moment'

import api from 'services/adminApiService'
import Icon from 'components/Icon'
import { TrainingVisibility, RegistrationsStatus, TrainingStatus } from 'components/Tags'
import DraggableModal from 'components/DraggableModal'

import { Card, Table, Button, message, Space, Modal, Form, DatePicker } from 'antd'

const { Column } = Table



const StatusCard = ({ training, setTraining }) => {

  const [saving, setSaving] = useState(false)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [form] = Form.useForm()

  const toggleVisibility = () => {
    api.trainings.put(training.id, { isPublished: !training.isPublished }, { loader: setSaving }).then(data => {
      setTraining(data)
      message.success(`La formation est maintenant ${data.isPublished ? "publiée" : "invisible"}.`)
    }).catch(console.error)
  }

  const confirmTraining = () => {
    Modal.confirm({
      title: "Êtes-vous sûr ?",
      content: "Ceci confirmera la formation, et enverra un mail à tous les participants, 20J avant la formation, avec les informations pratiques.",
      maskClosable: true,
      closable: true,
      onOk: () => {
        api.trainings.put(training.id, { status: 'confirmed' }, { loader: setSaving }).then(data => {
          setTraining(data)
          message.success("La formation est confirmée.")
        }).catch(console.error)
      }
    })
  }

  const cancelTraining = () => {
    Modal.confirm({
      title: "Êtes-vous sûr ?",
      content: <>Ceci annulera la formation, et enverra un mail à tous les participants pour le signaler. De même, cela créera des remboursements pour tous les paiements effectués jusqu'à présent par les participants concernés.<br /><strong>Cette opération est irréversible !</strong></>,
      maskClosable: true,
      closable: true,
      onOk: () => {
        api.trainings.put(training.id, { status: 'cancelled' }, { loader: setSaving }).then(data => {
          setTraining(data)
          message.success("La formation est annulée.")
        }).catch(console.error)
      }
    })
  }

  const openRegistrations = () => {
    Modal.confirm({
      title: "Êtes-vous sûr ?",
      content: <>Ceci ouvrira le formulaire d'inscription pour cette formation.<br /><br />Vérifiez que la formation est bien publiée sur le site, sans quoi les participants ne verront pas la formation.</>,
      maskClosable: true,
      closable: true,
      onOk: () => {
        api.trainings.put(training.id, { registrationsStatus: 'open' }, { loader: setSaving }).then(data => {
          setTraining(data)
          message.success("Les inscriptions sont maintenant ouvertes.")
        }).catch(console.error)
      }
    })
  }

  const closeRegistrations = () => {
    Modal.confirm({
      title: "Êtes-vous sûr ?",
      content: "Ceci fermera le formulaire d'inscription pour cette formation. Aucune nouvelle inscription sera acceptée.",
      maskClosable: true,
      closable: true,
      onOk: () => {
        api.trainings.put(training.id, { registrationsStatus: 'closed' }, { loader: setSaving }).then(data => {
          setTraining(data)
          message.success("Les inscriptions sont maintenant fermées.")
        }).catch(console.error)
      }
    })
  }

  const openRegistrationsOn = values => {
    api.trainings.put(training.id, { registrationsStatus: values.date.startOf('day') }, { loader: setSaving }).then(data => {
      setIsModalVisible(false)
      setTraining(data)
      message.success(`Les inscriptions seront ouvertes le ${values.date.format("L")}.`)
    }).catch(console.error)
  }

  const status = [
    {
      id: 1,
      title: "Publiée sur le site",
      status: <TrainingVisibility training={training} />,
      actions: <><Button size="small" icon={<Icon icon="eye" />} onClick={toggleVisibility} loading={saving}>{training.isPublished ? "Cacher" : "Publier"}</Button></>
    },
    {
      id: 2,
      title: "Inscriptions",
      status: <RegistrationsStatus training={training} />,
      actions: <Space wrap>
        {training.registrationsStatus !== 'open' && <Button size="small" icon={<Icon icon="lock-open" />} onClick={openRegistrations} loading={saving}>Ouvrir</Button>}
        {training.registrationsStatus === 'closed' && <Button size="small" icon={<Icon icon="clock" />} onClick={() => setIsModalVisible(true)} loading={saving}>Ouvrir le</Button>}
        {training.registrationsStatus !== 'closed' && <Button size="small" icon={<Icon icon="lock" />} danger onClick={closeRegistrations} loading={saving}>Fermer</Button>}
      </Space>
    },
    {
      id: 3,
      title: "Organisation",
      status: <TrainingStatus training={training} />,
      actions: <Space wrap>
        {training.status === 'project' && <Button size="small" icon={<Icon icon="check" />} onClick={confirmTraining} loading={saving}>Confirmer</Button>}
        {training.status !== 'cancelled' && <Button size="small" icon={<Icon icon="ban" />} danger onClick={cancelTraining} loading={saving}>Annuler</Button>}
      </Space>
    }
  ]

  return (
    <Card title={<><Icon icon="sliders-v" /> État</>}>
      <Table dataSource={status} rowKey="id" size="middle" pagination={false} showHeader={false}>
        <Column dataIndex="title" />
        <Column dataIndex="status" />
        <Column dataIndex="actions" width={100} />
      </Table>
      <DraggableModal
        title={<><Icon icon="clock" fixedWidth /> Ouvrir la formation le</>}
        open={isModalVisible}
        maskClosable={true}
        onCancel={() => setIsModalVisible(false)}
        onOk={form.submit}
      >
        <Form form={form} onFinish={openRegistrationsOn}>
          <Form.Item name="date" label="Date d'ouverture" rules={[{ required: true }]}>
            <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} disabledDate={(date) => date.isBefore(moment())} />
          </Form.Item>
        </Form>
      </DraggableModal>
    </Card>
  )

}

export default StatusCard