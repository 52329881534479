import React, { useState } from 'react'

import './PaymentModal.less'

import Icon from 'components/Icon'
import { PriceStatus } from 'components/Tags'
import { formatPaymentCom } from 'misc/utils'
import CFPaymentModal from './CFPaymentModal'

import { Button, Col, Descriptions, Divider, Modal, Row } from 'antd'



const PaymentModal = ({ registration, isVisible, onOK, onCancel }) => {

  const [currentStep, setCurrentStep] = useState('choice')

  const [isCFModalVisible, setIsCFModalVisible] = useState(false)


  const CFPaymentsOK = registration.payments.filter(p => p.paymentCF?.status === "accepted" || p.paymentCF?.status === "invoiced" || p.paymentCF?.status === "paid")
  const CFBalance = CFPaymentsOK.reduce((acc, curr) => acc + curr.amount, 0)
  const paid = registration.price + registration.balance - CFBalance
  const deposit = registration.training.deposit
  const paymentCommunication = formatPaymentCom(registration.paymentCommunication)

  const close = () => {
    setCurrentStep('choice')
    onCancel()
  }

  const openCFModal = () => {
    close()
    setIsCFModalVisible(true)
  }

  const payDeposit = () => {
    if (!registration.isDepositPaid) setCurrentStep('deposit')
  }

  const payAll = () => {
    if (registration.balance < 0) setCurrentStep('solde')
  }

  return (
    <>
      <Modal className="payment-modal" title={<><Icon icon="money-check-alt" /> Paiement de la formation</>} open={isVisible} width="650px" footer={null} onCancel={close} maskClosable={false}>
        {currentStep === 'choice' && <>
          <p>Prix de la formation : {registration.price} € &nbsp;&nbsp;<PriceStatus registration={registration} /></p>
          <p>Accompte à verser pour valider l'inscription : {deposit} €</p>
          <p>Montant déjà payé : {paid} €</p>
          <Divider />
          <div className="price-list">
            <div className={`price-card ${registration.isDepositPaid ? "price-card-disabled" : ''}`} onClick={payDeposit}>
              <div className="price-card-content">Je paye l'acompte et paierai le solde plus tard.</div>
              <div className="price-card-footer">{deposit - paid <= 0 ? "Déjà payé" : deposit - paid + " €"}</div>
            </div>
            <div className={`price-card ${registration.balance >= 0 ? "price-card-disabled" : ''}`} onClick={payAll}>
              <div className="price-card-content">Je paye le solde.</div>
              <div className="price-card-footer">{-registration.balance} €</div>
            </div>
            <div className="price-card" hidden={!registration.training.isCFAccepted} onClick={openCFModal}>
              <div className="price-card-content">Je souhaite payer avec des chèques formation.</div>
              <div className="price-card-footer" style={{ fontSize: 14, fontWeight: 'lighter' }}>Acompte de 140€ remboursé ensuite.</div>
            </div>
          </div>
        </>}
        {(currentStep === "solde" || currentStep === "deposit") && <>
          <p>Merci d'affectuer le paiement de {currentStep === "solde" ? -registration.balance : deposit - paid} € sur le compte bancaire du Centre de Formation Continue</p>
          <Descriptions column={1} bordered size="small">
            <Descriptions.Item label="Montant">{currentStep === "solde" ? -registration.balance : deposit - paid} €</Descriptions.Item>
            <Descriptions.Item label="Compte bancaire">BE04-0682-4057-9831</Descriptions.Item>
            <Descriptions.Item label="Destinataire">HE Vinci asbl</Descriptions.Item>
            <Descriptions.Item label="Communication structurée">{paymentCommunication}</Descriptions.Item>
          </Descriptions>
          <br />
          <p>Dès que nous reçevrons votre paiement, votre inscription sera mise à jour. Notez que le délai de traitement pour un paiement par virement bancaire peut prendre plusieurs jours.</p>
          <Divider />
          <Row justify="space-between">
            <Col><Button icon={<Icon icon="arrow-left" />} onClick={() => setCurrentStep('choice')}>Prédécent</Button></Col>
            <Col><Button type="primary" htmlType="submit" icon={<Icon icon="check" />} onClick={close}>OK</Button></Col>
          </Row>
        </>}
      </Modal>

      <CFPaymentModal onOK={() => { setIsCFModalVisible(false); onOK() }} onCancel={() => { setIsCFModalVisible(false); onCancel() }} registration={registration} isVisible={isCFModalVisible} />
    </>
  )

}

export default PaymentModal