import React, {useEffect, useState} from 'react'

import api from 'services/adminApiService'

import {Button, Card, Descriptions, Form, Input, message, Space} from 'antd'
import Icon from "../../components/Icon";
import {address} from "../../misc/utils";



const Profile = ({ user, setUser }) => {

  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(user)
  }, [user, form])

  const onFinishForm = (values) => {
    setIsLoading(true)
    api.users.put(user.id, values)
      .then(user => {
        setIsLoading(false)
        setUser(user)
        message.success("Modifications sauvegardés.")
      })
      .catch(e => {
        setIsLoading(false)
        console.error(e)
      })
  }

  return (
    <>
      <Form form={form} onFinish={onFinishForm} layout="vertical">
        <Card title={<><Icon icon="clipboard-user" /> Informations personnelles</>}>
          <Descriptions bordered size="small" column={1}>
            <Descriptions.Item label="Numéro de client">{user.clientNumber}</Descriptions.Item>
            <Descriptions.Item label="Numéro de client comptabilité">
              <Space.Compact size="small">
                <Form.Item name="invoicingClientNumber" noStyle>
                  <Input placeholder="Numéro de client comptabilité" />
                </Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit" icon={<Icon icon="save" />} style={{width: 30}}></Button>
              </Space.Compact>

            </Descriptions.Item>
            <Descriptions.Item label="Prénom">{user.firstname}</Descriptions.Item>
            <Descriptions.Item label="Nom">{user.lastname}</Descriptions.Item>
            <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
            <Descriptions.Item label="Adresse">{address(user)}</Descriptions.Item>
          </Descriptions>
        </Card>
      </Form>
    </>
  )

}

export default Profile


