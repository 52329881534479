
import escapeHtml from 'escape-html'
import { jsx } from 'slate-hyperscript'
import { isArray, isString } from 'lodash'
import { Text } from 'slate'



export const EMPTY_VALUE = [{ type: "paragraph", children: [{ text: "" }] }];

export const serialize = node => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)
    if (node.bold) string = `<strong>${string}</strong>`
    if (node.code) string = `<code>${string}</code>`
    if (node.italic) string = `<em>${string}</em>`
    if (node.underline) string = `<u>${string}</u>`
    return string
  }
  const children = (isArray(node) ? node : node.children).map(n => serialize(n)).join('')

  switch (node.type) {
    case 'quote':
      return `<blockquote>${children}</blockquote>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    case 'heading-two':
      return `<h2>${children}</h2>`
    case 'heading-three':
      return `<h3>${children}</h3>`
    case 'list-item':
      return `<li>${children}</li>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`
    case 'paragraph':
      return `<p>${children}</p>`
    default:
      return children
  }
}

export const deserialize = el => {
  if (!el) return []
  if (isString(el)) el = new DOMParser().parseFromString(el, 'text/html').body
  if (el.nodeType === 3) {
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  }

  let children = Array.from(el.childNodes).map(deserialize)

  if (children.length === 0) {
    children = [{ text: '' }]
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children)
    case 'BR':
      return '\n'
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'quote' }, children)
    case 'P':
      return jsx('element', { type: 'paragraph' }, children)
    case 'H2':
      return jsx('element', { type: 'heading-two' }, children)
    case 'H3':
      return jsx('element', { type: 'heading-three' }, children)
    case 'LI':
      return jsx('element', { type: 'list-item' }, children)
    case 'UL':
      return jsx('element', { type: 'bulleted-list' }, children)
    case 'OL':
      return jsx('element', { type: 'numbered-list' }, children)
    case 'A':
      return jsx('element', { type: 'link', url: el.getAttribute('href') }, children)
    case 'STRONG':
      return Object.assign({ text: el.textContent, bold: true }, children[0])
    case 'EM':
      return Object.assign({ text: el.textContent, italic: true }, children[0])
    case 'CODE':
      return Object.assign({ text: el.textContent, code: true }, children[0])
    case 'U':
      return Object.assign({ text: el.textContent, underline: true }, children[0])
    default:
      return el.textContent
  }
}