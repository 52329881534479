import React, { useEffect, useState } from 'react'

import { useCounter } from 'react-use'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import { fullname } from 'misc/utils'
import DraggableModal from 'components/DraggableModal'

import { Alert, Button, Col, Divider, Empty, Form, Input, Row, Spin, Steps, Table } from 'antd'

const { Column } = Table



const MergeUserModal = ({ user, isVisible, onOK, onCancel }) => {

  const [isLoading, setIsLoading] = useState(false)
  const [otherUser, setOtherUser] = useState(null)
  const [step, { inc: nextStep, dec: prevStep, reset: resetStep }] = useCounter(0, 2, 0)

  /**
   * Search
   */
  const [isSearching, setIsSearching] = useState(false)
  const [searchUserInput, setSearchUserInput] = useState("")

  const [searchResults, setSearchResults] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    defaultPageSize: 5,
    total: 0,
    showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} éléments`,
    showSizeChanger: true
  })

  const searchUser = (search, pageSize, current) => {
    setSearchUserInput(search)
    api.users.get({ loader: setIsSearching, params: { results: pageSize, page: current, search } }).then(result => {
      setSearchResults(result.data)
      setPagination({ ...pagination, pageSize, current, total: result.totalCount })
    }).catch(console.error)
  }

  const onSearchUser = search => searchUser(search, pagination.pageSize, 1)

  const onUsersTableChange = (pagination, filters, sorter) => searchUser(searchUserInput, pagination.pageSize, pagination.current)

  useEffect(() => {
    if (user && isVisible) {
      resetStep()
      setOtherUser(null)
      setSearchResults([])
      form.setFieldsValue(user)
    }
  }, [isVisible, user]) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Form
   */
  const [form] = Form.useForm()

  const merge = () => {
    api.post(`/users/${user.id}/merge`, { user: otherUser.id }, { loader: setIsLoading })
      .then(onOK)
      .catch(console.error)
  }

  return (
    <DraggableModal
      title={<><Icon icon="share-square" fixedWidth /> Fusionner 2 utilisateurs</>}
      open={isVisible}
      maskClosable={false}
      onCancel={onCancel}
      footer={null}
      width={700}
      destroyOnClose
    >
      <Steps progressDot size="small" current={step}>
        <Steps.Step title="Avertissement" />
        <Steps.Step title="Choisir l'utilisateur" />
        <Steps.Step title="Confirmation" />
      </Steps>
      <Divider />

      {step === 0 && <>
        <Alert
          showIcon
          type="warning"
          message="Attention !"
          description={<>
            <p>Fusionner deux comptes déplacera toutes les inscriptions/formationsde l'utilisateur choisi vers l'utilisateur actuel. Merci de faire attention aux points suivants :</p>
            <ul>
              <li>Dans l'étape suivante, vous choisirez un utilisateur. C'est celui-la dont les données seront déplacées dans l'utilisateur actuel.</li>
              <li>Les formations (des formateurs), les inscriptions (des participants) et toutes les actions de la timeline seront déplacées.</li>
              <li>Les informations de la fiche signalétique ne seront <strong>PAS</strong> copiées.</li>
              <li><strong>Aucun compte utilisateur ne sera supprimé.</strong> Si le compte "vide" doit être supprimé, c'est une seconde opération à effectuer.</li>
            </ul>
          </>}
        />
        <Row justify="end" style={{ marginTop: 25 }}>
          <Col><Button type="primary" icon={<Icon icon="arrow-right" />} onClick={() => nextStep()}>Je comprends</Button></Col>
        </Row>
      </>}

      {step === 1 && <>
        <Input.Search placeholder="Rechercher un utilisateur" prefix={<Icon icon="user" />} enterButton loading={isSearching} onSearch={onSearchUser} style={{ marginBottom: 25 }} />
        {searchResults.length ? <>
          <Table
            dataSource={searchResults}
            rowKey="id"
            size="small"
            pagination={pagination}
            onChange={onUsersTableChange}
            rowSelection={{
              type: 'radio',
              selectedRowKeys: otherUser ? [otherUser.id] : [],
              onSelect: (record, selected) => setOtherUser(record)
            }}
          >
            <Column title="Numéro Client" dataIndex="clientNumber" />
            <Column title="Nom" render={fullname} />
            <Column title="Email" dataIndex="email" />
          </Table>
        </> : null}
        {
          !isSearching && !searchResults.length && <>
            <Empty description="Aucun résultats" imageStyle={{ maxHeight: 75 }} style={{ width: '100%' }} />
          </>
        }
        {isSearching && <Row justify="center"><Col><Spin size="large" /></Col></Row>}
        <Row justify="space-between" style={{ marginTop: 25 }}>
          <Col><Button icon={<Icon icon="arrow-left" />} onClick={() => prevStep()}>Précédent</Button></Col>
          <Col><Button type="primary" icon={<Icon icon="arrow-right" />} onClick={() => nextStep()} disabled={!otherUser}>Choisir cet utilisateur</Button></Col>
        </Row>
      </>}

      {step === 2 && <>
        {otherUser.id === user.id && <Alert
          showIcon
          type="error"
          message="Attention !"
          description="Vous avez choisi 2 fois le même compte utilisateur. On ne peut pas fusionner un compte avec lui-même. Veuillez choisir un autre compte utilisateur."
          style={{ marginBottom: 15 }}
        />}
        {otherUser.id !== user.id && <>
          <Alert
            showIcon
            type="warning"
            message="Êtes-vous sûr ?"
            description={<>
              L'utilisateur :<br />
              <span style={{ marginLeft: 40, fontWeight: 'bold' }}>{otherUser.clientNumber} {fullname(otherUser)}</span><br />
              va être fusionné dans l'utilisateur :<br />
              <span style={{ marginLeft: 40, fontWeight: 'bold' }}>{user.clientNumber} {fullname(user)}</span><br />
              Toutes ses inscriptions, ses formations... y seront déplacées.
            </>}
            style={{ marginBottom: 15 }}
          />
        </>}
        <Row justify="space-between" style={{ marginTop: 25 }}>
          <Col><Button icon={<Icon icon="arrow-left" />} onClick={() => prevStep()}>Précédent</Button></Col>
          <Col><Button type="primary" icon={<Icon icon="check" />} onClick={merge} disabled={!otherUser || otherUser.id === user.id} loading={isLoading}>Valider la fusion</Button></Col>
        </Row>
      </>}

    </DraggableModal >
  )

}

export default MergeUserModal