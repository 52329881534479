import React from 'react'

import { useHistory } from 'react-router-dom'
import { orderBy } from 'lodash'

import Icon from 'components/Icon'
import { isoDateToDateShort } from 'misc/utils'

import { Card, Table } from 'antd'

const { Column } = Table



const SessionsCard = ({ training, style = {} }) => {

  const history = useHistory()

  // Get sessions
  let sessions = []
  if (training?.parent) {
    sessions.push(training.parent)
    if (training.parent?.children) {
      sessions = sessions.concat(training.parent.children)
    }
  } else if (training?.children) {
    sessions = sessions.concat(training, training.children)
  }
  sessions = orderBy(sessions, ['start'], ['desc'])

  // On session click
  const onRowHandlers = (record, rowIndex) => ({
    onClick: e => {
      if (training.id !== record.id) {
        history.push(`/admin/training/${record.id}`)
      }
    }
  })

  // JSX
  return (
    <>
      <Card title={<><Icon icon="history" fixedWidth /> Sessions</>} style={style}>
        <Table
          dataSource={sessions}
          rowKey="id"
          size="small"
          pagination={false}
          childrenColumnName={"no"}
          rowClassName={record => record.id === training.id ? "row-disabled" : "row-clickable"}
          onRow={onRowHandlers}
        >
          <Column title="Code" key="code" render={item => item.code} />
          <Column title="Date (début/fin)" render={item => item.start ? isoDateToDateShort(item.start) + ' - ' + isoDateToDateShort(item.end) : '-'} />
        </Table>
      </Card>
    </>
  )

}

export default SessionsCard