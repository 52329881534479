import React from 'react'

import { Link } from 'react-router-dom'

import ModalLayout from 'components/ModalLayout/ModalLayout'
import Icon from 'components/Icon'

import { Button, Result } from 'antd'
import { isoDateToDateShort } from 'misc/utils'
import { DateTime } from 'luxon'



const RegistrationsClosed = ({ training }) => {

  if (training.registrationsStatus === 'closed' && DateTime.fromISO(training.start) < DateTime.now())
    return <ModalLayout>
      <Result
        title="Inscriptions terminées"
        subTitle={<><p>Les inscriptions sont terminées pour la formation "{training.code}".</p><p>Au plaisir de vous voir à une autre formation <Icon icon="smile-beam" /></p></>}
        style={{ paddingTop: 20 }}
        icon={<Icon icon="check" />}
        extra={<Link to="/register"><Button type="primary" icon={<Icon icon="book-open" />}>M'inscrire à une autre formation</Button></Link>}
      />
    </ModalLayout>

  if (training.registrationsStatus === 'closed')
    return <ModalLayout>
      <Result
        title="Inscriptions fermées"
        subTitle={<><p>Les inscriptions sont actuellement fermées pour la formation "{training.code}".</p><p>Revenez bientôt nous voir <Icon icon="smile-beam" /></p></>}
        style={{ paddingTop: 20 }}
        icon={<Icon icon="times-circle" />}
        extra={<Link to="/register"><Button type="primary" icon={<Icon icon="book-open" />}>M'inscrire à une autre formation</Button></Link>}
      />
    </ModalLayout>

  return (
    <ModalLayout>
      <Result
        title={`Ouverture des inscriptions le ${isoDateToDateShort(training.registrationsStatus)}`}
        subTitle={<><p>Encore un peu de patience, les inscriptions vont bientôt ouvrir pour la formation "{training.code}" !</p><p>Revenez nous voir à ce moment là <Icon icon="smile-beam" /></p></>}
        style={{ paddingTop: 20 }}
        icon={<Icon icon="clock" />}
        extra={<Link to="/register"><Button type="primary" icon={<Icon icon="book-open" />}>M'inscrire à une autre formation</Button></Link>}
      />
    </ModalLayout>
  )
}

export default RegistrationsClosed