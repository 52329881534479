import React, { useEffect } from 'react'

import Icon from 'components/Icon'
import DraggableModal from 'components/DraggableModal'

import { Form, Input } from 'antd'



const SodexoRefusedModal = ({ isVisible, onOK, onCancel }) => {

  const [form] = Form.useForm()

  useEffect(() => {
    if (isVisible) form.resetFields()
  }, [form, isVisible])

  return (
    <DraggableModal
      title={<><Icon icon="times" fixedWidth /> Refus de paiement</>}
      open={isVisible}
      maskClosable={false}
      onCancel={onCancel}
      onOk={form.submit}
      okText="Enregistrer"
      okButtonProps={{ icon: <Icon icon="save" /> }}
      destroyOnClose
    >
      <Form form={form} layout="vertical" onFinish={onOK}>
        <Form.Item label="Raison du refus (public)" name="notes">
          <Input.TextArea autoSize={{ minRows: 3 }} />
        </Form.Item>
      </Form>
    </DraggableModal >
  )

}

export default SodexoRefusedModal