import React from 'react'

import { useLocalStorage } from 'react-use'



const MAX_RECENTS = 5

const RecentsContext = React.createContext(null)



const RecentsProvider = props => {

  const [recents, setRecents] = useLocalStorage('recents', [])

  const addRecent = (item, type) => {
    // Remove if existing
    let newRecents = recents.filter(i => i.type !== type || i.id !== item.id)
    // Take max -1 last items
    newRecents = newRecents.slice(-(MAX_RECENTS - 1))
    // Sanitize
    if (type === 'training') item = { id: item.id, code: item.code }
    if (type === 'user') item = { id: item.id, firstname: item.firstname, lastname: item.lastname }
    // Push
    newRecents.push({ ...item, type })
    // Done
    setRecents(newRecents)
  }

  const clearRecents = () => setRecents([])

  const exposedValue = {
    recents,
    addRecent,
    clearRecents
  }

  return (
    <RecentsContext.Provider value={exposedValue}>
      {props.children}
    </RecentsContext.Provider>
  )

}

export { RecentsContext, RecentsProvider }
export default RecentsContext