import React from 'react'

import Draggable from 'react-draggable'

import { Modal } from 'antd'



const DraggableModal = (props) => <Modal
  {...props}
  className={'draggable-modal ' + props.className}
  modalRender={modal => <Draggable handle=".ant-modal-header">{modal}</Draggable>}
>
  {props.children}
</Modal>

export default DraggableModal