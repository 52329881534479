import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import Icon from 'components/Icon'
import api from 'services/adminApiService'

import { Card, Input, PageHeader, Tabs } from 'antd'
import RegistrationsList from './RegistrationsList/RegistrationsList'



const RegistrationsTable = ({ type }) => {

  const [registrations, setRegistrations] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    defaultPageSize: 20,
    position: ['bottomRight', 'topRight'],
    total: 0,
    showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} éléments`,
    showSizeChanger: true
  })
  const [search, setSearch] = useState(null)

  const loadRegistrations = (pageSize, current) => {
    api.registrations.get({ loader: setLoading, params: { results: pageSize, page: current, search, type } }).then(result => {
      setRegistrations(result.data)
      setPagination({ ...pagination, pageSize, current, total: result.totalCount })
    }).catch(console.error)
  }

  useEffect(() => {
    loadRegistrations(pagination.pageSize, 1)
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  const onTableChange = (pagination, filters, sorter) => {
    loadRegistrations(pagination.pageSize, pagination.current)
  }

  return (
    <>
      <Input.Search
        placeholder="Rechercher..."
        onSearch={setSearch}
        style={{ maxWidth: 250, position: 'absolute', top: 32, zIndex: 10 }}
        enterButton
        allowClear
      />
      <div style={{ marginTop: registrations?.length ? 0 : 56 }}      >
        <RegistrationsList
          registrations={registrations}
          refresh={() => loadRegistrations(pagination.pageSize, pagination.current)}
          loading={loading}
          showIsClosed={false}
          pagination={pagination}
          onChange={onTableChange}
        />
      </div>
    </>
  )

}



const Registrations = () => {

  // @ts-ignore
  const { tab = 'current' } = useParams()

  const history = useHistory()

  return (
    <>
      <PageHeader title={<><Icon icon="file-signature" fixedWidth /> Inscriptions</>} />

      <Tabs
        defaultActiveKey="current"
        activeKey={tab}
        onTabClick={key => history.push(`/admin/registrations/${key}`)}
        destroyInactiveTabPane={true}
        items={[
          {
            key: "past",
            label: <><Icon icon="history" />Inscriptions passées</>,
            children: <Card>
              <RegistrationsTable type="past" />
            </Card>
          }, {
            key: "current",
            label: <><Icon icon="calendar-check" />Inscriptions en cours</>,
            children: <Card>
              <RegistrationsTable type="current" />
            </Card>
          }, {
            key: "tovalidate",
            label: <><Icon icon="check-double" />Inscriptions à vérifier</>,
            children: <Card>
              <RegistrationsTable type="tovalidate" />
            </Card>
          },
        ]}
      />
    </>
  )

}

export default Registrations