import React from 'react'

import { SelectsProvider } from 'contexts/SelectsContext'
import { RecentsProvider } from 'contexts/RecentsContext'

import App from 'components/App/App'
import Login from 'components/Login'

// MSAL Authentication
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import { msalInstance } from 'misc/authConfig'



const AdminAppRouter = () => (
  <MsalProvider instance={msalInstance}>
    <UnauthenticatedTemplate>
      <Login />
    </UnauthenticatedTemplate>
    <AuthenticatedTemplate>
      <SelectsProvider>
        <RecentsProvider>
          <App />
        </RecentsProvider>
      </SelectsProvider>
    </AuthenticatedTemplate>
  </MsalProvider>
)

export default AdminAppRouter