import React, { useEffect, useState } from 'react'


import Icon from 'components/Icon'
import api from 'services/adminApiService'
import TableLink from 'components/TableLink'
import { isoDateToDateShort, isoDateToDateTime } from 'misc/utils'
import { PaymentStatus, PresenceRate, RegistrationStatus } from 'components/Tags'

import { Card, Descriptions, PageHeader, Table } from 'antd'

const { Column } = Table



const CFPayments = () => {

  const [refunds, setRefunds] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const loadRefunds = () => {
    api.payments.get({ loader: setIsLoading, params: { type: `cf-all` } }).then(setRefunds).catch(console.error)
  }

  useEffect(loadRefunds, [])

  return (
    <>
      <PageHeader title={<><Icon icon="list" fixedWidth /> Paiements CF</>} />

      <Card>
        <Table dataSource={refunds} pagination={{ pageSize: 20 }} loading={isLoading} rowKey="id" size="small" className="row-clickable" expandable={{
          expandRowByClick: true,
          expandIconColumnIndex: -1,
          expandedRowRender: p => {
            const data = JSON.parse(p.rawBankData)
            return <>
              <Descriptions bordered size="small">
                <Descriptions.Item label="Raison" span={3}>{p.note}</Descriptions.Item>
                {p.paymentCF && <>
                  <Descriptions.Item label="Numéro d'autorisation client" span={3}>{p.paymentCF.authorizationNumber}</Descriptions.Item>
                  <Descriptions.Item label="Code d'utilisation" span={3}>{p.paymentCF.usageCode}</Descriptions.Item>
                </>}
                {data && data.payment_date && <>
                  <Descriptions.Item label="N° d'extrait">{data.file_number}</Descriptions.Item>
                  <Descriptions.Item label="N° sur extrait">{data.number_on_file}</Descriptions.Item>
                  <Descriptions.Item label="IBAN">{data.iban}</Descriptions.Item>
                  <Descriptions.Item label="Date comptabilisation">{data.payment_date}</Descriptions.Item>
                  <Descriptions.Item label="IBAN Contrepartie">{data.counterpart_iban}</Descriptions.Item>
                  <Descriptions.Item label="Nom Contrepartie">{data.counterpart_name}</Descriptions.Item>
                  <Descriptions.Item label="Rue et numéro">{data.counterpart_street_number}</Descriptions.Item>
                  <Descriptions.Item label="CP et localité">{data.counterpart_zip_city}</Descriptions.Item>
                  <Descriptions.Item label="Communication">{data.communication}</Descriptions.Item>
                  <Descriptions.Item label="Date valeur">{data.date}</Descriptions.Item>
                  <Descriptions.Item label="Montant">{data.amount}</Descriptions.Item>
                  <Descriptions.Item label="Devise">{data.currency}</Descriptions.Item>
                  <Descriptions.Item label="Moyen">{data.payment_method}</Descriptions.Item>
                  <Descriptions.Item label="BIC">{data.bic}</Descriptions.Item>
                  <Descriptions.Item label="Pays">{data.country}</Descriptions.Item>
                  <Descriptions.Item label="Transaction">{data.transaction}</Descriptions.Item>
                </>}
              </Descriptions>
            </>
          }
        }}>
          <Column title="Date" render={payment => isoDateToDateTime(payment.date)} />
          <Column title="Participant" render={payment => payment.registration && <TableLink.User user={payment.registration.user} />} />
          <Column title="Formation" render={payment => payment.registration && <TableLink.Training training={payment.registration.training} />} />
          <Column title="Status d'inscription" render={payment => payment.registration && <RegistrationStatus registration={payment.registration} />} />
          <Column title="Présence" render={payment => <PresenceRate registration={payment.registration} />} />
          <Column title="Statut" render={payment => <PaymentStatus payment={payment} />} />
          <Column title="Forem" render={payment => <Icon.Boolean boolean={payment.paymentCF.encodedForem} />} />
          <Column title="Nb. Chèques" render={payment => payment.paymentCF.nbChecks || '-'} />
          <Column title="Validité" render={payment => isoDateToDateShort(payment.paymentCF.validityDate) || '-'} />
          <Column title="Montant" render={payment => `${-payment.amount} €`} />
          <Column title="No. remise" render={payment => payment.paymentCF.remiseNumber || '-'} />
          <Column title="Date remise" render={payment => isoDateToDateShort(payment.paymentCF.remiseDate) || '-'} />
          <Column title="N° de compte" render={payment => payment.iban || '-'} />
        </Table>
      </Card>
    </>
  )

}

export default CFPayments