import React from 'react'

import { PageHeader } from 'antd'
import Icon from 'components/Icon'



const Tasks = () => {

  return (
    <>
      <PageHeader title={<><Icon icon="tasks" fixedWidth /> Tâches</>} />
      <p>Cette liste de tâches reprend un certain nombre d'éléments qu'il ne faut pas oublier de faire à un moment donné. Par exemple :</p>
      <ul>
        <li>Encoder le numéro de cours FLink pour une formation 40 jours avant la formation.</li>
        <li>Valider l'encodage des absences à la fin d'une formation. Ceci envoit automatiquement les attestations de suivi.</li>
        <li>Vérifier les formateurs d'une formation car il y en a qui sont listés mais pas programmés dans le planning.</li>
        <li>Vérifier le planning d'une formation car le nombre d'heures aggréées CF ne correspond pas.</li>
        <li>Vérifier une formation car un site est listé mais aucun local n'est utilisé dans le planning.</li>
        <li>Clôturer le planning de la formation X jours avant le début à l'aide d'un bouton spécifique ?</li>
        <li>Faire les attestations CEP pour une formation, à chaque trimestre.</li>
      </ul>
    </>
  )

}

export default Tasks