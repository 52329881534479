import React, { useContext, useEffect, useState } from 'react'

import { Link, useHistory } from 'react-router-dom'

import Icon from 'components/Icon'
import UserAuthContext from 'contexts/UserAuthContext'
import api from 'services/userApiService'

import { Button, Checkbox, Col, Divider, Form, Input, message, Result, Row, Modal } from 'antd'



const Login = ({ training, next }) => {

  const { isAuthenticated, user, isLoading, login, logout } = useContext(UserAuthContext)

  const [isRegistering, setIsRegistering] = useState(false)
  const [userNotValidated, setUserNotValidated] = useState(null) // contains email/password to login again

  const history = useHistory()

  const checkCanRegisterAndNext = (userAfterLogin) => {
    const userToCheck = user || userAfterLogin
    if (userToCheck.registrations?.find(r => r.trainingId === training.id && r.status !== 'cancelled' && r.status !== 'rejected')) {
      Modal.warning({
        title: "Vous êtes déjà inscrit...",
        content: <>
          <p>Il semblerait que vous ayez déjà une inscription en cours à cette formation.</p>
          <p>Nous vous invitons à consulter votre portail pour vérifier l'état de l'inscription.</p>
          <p>S'il s'agit d'une erreur, vous pouvez prendre contact avec le Centre de Formation Continue.</p>
        </>,
        okText: "Aller à mon portail",
        okButtonProps: { icon: <Icon icon="user" /> },
        onOk: () => history.push('/portal')
      })
    } else {
      next()
    }
  }

  const onFinishLoginForm = (values) => {
    login(values)
      .then(checkCanRegisterAndNext)
      .catch(response => {
        if (response?.json?.error === "auth.email_not_validated") {
          setUserNotValidated(values)
        }
      })
  }

  const onFinishRegisterForm = (values) => {
    api.post('/auth/register', values, { loader: setIsRegistering, noErrorMessage: true })
      .then(() => setUserNotValidated(values))
      .catch(response => {
        if (response?.json?.error === "auth.email_already_used") {
          Modal.warning({
            title: "Avez-vous déjà un compte ?",
            content: <>
              <p>Un compte existe déjà pour cette adresse e-mail.</p>
              <p>Avez-vous déjà suivi une formation chez nous ? Avons-nous déjà eu des contacts ensemble ? Si oui, nous vous avons certainement déjà créé un compte.</p>
              <p>Dès lors, utilisez le lien "mot de passe oublié" pour définir un nouveau mot de passe.</p>
            </>,
            closable: true,
            cancelText: "Annuler",
            okText: "Mot de passe oublié",
            okButtonProps: { icon: <Icon icon="lock-alt" /> },
            onOk: () => history.push('/forgot-password')
          })
        }
      })
  }

  const send = () => {
    api.post('/auth/send-validation-email', { email: userNotValidated.email })
      .then(() => message.success("Un nouvel e-mail vient de vous être envoyé."))
      .catch(console.error)
  }

  useEffect(() => {
    if (userNotValidated) {
      const interval = setInterval(() => {
        login(userNotValidated)
          .then(() => {
            setUserNotValidated(null)
            clearInterval(interval)
          })
          .catch(console.error)
      }, 4000);
      return () => clearInterval(interval)
    }
  }, [userNotValidated, login])

  if (isAuthenticated) {
    return <>
      <Result
        title={`Bonjour ${user.firstname} ${user.lastname} !`}
        subTitle={`Vous êtes connecté en tant que ${user.firstname} ${user.lastname}. Si ce n'est pas vous, cliquez sur le bouton pour vous connecter avec un autre compte, sinon cliquez sur le bouton pour continuer l'inscription.`}
        status="info"
        style={{ paddingTop: 20 }}
        icon={<Icon icon="smile-beam" />}
        extra={<>
          <Button icon={<Icon icon="sign-out" />} style={{ marginRight: 50 }} onClick={logout}>Se déconnecter</Button>
          <Button type="primary" icon={<Icon icon="check" />} onClick={checkCanRegisterAndNext}>Continer l'inscription</Button>
        </>} />
    </>
  }

  if (userNotValidated) {
    return <>
      <Result
        title="Veuillez valider votre adresse email."
        subTitle="Vous avez reçu un e-mail de validation. Une fois que vous aurez cliqué sur le lien, vous serez automatiquement connecté ici, et vous pourrez continuer votre inscription."
        status="info"
        style={{ paddingTop: 20 }}
        icon={<Icon icon="spinner-third" spin />}
        extra={<Button type="primary" icon={<Icon icon="paper-plane" />} onClick={send}>Renvoyer l'e-mail</Button>} />
    </>
  }

  return (
    <>
      <Row gutter={24} className="register-login">
        <Col flex="1 1 300px">
          <h1>Vous avez déjà un compte ?</h1>
          <p className="sub-title-light">Connectez-vous pour continuer votre inscription à la formation.</p>
          <Form onFinish={onFinishLoginForm}>
            <Form.Item name="email" rules={[{ required: true, message: 'Veuillez entrer votre adresse e-mail.' }]}>
              <Input prefix={<Icon icon="envelope" />} placeholder="E-mail" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Veuillez entrer votre mot de passe.' }]}>
              <Input.Password prefix={<Icon icon="lock" />} placeholder="Mot de passe" />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Se souvenir de moi</Checkbox>
              </Form.Item>
              <Link style={{ float: 'right' }} to="/forgot-password">Mot de passe oublié ?</Link>
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" loading={isLoading} htmlType="submit" icon={<Icon icon="sign-in" />}>Se connecter</Button>
            </Form.Item>
          </Form>
        </Col>
        <Col flex="31px"><Divider type="vertical" style={{ height: '100%' }} /></Col>
        <Col flex="1 1 300px">
          <h1>Pas encore de compte ?</h1>
          <p className="sub-title-light">Créez-en un pour vous inscrire, suivre votre dossier, accéder aux ressources pédagogiques...</p>
          <Form onFinish={onFinishRegisterForm} layout="vertical">
            <Form.Item name="firstname" label="Prénom" rules={[{ required: true, message: 'Veuillez entrer votre prénom.' }]}>
              <Input placeholder="Prénom" />
            </Form.Item>
            <Form.Item name="lastname" label="Nom" rules={[{ required: true, message: 'Veuillez entrer votre nom.' }]}>
              <Input placeholder="Nom" />
            </Form.Item>
            <Form.Item name="email" label="Adresse e-mail" rules={[{ required: true, message: 'Veuillez entrer votre adresse e-mail.' }]}>
              <Input prefix={<Icon icon="envelope" />} placeholder="E-mail" />
            </Form.Item>
            <Form.Item name="password" label="Mot de passe" rules={[
              { required: true, message: 'Veuillez entrer votre mot de passe' },
              { min: 8, message: 'Le mot de passe doit faire au minimum 8 carractères.' }
            ]}>
              <Input.Password prefix={<Icon icon="lock" />} placeholder="Mot de passe" />
            </Form.Item>
            <Form.Item name="confirm-password" dependencies={['password']} rules={[
              { required: true, message: 'Veuillez confirmer votre mot de passe' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) return Promise.resolve()
                  return Promise.reject(new Error('Les deux mots de passe ne correspondent pas.'));
                }
              })
            ]}>
              <Input.Password prefix={<Icon icon="lock" />} placeholder="Confirmez le mot de passe" />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button loading={isRegistering} type="primary" htmlType="submit" icon={<Icon icon="user-check" />}>Créer mon compte</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>

    </>
  )

}

export default Login