import React from 'react'

import { PageHeader } from 'antd'
import Icon from 'components/Icon'



const Inbox = () => {

  return (
    <>
      <PageHeader title={<><Icon icon="inbox" fixedWidth /> Boîte de réception</>} />
      <p>Dans cette liste, on retrouve tous les mails non traités de l'adresse e-mail formation continue, comme dans une boîte mail classique. Pour chaque e-mail, on peut</p>
      <ul>
        <li>Répondre directement au mail.</li>
        <li>Si l'adresse e-mail de l'expéditeur est connue du système, le nom de l'utilisateur s'affiche (cliquable pour aller directement au dossier participant).</li>
        <li>Si l'adresse e-mail n'est pas connue, on peut lier le mail à un compte participant pour qu'il apparaisse dans la timeline</li>
        <li>Si l'e-mail mérite d'être conservé, on peut cliquer sur le bouton "conserver" ce qui le supprime de la liste, mais l'ajoute dans la timeline du participant.</li>
        <li>Si l'e-mail n'est pas utile, on peut cliquer sur "archiver" ce qui l'archive sans le lier à la timeline</li>
      </ul>
      <p>Attention, pour être conservé, un e-mail doit obligatoirement avoir été lié à un participant, si ce n'est pas fait automatiquement.</p>
      <p>Une liste des 100 derniers mails traîtés est visible dans un onglet séparé.</p>
      <p>L'onglet principal ne contient que les e-mails non traités (à conserver/archiver)</p>
      <p>Concrètement, dans la boite e-mail, les e-mails non traités resteront dans la boîte de réception. Les e-mails à conserver seront dans un dossier spécifique, de même que les e-mails archivés.</p>
    </>
  )

}

export default Inbox