import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import api from 'services/adminApiService'
import Icon from 'components/Icon'
import { UserTypes } from 'components/Tags'

import { Card, Input, PageHeader, Table } from 'antd'

const { Column } = Table



const Users = () => {

  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    defaultPageSize: 20,
    position: ['bottomRight', 'topRight'],
    total: 0,
    showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} éléments`,
    showSizeChanger: true
  })
  const [search, setSearch] = useState()

  const history = useHistory()

  const loadUsers = (pageSize, current) => {
    api.users.get({ loader: setLoading, params: { results: pageSize, page: current, search } }).then(result => {
      setUsers(result.data)
      setPagination({ ...pagination, pageSize, current, total: result.totalCount })
    }).catch(console.error)
  }

  useEffect(() => {
    loadUsers(pagination.pageSize, 1)
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  const onRowHandlers = (record, rowIndex) => ({
    onClick: e => history.push(`/admin/user/${record.id}`)
  })

  const onTableChange = (pagination, filters, sorter) => {
    loadUsers(pagination.pageSize, pagination.current)
  }

  return (
    <>
      <PageHeader title={<><Icon icon="users" fixedWidth /> Tous les utilisateurs</>} />
      <Card>
        <Input.Search
          placeholder="Rechercher..."
          onSearch={setSearch}
          style={{ maxWidth: 250, position: 'absolute', top: 32, zIndex: 10 }}
          enterButton
          allowClear
        />
        <Table
          dataSource={users}
          loading={loading}
          onRow={onRowHandlers}
          rowKey="id"
          rowClassName="row-clickable"
          size="small"
          pagination={pagination}
          onChange={onTableChange}
          style={{ marginTop: users?.length ? 0 : 56 }}
        >
          <Column title="Nom" render={user => `${user.lastname} ${user.firstname}`} />
          <Column title="Email" dataIndex="email" />
          <Column title="Téléphone" dataIndex="phone" />
          <Column title="Pays" dataIndex="country" />
          <Column title="Type" render={user => <UserTypes user={user} />} />
        </Table>
      </Card>
    </>
  )

}

export default Users