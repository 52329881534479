import React, { useState } from 'react'

import moment from 'moment'

import Icon from 'components/Icon'
import { FLinkNormalAccessStatus, FLinkNormalAccessStatusTrainer, FLinkSpecialAccessStatus, FLinkSpecialAccessStatusTrainer, UserType } from 'components/Tags'
import TableLink from 'components/TableLink'
import { isoDateToDateShort } from 'misc/utils'
import TableButton from 'components/TableButton'
import api from 'services/adminApiService'
import DraggableModal from 'components/DraggableModal'

import { Card, Typography, Table, Form, DatePicker, Row, Col, message } from 'antd'


const { Column } = Table
const { Text } = Typography



const FLinkAccess = ({ user, refreshUser }) => {

  const [isLoading, setIsLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)

  const [form] = Form.useForm()

  const edit = (item) => {
    setCurrentItem(item)
    form.setFieldsValue({ flinkSpecialAccess: item.flinkSpecialAccess ? moment(item.flinkSpecialAccess) : null })
    setIsModalVisible(true)
  }

  const removeSpecialAccess = (item) => {
    // If registrations
    if (item.id) {
      api.registrations.put(item.id, { flinkSpecialAccess: null }, { loader: setIsLoading }).then(() => {
        message.success("Accès spécial supprimé.")
        refreshUser()
      }).catch(console.error)
    }
    // If trainer
    else {
      api.put(`/trainings/${item.trainingId}/trainers`, item.userId, { flinkSpecialAccess: null }, { loader: setIsLoading }).then(() => {
        message.success("Accès spécial supprimé.")
        refreshUser()
      }).catch(console.error)
    }
  }

  const saveSpecialAccess = (values) => {
    // If registrations
    if (currentItem.id) {
      api.registrations.put(currentItem.id, values, { loader: setIsLoading }).then(() => {
        message.success("Accès spécial modifié.")
        setCurrentItem(null)
        setIsModalVisible(false)
        refreshUser()
      }).catch(console.error)
    }
    // If trainer
    else {
      api.put(`/trainings/${currentItem.trainingId}/trainers`, currentItem.userId, values, { loader: setIsLoading }).then(() => {
        message.success("Accès spécial modifié.")
        setCurrentItem(null)
        setIsModalVisible(false)
        refreshUser()
      }).catch(console.error)
    }
  }

  const onRowHandlers = (record, rowIndex) => ({
    onClick: e => edit(record)
  })

  return (
    <>
      <Row gutter={[16, 16]} >
        {user.isParticipant && <Col flex={1}>
          <Card title={<><Icon icon="graduation-cap" />Accès FLink <UserType.Participant /></>}>
            <Table dataSource={user.registrations} size="small" rowKey="id" className="row-clickable" onRow={onRowHandlers}>
              <Column title="Formation" render={item => <>
                <TableLink.Training training={item.training} hideDate />
                <Text type="secondary" italic style={{ marginLeft: 7, fontSize: '90%' }}>{isoDateToDateShort(item.training.start)}</Text>
              </>} />
              <Column title="Accès normal" render={item => <FLinkNormalAccessStatus registration={item} />} />
              <Column title="Accès spécial" render={item => <FLinkSpecialAccessStatus registration={item} />} />
              <Column title="Actions" width={100} className="actions-column" render={item => <>
                <TableButton icon="pencil" onClick={() => edit(item)} />
                <TableButton icon="times" onClick={() => removeSpecialAccess(item)} danger confirmMessage={"Êtes-vous sûr de vouloir supprimer l'accès spécial ?"} />
              </>} />
            </Table>
          </Card>
        </Col>}
        {user.isTrainer && <Col flex={1}>
          <Card title={<><Icon icon="graduation-cap" />Accès FLink <UserType.Trainer /></>}>
            <Table dataSource={user.trainings} size="small" rowKey="trainingId" className="row-clickable" onRow={onRowHandlers}>
              <Column title="Formation" render={item => <>
                <TableLink.Training training={item.training} hideDate />
                <Text type="secondary" italic style={{ marginLeft: 7, fontSize: '90%' }}>{isoDateToDateShort(item.training.start)}</Text>
              </>} />
              <Column title="Accès normal" render={item => <FLinkNormalAccessStatusTrainer trainer={item} />} />
              <Column title="Accès spécial" render={item => <FLinkSpecialAccessStatusTrainer trainer={item} />} />
              <Column title="Actions" width={100} className="actions-column" render={item => <>
                <TableButton icon="pencil" onClick={() => edit(item)} />
                <TableButton icon="times" onClick={() => removeSpecialAccess(item)} danger confirmMessage={"Êtes-vous sûr de vouloir supprimer l'accès spécial ?"} />
              </>} />
            </Table>
          </Card>
        </Col>}
      </Row>

      <DraggableModal
        title={<><Icon icon="pencil" fixedWidth /> Modifier la date d'accès spécial</>}
        open={isModalVisible}
        maskClosable={false}
        onCancel={() => setIsModalVisible(false)}
        onOk={form.submit}
        okText="Enregistrer"
        okButtonProps={{ icon: <Icon icon="save" />, loading: isLoading }}
      >
        <Form form={form} layout="vertical" onFinish={saveSpecialAccess}>
          <Form.Item label="Accès jusqu'au" name="flinkSpecialAccess">
            <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </DraggableModal>
    </>
  )

}

export default FLinkAccess


