import React, { useCallback, useMemo } from 'react'

import isUrl from 'is-url'
import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import { Editor, Transforms, createEditor, Range, Element as SlateElement } from 'slate'
import { withHistory } from 'slate-history'

import './RichTextEditor.css'

import Icon from './Icon'

import { Button } from 'antd'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const defaultValue = [{ type: 'paragraph', children: [{ text: 'aa' }], }]



const withLinks = editor => {
  const { insertData, insertText, isInline } = editor
  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }
  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }
  editor.insertData = data => {
    const text = data.getData('text/plain')
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }
  return editor
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n['type']),
    split: true,
  })
  Transforms.setNodes(editor, { type: isActive ? 'paragraph' : isList ? 'list-item' : format })
  if (!isActive && isList) {
    Transforms.wrapNodes(editor, { type: format, children: [] })
  }
}

const toggleMark = (editor, format) => {
  if (isMarkActive(editor, format)) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
}

const wrapLink = (editor, url) => {
  if (isBlockActive(editor, 'link')) {
    unwrapLink(editor)
  }
  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }
  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const isBlockActive = (editor, format) => {
  const { selection } = editor
  if (!selection) return false
  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n['type'] === format,
  })
  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'link':
      return <a {...attributes} href={element.url}>{children}</a>
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) children = <strong>{children}</strong>
  if (leaf.code) children = <code>{children}</code>
  if (leaf.italic) children = <em>{children}</em>
  if (leaf.underline) children = <u>{children}</u>
  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      type={isBlockActive(editor, format) ? 'primary' : null}
      size="small"
      icon={<Icon icon={icon} />}
      onMouseDown={e => { e.preventDefault(); toggleBlock(editor, format) }}
    />
  )
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      type={isMarkActive(editor, format) ? 'primary' : null}
      size="small"
      icon={<Icon icon={icon} />}
      onMouseDown={e => { e.preventDefault(); toggleMark(editor, format) }}
    />
  )
}

const LinkButton = () => {
  const editor = useSlate()
  return (
    <Button
      type={isBlockActive(editor, 'link') ? 'primary' : null}
      size="small"
      icon={<Icon icon="link" />}
      onMouseDown={e => {
        e.preventDefault()
        const url = window.prompt('URL du lien :')
        if (!url) return
        if (editor.selection) {
          wrapLink(editor, url)
        }
      }}
    />
  )
}

const UnlinkButton = () => {
  const editor = useSlate()
  return (
    <Button
      type={isBlockActive(editor, 'link') ? 'primary' : null}
      size="small"
      icon={<Icon icon="unlink" />}
      onMouseDown={e => { if (isBlockActive(editor, 'link')) unwrapLink(editor) }}
    />
  )
}



const RichTextEditor = ({ value = defaultValue, onChange = () => { }, placeholder = "" }) => {

  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withLinks(withHistory(withReact(createEditor()))), [])
  editor.children = value

  return (
    <div className="slate-container">
      <Slate editor={editor} value={value} onChange={onChange}>
        <div className="slate-menu">
          <MarkButton format="bold" icon="bold" />
          <MarkButton format="italic" icon="italic" />
          <MarkButton format="underline" icon="underline" />
          <MarkButton format="code" icon="code" />
          <LinkButton />
          <UnlinkButton />
          <BlockButton format="heading-two" icon="h2" />
          <BlockButton format="heading-three" icon="h3" />
          <BlockButton format="quote" icon="quote-right" />
          <BlockButton format="numbered-list" icon="list-ol" />
          <BlockButton format="bulleted-list" icon="list-ul" />
        </div>
        <Editable
          className="slate-content"
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
          spellCheck
          autoFocus
          onKeyDown={e => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, e)) {
                e.preventDefault()
                toggleMark(editor, HOTKEYS[hotkey])
              }
            }
          }}
        />
      </Slate>
    </div>
  )
}

export default RichTextEditor