import React from 'react'

import { Badge, Tooltip } from 'antd'



const Badges = {}

Badges.Blue = ({ count }) => <Badge count={count} style={{ backgroundColor: "#1890ff" }} showZero />

Badges.Materials = ({ materials }) => {
  const nbChecked = materials?.filter(m => m.isChecked).length || 0
  const nbTotal = materials?.length || 0
  let color = '#f5222d' // Red-6
  if (nbChecked > 0) color = '#fa8c16' // Orange-6
  if (nbTotal === nbChecked) color = '#52c41a' // Green-6
  return <Badge count={`${nbChecked}/${nbTotal}`} style={{ backgroundColor: color }} />
}

Badges.WaitingList = ({ registrations }) => <Badges.Blue count={registrations?.filter(r => r.status === 'waiting-list').length || 0} />

Badges.Registrations = ({ training }) => {
  const count = training.registrations?.filter(r => r.status === 'validated').length || 0
  const max = training.maxParticipants
  const min = training.minParticipants
  let color = '#f5222d' // Red-6
  if (count / max >= 0.3) color = '#fa8c16' // Orange-6
  if (count >= min) color = '#a0d911' // Lime-6
  if (count >= max) color = '#52c41a' // Green-6
  return <Tooltip title={`${min} - ${max}`}><Badge count={`${count}/${max}`} style={{ backgroundColor: color }} /></Tooltip>
}

Badges.CovenantCount = ({ trainerOnTraining }) => {
  const count = trainerOnTraining.actions.filter(a => a.type === 'covenant-generated').length
  return <Badge count={count} style={{ backgroundColor: count > 0 ? "#52c41a" : "#bfbfbf" }} showZero />
}



export default Badges