import React from 'react'

import Icon from 'components/Icon'
import DraggableModal from 'components/DraggableModal'

import { Descriptions } from 'antd'
import {PaymentStatus} from "./Tags";
import {isoDateToDateShort} from "../misc/utils";



const PaymentModal = ({ payment, isVisible, onClose }) => {

  if (!payment) return <></>

  // Raw bank data
  const data = JSON.parse(payment.rawBankData)

  return (
    <DraggableModal
      title={<><Icon icon="eye" fixedWidth /> Détails du paiement </>}
      open={isVisible}
      maskClosable={true}
      onCancel={onClose}
      footer={null}
      width="80%"
    >
      {payment.paymentCF && <Descriptions bordered size="small" title="Données CF">
        <Descriptions.Item label="Statut"><PaymentStatus payment={payment} /></Descriptions.Item>
        <Descriptions.Item label="Nb. chèques">{payment.paymentCF.nbChecks}</Descriptions.Item>
        <Descriptions.Item label="Date validité">{isoDateToDateShort(payment.paymentCF.validityDate)}</Descriptions.Item>
        <Descriptions.Item label="N° remise">{payment.paymentCF.remiseNumber}</Descriptions.Item>
        <Descriptions.Item label="Date remise">{isoDateToDateShort(payment.paymentCF.remiseDate)}</Descriptions.Item>
        <Descriptions.Item label="Date payment sod.">{isoDateToDateShort(payment.paymentCF.paymentDate)}</Descriptions.Item>
        <Descriptions.Item label="Notes">{payment.paymentCF.notes || '/'}</Descriptions.Item>
      </Descriptions>}
      {data && <Descriptions bordered size="small" title="Données bancaires">
        <Descriptions.Item label="N° d'extrait">{data.file_number}</Descriptions.Item>
        <Descriptions.Item label="N° sur extrait">{data.number_on_file}</Descriptions.Item>
        <Descriptions.Item label="IBAN">{data.iban}</Descriptions.Item>
        <Descriptions.Item label="Date comptabilisation">{data.payment_date}</Descriptions.Item>
        <Descriptions.Item label="IBAN Contrepartie">{data.counterpart_iban}</Descriptions.Item>
        <Descriptions.Item label="Nom Contrepartie">{data.counterpart_name}</Descriptions.Item>
        <Descriptions.Item label="Rue et numéro">{data.counterpart_street_number}</Descriptions.Item>
        <Descriptions.Item label="CP et localité">{data.counterpart_zip_city}</Descriptions.Item>
        <Descriptions.Item label="Communication">{data.communication}</Descriptions.Item>
        <Descriptions.Item label="Date valeur">{data.date}</Descriptions.Item>
        <Descriptions.Item label="Montant">{data.amount}</Descriptions.Item>
        <Descriptions.Item label="Devise">{data.currency}</Descriptions.Item>
        <Descriptions.Item label="Moyen">{data.payment_method}</Descriptions.Item>
        <Descriptions.Item label="BIC">{data.bic}</Descriptions.Item>
        <Descriptions.Item label="Pays">{data.country}</Descriptions.Item>
        <Descriptions.Item label="Transaction">{data.transaction}</Descriptions.Item>
      </Descriptions>}
    </DraggableModal >
  )

}

export default PaymentModal