import React, { useContext, useEffect, useState } from 'react'

import { useParams, useLocation } from 'react-router-dom'

import './Register.less'

import api from 'services/userApiService'
import Icon from 'components/Icon'
import Login from './Login'
import UserInfos from './UserInfos'
import Training from './Training'
import UserAuthContext from 'contexts/UserAuthContext'
import Confirmation from './Confirmation'
import Loader from 'components/Loader'
import Maintenance from 'user-app/Maintenance'
import { REGISTRATION_MAINTENANCE } from 'env'
import RegistrationsClosed from 'user-app/RegistrationsClosed'

import { Layout, Steps, Row, Col } from 'antd'

import logoFC from 'static/images/VINCI_FC_logo.png'
import logoVinci from 'static/images/VINCI_logo_white.png'
import logoNeoxis from 'static/images/NEOXIS_logo_inline_white.png'
import logo_background from 'static/images/VINCI_logo_background.svg'
import logo_background2 from 'static/images/VINCI_FC_background_logo.svg'

const { Content, Footer } = Layout



const Register = () => {

  const isAuthRecovering = useContext(UserAuthContext).isRecovering

  const [currentStep, setCurrentStep] = useState(0)
  const [training, setTraining] = useState(null)
  const [isLoadingTraining, setIsLoadingTraining] = useState(false)
  const [registration, setRegistration] = useState()

  const code = new URLSearchParams(useLocation().search).get("code")

  // @ts-ignore
  const { id } = useParams()

  useEffect(() => {
    api.getById('/trainings', id, { loader: setIsLoadingTraining }).then(setTraining).catch(console.error)
  }, [id])

  // Navigation functions
  const prev = () => setCurrentStep(currentStep - 1 >= 0 ? currentStep - 1 : 0)
  const next = () => setCurrentStep(currentStep + 1 <= 3 ? currentStep + 1 : 3)

  // Maintenance mode
  if (REGISTRATION_MAINTENANCE) return <Maintenance />

  // Registrations closed
  if (training && !code && training.registrationsStatus !== 'open') return <RegistrationsClosed training={training} />

  // Loading
  if (isLoadingTraining || !training || isAuthRecovering) {
    return <Loader />
  }

  // Registration screen
  return (
    <Layout className="register-layout">
      <div className="modal-layout-background" >
        <img src={logo_background} alt="Logo FC arrière plan" />
        <img src={logo_background2} alt="Logo FC arrière plan" />
      </div>
      <Layout>
        <div className="header">
          <Row align="middle" className="header-blue">
            <Col>
              <img src={logoFC} alt="Logo formation continue" />
            </Col>
            <Col flex="auto" >
              <h2>Formulaire d'inscription</h2>
              <h3>{training.code}</h3>
            </Col>
          </Row>
          <div className="header-border">
            <div style={{ backgroundColor: "#E7276D" }} />
            <div style={{ backgroundColor: "#F8AA00" }} />
            <div style={{ backgroundColor: "#009670" }} />
          </div>
          <Steps type="navigation" size="small" current={currentStep} responsive>
            <Steps.Step title="Connexion" icon={<Icon icon="lock" />} />
            <Steps.Step title="Signalétique" icon={<Icon icon="user" />} />
            <Steps.Step title="Formation" icon={<Icon icon="users-class" />} />
            <Steps.Step title="Confirmation" icon={<Icon icon="smile-beam" />} />
          </Steps>
        </div>
        <Content>
          {currentStep === 0 && <Login training={training} next={next} />}
          {currentStep === 1 && <UserInfos registration={registration} setRegistration={setRegistration} prev={prev} next={next} />}
          {currentStep === 2 && <Training training={training} registration={registration} setRegistration={setRegistration} prev={prev} next={next} />}
          {currentStep === 3 && <Confirmation training={training} />}
        </Content>
        <Footer>
          <Row align="bottom">
            <Col className="logo-vinci" span={6}>
              <a href="https://www.vinci.be" target="_blank" rel="noreferrer"><img src={logoVinci} alt="Logo Vinci" /></a>
            </Col>
            <Col className="copyright" span={12}>
              &copy; {new Date().getFullYear()} - Développé par <a href="https://www.neoxis.be" target="_blank" rel="noreferrer"><img src={logoNeoxis} alt="Neoxis logo" /></a>
            </Col>
            <Col className="links" span={6}>
              <div>
                <a href="https://www.facebook.com/formationcontinuevinci/" target="_blank" rel="noreferrer"><Icon icon="fab facebook" /> Facebook</a>
                <a href="https://www.linkedin.com/showcase/centre-de-formation-continue-vinci/" target="_blank" rel="noreferrer"><Icon icon="fab linkedin" /> Linkedin</a>
              </div>
            </Col>
          </Row>
        </Footer>
      </Layout>
    </Layout>
  )
}

export default Register