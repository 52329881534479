import React, { useEffect, useState } from 'react'

import moment from 'moment'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import DraggableModal from 'components/DraggableModal'

import { DatePicker, Form, Input, InputNumber, Radio, Select, Switch, Tabs } from 'antd'



const EditPaymentModal = ({ payment, isVisible, onOK, onCancel }) => {

  const [isLoading, setIsLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState("1")

  const [form] = Form.useForm()

  useEffect(() => {
    if (isVisible) {
      setCurrentTab("1")
      const values = { ...payment }
      if (values.paymentDate) values.paymentDate = moment(payment.paymentDate)
      if (values.paymentCF) {
        values.paymentCF = { ...values.paymentCF }
        values.paymentCF.studyLevel = JSON.parse(values.paymentCF.studyLevel)
        values.paymentCF.workStatus = JSON.parse(values.paymentCF.workStatus)
        if (values.paymentCF.validityDate) values.paymentCF.validityDate = moment(values.paymentCF.validityDate)
        if (values.paymentCF.remiseDate) values.paymentCF.remiseDate = moment(values.paymentCF.remiseDate)
      }
      form.setFieldsValue(values)
    }
  }, [payment, isVisible, form])

  const save = values => {
    api.payments.put(payment.id, values, { loader: setIsLoading }).then(onOK).catch(console.error)
  }

  return (
    <DraggableModal
      title={<><Icon icon="pencil" fixedWidth /> Modifier un paiement</>}
      open={isVisible}
      maskClosable={false}
      onCancel={onCancel}
      onOk={form.submit}
      okText="Enregistrer"
      okButtonProps={{ icon: <Icon icon="save" />, loading: isLoading }}
    >

      <Form form={form} layout="vertical" onFinish={save}>

        <Tabs activeKey={currentTab} onChange={setCurrentTab}>

          <Tabs.TabPane tab="Paiement" key="1">
            <Form.Item name="type" label="Type de paiement" rules={[{ required: true }]}>
              <Select>
                <Select.Option value="payment">Paiement</Select.Option>
                <Select.Option value="refund">Remboursement</Select.Option>
                <Select.Option value="cf">Chèques formation</Select.Option>
                <Select.Option value="cf-refund">Remboursement CF</Select.Option>
                <Select.Option value="holdback">Retenue</Select.Option>
                <Select.Option value="to-apply">A lettrer</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="amount" label="Montant" rules={[{ required: true }]}>
              <InputNumber precision={2} style={{ width: 100 }} className="input-number-euro" />
            </Form.Item>
            <Form.Item name="paymentDate" label="Date du paiement">
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prev, curr) => prev.type !== curr.type}>
              {({ getFieldValue }) => getFieldValue('type') === "refund" &&
                <Form.Item name="isDone" label="Remboursement déjà effectué ?" valuePropName="checked" initialValue={true}>
                  <Switch checkedChildren="Oui" unCheckedChildren="Non" />
                </Form.Item>
              }
            </Form.Item>
            <Form.Item name="iban" label="Compte bancaire">
              <Input />
            </Form.Item>
            <Form.Item name="note" label="Note (raison du remboursement...)">
              <Input />
            </Form.Item>
            <Form.Item name="rawBankData" label="Données brutes">
              <Input.TextArea rows={3} />
            </Form.Item>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Statut CF" key="2" disabled={!payment?.paymentCF}>
            <Form.Item name={['paymentCF', 'status']} label="Statut du paiement" rules={[{ required: true }]}>
              <Select>
                <Select.Option value="encoded">Chèques encodés</Select.Option>
                <Select.Option value="accepted">Chèques acceptés</Select.Option>
                <Select.Option value="rejected">Chèques refusés</Select.Option>
                <Select.Option value="invoiced">Facturés à Sodexo</Select.Option>
                <Select.Option value="paid">Paiement effectué par Sodexo</Select.Option>
                <Select.Option value="payment-rejected">Paiement rejeté par sodexo</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name={['paymentCF', 'nbChecks']} label="Nombre de chèques">
              <InputNumber min={1} step={1} />
            </Form.Item>
            <Form.Item name={['paymentCF', 'validityDate']} label="Date de validité">
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
            <Form.Item name={['paymentCF', 'remiseNumber']} label="Numéro de remise">
              <Input />
            </Form.Item>
            <Form.Item name={['paymentCF', 'remiseDate']} label="Date de remise">
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
            <Form.Item name={['paymentCF', 'notes']} label="Note (raison du remboursement...)">
              <Input />
            </Form.Item>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Infos CF" key="3" disabled={!payment?.paymentCF}>
            <Form.Item name={['paymentCF', 'NRNumber']} label="Numéro de registre national" rules={[
              { required: true },
              { pattern: /^\d\d\.\d\d\.\d\d-\d\d\d\.\d\d$/, message: "Le numéro doit être au format : 00.00.00-000.00 (avec les points et le tiret)" }
            ]}>
              <Input placeholder="00.00.00-000.00" />
            </Form.Item>
            <Form.Item name={['paymentCF', 'nationality']} label="Nationalité" rules={[{ required: true }]}>
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="belgian">Belge</Radio.Button>
                <Radio.Button value="ue">Union Européenne</Radio.Button>
                <Radio.Button value="out-ue">Hors UE</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item name={['paymentCF', 'workStatus']} label="Statut" rules={[{ required: true }]}>
              <Select mode="multiple">
                <Select.Option value="independant">Indépendant</Select.Option>
                <Select.Option value="employe">Employé</Select.Option>
                <Select.Option value="cadre">Cadre</Select.Option>
                <Select.Option value="ouvrier">Ouvrier</Select.Option>
                <Select.Option value="interimaire">Intérimaire</Select.Option>
                <Select.Option value="conjoint-aidant">Conjoint aidant</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name={['paymentCF', 'experience']} label="Expérience professionnelle totale" rules={[{ required: true }]}>
              <Select>
                <Select.Option value="<5">&lt;5 ans</Select.Option>
                <Select.Option value="5-9">5-9 ans</Select.Option>
                <Select.Option value="10-14">10-14 ans</Select.Option>
                <Select.Option value="15-19">15-19 ans</Select.Option>
                <Select.Option value=">20">20 ans et plus</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name={['paymentCF', 'studyLevel']} label="Niveau d'études" rules={[{ required: true }]}>
              <Select mode="multiple">
                <Select.Option value="univ">Universitaire</Select.Option>
                <Select.Option value="sup-non-univ">Supérieur non universitaire</Select.Option>
                <Select.Option value="sec-sup">Secondaire supérieur</Select.Option>
                <Select.Option value="exp-pro">Expérience professionnelle</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name={['paymentCF', 'companyNumber']} label="Numéro d'entreprise" rules={[
              { required: true },
              { pattern: /^\d\d\d\d\.\d\d\d\.\d\d\d$/, message: "Le numéro doit être au format : 1234.123.123 (avec les points)" }
            ]}>
              <Input placeholder="1234.123.123" />
            </Form.Item>
            <Form.Item name={['paymentCF', 'companyName']} label="Nom de l'entreprise" rules={[{ required: true }]}>
              <Input placeholder="Nom de l'entreprise" />
            </Form.Item>
            <Form.Item name={['paymentCF', 'companyZip']} label="Code postal" rules={[{ required: true }]}>
              <Input placeholder="Code postal de l'entreprise" />
            </Form.Item>
            <Form.Item name={['paymentCF', 'companyCity']} label="Localité" rules={[{ required: true }]}>
              <Input placeholder="Localité de l'entreprise" />
            </Form.Item>
            <Form.Item name={['paymentCF', 'authorizationNumber']} label="N° d'autorisation client" rules={[
              { required: true },
              { pattern: /^\d\d\d\/\d\d\d\d\/\d\d\d\d\d$/, message: "Le numéro doit être au format : 123/1234/12345 (avec les /)" }
            ]}>
              <Input placeholder="123/1234/12345" />
            </Form.Item>
            <Form.Item name={['paymentCF', 'usageCode']} label="Code d'utilisation" rules={[
              { required: true },
              { pattern: /^\d{8}$/, message: "Le numéro doit être composé de 8 chiffres" }
            ]}>
              <Input placeholder="12345678" />
            </Form.Item>
          </Tabs.TabPane>

        </Tabs>

      </Form>

    </DraggableModal >
  )

}

export default EditPaymentModal