import React, { useMemo } from 'react'

import { sortBy, cloneDeep } from "lodash"
import { useHistory } from 'react-router'

import Icon from 'components/Icon'
import RegistrationsList from 'admin-app/RegistrationsList/RegistrationsList'
import Badges from 'components/Badges'

import { Button, Card, Tooltip } from 'antd'



const Registrations = ({ training, refreshTraining }) => {

  const history = useHistory()

  const registrations = useMemo(() => {
    const registrations = cloneDeep(training?.registrations?.filter(r => r.status === 'validated')) || []
    registrations.forEach(r => r.training = training)
    return sortBy(registrations, ['balance', 'lastname'])
  }, [training])

  return (
    <Card
      title={<><Icon icon="users" /> Inscriptions validées <Badges.Registrations training={training} /></>}
      extra={<Tooltip title="Envoyer un e-mail"><Button size="small" icon={<Icon icon="paper-plane" />} onClick={() => history.push(`/admin/send-mail?trainingId=${training.id}&status=validated`)} /></Tooltip>}
    >
      <RegistrationsList
        registrations={registrations}
        refresh={refreshTraining}
        from='training'
        showStatus={false}
      />
    </Card>
  )

}

export default Registrations


