import React, { useMemo } from 'react'

import { useHistory } from 'react-router-dom'
import { sortBy, cloneDeep } from "lodash"

import Icon from 'components/Icon'
import RegistrationsList from 'admin-app/RegistrationsList/RegistrationsList'
import Badges from 'components/Badges'

import { Button, Card, Col, Row, Tooltip } from 'antd'




const WaitingList = ({ training, refreshTraining }) => {

  const history = useHistory()

  const waitingRegistrations = useMemo(() => {
    const registrations = cloneDeep(training?.registrations?.filter(r => r.status === 'waiting-list')) || []
    registrations.forEach(r => r.training = training)
    return sortBy(registrations, ['isStudent', 'balance', 'lastname'])
  }, [training])

  const cancelledRegistrations = useMemo(() => {
    const registrations = training?.registrations?.filter(r => r.status === 'cancelled' || r.status === 'to-transfer' || r.status === 'transfered') || []
    registrations.forEach(r => r.training = training)
    return registrations
  }, [training])

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          title={<><Icon icon="user-clock" /> Liste d'attente <Badges.Blue count={waitingRegistrations.length || 0} /></>}
          extra={<Tooltip title="Envoyer un e-mail"><Button size="small" icon={<Icon icon="paper-plane" />} onClick={() => history.push(`/admin/send-mail?trainingId=${training.id}&status=waiting-list`)} /></Tooltip>}
        >
          <RegistrationsList
            registrations={waitingRegistrations}
            refresh={refreshTraining}
            from='training'
            showStatus={false}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card
          title={<><Icon icon="times-circle" /> Désistements <Badges.Blue count={cancelledRegistrations.length || 0} /></>}
          extra={<Tooltip title="Envoyer un e-mail"><Button size="small" icon={<Icon icon="paper-plane" />} onClick={() => history.push(`/admin/send-mail?trainingId=${training.id}&status=cancelled`)} /></Tooltip>}
        >
          <RegistrationsList
            registrations={cancelledRegistrations}
            refresh={refreshTraining}
            from='training'
          />
        </Card>
      </Col>
    </Row>
  )

}

export default WaitingList


