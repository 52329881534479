import React, { useState } from 'react'

import { isString } from 'lodash'

import Icon from 'components/Icon'
import { callGraph } from 'services/graphService'
import { isoDateToDateTime, nfEur } from 'misc/utils'
import { PaymentMethod, PaymentStatus } from 'components/Tags'
import api from 'services/adminApiService'
import AddNoteModal from './AddNoteModal'
import AddFileModal from './AddFileModal'

import { Button, Descriptions, Divider, message, Popconfirm, Space } from 'antd'



const Action = ({ action, user }) => {

  const data = JSON.parse(action.data)

  switch (action.type) {
    case 'note':
      return <>
        {data.important && <Icon.ImportantNote style={{ marginRight: 7 }} />}
        <span>{data.title}</span>
      </>
    case 'file':
      return <Action.WithButton name={<>
        {data.important && <Icon.ImportantNote style={{ marginRight: 7 }} />}
        <span>{data.title}</span>
      </>} data={data} user={user} />

    case 'pro-forma-invoice':
      return <Action.WithButton name="Envoi d'une facture pro-forma" data={data} user={user} />
    case 'ack-of-debt-generated':
      return <Action.WithButton name="Création d'une reconnaissance de dette" data={data} user={user} />
    case 'send-registration-received':
    case 'create-registration':
      return <span>Réception du bulletin d'inscription</span>
    case 'cancel-registration':
      return <span>Désistement</span>
    case 'send-email':
      return <span>Envoi d'un email</span>
    case 'cf-accepted':
      return <span>Chèques formation acceptés</span>
    case 'cf-rejected':
      return <span>Chèques formation refusés</span>
    case 'training-full-warning':
      return <span>Avertissement de formation complète</span>
    case 'registration-rejected':
      return <span>Inscription refusée</span>
    case 'registration-accepted':
      return <span>Inscription acceptée</span>
    case 'registration-confirmed':
      return <span>Inscription validée</span>
    case 'registration-to-transfer':
      return <span>Transfert d'inscription</span>
    case 'registration-transfered':
      return <span>Inscription transférée</span>
    case 'trainer-invited':
      return <span>Invitation formateur envoyée</span>
    case 'refund-done':
      return <span>Remboursement de {nfEur(-data.payment.amount)} effectué</span>
    case 'payment-created':
      if (data.payment.type === 'cf') return <span>Demande de paiement par CF reçue</span>
      if (data.payment.type === 'holdback') return <span>Retenue de {nfEur(-data.payment.amount)} effectuée</span>
      if (data.payment.amount < 0) return <span>Remboursement de {nfEur(-data.payment.amount)} demandé</span>
      return <span>Paiement de {nfEur(data.payment.amount)} reçu</span>
    case 'payment-deleted':
      return <span>Paiement supprimé</span>
    case 'payment-updated':
      return <span>Paiement modifié</span>
    case 'payment-reminder':
      return <span>Rappel de paiement envoyé</span>
    case 'practical-info-sent':
      return <span>Informations pratiques envoyées</span>
    case 'attendance-certificate-sent':
      return <Action.WithButton name="Attestation de présence envoyée" data={data} user={user} />
    case 'registration-closed':
      return <span>Inscription terminée</span>
    case 'auto-refund-training-cancelled':
      return <span>Remboursement automatique (formation annulée)</span>
    case 'auto-refund-waiting-list':
      return <span>Remboursement automatique (liste d'attente)</span>
    case 'auto-refund-cf-paid':
      return <span>Remboursement automatique (CF payés)</span>
    case 'auto-refund-paid-too-much':
      return <span>Remboursement automatique (trop payé)</span>
    case 'inform-training-cancelled':
      return <span>Information formation annulée</span>
    case 'warn-remove-flink-access-trainer':
      return <span>Avertissement de fin d'accès fLink</span>
    case 'warn-remove-flink-access-participant':
      return <span>Avertissement de fin d'accès fLink</span>
    case 'invoice-sent':
      if (!data.values?.invoice) return <Action.WithButton name="Facture envoyée" data={data} user={user} />
      if (data.values?.invoice?.amount >= 0) return <Action.WithButton name={`Facture ${data.values?.invoice?.invoiceNumber} (${nfEur(data.values?.invoice?.amount)}) envoyée`} data={data} user={user} />
      else return <Action.WithButton name={`Note de crédit ${data.values?.invoice?.invoiceNumber} (${nfEur(data.values?.invoice?.amount)}) envoyée`} data={data} user={user} />
    case 'update-email-admin':
      return <span>Modification de l'adresse e-mail (par un administrateur)</span>
    default: return <></>
  }
}

export default Action



Action.Icon = ({ action }) => {
  if (!action.isDone) return <Icon icon="spinner-third" spin />
  switch (action.type) {
    case 'note': return <Icon icon="sticky-note" />
    case 'file': return <Icon icon="file-alt" />
    case 'cancel-registration': return <Icon icon="times-circle" color="red" />
    case 'create-registration': return <Icon icon="file-signature" />
    case 'cf-accepted': return <Icon icon="shield-check" />
    case 'cf-rejected': return <Icon icon="times-circle" />
    case 'send-email':
    case 'training-full-warning':
    case 'trainer-invited':
    case 'inform-training-cancelled':
    case 'update-email-admin':
    case 'send-registration-received': return <Icon icon="envelope" />
    case 'registration-rejected': return <Icon icon="times-circle" color="red" />
    case 'registration-accepted': return <Icon icon="check" color="green" />
    case 'registration-confirmed': return <Icon icon="check-circle" color="green" />
    case 'registration-to-transfer': return <Icon icon="share-square" color="orange" />
    case 'registration-transfered': return <Icon icon="share-square" color="green" />
    case 'payment-created':
    case 'payment-deleted':
    case 'payment-updated':
    case 'refund-done': return <Icon icon="credit-card" />
    case 'payment-reminder': return <Icon icon="bell-exclamation" />
    case 'practical-info-sent': return <Icon icon="info-circle" />
    case 'pro-forma-invoice': return <Icon icon="file-invoice-dollar" />
    case 'attendance-certificate-sent': return <Icon icon="file-user" />
    case 'registration-closed': return <Icon icon="check-double" color="green" />
    case 'auto-refund-training-cancelled':
    case 'auto-refund-waiting-list':
    case 'auto-refund-cf-paid':
    case 'auto-refund-paid-too-much': return <Icon icon="credit-card" />
    case 'invoice-created': return <Icon icon="file-invoice-dollar" />
    case 'invoice-sent': return <Icon icon="file-invoice-dollar" />
    case 'warn-remove-flink-access-trainer':
    case 'warn-remove-flink-access-participant': return <Icon icon="exclamation-circle" color="gold" />
    default: return <Icon icon="dot-circle" />
  }
}



const Description = ({ action, user, refresh }) => {

  const [isAddNoteModalVisible, setIsAddNoteModalVisible] = useState(false)
  const [isAddFileModalVisible, setIsAddFileModalVisible] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const deleteAction = (action) => {
    api.actions.delete(action.id, { loader: setIsLoading })
      .then(() => {
        message.success('Élément supprimé.')
        refresh()
      })
      .catch(console.error)
  }

  const updateDone = () => {
    setIsAddNoteModalVisible(false)
    setIsAddFileModalVisible(false)
    message.success("Élément modifié.")
    refresh()
  }

  const data = JSON.parse(action.data)

  if (data.email) data.email.html = data.email.html.replace(/<div style="color: #058aae; margin-bottom: 7px;">.*$/, '')
  return <>
    <Descriptions bordered column={4} size="small" layout="vertical">
      <Descriptions.Item label="Date de création">{isoDateToDateTime(action.date)}</Descriptions.Item>
      <Descriptions.Item label="Opération terminé"><Icon.Boolean boolean={action.isDone} /></Descriptions.Item>
      <Descriptions.Item label="Date de fin">{isoDateToDateTime(action.doneDate)}</Descriptions.Item>
      <Descriptions.Item label="Qui">{action.who === 'system' ? 'Système' : action.who === 'user' ? 'Utilisateur' : action.who}</Descriptions.Item>
      {data.payment && <>
        <Descriptions.Item label="Paiement" span={4}>
          <Descriptions bordered column={1} size="small">
            <Descriptions.Item label="Date d'encodage">{isoDateToDateTime(data.payment.date)}</Descriptions.Item>
            <Descriptions.Item label="Date du paiement">{isoDateToDateTime(data.payment.paymentDate)}</Descriptions.Item>
            <Descriptions.Item label="Montant">{nfEur(data.payment.amount)}</Descriptions.Item>
            <Descriptions.Item label="Moyen de paiement"><PaymentMethod payment={data.payment} /></Descriptions.Item>
            <Descriptions.Item label="Statut"><PaymentStatus payment={data.payment} /></Descriptions.Item>
            <Descriptions.Item label="Compte bancaire">{data.payment.iban}</Descriptions.Item>
            <Descriptions.Item label="Note">{data.payment.note}</Descriptions.Item>
          </Descriptions>
        </Descriptions.Item>
      </>}
      {data.email && <Descriptions.Item label="Email" span={4}>
        <strong>De : </strong>{data.email.from}<br />
        <strong>A : </strong>{data.email.to}<br />
        <strong>Objet : </strong>{data.email.subject}<br />
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <div dangerouslySetInnerHTML={{ __html: data.email.html }}></div>
      </Descriptions.Item>}
      {action.type === 'note' && <Descriptions.Item label="Note" span={4}>
        <h3>{data.title}</h3>
        <pre>{data.content}</pre>
      </Descriptions.Item>}
      {action.type === 'file' && <Descriptions.Item label={<>Fichier<small style={{ marginLeft: 10 }}>- {data.filename}</small></>} span={4}>
        <h3>{data.title}</h3>
        <pre>{data.content}</pre>
      </Descriptions.Item>}
    </Descriptions>

    {action.type === 'note' && <Space style={{ marginTop: 10 }}>
      <Popconfirm title="Êtes-vous sûr ?" okText="Oui" cancelText="Non" onConfirm={() => deleteAction(action)}>
        <Button icon={<Icon icon="trash" />} size="small" danger loading={isLoading}>Supprimer</Button>
      </Popconfirm>
      <Button icon={<Icon icon="pencil" />} size="small" onClick={() => setIsAddNoteModalVisible(true)}>Modifier</Button>
      <AddNoteModal isVisible={isAddNoteModalVisible} onOK={updateDone} onCancel={() => setIsAddNoteModalVisible(false)} note={action} user={user} />
    </Space>}

    {action.type === 'file' && <Space style={{ marginTop: 10 }}>
      <Popconfirm title="Êtes-vous sûr ?" okText="Oui" cancelText="Non" onConfirm={() => deleteAction(action)}>
        <Button icon={<Icon icon="trash" />} size="small" danger loading={isLoading}>Supprimer</Button>
      </Popconfirm>
      <Button icon={<Icon icon="pencil" />} size="small" onClick={() => setIsAddFileModalVisible(true)}>Modifier</Button>
      <AddFileModal isVisible={isAddFileModalVisible} onOK={updateDone} onCancel={() => setIsAddFileModalVisible(false)} file={action} user={user} />
    </Space>}

  </>
}

Action.Description = Description



const WithButton = ({ data, user, name }) => {

  const [isDownloading, setIsDownloading] = useState(false)

  const openFile = file => {
    setIsDownloading(true)
    let endpoint
    if (isString(file)) endpoint = `/drive/special/approot:/Utilisateurs/${user.id}/${file}`
    else if (file?.id) endpoint = `/drive/items/${file.id}`
    else throw new Error("Missing file")
    callGraph({ endpoint }).then((data) => {
      window.open(data.webUrl, '_blank')
      setIsDownloading(false)
    }).catch(console.error)
  }

  return (
    <span className="action-with-button">
      {name}
      {data.pdfFile && <Button icon={<Icon icon="file-pdf" />} size="small" shape="circle" onClick={() => openFile(data.pdfFile)} loading={isDownloading} />}
      {data.filename && <Button icon={<Icon icon="file-alt" />} size="small" shape="circle" onClick={() => openFile(data.filename)} loading={isDownloading} />}
    </span>
  )
}
Action.WithButton = WithButton