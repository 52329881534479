import React, { useEffect, useState } from 'react'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import { sorters } from 'misc/utils'
import Color from 'components/Color'
import TableButton from 'components/TableButton'
import DraggableModal from 'components/DraggableModal'

import { PageHeader, Table, Button, Card, Input, Form, message, Select } from 'antd'

const { Column } = Table



const Categories = () => {

  const [categories, setCategories] = useState([])
  const [expandedCategories, setExpandedCategories] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [currentCategory, setCurrentCategory] = useState()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [form] = Form.useForm()

  const loadCategories = () => {
    api.categories.get({ loader: setIsLoading }).then(data => {
      setCategories(data)
      setExpandedCategories(data.map(c => c.id))
    }).catch(console.error)
  }

  useEffect(loadCategories, [])

  const onRowHandlers = (record, rowIndex) => ({
    onClick: e => {
      setCurrentCategory(record)
      form.setFieldsValue(record)
      setIsModalVisible(true)
    }
  })

  const openAddModal = () => {
    setCurrentCategory(null)
    form.resetFields()
    setIsModalVisible(true)
  }

  const save = () => {
    // ParentId or null
    const values = form.getFieldsValue()
    values.parentId = values.parentId || null
    // Update
    if (currentCategory) {
      // Max 2 levels
      if (currentCategory.children?.length && values.parentId) {
        return message.warning("Impossible de définir une catégorie parent, alors que la catégorie à déjà des enfants. Maximum 2 niveaux.")
      }
      // Update
      api.categories.put(currentCategory.id, values, { loader: setIsSaving }).then(data => {
        message.success("Modifications sauvegardées.")
        setIsModalVisible(false)
        loadCategories()
      }).catch(console.error)
    }
    // Insert
    else {
      api.categories.post(values, { loader: setIsSaving }).then(data => {
        message.success("Catégorie ajoutée.")
        setIsModalVisible(false)
        loadCategories()
      }).catch(console.error)
    }
  }

  const remove = signature => {
    api.categories.delete(signature.id, { loader: setIsLoading }).then(data => {
      message.success("Catégorie supprimée.")
      loadCategories()
    }).catch(console.error)
  }

  return (
    <>
      <PageHeader
        title={<><Icon icon="tags" fixedWidth /> Catégories de formation</>}
        extra={<Button icon={<Icon icon="plus" />} onClick={openAddModal}>Ajouter une catégorie</Button>}
      />
      <Card>
        <Table
          dataSource={categories}
          loading={isLoading}
          onRow={onRowHandlers}
          rowKey="id"
          rowClassName="row-clickable"
          size="small"
          expandable={{
            expandedRowKeys: expandedCategories,
            onExpand: (expanded, record) => {
              if (expanded) {
                setExpandedCategories(expandedCategories.concat(record.id))
              } else {
                setExpandedCategories(expandedCategories.filter(c => c !== record.id))

              }
            }
          }}
        >
          <Column title="Nom" dataIndex="name" sorter={sorters.string('name')} />
          <Column title="Couleur" dataIndex="color" render={value => <Color color={value} size="small" />} />
          <Column title="Formations" dataIndex={["_count", "trainings"]} />
          <Column title="Actions" width={60} render={item =>
            <TableButton
              icon="trash"
              danger
              onClick={() => remove(item)}
              confirmMessage={<>Êtes-vous sûr ? {item.children?.length ? <><br />Toutes les sous-catégories seront également supprimées.</> : ""}</>}
            />
          } />
        </Table>
      </Card>
      <DraggableModal
        title={<><Icon icon="tag" />{currentCategory ? "Modifier la catégorie" : "Nouvelle catégorie"}</>}
        open={isModalVisible}
        onOk={save}
        okButtonProps={{ loading: isSaving }}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          <Form.Item name="name" label="Nom"><Input /></Form.Item>
          <Form.Item label="Couleur">
            <Input.Group compact>
              <Form.Item name="color" noStyle>
                <Select style={{ width: "40%" }}>
                  <Select.Option value="#e7276c">Rose</Select.Option>
                  <Select.Option value="#019670">Vert</Select.Option>
                  <Select.Option value="#f8aa00">Jaune</Select.Option>
                  <Select.Option value="#058aae">Bleu</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="color" noStyle>
                <Input style={{ width: "40%" }} />
              </Form.Item>
              <Form.Item noStyle shouldUpdate={(prev, curr) => prev.color !== curr.color}>
                {({ getFieldValue }) => <Color color={getFieldValue('color')} style={{ marginLeft: 10 }} />}
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item name="parentId" label="Catégorie parent">
            <Select options={categories.map(c => ({ value: c.id, label: c.name }))} allowClear />
          </Form.Item>
        </Form>
      </DraggableModal>
    </>
  )

}

export default Categories