import React, { useState } from 'react'

import api from 'services/userApiService'

import Icon from 'components/Icon'
import ModalLayout from 'components/ModalLayout/ModalLayout'

import { Button, Form, Input, Result } from 'antd'



const ForgotPassword = () => {

  const [isDone, setIsDone] = useState(false)

  const send = (values) => {
    api.post('/auth/forgot-password', values).then(() => { setIsDone(true) }).catch(console.error)
  }

  return (
    <ModalLayout title={isDone || "Mot de passe oublié ?"}>
      {isDone
        ? <Result
          title="C'est fait !"
          subTitle="Si l'adresse que vous nous avez communiquée est enregistrée chez nous, nous vous avons envoyé un mail. Si vous n'avez rien reçu, vérifiez vos spams."
          status="success"
          style={{ paddingTop: 20 }}
          icon={<Icon icon="check-circle" />} />
        : <>
          <p style={{ textAlign: 'center' }}>Ne vous inquiétez pas. Récupérer son compte est très facile. Donnez-nous l'adresse e-mail avec laquelle vous avez créé votre compte, et nous vous enverrons un lien pour enregistrer un nouveau mot de passe <Icon icon="smile-wink" /></p>
          <Form onFinish={send} style={{ marginTop: 24 }}>
            <Form.Item name="email" rules={[{ required: true, message: 'Veuillez entrer votre adresse e-mail.' }, { type: "email", message: 'Veuillez entrer une adresse e-mail valide.' }]}>
              <Input prefix={<Icon icon="envelope" />} placeholder="E-mail" />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit" icon={<Icon icon="paper-plane" />}>Envoyer</Button>
            </Form.Item>
          </Form>
        </>}

    </ModalLayout>
  )

}

export default ForgotPassword