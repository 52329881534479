import React, { useContext, useEffect, useState } from 'react'

import moment from 'moment'
import { omit, pick } from "lodash"

import UserAuthContext from 'contexts/UserAuthContext'
import api from 'services/userApiService'

import Icon from 'components/Icon'

import { Button, Card, Col, DatePicker, Divider, Form, Input, Radio, Row, Space, Switch } from 'antd'



const registrationFields = ['billing', 'billingName', 'billingCompanyNumber', 'billingVAT', 'billingContact', 'billingEmail', 'billingPhone', 'billingStreet', 'billingNumber', 'billingZip', 'billingCity', 'billingCountry']



const UserInfos = ({ prev, next, registration, setRegistration }) => {

  const { user, setUser } = useContext(UserAuthContext)

  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    const userData = Object.assign({}, user, registration)
    if (userData.birthDate) userData.birthDate = moment(userData.birthDate)
    form.setFieldsValue(userData)
  }, [user, registration, form])

  const onFinishForm = (values) => {
    setIsLoading(true)
    const data = omit(values, registrationFields)
    setRegistration(pick(values, registrationFields))
    api.post('/auth/user', data)
      .then(user => {
        setUser(user)
        setIsLoading(false)
        next()
      })
      .catch((err) => {
        setIsLoading(false)
        console.error(err)
      })
  }

  return (
    <>
      <h1>Qui êtes-vous ?</h1>
      <p className="sub-title-light">Nous avons besoin de ces informations pour vous inscrire, vous envoyer l'attestation de participation, les factures... Mais ne vous inquiétez pas, nous traitons vos données avec le plus grand soin conformément au Règlement Général de Protection des Données.</p>

      <Form form={form} onFinish={onFinishForm} layout="vertical">

        <Space direction="vertical" style={{ display: 'flex' }} size="large">

          <Card title={<><Icon icon="clipboard-user" /> Informations personnelles</>}>
            <Row gutter={24}>

              <Col flex="1 1 300px">
                <Form.Item name="firstname" label="Prénom" rules={[{ required: true, message: 'Veuillez entrer votre prénom.' }]}>
                  <Input placeholder="Prénom" />
                </Form.Item>
                <Form.Item name="lastname" label="Nom" rules={[{ required: true, message: 'Veuillez entrer votre nom.' }]}>
                  <Input placeholder="Nom" />
                </Form.Item>
                <Form.Item name="gender" label="Genre" className="horizontal-6" rules={[{ required: true, message: 'Veuillez entrer votre genre.' }]}>
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="m">Homme</Radio.Button>
                    <Radio.Button value="f">Femme</Radio.Button>
                    <Radio.Button value="o">Autre</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item name="birthDate" label="Date de naissance" rules={[{ required: true, message: 'Veuillez entrer votre date de naissance.' }]}>
                  <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item name="birthPlace" label="Lieu de naissance" rules={[{ required: true, message: 'Veuillez entrer votre lieu de naissance.' }]}>
                  <Input prefix={<Icon icon="map-marker-alt" />} placeholder="Lieu de naissance" />
                </Form.Item>
                <Form.Item name="phone" label="Numéro de téléphone" rules={[{ required: true, message: 'Veuillez entrer votre numéro de téléphone.' }]}>
                  <Input prefix={<Icon icon="phone" />} placeholder="Téléphone" />
                </Form.Item>
              </Col>

              <Col flex="31px"><Divider type="vertical" style={{ height: '100%' }} /></Col>

              <Col flex="1 1 300px">
                <Form.Item label="Adresse" required>
                  <Row gutter={6} style={{ marginBottom: 6 }}>
                    <Col flex="4">
                      <Form.Item name="street" noStyle rules={[{ required: true, message: "Veuillez entrer la rue" }]}>
                        <Input placeholder="Rue" />
                      </Form.Item>
                    </Col>
                    <Col flex="1">
                      <Form.Item name="number" noStyle rules={[{ required: true, message: "Veuillez entrer le numéro" }]}>
                        <Input placeholder="Numéro" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={6} style={{ marginBottom: 6 }}>
                    <Col flex="2">
                      <Form.Item name="zip" noStyle rules={[{ required: true, message: "Veuillez entrer le code postal" }]}>
                        <Input placeholder="Code postal" />
                      </Form.Item>
                    </Col>
                    <Col flex="3">
                      <Form.Item name="city" noStyle rules={[{ required: true, message: "Veuillez entrer la ville" }]}>
                        <Input placeholder="Ville" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col flex="auto">
                      <Form.Item name="country" noStyle rules={[{ required: true, message: "Veuillez entrer le pays" }]}>
                        <Input placeholder="Pays" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="diplomasInstitutions" label="Etablissement(s) de délivrance (diplômes)" rules={[{ required: true, message: 'Veuillez mentionner le(s) établissement(s) de délivrance ou noter explicitement "/"' }]}>
                  <Input.TextArea placeholder="Etablissement(s) de délivrance (diplômes)" autoSize={{ minRows: 2 }} />
                </Form.Item>
                <Form.Item name="diplomas" label="Diplôme(s)" rules={[{ required: true, message: 'Veuillez mentionner vos diplômes ou noter explicitement "/"' }]}>
                  <Input.TextArea placeholder="Diplôme(s) et établissement de délivrance" autoSize={{ minRows: 2 }} />
                </Form.Item>
              </Col>

            </Row>
          </Card>

          <Card title={<><Icon icon="chalkboard-teacher" /> Informations de facturation pour cette inscription</>}>
            <Row gutter={24}>
              <Col flex="1 1 300px">
                <Form.Item name="billing" label="Facturation" rules={[{ required: true, message: 'Veuillez choisir une méthode de facturation' }]}>
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="personal">À mon nom</Radio.Button>
                    <Radio.Button value="company">À une société</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(prev, curr) => prev.billing !== curr.billing}>
                  {({ getFieldValue }) => {
                    if (getFieldValue('billing') === "personal") return <></>
                    if (getFieldValue('billing') === "company") return <>
                      <Form.Item name="billingName" label="Société/Institution" rules={[{ required: true, message: 'Veuillez entrer le nom de la société/institution.' }]}>
                        <Input placeholder="Société/Institution" />
                      </Form.Item>
                      <Form.Item label="Numéro d'entreprise" name="billingCompanyNumber" rules={[{ required: true, message: 'Veuillez entrer le numéro d\'entreprise.' }]}>
                        <Input placeholder="Numéro d'entreprise" />
                      </Form.Item>
                      <Form.Item label="Numéro de TVA" name="billingVAT">
                        <Input placeholder="Numéro de TVA" />
                      </Form.Item>
                      <Form.Item name="billingContact" label="Personne de contact" rules={[{ required: true, message: 'Veuillez entrer la personne de contact.' }]}>
                        <Input placeholder="Personne de contact" />
                      </Form.Item>
                    </>
                  }}
                </Form.Item>
              </Col>

              <Col flex="31px"><Divider type="vertical" style={{ height: '100%' }} /></Col>

              <Col flex="1 1 300px">
                <Form.Item noStyle shouldUpdate={(prev, curr) => prev.billing !== curr.billing}>
                  {({ getFieldValue }) => getFieldValue('billing') === "company" && <>
                    <Form.Item name="billingEmail" label="Adresse e-mail" rules={[{ required: true, message: 'Veuillez entrer l\' adresse e-mail.' }]}>
                      <Input prefix={<Icon icon="envelope" />} placeholder="E-mail" />
                    </Form.Item>
                    <Form.Item name="billingPhone" label="Numéro de téléphone" rules={[{ required: true, message: 'Veuillez entrer le numéro de téléphone.' }]}>
                      <Input prefix={<Icon icon="phone" />} placeholder="Téléphone" />
                    </Form.Item>
                    <Form.Item label="Adresse" required>
                      <Row gutter={6} style={{ marginBottom: 6 }}>
                        <Col flex="4">
                          <Form.Item name="billingStreet" noStyle rules={[{ required: true, message: "Veuillez entrer la rue" }]}>
                            <Input placeholder="Rue" />
                          </Form.Item>
                        </Col>
                        <Col flex="1">
                          <Form.Item name="billingNumber" noStyle rules={[{ required: true, message: "Veuillez entrer le numéro" }]}>
                            <Input placeholder="Numéro" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6} style={{ marginBottom: 6 }}>
                        <Col flex="2">
                          <Form.Item name="billingZip" noStyle rules={[{ required: true, message: "Veuillez entrer le code postal" }]}>
                            <Input placeholder="Code postal" />
                          </Form.Item>
                        </Col>
                        <Col flex="3">
                          <Form.Item name="billingCity" noStyle rules={[{ required: true, message: "Veuillez entrer la ville" }]}>
                            <Input placeholder="Ville" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col flex="auto">
                          <Form.Item name="billingCountry" noStyle rules={[{ required: true, message: "Veuillez entrer le pays" }]}>
                            <Input placeholder="Pays" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  </>
                  }
                </Form.Item>
              </Col>
            </Row>
          </Card>

        </Space>

        <div style={{ marginTop: 25, marginBottom: 30 }}>
          <span>Je souhaite être tenu informé(e) des activités du Centre de Formation Continue Vinci.</span>
          <Form.Item name="keepInformed" valuePropName="checked" noStyle>
            <Switch checkedChildren="Oui" unCheckedChildren="Non" style={{ marginLeft: 10 }} />
          </Form.Item>
        </div>

        <Row justify="space-between">
          <Col><Button icon={<Icon icon="arrow-left" />} onClick={prev}>Prédécent</Button></Col>
          <Col><Button loading={isLoading} type="primary" htmlType="submit" icon={<Icon icon="save" />}>Sauvegarder mes données</Button></Col>
        </Row>

      </Form>

      <p className="rgpd-infos" style={{ marginTop: 35 }}>L’ASBL HE Vinci est soucieuse de traiter vos données à caractère personnel conformément au Règlement Général de Protection des Données entré en vigueur ce 25 mai 2018. Nous vous informons traiter les données collectées par ce formulaire aux seules fins de l’administration de nos formations continues. Les traitements, en ce compris la collecte, sont effectués conformément aux dispositions légales applicables en matière de formation continue, mais aussi en vue de pouvoir réaliser nos obligations contractuelles ou précontractuelles à votre égard dans le cadre de nos prestations.</p>
      <p className="rgpd-infos">Vous avez un droit d’accès, de rectification sans frais, à l’effacement et à la limitation des traitements vous concernant. Par ailleurs, vous avez le droit de recevoir les données vous concernant dans un format structuré et/ou de les faire transmettre à une autre entreprise.</p>
      <p className="rgpd-infos">La Haute École reste à votre disposition pour toutes les questions, les remarques ou les plaintes à formuler concernant la protection de vos données personnelles. Si vous avez connaissance de l’existence d’une fuite de données ou si vous en suspectez une, nous vous demandons de nous la signaler immédiatement à l’adresse <a href="mailto:privacy@vinci.be">privacy@vinci.be</a></p>
      <p className="rgpd-infos">En outre, et conformément à l’article 37 du RGPD, la Haute École a procédé à la désignation d’une Data Protection Office ou délégué à la protection des données, en la personne de Madame Saba PARSA, avocate et DPO Certifiée. Vous pouvez la contacter à l’adresse mail suivante dpo@vinci.be. Enfin, vous disposez également du droit d’introduire une réclamation auprès de l’Autorité de Protection des Données (APD) à l’adresse suivante : rue de la Presse 35 - 1000 Bruxelles - téléphone 02/274.48.00 - ou via leur site internet : <a href="https://www.autoriteprotectiondonnees.be" target="_blank" rel="noreferrer">https://www.autoriteprotectiondonnees.be</a></p>
    </>
  )

}

export default UserInfos