import React, { useState } from 'react'

import './ImportPayments.less'

import { useLocalStorage } from 'react-use'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import { isoDateToDateShort, nfEur } from 'misc/utils'
import TableLink from 'components/TableLink'
import TableButton from 'components/TableButton'
import SearchRegistrationModal from './SearchRegistrationModal'
import RefundModal from './RefundModal'

import { Button, Card, Col, Descriptions, message, PageHeader, Popconfirm, Row, Space, Table, Upload, Alert } from 'antd'

const { Column } = Table



const ImportPayments = () => {

  const [payments, setPayments] = useLocalStorage('import-payments-payments', [])
  const [isLoading, setIsLoading] = useState(false)
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false)
  const [isRefundModalVisible, setIsRefundModalVisible] = useState(false)
  const [currentPayment, setCurrentPayment] = useState(null)

  /**
   * Upload the file
   */
  const uploadFile = async ({ file, filename, onSuccess }) => {
    const formData = new FormData()
    formData.append(filename, file)
    api.call('/payments/upload', { method: 'POST', body: formData, loader: setIsLoading })
      .then(data => {
        onSuccess(data, file)
        setPayments(data)
      })
      .catch(console.error)
  }

  const cancel = () => {
    setPayments([])
  }

  const savePayments = () => {
    api.payments.post(payments, { loader: setIsLoading })
      .then(data => {
        message.success("Paiements correctement encodés.")
        setPayments([])
      })
      .catch(console.error)
  }

  const getRowClassName = payment => `row-payment-${payment.action}`

  const ignore = payment => {
    payment.action = 'ignore'
    setPayments([...payments])
  }
  const cancelIgnore = payment => {
    payment.action = payment.registrationId ? 'apply' : 'check'
    setPayments([...payments])
  }
  const startApply = payment => {
    setIsSearchModalVisible(true)
    setCurrentPayment(payment)
  }
  const startRefund = payment => {
    setIsRefundModalVisible(true)
    setCurrentPayment(payment)
  }
  const cancelRefund = payment => {
    payment.action = payment.registrationId ? 'apply' : 'check'
    delete payment.refund
    setPayments([...payments])
  }
  const apply = (registration) => {
    setIsSearchModalVisible(false)
    currentPayment.action = 'apply'
    currentPayment.registration = registration
    currentPayment.registrationId = registration.id
    setPayments([...payments])
  }
  const refund = (refund) => {
    setIsRefundModalVisible(false)
    currentPayment.action = 'refund'
    currentPayment.refund = refund
    setPayments([...payments])
  }

  return (
    <>
      <PageHeader title={<><Icon icon="file-import" fixedWidth /> Importer des paiements</>} />

      <Card title={payments.length ? <><Icon icon="cogs" /> Lettrage des opérations</> : <><Icon icon="file-upload" /> Envoi du fichier</>}>

        {!payments.length &&
          <Upload.Dragger name="file" customRequest={uploadFile} showUploadList={false} maxCount={1} accept="text/csv,application/vnd.ms-excel,application/csv,application/vnd.msexcel,application/excel">
            {isLoading
              ? <Icon icon="spinner-third" size="2x" spin />
              : <Icon icon="inbox" size="2x" />}
            <p className="ant-upload-text">Cliquez ou déposez le fichier CSV de la banque ici.</p>
            <p className="ant-upload-hint">Un seul fichier *.csv, maximum 0,5Mo</p>
          </Upload.Dragger>
        }

        {!!payments.length &&
          <>
            <Alert
              message="Aide à l'encodage"
              description={<>
                En fonction de la couleur de l'opération, certaines actions seront faites ou non dans l'application :
                <ul>
                  <li><span style={{ backgroundColor: 'white', color: '#d9d9d9' }}>Une opération grisée ne sera pas intégrée dans l'application</span></li>
                  <li><span className="row-payment-apply">Une opération verte sera associée à l'inscription d'un participant</span></li>
                  <li><span className="row-payment-refund">Une opération orange créera une demande de remboursement</span></li>
                </ul>
                <p><strong>Astuce !</strong> Une opération orange qui contient quelque-chose dans la colonne utilisateur/inscription ajoutera le paiement et le remboursement pour l'inscription d'un participant. S'il n'y a rien, seul la demande de remboursement sera créée, mais pas liée au compte du participant. <br />Pour lettrer une demande de remboursement, il faut d'abord lettrer la demande, et ensuite encoder le remboursement.</p>
              </>}
              showIcon
              closable
              style={{ marginBottom: 25 }}
            />
            <Table
              dataSource={payments}
              size="small"
              pagination={false}
              rowClassName={getRowClassName}
              className="row-clickable"
              expandable={{
                expandRowByClick: true,
                expandIconColumnIndex: -1,
                expandedRowRender: payment => <>
                  <Descriptions bordered size="small">
                    <Descriptions.Item label="N° d'extrait">{payment.rawBankData.file_number}</Descriptions.Item>
                    <Descriptions.Item label="N° sur extrait">{payment.rawBankData.number_on_file}</Descriptions.Item>
                    <Descriptions.Item label="IBAN">{payment.rawBankData.iban}</Descriptions.Item>
                    <Descriptions.Item label="Date comptabilisation">{payment.rawBankData.payment_date}</Descriptions.Item>
                    <Descriptions.Item label="IBAN Contrepartie">{payment.rawBankData.counterpart_iban}</Descriptions.Item>
                    <Descriptions.Item label="Nom Contrepartie">{payment.rawBankData.counterpart_name}</Descriptions.Item>
                    <Descriptions.Item label="Rue et numéro">{payment.rawBankData.counterpart_street_number}</Descriptions.Item>
                    <Descriptions.Item label="CP et localité">{payment.rawBankData.counterpart_zip_city}</Descriptions.Item>
                    <Descriptions.Item label="Communication">{payment.rawBankData.communication}</Descriptions.Item>
                    <Descriptions.Item label="Date valeur">{payment.rawBankData.date}</Descriptions.Item>
                    <Descriptions.Item label="Montant">{payment.rawBankData.amount}</Descriptions.Item>
                    <Descriptions.Item label="Devise">{payment.rawBankData.currency}</Descriptions.Item>
                    <Descriptions.Item label="Moyen">{payment.rawBankData.payment_method}</Descriptions.Item>
                    <Descriptions.Item label="BIC">{payment.rawBankData.bic}</Descriptions.Item>
                    <Descriptions.Item label="Pays">{payment.rawBankData.country}</Descriptions.Item>
                    <Descriptions.Item label="Transaction">{payment.rawBankData.transaction}</Descriptions.Item>
                  </Descriptions>
                </>
              }}
              rowKey={record => record.rawBankData.transaction}
            >
              <Column title="Date" render={payment => isoDateToDateShort(payment.paymentDate)} />
              <Column title="Utilisateur" render={payment => <TableLink.User user={payment.registration?.user} />} />
              <Column title="Inscription" render={payment => <TableLink.Training training={payment.registration?.training} />} />
              <Column title="Solde" render={payment => nfEur(payment.registration?.balance)} />
              <Column title="Montant" render={payment => nfEur(payment.amount)} />
              <Column title="IBAN" dataIndex="iban" />
              <Column title="Communication" dataIndex={['rawBankData', 'communication']} />
              <Column title="Actions" width="100px" render={payment => <Space>
                {payment.action !== "ignore" && <TableButton icon="trash" onClick={() => ignore(payment)} danger tooltip="Ignorer le paiement" />}
                {payment.action === "ignore" && <TableButton icon="trash-restore" onClick={() => cancelIgnore(payment)} success tooltip="Ne pas ignorer le paiement" />}
                {payment.action !== "ignore" && <TableButton icon="pencil" onClick={() => startApply(payment)} tooltip="Lettrer le paiement à une inscription" />}
                {(payment.action === "check" || payment.action === "apply") && <TableButton icon="undo" onClick={() => startRefund(payment)} tooltip="Créer une demande de remboursement" />}
                {payment.action === "refund" && <TableButton icon="redo" onClick={() => cancelRefund(payment)} tooltip="Ne pas créer la demande de remboursement" />}
              </Space>} />
            </Table>
            <Row justify="space-between" style={{ marginTop: 25 }}>
              <Col>
                <Popconfirm onConfirm={cancel} title={<>Êtes-vous sur ?<br />Toutes les modifications seront perdues.</>} >
                  <Button icon={<Icon icon="undo" />}>Annuler</Button>
                </Popconfirm>
              </Col>
              <Col>
                <Popconfirm onConfirm={savePayments} title={<>Êtes-vous sur ?<br />Ceci enregistrera tous les paiements.</>} >
                  <Button type="primary" icon={<Icon icon="check" />}>Valider cet encodage</Button>
                </Popconfirm>
              </Col>
            </Row>
          </>
        }

      </Card>

      <SearchRegistrationModal
        isVisible={isSearchModalVisible}
        onOK={apply}
        onCancel={() => setIsSearchModalVisible(false)}
      />
      <RefundModal
        isVisible={isRefundModalVisible}
        amount={currentPayment?.amount}
        onOK={refund}
        onCancel={() => setIsRefundModalVisible(false)}
        showInfo={!currentPayment?.registration}
      />
    </>
  )

}

export default ImportPayments