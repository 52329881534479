import React, { useContext } from 'react'

import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'

import './Portal.less'

import Icon from 'components/Icon'
import UserAuthContext from 'contexts/UserAuthContext'
import Profile from './Profile'
import Credentials from './Credentials'
import { isUserProfileOK } from 'misc/utils'
import Registrations from './Registrations'
import Registration from './Registration'
import Trainings from './Trainings'
import Training from 'user-app/Portal/Training'

import logoFC from 'static/images/VINCI_FC_logo.png'
import logoVinci from 'static/images/VINCI_logo_white.png'
import logoNeoxis from 'static/images/NEOXIS_logo_inline_white.png'
import logo_background from 'static/images/VINCI_logo_background.svg'
import logo_background2 from 'static/images/VINCI_FC_background_logo.svg'

import { Layout, Menu, Row, Col, Button } from 'antd'

const { Content, Footer } = Layout

const { REACT_APP_FLINK_URL, REACT_APP_VINCI_URL } = process.env



const Portal = () => {

  const history = useHistory()
  const location = useLocation()

  const { user, logout } = useContext(UserAuthContext)

  const onTabSwitch = e => {
    if (e.key !== "flink") {
      history.push(e.key)
    }
  }

  const userProfileOK = isUserProfileOK(user)

  return (
    <Layout className="portal-layout">
      <div className="modal-layout-background" >
        <img src={logo_background} alt="Logo FC arrière plan" />
        <img src={logo_background2} alt="Logo FC arrière plan" />
      </div>
      <Layout>
        <div className="header">
          <Row align="top" justify="space-between" className="header-blue">
            <Col>
              <img src={logoFC} alt="Logo formation continue" />
            </Col>
            <Col className="user-infos">
              <h2>Bonjour {user.firstname} ! <Icon icon="smile-beam" /></h2>
              <p className="client-number">Numéro de client : {user.clientNumber}</p>
              <Button ghost size="small" onClick={logout}>Me déconnecter</Button>
            </Col>
          </Row>
          <div className="header-border">
            <div style={{ backgroundColor: "#E7276D" }} />
            <div style={{ backgroundColor: "#F8AA00" }} />
            <div style={{ backgroundColor: "#009670" }} />
          </div>
          <Menu mode="horizontal" onSelect={onTabSwitch} selectedKeys={[location.pathname]} disabled={!userProfileOK}>
            <Menu.Item key="/portal/profile" icon={<Icon icon="user" />}>Mon profil</Menu.Item>
            {user.isParticipant && <Menu.Item key="/portal/registrations" icon={<Icon icon="clipboard-list-check" />}>Mes inscriptions</Menu.Item>}
            {user.isTrainer && <Menu.Item key="/portal/trainings" icon={<Icon icon="chalkboard-teacher" />}>Mes formations</Menu.Item>}
            <Menu.Item key="catalog" icon={<Icon icon="book-open" />}>
              <a href={REACT_APP_VINCI_URL} target="_blank" rel="noopener noreferrer">Catalogue de formation</a>
            </Menu.Item>
            <Menu.Item key="flink" icon={<Icon icon="external-link" />}>
              <a href={REACT_APP_FLINK_URL} target="_blank" rel="noopener noreferrer">Plateforme pédagogique FLink</a>
            </Menu.Item>
          </Menu>
        </div>
        <Content>
          {userProfileOK
            ? <Switch>
              <Route path="/portal/profile"><Profile /></Route>
              <Route path="/portal/credentials"><Credentials /></Route>
              <Route path="/portal/trainings/:id"><Training /></Route>
              <Route path="/portal/trainings"><Trainings /></Route>
              <Route path="/portal/registrations/:id"><Registration /></Route>
              <Route path="/portal/registrations"><Registrations /></Route>
              <Route path="*"><Redirect to="/portal/profile" /></Route>
            </Switch>
            : <Profile forceFill={true} />
          }
          <div hidden>
            <p>Un participant voit directement la liste de ses inscriptions en cours. Pour chaque inscription, il peut suivre son état :</p>
            <ul>
              <li>Acompte payé</li>
              <li>En attente de paiement (il peut alors cliquer sur un bouton "payer")</li>
              <li>Inscription validée</li>
            </ul>
            <p>Le client peut choisir de payer l'acompte, ou directement le solde. Mais 3 semaines avant le début de la formation, il doit payer le solde. Passé cette date, il ne peut donc plus choisir de payer uniquement l'acompte.</p>
            <p>Une formation en attente de paiement peut être payée directement :</p>
            <ul>
              <li>Par virement bancaire, dans ce cas le client voit les informations de paiement (compte, communication structurée, montant)</li>
              <li>En ligne (mollie?) </li>
              <li>Par chèques formation, dans ce cas il doit remplir le formulaire en ligne directement (document 1).</li>
            </ul>
            <p>Pour chaque inscription, le participant peut visualiser la liste des paiements effectués et leur état (réception du virement, état de validation des chèques formation)</p>
            <p>Un participant peut annuler une inscription (se désister). Dans ce cas, 25€ de frais administratifs sont déduits d’office, si le désistement est entre 3-6 semaines avant le début l’acompte est déduit, si c’est moins de 3 semaines, la totalité est perdue, mais on ne réclame pas ce que le participant n’a pas payé.</p>
            <p>Un participant peut également se désister pour raison médicale. Dans ce cas, aucun remboursement, mais possibilité de faire la formation l’année prochaine, a condition d’avoir un certificat médical, formation effectivement reprogrammée, un mail à envoyer pour demander l’inscription quand il y a encore de la place. L'envoi du mail avec le certificat médical sera archivé dans le dossier participant.</p>
            <p>Pour chaque inscription, le participant peut téléchager les attestations une fois le solde OK, et les présences encodées. Un statut montre cela : solde KO, en attente (d'encdage des présences), présences KO, OK</p>
            <p>L'attestation de présence n'est délivrée que lorsque le participant à 75% de présence à la formation (sauf exceptions).</p>
            <p>Attention, dans le cas ou le participant a un taux de présence qui fait diminuer le montant des chèques formations, et que l’acompte de compense pas la différence, il ne peut pas télécharger les attestations/certificats tant que le solde n'est pas payé.</p>
          </div>
        </Content>
        <Footer>
          <Row align="bottom">
            <Col className="logo-vinci" span={6}>
              <a href="https://www.vinci.be" target="_blank" rel="noreferrer"><img src={logoVinci} alt="Logo Vinci" /></a>
            </Col>
            <Col className="copyright" span={12}>
              &copy; {new Date().getFullYear()} - Développé par <a href="https://www.neoxis.be" target="_blank" rel="noreferrer"><img src={logoNeoxis} alt="Neoxis logo" /></a>
            </Col>
            <Col className="links" span={6}>
              <div>
                <a href="https://www.facebook.com/formationcontinuevinci/" target="_blank" rel="noreferrer"><Icon icon="fab facebook" /> Facebook</a>
                <a href="https://www.linkedin.com/showcase/centre-de-formation-continue-vinci/" target="_blank" rel="noreferrer"><Icon icon="fab linkedin" /> Linkedin</a>
              </div>
            </Col>
          </Row>
        </Footer>
      </Layout>
    </Layout>
  )
}

export default Portal