import React, { useState } from 'react'

import Icon from 'components/Icon'
import api from 'services/userApiService'

import { Button, Tag } from 'antd'



const DocumentStatus = ({ document }) => {

  const [isDownloading, setIsDownloading] = useState(false)

  if (document.isDone && !document.data?.pdfFile && !document.data.filename) {
    return <Tag color="green" icon={<Icon icon="check" />}>Envoyé</Tag>
  }

  if (document.isDone) return <Button
    icon={<Icon icon="download" />}
    size="small"
    loading={isDownloading}
    onClick={() => {
      setIsDownloading(true)
      api.get(`/documents/${document.id}/download`)
        .then(data => {
          window.open(window.URL.createObjectURL(data), '_blank')
          setIsDownloading(false)
        })
        .catch(err => {
          setIsDownloading(false)
          console.error(err)
        })
    }}
  >Télécharger</Button>

  return <Tag color="blue" icon={<Icon icon="hourglass" />}>En cours d'envoi</Tag>
}

export default DocumentStatus