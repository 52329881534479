import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import Icon from 'components/Icon'
import api from 'services/userApiService'

import { Button, Card, Col, Form, Input, message, Modal, PageHeader, Row } from 'antd'



const Credentials = () => {

  const [isLoading, setIsLoading] = useState(false)
  const [request, setRequest] = useState(null)
  const [password, setPassword] = useState("")

  const [emailForm] = Form.useForm()
  const [passwordForm] = Form.useForm()

  const history = useHistory()

  const hideModal = () => {
    setPassword("")
    setRequest(null)
  }

  const onConfirm = () => {
    if (request.email) {
      api.post('/auth/send-update-email', { email: request.email, password }, { loader: setIsLoading })
        .then(() => {
          emailForm.resetFields()
          hideModal()
          message.success("Un e-mail avec un lien de confirmation vient de vous être envoyé sur votre nouvelle adresse e-mail. Celui-ci est valable 15 minutes.", 5)
        })
        .catch(console.error)
    } else {
      api.post('/auth/user', { password, newPassword: request.newPassword }, { loader: setIsLoading })
        .then(() => {
          passwordForm.resetFields()
          hideModal()
          message.success("Votre mot de passe est bien changé.")
        })
        .catch(console.error)
    }
  }

  return (
    <>
      <PageHeader
        title={<><Icon icon="user-lock" /> Mes données de connexion</>}
        onBack={() => history.goBack()}
        backIcon={<Icon icon="arrow-left" fixedWidth color="#006f91" />}
      />
      <p className="sub-title-light">Pour vous connecter, vous devez utiliser votre adresse e-mail et un mot de passe. Ce sont ces mêmes identifiants qui vous permettent de vous connecter à notre plateforme pédagogique FLink. Conservez-les précieusement.</p>

      <Row gutter={24}>
        <Col span={24} md={12}>
          <Card title={<><Icon icon="lock-alt" /> Changer mon mot de passe</>} style={{ marginTop: 15 }}>
            <p className="sub-title-light">Entrez ici un nouveau mot de passe. Celui-ci sera valable pour votre compte CFC ainsi que pour FLink.</p>
            <Form form={passwordForm} onFinish={setRequest}>
              <Form.Item name="newPassword" rules={[
                { required: true, message: 'Veuillez entrer votre nouveau mot de passe.' },
                { min: 8, message: 'Le mot de passe doit faire au minimum 8 caractères.' }
              ]}>
                <Input.Password prefix={<Icon icon="lock" />} placeholder="Nouveau mot de passe" />
              </Form.Item>
              <Form.Item name="confirm-password" dependencies={['newPassword']} rules={[
                { required: true, message: 'Veuillez confirmer votre nouveau mot de passe.' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) return Promise.resolve()
                    return Promise.reject(new Error('Les deux mots de passe ne correspondent pas.'));
                  }
                })
              ]}>
                <Input.Password prefix={<Icon icon="lock" />} placeholder="Confirmez le mot de passe" />
              </Form.Item>
              <Button loading={isLoading} type="primary" htmlType="submit" icon={<Icon icon="save" />}>Enregistrer</Button>
            </Form>
          </Card>
        </Col>
        <Col span={24} md={12}>
          <Card title={<><Icon icon="envelope" /> Changer mon adresse e-mail</>} style={{ marginTop: 15 }}>
            <p className="sub-title-light">Entrez ici une nouvelle adresse e-mail. Vous recevrez un e-mail avec un lien de validation valable 15 minutes. Cliquez dessus pour valider le changement d'adresse.</p>
            <Form form={emailForm} onFinish={setRequest}>
              <Form.Item name="email" rules={[{ required: true, message: 'Veuillez entrer votre nouvelle adresse e-mail.' }, { type: "email", message: 'Veuillez entrer une adresse e-mail valide.' }]}>
                <Input prefix={<Icon icon="envelope" />} placeholder="Nouvelle adresse e-mail" />
              </Form.Item>
              <Button loading={isLoading} type="primary" htmlType="submit" icon={<Icon icon="paper-plane" />}>Envoyer le lien</Button>
            </Form>
          </Card>
        </Col>
      </Row>





      <Modal open={request} onCancel={hideModal} closable={false} width={350} style={{ textAlign: 'center' }} onOk={onConfirm} confirmLoading={isLoading}>
        <h2>Une dernière chose...</h2>
        {request?.email && <p className="sub-title-light">Entrez votre mot de passe pour confirmer la demande de changement.</p>}
        {request?.newPassword && <p className="sub-title-light">Entrez votre mot de passe actuel pour confirmer le changement.</p>}
        <Input.Password prefix={<Icon icon="lock" />} placeholder="Mot de passe" value={password} onChange={e => setPassword(e.target.value)} />
      </Modal>

    </>
  )

}

export default Credentials