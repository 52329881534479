import React, { useMemo } from 'react'

import { sortBy, cloneDeep } from "lodash"

import Icon from 'components/Icon'
import RegistrationsList from 'compta-app/RegistrationsList/RegistrationsList'
import Badges from 'components/Badges'

import { Card } from 'antd'



const Registrations = ({ training, refreshTraining }) => {

  const registrations = useMemo(() => {
    const registrations = cloneDeep(training?.registrations?.filter(r => r.status === 'validated')) || []
    registrations.forEach(r => r.training = training)
    return sortBy(registrations, ['balance', 'lastname'])
  }, [training])

  return (
    <Card title={<><Icon icon="users" /> Inscriptions validées <Badges.Registrations training={training} /></>}>
      <RegistrationsList
        registrations={registrations}
        refresh={refreshTraining}
        from='training'
        showStatus={false}
      />
    </Card>
  )

}

export default Registrations


