import React, { useEffect, useState } from 'react'

import Icon from 'components/Icon'

import { Button, Card, List, Popconfirm, Upload } from 'antd'
import api from 'services/adminApiService'



const FilesCard = ({ training, style = {} }) => {

  const [files, setFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const loadFiles = () => {
    api.get(`/trainings/${training.id}/files`, { loader: setIsLoading })
      .then(data => setFiles(data.map(f => ({ name: f }))))
      .catch(console.error)
  }

  useEffect(loadFiles, [training.id])

  /**
   * Upload the file
   */
  const uploadFile = async ({ file, filename, onSuccess }) => {
    file.status = 'uploading'
    setFiles(files.concat(file))
    const formData = new FormData()
    formData.append(filename, file)
    api.call(`/trainings/${training.id}/files`, { method: 'POST', body: formData })
      .then(loadFiles)
      .catch(err => {
        console.error(err)
        setFiles(files.filter(f => f.name !== filename))
      })
  }

  const removeFile = (file) => {
    api.call(`/trainings/${training.id}/files/${file.name}`, { method: 'DELETE' })
      .then(loadFiles)
      .catch(console.error)
  }

  // JSX
  return (
    <>
      <Card
        title={<><Icon icon="file" fixedWidth /> Fichiers site web</>}
        style={style}
        extra={<Upload key="upload" name="file" customRequest={uploadFile} showUploadList={false}>
          <Button icon={<Icon icon="upload" />} size="small">Ajouter</Button>
        </Upload>}
      >
        <List
          size="small"
          dataSource={files}
          loading={isLoading}
          renderItem={f =>
            <List.Item actions={[
              <Popconfirm key="delete" title="Êtes-vous sûr ?" onConfirm={() => removeFile(f)}>
                <Button size="small" shape="circle" icon={<Icon icon="trash" />} />
              </Popconfirm>
            ]}>
              {f.name}
            </List.Item>
          }
        />
      </Card>
    </>
  )

}

export default FilesCard