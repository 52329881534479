import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import './Registrations.less'

import Icon from 'components/Icon'
import { isoDateToDateShort } from 'misc/utils'
import { PriceStatus, PublicRegistrationStatus } from 'components/Tags'
import PaymentModal from './PaymentModal'
import api from 'services/userApiService'

import { Card, Col, Empty, Row, Spin } from 'antd'



const Registrations = () => {

  const history = useHistory()

  const [registrations, setRegistrations] = useState([])
  const [isLoading, setIsLoading] = useState(null)

  useEffect(() => {
    api.get('/registrations', { loader: setIsLoading }).then(setRegistrations).catch(console.error)
  }, [])

  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false)
  const [currentRegistration, setCurrentRegistration] = useState(null)

  const showPaymentModal = registration => {
    setCurrentRegistration(registration)
    setIsPaymentModalVisible(true)
  }

  if (!registrations || isLoading) {
    return <Spin size="large" />
  }

  return (
    <>
      <h1><Icon icon="clipboard-list-check" /> Mes inscriptions</h1>
      <p className="sub-title-light">Voici toutes vos inscriptions en cours, ou passées.</p>

      {!registrations.length && <Empty image={<Icon icon="inbox" size="4x" />} description={<h2>Vous n'avez pas encore d'inscription.</h2>} imageStyle={{ height: 65, color: '#006f91' }}></Empty>}

      <Row gutter={[16, 16]}>
        {registrations.map(registration => <Col key={registration.id}>
          <Card
            className="registration-card"
            actions={registration.status === 'cancelled' || registration.training.status === 'cancelled' || registration.status === "to-check" || registration.status === "rejected" || registration.isClosed ? [
              <div onClick={() => history.push(`/portal/registrations/${registration.id}`)}><Icon icon="eye" /><br />Détails</div>
            ] : [
              <div onClick={e => showPaymentModal(registration)}><Icon icon="money-check-alt" /><br />Effectuer un paiement</div>,
              <div onClick={() => history.push(`/portal/registrations/${registration.id}`)}><Icon icon="eye" /><br />Détails</div>
            ]}
          >
            <Card.Meta
              title={registration.training.name}
              description={<>
                <p>
                  {registration.training.code}<br />
                  {registration.training.start ? `${isoDateToDateShort(registration.training.start)} - ${isoDateToDateShort(registration.training.end)}` : "Pas encore programmée."}<br />
                </p>
                <p>
                  Prix : {registration.price} € &nbsp;&nbsp;<PriceStatus registration={registration} />
                </p>
                <p>Statut de l'inscription : &nbsp;&nbsp;<PublicRegistrationStatus registration={registration} /></p>
              </>}
            />
          </Card>
        </Col>)}
      </Row>

      {currentRegistration && <PaymentModal
        isVisible={isPaymentModalVisible}
        onCancel={() => setIsPaymentModalVisible(false)}
        onOK={() => { setIsPaymentModalVisible(false) }}
        registration={currentRegistration}
      />}
    </>
  )

}

export default Registrations