import React, { useContext } from 'react'

import { Route } from 'react-router-dom'

import { UserAuthProvider, UserAuthContext } from 'contexts/UserAuthContext'
import Login from 'user-app/Login'
import Loader from './Loader'



const UserAuthRoute = ({ children, ...rest }) => (
  <Route {...rest}>
    <UserAuthProvider>
      <UserLoadingTemplate>
        <Loader />
      </UserLoadingTemplate>
      <UserAuthenticatedTemplate>
        {children}
      </UserAuthenticatedTemplate>
      <UserUnAuthenticatedTemplate>
        <Login />
      </UserUnAuthenticatedTemplate>
    </UserAuthProvider>
  </Route>
)

export default UserAuthRoute



const UserAuthenticatedTemplate = ({ children }) => {
  const { isAuthenticated } = useContext(UserAuthContext)
  return isAuthenticated ? children : <></>
}

const UserUnAuthenticatedTemplate = ({ children }) => {
  const { isAuthenticated } = useContext(UserAuthContext)
  return isAuthenticated ? <></> : children
}

const UserLoadingTemplate = ({ children }) => {
  const { isLoading } = useContext(UserAuthContext)
  return isLoading ? children : <></>
}