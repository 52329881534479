import React from 'react'

import { Link } from 'react-router-dom'

import Icon from 'components/Icon'

import { Button, Result } from 'antd'



const Confirmation = ({ training }) => {

  return (
    <>
      <Result
        status="success"
        title="Merci ! Nous avons bien reçu votre formulaire d'inscription."
        subTitle={<>
          <p style={{ marginTop: 30 }}>Votre inscription est maintenant <strong>en attente d'acceptation</strong>.<br />Notre équipe va traiter votre formulaire d'inscription dans les meilleurs délais.</p>
          <p>Une fois l'inscription acceptée, vous reçevrez par mail toutes les informations pour effectuer le paiement de l'acompte. Dès que nous aurons reçu le paiement de l'acompte, votre inscription sera validée. Le solde devra ensuite être payé au plus tard 20 jours avant le début de la formation.</p>
          <p>Un e-mail avec les informations pratiques vous sera envoyé avant la formation, une fois le solde payé.</p>
          <p className="sub-title-light">N'hésitez pas à suivre l'état de votre inscription dans votre espace participant !</p>
        </>}
        extra={<Link to="/portal"><Button type="primary" key="redirect" icon={<Icon icon="user" />}>Mon espace participant</Button></Link>}
      />
    </>
  )

}

export default Confirmation