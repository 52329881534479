import React, { useEffect, useState } from 'react'

import './Partners.less'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import { API_ROOT } from 'services/userApiService'
import DraggableModal from 'components/DraggableModal'

import { PageHeader, Button, Card, Input, Form, message, Row, Col, Upload, Popconfirm, Empty } from 'antd'



const Partners = () => {

  const [partners, setPartners] = useState([])
  const [isSaving, setIsSaving] = useState(false)
  const [currentPartner, setCurrentPartner] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [form] = Form.useForm()

  const loadPartners = () => {
    api.partners.get().then(setPartners).catch(console.error)
  }

  useEffect(loadPartners, [])

  const edit = (partner) => {
    setCurrentPartner(partner)
    form.setFieldsValue({ ...partner, logoFileName: [{ name: partner.logoFileName, url: `${API_ROOT}/partners/${partner.logoFileName}` }] })
    setIsModalVisible(true)
  }

  const openAddModal = () => {
    setCurrentPartner(null)
    form.resetFields()
    setIsModalVisible(true)
  }

  const save = (values) => {
    values.logoFileName = values.logoFileName[0].response?.fileName || undefined
    if (currentPartner) {
      api.partners.put(currentPartner.id, values, { loader: setIsSaving }).then(data => {
        message.success("Modifications sauvegardées.")
        setIsModalVisible(false)
        loadPartners()
      }).catch(console.error)
    } else {
      api.partners.post(values, { loader: setIsSaving }).then(data => {
        message.success("Partenaire ajouté.")
        setIsModalVisible(false)
        loadPartners()
      }).catch(console.error)
    }
  }

  const remove = partner => {
    api.partners.delete(partner.id).then(data => {
      message.success("Partenaire supprimée.")
      loadPartners()
    }).catch(console.error)
  }

  /**
   * Upload : get value from event for the form
   */
  const normFile = (e) => {
    if (Array.isArray(e)) { return e }
    return e && e.fileList
  }

  /**
   * Upload the file
   */
  const uploadFile = async ({ file, filename, onSuccess }) => {
    const formData = new FormData()
    formData.append(filename, file)
    api.call('/partners/upload', { method: 'POST', body: formData })
      .then(data => onSuccess(data, file))
      .catch(console.error)
  }

  return (
    <>
      <PageHeader
        title={<><Icon icon="handshake" fixedWidth /> Partenaires de formation</>}
        extra={<Button icon={<Icon icon="plus" />} onClick={openAddModal}>Ajouter une partenaire</Button>}
      />

      <Card>
        <Row gutter={[16, 16]}>
          {partners.map(p =>
            <Col key={p.id}>
              <Card
                className="partner-card"
                cover={<img alt="logo" src={`${API_ROOT}/partners/${p.logoFileName}`} />}
                actions={[
                  <Icon key="1" icon="pen" onClick={() => edit(p)} />,
                  <Popconfirm key="2" title="Êtes-vous sûr ?" onConfirm={() => remove(p)}>
                    <Icon icon="trash" />
                  </Popconfirm>
                ]}
              >
                <Card.Meta title={p.name} description={<a href={p.website} target="_blank" rel="noopener noreferrer nofollow" >{p.website}</a>} />
              </Card>
            </Col>
          )}
          {!partners.length && <Empty description="Aucun partenaire pour l'instant." style={{ width: '100%' }}>
            <Button type="primary" icon={<Icon icon="plus" />} onClick={openAddModal}>Ajouter une partenaire</Button>
          </Empty>}
        </Row>
      </Card>

      <DraggableModal
        title={<><Icon icon="handshake" />{currentPartner ? "Modifier le partenaire" : "Nouveau partenaire"}</>}
        open={isModalVisible}
        onOk={form.submit}
        okText={currentPartner ? "Modifier" : "Ajouter"}
        okButtonProps={{ loading: isSaving }}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose
      >
        <Form form={form} onFinish={save} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          <Form.Item name="name" label="Nom" rules={[{ required: true }]}><Input /></Form.Item>
          <Form.Item name="website" label="Site web"><Input prefix={<Icon icon="globe" />} /></Form.Item>
          <Form.Item name="logoFileName" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
            <Upload.Dragger name="logo" customRequest={uploadFile} listType="picture" maxCount={1} className="logo-upload">
              <Icon icon="inbox" size="2x" />
              <p className="ant-upload-text">Cliquez ou déposez le fichier du logo ici.</p>
              <p className="ant-upload-hint">Un seul fichier image, maximum 0,5Mo</p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </DraggableModal>
    </>
  )

}

export default Partners