import React, { useEffect, useState } from 'react'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import {formatPaymentCom, isoDateToDateTime, nfEur} from 'misc/utils'
import TableLink from 'components/TableLink'

import {Card, Input, PageHeader, Space, Table} from 'antd'

const { Column } = Table



const Invoices = () => {

  const [invoices, setInvoices] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    defaultPageSize: 20,
    position: ['bottomRight', 'topRight'],
    total: 0,
    showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} éléments`,
    showSizeChanger: true
  })
  const [search, setSearch] = useState(null)

  const loadInvoices = (pageSize = pagination.pageSize, current = pagination.current) => {
    api.invoices.get({ loader: setLoading, params: { results: pageSize, page: current, search } }).then(result => {
      setInvoices(result.data)
      setPagination({ ...pagination, pageSize, current, total: result.totalCount })
    }).catch(console.error)
  }

  useEffect(() => {
    loadInvoices(pagination.pageSize, 1)
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  const onTableChange = (pagination) => {
    loadInvoices(pagination.pageSize, pagination.current)
  }

  const getCN = (invoice) => invoice.isBillingSpecific ? invoice.invoicingClientNumber : invoice.registration.user.invoicingClientNumber
  
  return (
    <>
      <PageHeader title={<><Icon icon="file-invoice-dollar" fixedWidth /> Factures et notes de crédit</>} />

      <Card>
        <Space style={{ position: 'absolute', top: 32, zIndex: 10, display: 'flex', alignItems: 'center' }}>
          <Input.Search
            placeholder="Rechercher..."
            onSearch={setSearch}
            style={{ maxWidth: 250 }}
            enterButton
            allowClear
          />
          <Icon.Info popover={{title: "Filtres disponibles :", content: <>
              - Nom sur la facture (participant ou entreprise)<br />
              - Numéro du client<br />
              - Montant<br />
              - Communication structurée (sans + et /)<br />
              - N° entreprise<br />
              - Numéro de facture<br />
              - Code formation
            </>}} />
        </Space>
        <Table
          dataSource={invoices}
          loading={loading}
          rowKey="id"
          size="small"
          pagination={pagination}
          onChange={onTableChange}
          style={{ marginTop: invoices?.length ? 0 : 56 }}
        >
          <Column title="N°" dataIndex="invoiceNumber" />
          <Column title="Date" dataIndex="date" render={isoDateToDateTime} />
          <Column title="Montant" dataIndex="amount" render={nfEur} />
          <Column title="Communic. struct." dataIndex="paymentCommunication" render={formatPaymentCom} />
          <Column title="Formation" render={i => <TableLink.Training training={i.registration.training} hideDate />} />
          <Column title="Nom" render={i => i.isBillingSpecific ? i.name : <TableLink.User user={i.registration.user} text={i.name} />} />
          <Column title="Entreprise" dataIndex="isBillingSpecific" render={v => <Icon.Boolean boolean={v} noError />} />
          <Column title="N° entrep." render={i => i.isBillingSpecific ? i.companyNumber : ''}  />
          <Column title="N° partic." render={i => <TableLink.User user={i.registration.user} text={i.registration.user.clientNumber} />} />
          <Column title="N° client" render={getCN} />
        </Table>
      </Card>
    </>
  )

}

export default Invoices