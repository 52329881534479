import React from 'react'

import './ModalLayout.less'

import logo_background from 'static/images/VINCI_logo_background.svg'
import logo_background2 from 'static/images/VINCI_FC_background_logo.svg'
import logo from 'static/images/VINCI_FC_logo.png'



const ModalLayout = ({ children, width = 420, title = null, style = {} }) => (
  <div className="modal-layout-container">
    <div className="modal-layout-background" >
      <img src={logo_background} alt="Logo FC arrière plan" />
      <img src={logo_background2} alt="Logo FC arrière plan" />
    </div>
    <div className="modal-layout-data" style={{ maxWidth: width }}>
      <div className="modal-layout-header">
        <img className="logo" src={logo} alt="Logo formation continue" />
      </div>
      <div className="modal-layout-header-border">
        <div style={{ backgroundColor: "#E7276D" }} />
        <div style={{ backgroundColor: "#F8AA00" }} />
        <div style={{ backgroundColor: "#009670" }} />
      </div>
      <div className="modal-layout-content" style={style}>
        {title && <h1>{title}</h1>}
        {children}
      </div>
    </div>
  </div >
)

export default ModalLayout