import React, { useEffect, useState } from 'react'

import './ImportPayments.less'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import { isoDateToDateShort, nfEur } from 'misc/utils'

import { Card, Descriptions, message, PageHeader, Space, Table } from 'antd'
import TableButton from 'components/TableButton'
import SearchRegistrationModal from './SearchRegistrationModal'
import RefundModal from './RefundModal'

const { Column } = Table



const PaymentsToApply = () => {

  const [payments, setPayments] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const loadPayments = () => {
    api.payments.get({ loader: setIsLoading, params: { type: 'toApply' } }).then(setPayments).catch(console.error)
  }

  useEffect(loadPayments, [])


  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false)
  const [isRefundModalVisible, setIsRefundModalVisible] = useState(false)
  const [currentPayment, setCurrentPayment] = useState(null)

  const remove = payment => {
    api.payments.delete(payment.id, { loader: setIsLoading })
      .then(data => {
        message.success("Paiement correctement ignoré.")
        loadPayments()
      })
      .catch(console.error)
  }
  const startApply = payment => {
    setIsSearchModalVisible(true)
    setCurrentPayment(payment)
  }
  const startRefund = payment => {
    setIsRefundModalVisible(true)
    setCurrentPayment(payment)
  }
  const apply = (registration) => {
    setIsSearchModalVisible(false)
    api.payments.put(currentPayment.id, { registrationId: registration.id, type: 'payment' }, { loader: setIsLoading })
      .then(data => {
        message.success("Paiement correctement lettré.")
        loadPayments()
      })
      .catch(console.error)
  }
  const refund = (refund) => {
    setIsRefundModalVisible(false)
    api.post(`/payments/${currentPayment.id}/refund`, refund, { loader: setIsLoading })
      .then(data => {
        message.success("Remboursement créé.")
        loadPayments()
      })
      .catch(console.error)
  }

  return (
    <>
      <PageHeader title={<><Icon icon="file-check" fixedWidth /> Paiements à lettrer</>} />

      <Card>

        <Table
          dataSource={payments}
          loading={isLoading}
          size="small"
          pagination={false}
          className="row-clickable"
          rowKey="id"
          expandable={{
            expandRowByClick: true,
            expandIconColumnIndex: -1,
            expandedRowRender: payment => {
              const data = JSON.parse(payment.rawBankData)
              return <>
                <Descriptions bordered size="small">
                  <Descriptions.Item label="N° d'extrait">{data.file_number}</Descriptions.Item>
                  <Descriptions.Item label="N° sur extrait">{data.number_on_file}</Descriptions.Item>
                  <Descriptions.Item label="IBAN">{data.iban}</Descriptions.Item>
                  <Descriptions.Item label="Date comptabilisation">{data.payment_date}</Descriptions.Item>
                  <Descriptions.Item label="IBAN Contrepartie">{data.counterpart_iban}</Descriptions.Item>
                  <Descriptions.Item label="Nom Contrepartie">{data.counterpart_name}</Descriptions.Item>
                  <Descriptions.Item label="Rue et numéro">{data.counterpart_street_number}</Descriptions.Item>
                  <Descriptions.Item label="CP et localité">{data.counterpart_zip_city}</Descriptions.Item>
                  <Descriptions.Item label="Communication">{data.communication}</Descriptions.Item>
                  <Descriptions.Item label="Date valeur">{data.date}</Descriptions.Item>
                  <Descriptions.Item label="Montant">{data.amount}</Descriptions.Item>
                  <Descriptions.Item label="Devise">{data.currency}</Descriptions.Item>
                  <Descriptions.Item label="Moyen">{data.payment_method}</Descriptions.Item>
                  <Descriptions.Item label="BIC">{data.bic}</Descriptions.Item>
                  <Descriptions.Item label="Pays">{data.country}</Descriptions.Item>
                  <Descriptions.Item label="Transaction">{data.transaction}</Descriptions.Item>
                </Descriptions>
              </>
            }
          }}
        >
          <Column title="Date" render={payment => isoDateToDateShort(payment.paymentDate)} />
          <Column title="Montant" render={payment => nfEur(payment.amount)} />
          <Column title="IBAN" dataIndex="iban" />
          <Column title="Communication" dataIndex={['rawBankData', 'communication']} />
          <Column title="Actions" width="100px" render={payment => <Space>
            <TableButton confirmMessage={<>Êtes-vous sûr ?<br />Cette opération est irréversible.</>} icon="trash" onClick={() => remove(payment)} danger />
            <TableButton icon="pencil" onClick={() => startApply(payment)} />
            <TableButton icon="undo" onClick={() => startRefund(payment)} />
          </Space>} />
        </Table>

      </Card>

      <SearchRegistrationModal
        isVisible={isSearchModalVisible}
        onOK={apply}
        onCancel={() => setIsSearchModalVisible(false)}
      />
      <RefundModal
        isVisible={isRefundModalVisible}
        amount={currentPayment?.amount}
        onOK={refund}
        onCancel={() => setIsRefundModalVisible(false)}
      />
    </>
  )

}

export default PaymentsToApply