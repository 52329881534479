import React, { useEffect, useState } from 'react'

import { differenceBy } from 'lodash'

import api from '../services/adminApiService'



const SelectsContext = React.createContext(null)

const SelectsProvider = props => {

  const [sites, setSites] = useState([])
  const [trainings, setTrainings] = useState([])
  const [participants, setParticipants] = useState([])
  const [trainers, setTrainers] = useState([])
  const [materials, setMaterials] = useState([])
  const [signatures, setSignatures] = useState([])
  const [categories, setCategories] = useState([])
  const [emails, setEmails] = useState([])
  const [partners, setPartners] = useState([])
  const [globalSearchOptions, setGlobalSearchOptions] = useState([])

  const notDeletedSites = sites.filter(s => !s.deleted)

  useEffect(() => {
    api.get('/selects').then(data => {
      setSites(data.sites)
      setTrainings(data.trainings)
      setParticipants(data.participants)
      setTrainers(data.trainers || [])
      setMaterials(data.materials)
      setSignatures(data.signatures)
      setCategories(data.categories)
      setEmails(data.emails)
      setPartners(data.partners)
      const globalSearchOptions = [
        { label: "Formations", options: data.trainings.map(t => ({ label: t.code, value: 'T' + t.id })) },
        { label: "Participants", options: data.participants.map(p => ({ label: `${p.lastname} ${p.firstname} - ${p.clientNumber}`, value: 'P' + p.id })) },
      ]
      if (data.trainers?.length) globalSearchOptions.push({ label: "Formateurs", options: data.trainers?.map(p => ({ label: p.lastname + ' ' + p.firstname, value: 'F' + p.id })) || [] })
      setGlobalSearchOptions(globalSearchOptions)
    }).catch(console.error)
  }, [])

  // Object with classrooms, indexed by id, with site filled
  const classrooms = {}
  for (const site of sites) {
    for (const classroom of site.classrooms) {
      classroom.site = site
      classrooms[classroom.id] = classroom
    }
  }
  /**
   * Convenient methods
   */
  const getTrainersWithout = (existing) => differenceBy(trainers, existing, 'id')
  const getSitesWithout = (existing) => differenceBy(notDeletedSites, existing, 'id')
  const getClassroom = id => classrooms[id]
  const getSiteClassroomsWithout = (siteId, classrooms) => differenceBy(notDeletedSites.find(s => s.id === siteId)?.classrooms, classrooms, 'id')
  const getEmailByCode = (code) => emails.find(e => e.code === code)
  const getEmailById = (id) => emails.find(e => e.id === id)


  const exposedValue = {
    sites: notDeletedSites,
    getSitesWithout,
    trainers,
    getTrainersWithout,
    materials,
    getClassroom,
    getSiteClassroomsWithout,
    signatures,
    categories,
    emails,
    getEmailByCode,
    getEmailById,
    partners,
    trainings,
    participants,
    globalSearchOptions
  }

  return (
    <SelectsContext.Provider value={exposedValue}>
      {props.children}
    </SelectsContext.Provider>
  )

}

export { SelectsContext, SelectsProvider }
export default SelectsContext