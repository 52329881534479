import React, { useState } from 'react'

import { Link, useLocation } from 'react-router-dom'

import api from 'services/userApiService'
import Icon from 'components/Icon'
import ModalLayout from 'components/ModalLayout/ModalLayout'
import Error500 from './Error500'

import { Button, Form, Input, Result } from 'antd'



const RecoverAccount = () => {

  const [isDone, setIsDone] = useState(false)

  const code = new URLSearchParams(useLocation().search).get("code")

  const send = (values) => {
    api.post('/auth/reset-password', { code, password: values.password })
      .then(() => { setIsDone(true) })
      .catch(console.error)
  }

  if (!code) {
    return <Error500 />
  }

  return (
    <ModalLayout title={isDone || <>Création d'un compte</>}>

      {isDone

        ? <Result
          title="C'est fait !"
          subTitle={<>
            <p>Maintenant, il est temps d'aller sur le portail et de compléter votre profil.</p>
            <p>Vous pouvez vous connecter avec l'adresse e-mail sur laquelle vous avez reçu l'invitation, et le mot de passe que vous venez de définir.</p>
          </>}
          status="success"
          style={{ paddingTop: 20 }}
          icon={<Icon icon="check-circle" />}
          extra={<Link to="/portal"><Button type="primary" icon={<Icon icon="lock" />}>Aller à l'écran de connexion</Button></Link>} />

        : <>
          <p style={{ textAlign: 'center' }}>Merci de créer votre compte sur notre plateforme <Icon icon="smile-beam" /><br />Ceci vous permettra de suivre au mieux vos inscriptions et vos formations !</p>
          <p style={{ textAlign: 'center' }}>Nous allons d'abord sécuriser votre compte.<br />Merci de choisir un mot de passe :</p>

          <Form onFinish={send} style={{ marginTop: 24 }}>
            <Form.Item name="password" rules={[
              { required: true, message: 'Veuillez entrer votre mot de passe' },
              { min: 8, message: 'Le mot de passe doit faire au minimum 8 carractères.' }
            ]}>
              <Input.Password prefix={<Icon icon="lock" />} placeholder="Mot de passe" />
            </Form.Item>
            <Form.Item name="confirm-password" dependencies={['password']} rules={[
              { required: true, message: 'Veuillez confirmer votre mot de passe' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) return Promise.resolve()
                  return Promise.reject(new Error('Les deux mots de passe ne correspondent pas.'));
                }
              })
            ]}>
              <Input.Password prefix={<Icon icon="lock" />} placeholder="Confirmez le mot de passe" />
            </Form.Item>
            <Form.Item style={{ textAlign: 'center' }}>
              <Button type="primary" htmlType="submit" icon={<Icon icon="save" />}>Enregistrer</Button>
            </Form.Item>
          </Form>
        </>}

    </ModalLayout>
  )

}

export default RecoverAccount