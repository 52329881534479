import React from 'react'

import { orderBy } from 'lodash'

import Icon from 'components/Icon'
import { isoDateToDateTime } from 'misc/utils'
import FileButton from 'components/FileButton'

import { Descriptions, List, Space } from 'antd'



const TrainingView = ({ trainerOnTraining, refresh }) => {

  // Prepare files list
  const orderedActions = orderBy(trainerOnTraining.actions, ['date'], ['desc'])
  const files = [
    { name: 'Conventions', files: orderedActions.filter(a => a.type === 'covenant-generated'), type: 'covenant-generated' }
  ]

  const getActions = (f) => {
    switch (f.type) {
      case 'covenant-generated': return [<Space>
        <FileButton.Edit overwriteDoc={true} trainerOnTraining={trainerOnTraining} refresh={refresh} type="covenant" />
        <FileButton.Download trainerOnTraining={trainerOnTraining} refresh={refresh} type="covenant" />
      </Space>]
      default: return []
    }
  }

  return <>
    <Descriptions bordered size="small" column={1} className="registration-descriptions">
      <Descriptions.Item label={<><Icon icon="plus" /> Complément taux horaire</>}>{trainerOnTraining.hourlyRateClauses}</Descriptions.Item>
      <Descriptions.Item label={<><Icon icon="handshake" /> Clauses particulières</>}>
        <div dangerouslySetInnerHTML={{ __html: trainerOnTraining.covenantClauses?.replace(/(?:\r\n|\r|\n)/g, "<br />") }} />
      </Descriptions.Item>
      <Descriptions.Item label={<><Icon icon="copy" /> Documents</>}>
        {files.length ? <List dataSource={files} size="small" renderItem={file => (
          <List.Item actions={getActions(file)}>
            <List.Item.Meta
              title={file.name}
              description={<Space direction="vertical">{file.files.map(a => <div key={a.id} className="file-item">
                <Icon icon={a.isDone ? "check" : "hourglass"} /> {isoDateToDateTime(a.date)} <FileButton.Open file={JSON.parse(a.data)?.pdfFile} />
              </div>)}</Space>}
            />
          </List.Item>
        )} /> : "Aucun document jusqu'à présent..."}
      </Descriptions.Item>
    </Descriptions>
  </>
}

export default TrainingView