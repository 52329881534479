import React, { useContext, useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { pick } from 'lodash'

import api from 'services/adminApiService'
import { callGraph } from 'services/graphService'

import Icon from 'components/Icon'
import { TrainingVisibility } from 'components/Tags'
import Info from './Info'
import Material from './Material'
import Planning from './Planning'
import WaitingList from './WaitingList'
import Dashboard from './Dashboard'
import RecentsContext from 'contexts/RecentsContext'
import Badges from 'components/Badges'
import Registrations from './Registrations'
import TrainersCard from './TrainersCard'

import { Badge, Button, Dropdown, Menu, PageHeader, Spin, Tabs, message, Modal, Tooltip, Typography, Alert } from 'antd'



const Training = () => {

  // Training states (training data, loading the training, saving the site training, form)
  const [training, setTraining] = useState(null)
  const [loading, setLoading] = useState(false)

  // Current site, if null it's a new one
  // @ts-ignore
  const { id, tab = 'dashboard' } = useParams()

  const history = useHistory()

  const { addRecent } = useContext(RecentsContext)

  const loadTraining = () => {
    if (id) api.trainings.getById(id, { loader: setLoading }).then(setTraining).catch(console.error)
  }

  useEffect(loadTraining, [id])

  useEffect(() => {
    if (training && training.id) addRecent(training, 'training')
  }, [training]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleActionsClick = ({ key }) => {
    switch (key) {
      case 'register-code':
        api.get(`/trainings/${id}/register-code`, { loader: setLoading }).then((data) => {
          Modal.info({
            title: "Code d'inscription",
            content: <>
              <Alert type='info' message="Le lien est valable 7 jours." showIcon style={{ marginTop: 15, marginBottom: 15 }} />
              <Typography.Paragraph copyable>{data}</Typography.Paragraph>
            </>
          })
        }).catch(console.error)
        break
      case 'rentability':
        callGraph({ endpoint: `/drive/special/approot:/Formations/${id}/Rentabilité.xlsx` }).then((data) => {
          window.open(data.webUrl, '_blank')
        }).catch(console.error)
        break
      case 'open-folder':
        callGraph({ endpoint: `/drive/special/approot:/Formations/${id}` }).then((data) => {
          window.open(data.webUrl, '_blank');
        }).catch(console.error)
        break
      case 'copy':
        history.push('/admin/training')
        const copy = pick(training, ['code', 'name', 'description', 'content', 'duration', 'hours', 'minParticipants', 'maxParticipants', 'isCFAccepted', 'CFHours', 'isCEP', 'isPQK', 'PQKPoints', 'isCertifying', 'conditions', 'requirements', 'deposit', 'price', 'reducedPrice', 'reducedPriceConditions', 'categories', 'materials'])
        copy.isNew = true
        setTraining(copy)
        break;
      case 'new-session':
        history.push('/admin/training')
        const session = pick(training, ['code', 'name', 'invoicingTrainingCode', 'description', 'content', 'duration', 'hours', 'minParticipants', 'maxParticipants', 'isCFAccepted', 'CFHours', 'isCEP', 'isPQK', 'PQKPoints', 'isCertifying', 'conditions', 'requirements', 'deposit', 'price', 'reducedPrice', 'reducedPriceConditions', 'categories', 'materials', 'parentId'])
        session.isNew = false
        session.parentId = training.parentId || training.id
        session.parent = training
        setTraining(session)
        break;
      case 'delete':
        Modal.confirm({
          title: "Êtes-vous sûr ?",
          content: <>Ceci supprimer la formation (session) ainsi que tous ces fichiers associés<br /><strong>Cette opération est irréversible.</strong></>,
          okText: "Supprimer",
          okButtonProps: { icon: <Icon icon="trash" />, danger: true },
          onOk: () => {
            api.trainings.delete(id, { loader: setLoading }).then(() => {
              message.success("Formation supprimée.")
              history.push('/admin/trainings/')
            }).catch(console.error)
          }
        })
        break
      default:
        break
    }
  }

  const onTabClick = key => {
    if (key === 'dashboard') loadTraining()
    history.push(`/admin/training/${id}/${key}`)
  }

  // New training view
  if (!id) {
    return <>
      <PageHeader title={<><Icon icon="graduation-cap" fixedWidth /> Nouvelle {training?.parent ? "session de " + training.parent.code : "formation"}</>} />
      <Info training={training} setTraining={setTraining} refreshTraining={loadTraining} />
    </>
  }

  // Training loading
  if (!training || !training.registrations) {
    return <div><Spin size="large" /></div>
  }

  // Existing training view
  return (
    <>
      <PageHeader
        // @ts-ignore
        title={<><Icon icon="graduation-cap" fixedWidth /> Formation : {training.code}</>}
        tags={[<TrainingVisibility key="visibility" training={training} />]}
        extra={<>
          <Button icon={<Icon icon="sync" spin={loading} />} onClick={loadTraining}></Button>
          <Tooltip title="Envoyer un e-mail"><Button icon={<Icon icon="paper-plane" />} onClick={() => history.push(`/admin/send-mail?trainingId=${training.id}`)} /></Tooltip>
          <Dropdown overlay={
            <Menu onClick={handleActionsClick}>
              <Menu.Item key="register-code" icon={<Icon icon="user-plus" />}>Générer un code d'inscription</Menu.Item>
              <Menu.Divider />
              <Menu.Item key="rentability" icon={<Icon icon="file-excel" />}>Rentabilité</Menu.Item>
              <Menu.Item key="open-folder" icon={<Icon icon="folder-open" />}>Ouvrir le dossier OneDrive</Menu.Item>
              <Menu.Divider />
              <Menu.Item key="new-session" icon={<Icon icon="copy" />}>Nouvelle session</Menu.Item>
              <Menu.Item key="copy" icon={<Icon icon="copy" />}>Copier la formation</Menu.Item>
              <Menu.Divider />
              <Menu.Item key="delete" icon={<Icon icon="trash" />} danger>Supprimer</Menu.Item>
            </Menu>
          } arrow>
            <Button icon={<Icon icon="ellipsis-v" />}></Button>
          </Dropdown>
        </>}
      />

      <Spin delay={500} spinning={loading}>

        <Tabs defaultActiveKey="informations" activeKey={tab} onTabClick={onTabClick} destroyInactiveTabPane={true}>

          <Tabs.TabPane tab={<><Icon icon="tachometer-alt" />Suivi</>} key="dashboard">
            <Dashboard training={training} setTraining={setTraining} refreshTraining={loadTraining} />
          </Tabs.TabPane>

          <Tabs.TabPane tab={<><Icon icon="info-circle" />Informations</>} key="info">
            <Info training={training} setTraining={setTraining} refreshTraining={loadTraining} />
          </Tabs.TabPane>

          <Tabs.TabPane tab={<><Icon icon="calendar-alt" />Planning <Badge count={training.events?.length || 0} style={{ backgroundColor: "#1890ff" }} showZero /></>} key="planning">
            <Planning training={training} refreshTraining={loadTraining} />
          </Tabs.TabPane>

          <Tabs.TabPane tab={<><Icon icon="chalkboard-teacher" />Formateurs <Badge count={training.trainers?.length || 0} style={{ backgroundColor: "#1890ff" }} showZero /></>} key="trainers">
            <TrainersCard training={training} refreshTraining={loadTraining} full={true} />
          </Tabs.TabPane>

          <Tabs.TabPane tab={<><Icon icon="tools" />Matériel <Badges.Materials materials={training.materials} /></>} key="material">
            <Material training={training} setTraining={setTraining} />
          </Tabs.TabPane>

          <Tabs.TabPane tab={<><Icon icon="users" />Participants <Badges.Registrations training={training} /></>} key="participants">
            <Registrations training={training} refreshTraining={loadTraining} />
            <p>Un champ note permet d'ajouter des notes à une inscription, ce qui permet de garder des traces de la raison d'une réduction du prix par exemple.</p>
          </Tabs.TabPane>

          <Tabs.TabPane tab={<><Icon icon="user-clock" />Liste d'attente <Badges.WaitingList registrations={training.registrations} /></>} key="waiting">
            <WaitingList training={training} refreshTraining={loadTraining} />
          </Tabs.TabPane>

          <Tabs.TabPane tab={<><Icon icon="chart-pie" />Satisfaction <Badge count={"5/12"} style={{ backgroundColor: "#fa8c16" }} /></>} key="satisfaction">
            <p>Le badge compteur de particition change de couleur en fonction du taux de participation.</p>
            <ul>
              <li>Rouge : moins de 20%</li>
              <li>Orange : 20% - 60%</li>
              <li>Vert clair : 60% - 80%</li>
              <li>Vert fonçé : 80% +</li>
            </ul>
            <p>Un formulaire de satisfaction doit être fait dans Moodle. Celui-ci doit répondre à un nom bien précis pour être trouvé automatiquement par l'application. Celle-ci va vérifier régulièrement que les participants ont bien répondu au formulaire. Si c'est le cas, OK. Sinon, un rappel est envoyé à intervalle régulier par mail.</p>
            <p>Un formulaire formateur est également disponible, et invisible pour les participants. Le même processus est mis en place pour les formateurs.</p>
            <p>Après 3 mois, l'e-mail n'est plus envoyé car le participant perd l'accès au cours dans FLink.</p>
            <p>Cet écran permet de voir la liste des participants et des formateurs, ainsi que les informations de participation (participé ? dit l'avoir fait ? date)</p>
            <p>Les résultats du formulaire sont visibles dans l'application, et consultables dans la timeline du formateur et du participant.</p>
          </Tabs.TabPane>

        </Tabs>

      </Spin>

    </>
  )

}

export default Training


