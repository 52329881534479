import React, { useEffect, useState } from 'react'

import Icon from 'components/Icon'
import TableLink from 'components/TableLink'
import TableButton from 'components/TableButton'
import api from 'services/adminApiService'
import { nfEur } from 'misc/utils'

import { Card, Descriptions, message, PageHeader, Table } from 'antd'

const { Column } = Table

const nf = new Intl.NumberFormat('fr-BE', { maximumFractionDigits: 2 })



const TrainersPayments = () => {

  const [payments, setPayments] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const loadPayments = () => {
    api.get('/trainers/payments', { loader: setIsLoading }).then(setPayments).catch(console.error)
  }

  useEffect(loadPayments, [])

  const markPaying = payment => {
    api.put(`/trainings/${payment.trainingId}/trainers`, payment.userId, { isPaying: true }).then(() => {
      message.success("Modification enregistrée.")
      loadPayments()
    }).catch(console.error)
  }

  return (
    <>
      <PageHeader title={<><Icon icon="hand-holding-usd" fixedWidth /> Paiement des formateurs</>} />
      <Card>
        <Table
          dataSource={payments}
          pagination={{ pageSize: 20 }}
          loading={isLoading}
          rowKey={p => `${p.trainingId}${p.userId}`}
          size="small"
          expandable={{
            expandRowByClick: true,
            expandIconColumnIndex: -1,
            expandedRowRender: p => <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label="Complément taux horaire">{p.hourlyRateClauses || '-'}</Descriptions.Item>
              <Descriptions.Item label="Clauses particulières">{p.covenantClauses || '-'}</Descriptions.Item>
            </Descriptions>
          }}
        >
          <Column title="Formateur" render={payment => <TableLink.User user={payment.user} />} />
          <Column title="Formation" render={payment => <TableLink.Training training={payment.training} />} />
          <Column title={<>Heures <Icon.Info tooltip="Les heures sont calculées hors heures de table." /></>} dataIndex="hours" render={value => value ? `${nf.format(value)} h` : '-'} />
          <Column title="Taux" dataIndex="hourlyRate" render={value => nfEur(value)} />
          <Column title="Clauses" dataIndex="covenantClauses" render={value => <Icon.Boolean boolean={!!value} noError />} />
          <Column title="Actions" width={100} className="actions-column" render={item => <>
            <TableButton icon="check" success onClick={() => markPaying(item)} tooltip="Paiement envoyé à la comptabilité." confirmMessage />
          </>} />
        </Table>
      </Card>
    </>
  )

}

export default TrainersPayments