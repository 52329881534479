import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import Icon from 'components/Icon'
import { isoDateToDateShort, nfEur } from 'misc/utils'
import api from 'services/adminApiService'
import TableLink from 'components/TableLink'
import TableButton from 'components/TableButton'

import { Button, Col, Dropdown, Form, Input, InputNumber, Menu, message, Modal, Row, Space, Table } from 'antd'
import TrainerOnTrainingView from './TrainerOnTrainingView'
import Badges from 'components/Badges'

const { Column } = Table

const nf = new Intl.NumberFormat('fr-BE', { maximumFractionDigits: 2 })



const TrainersOnTrainingsTable = ({ trainersOnTrainings, refresh, from = "user" }) => {

  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [currentTrainerOnTraining, setCurrentTrainerOnTraining] = useState(null)

  const [form] = Form.useForm()
  const encodedHourlyRate = Form.useWatch('hourlyRate', form)

  const history = useHistory()

  const edit = trainerOnTraining => {
    setCurrentTrainerOnTraining(trainerOnTraining)
    form.setFieldsValue(trainerOnTraining)
    setIsEditModalVisible(true)
  }

  const hideModal = () => {
    setCurrentTrainerOnTraining(null)
    setIsEditModalVisible(false)
  }

  const saveTraining = () => {
    form.validateFields().then((values) => {
      api.put(`/trainings/${currentTrainerOnTraining.trainingId}/trainers`, currentTrainerOnTraining.userId, values).then(() => {
        message.success("Modification effectuée.")
        hideModal()
        refresh()
      }).catch(console.error)
    }).catch(() => { })
  }

  const deleteTrainer = (trainerOnTraining) => {
    Modal.confirm({
      title: "Êtes-vous sûr ?",
      content: <>Êtes-vous sûr de vouloir supprimer la formation pour ce formateur ?{currentTrainerOnTraining?.hours
        ? <><br />Le formateur est programmé dans le planning de la formation.<br />Il sera supprimé de toutes les plages qui le concerne.</>
        : null}
      </>,
      onOk: () => {
        api.delete(`/trainings/${trainerOnTraining.trainingId}/trainers`, trainerOnTraining.userId).then(() => {
          message.success("La formation a été supprimée pour ce formateur.")
          hideModal()
          refresh()
        }).catch(console.error)
      }
    })
  }

  const sendMail = trainerOnTraining => {
    // TODO
    history.push('/admin/send-mail?training=' + JSON.stringify({
      training: {
        id: trainerOnTraining.trainingId,
        code: trainerOnTraining.training.code
      },
      user: {
        id: trainerOnTraining.userId,
        name: `${trainerOnTraining.user.firstname} ${trainerOnTraining.user.lastname}`
      }
    }))
  }

  const payTrainer = (trainerOnTraining, isPaying) => {
    api.put(`/trainings/${trainerOnTraining.trainingId}/trainers`, trainerOnTraining.userId, { isPaying }).then(() => {
      message.success("Modification effectuée.")
      hideModal()
      refresh()
    }).catch(console.error)
  }

  return (
    <>
      <Table
        dataSource={trainersOnTrainings}
        rowKey={item => item.trainingId + '-' + item.userId}
        size="middle"
        pagination={false}
        rowClassName="row-clickable"
        expandable={{
          expandRowByClick: true,
          expandIconColumnIndex: -1,
          expandedRowRender: t => <TrainerOnTrainingView trainerOnTraining={t} refresh={refresh} />
        }}
      >
        {from === "training" && <Column title="Formateur" render={item => <TableLink.User user={item.user} />} />}
        {from === "user" && <Column title="Formation" render={item => <TableLink.Training training={item.training} hideDate />} />}
        {from === "user" && <Column title="Date (début/fin)" render={item => item.training.start ? isoDateToDateShort(item.training.start) + ' - ' + isoDateToDateShort(item.training.end) : '-'} />}
        <Column title={<>Heures <Icon.Info tooltip="Les heures sont calculées hors heures de table." /></>} dataIndex="hours" render={value => value ? `${nf.format(value)} h` : '-'} />
        <Column title="Taux" render={item => nfEur(item.hourlyRate) + ' ' + (item.hourlyRateClauses || '')} />
        <Column title={<>Montant total <Icon.Info tooltip="Ce montant sera repris sur la convention. S'il n'est pas encodé, le montant calculé sera repris." style={{ marginLeft: 7 }} /></>} render={item => nfEur(item.totalRate) + ' ' + (item.hourlyRateClauses || '')} />
        <Column title="Clauses" dataIndex="covenantClauses" render={value => <Icon.Boolean boolean={!!value} noError />} />
        <Column title="Conventions" render={trainerOnTraining => <Badges.CovenantCount trainerOnTraining={trainerOnTraining} />} />
        <Column title="Paiement" dataIndex="isPaying" render={value => <Icon.Boolean boolean={!!value} />} />
        <Column title="Actions" width={100} className="actions-column" render={item => <Space>
          <TableButton icon="pencil" onClick={e => edit(item)} />
          <TableButton icon="paper-plane" tooltip="Envoyer un e-mail" onClick={() => sendMail(item)} />
          <div onClick={e => e.stopPropagation()}>
            <Dropdown arrow overlay={<Menu>
              {!item.isPaying && <Menu.Item key="payTrainer" icon={<Icon icon="check" fixedWidth />} onClick={() => payTrainer(item, true)}>Marquer comme payé</Menu.Item>}
              {item.isPaying && <Menu.Item key="unpayTrainer" icon={<Icon icon="times" fixedWidth />} onClick={() => payTrainer(item, false)}>Marquer comme non payé</Menu.Item>}
              <Menu.Divider />
              <Menu.Item key="deleteTrainer" danger icon={<Icon icon="trash" fixedWidth />} onClick={() => deleteTrainer(item)}>Supprimer de la formation</Menu.Item>
            </Menu>}>
              <Button shape="circle" icon={<Icon icon="ellipsis-v" />} size="small" />
            </Dropdown>
          </div>
        </Space>} />
      </Table>
      <Modal
        title={<><Icon icon={"chalkboard-teacher"} /> Participation à {currentTrainerOnTraining?.training.code}</>}
        open={isEditModalVisible}
        closable
        maskClosable={false}
        destroyOnClose
        onCancel={() => setIsEditModalVisible(false)}
        footer={<Row justify="end">
          <Col>
            <Button key="cancel" onClick={() => setIsEditModalVisible(false)}>Annuler</Button>
            <Button key="ok" type="primary" icon={<Icon icon="save" />} onClick={saveTraining}>Enregistrer</Button>
          </Col>
        </Row>}
      >
        <Form form={form} layout="vertical" preserve={false}>
          <Form.Item label="Taux horaire" name="hourlyRate" rules={[{ required: true, message: 'Veuillez encoder le taux horaire.' }]}>
            <InputNumber min={0} precision={2} style={{ width: 100 }} className="input-number-euro" />
          </Form.Item>
          <div style={{ marginBottom: 20, color: '#006f91' }}>Montant calculé : {(currentTrainerOnTraining?.hours * encodedHourlyRate) || 0} €</div>
          <Form.Item label={<>Montant total<Icon.Info tooltip="Ce montant sera repris sur la convention. S'il n'est pas encodé, le montant calculé sera repris." style={{ marginLeft: 7 }} /></>} name="totalRate">
            <InputNumber min={0} precision={2} style={{ width: 100 }} className="input-number-euro" />
          </Form.Item>
          <Form.Item label="Complément taux horaire (TTC, HT...)" name="hourlyRateClauses">
            <Input />
          </Form.Item>
          <Form.Item label="Clauses particulières" name="covenantClauses">
            <Input.TextArea placeholder="Clauses particulières qui seront reprises dans la convention" autoSize={{ minRows: 3 }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )

}

export default TrainersOnTrainingsTable


