import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import './Trainings.less'

import Icon from 'components/Icon'
import api from 'services/userApiService'
import { isoDateToDateShort } from 'misc/utils'

import { Card, Col, Empty, Row, Spin } from 'antd'
import { TrainingStatus } from 'components/Tags'



const Trainings = () => {

  const history = useHistory()

  const [trainings, setTrainings] = useState([])
  const [isLoading, setIsLoading] = useState(null)

  useEffect(() => {
    api.get('/trainer-trainings', { loader: setIsLoading }).then(setTrainings).catch(console.error)
  }, [])

  if (!trainings || isLoading) {
    return <Spin size="large" />
  }

  return (
    <>
      <h1><Icon icon="chalkboard-teacher" /> Mes formations</h1>
      <p className="sub-title-light">Voici toutes les formations dans lesquelles vous intervenez.</p>

      {!trainings.length && <Empty image={<Icon icon="inbox" size="4x" />} description={<h2>Vous n'avez pas encore de formation.</h2>} imageStyle={{ height: 65, color: '#006f91' }}></Empty>}

      <Row gutter={[16, 16]}>
        {trainings.map(training => <Col key={training.training.id}>
          <Card
            className="training-card"
            actions={[
              <div onClick={() => history.push(`/portal/trainings/${training.training.id}`)}><Icon icon="eye" /><br />Détails</div>
            ]}
          >
            <Card.Meta
              title={training.training.name}
              description={<>
                <p>
                  {training.training.code}<br />
                  {training.training.start ? `${isoDateToDateShort(training.training.start)} - ${isoDateToDateShort(training.training.end)}` : "Pas encore programmée."}<br />
                </p>
                <p>Statut : &nbsp;&nbsp;<TrainingStatus training={training.training} help /></p>
              </>}
            />
          </Card>
        </Col>)}
      </Row>
    </>
  )

}

export default Trainings