import React from 'react'

import { useMsal } from "@azure/msal-react"
import { loginRequest } from "misc/authConfig"

import { Button, message } from 'antd'
import Icon from 'components/Icon'

import ModalLayout from 'components/ModalLayout/ModalLayout'



const Login = () => {

  const { instance, inProgress } = useMsal()

  const login = () => instance.loginPopup(loginRequest).catch(e => {
    if (e?.errorMessage?.startsWith('AADSTS50105')) {
      message.warn("Vous n'avez pas les droits nécessaires pour accéder à cette application.")
    } else {
      message.error("Une erreur inconnue est survenue. Veuillez contacter l'administrateur si le problème persiste.")
    }
  })

  if (inProgress === "startup" || inProgress === "handleRedirect") {
    return (
      <div className="login-container"></div >
    )
  }

  return (
    <ModalLayout title="Bonjour !" style={{ textAlign: 'center' }}>
      <p>Pour vous connecter avec Azure, veuillez cliquer sur le bouton ci-dessous et une page de connexion de Microsoft s'ouvrira.</p>
      <Button loading={inProgress === 'login'} type="primary" icon={<Icon icon="fab microsoft" />} onClick={login} style={{ marginBottom: 20 }}>Se connecter avec Azure</Button>
    </ModalLayout>
  )

}

export default Login