import { PublicClientApplication, EventType, LogLevel } from "@azure/msal-browser"



const {
  REACT_APP_CI_ENVIRONMENT,
  REACT_APP_MSAL_CLIENT_ID,
  REACT_APP_MSAL_AUTHORITY,
  REACT_APP_MSAL_REDIRECT_URI,
  REACT_APP_MSAL_POST_LOGOUT_REDIRECT_URI,
  REACT_APP_MSAL_CACHE_LOCATION
} = process.env

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
const msalConfig = {
  auth: {
    clientId: REACT_APP_MSAL_CLIENT_ID,
    authority: REACT_APP_MSAL_AUTHORITY,
    redirectUri: REACT_APP_MSAL_REDIRECT_URI,
    postLogoutRedirectUri: REACT_APP_MSAL_POST_LOGOUT_REDIRECT_URI
  },
  cache: {
    cacheLocation: REACT_APP_MSAL_CACHE_LOCATION
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message) => {
        if (REACT_APP_CI_ENVIRONMENT === 'dev') {
          switch (level) {
            case LogLevel.Error:
              console.error(message)
              return
            case LogLevel.Info:
              console.info(message)
              return
            case LogLevel.Verbose:
              console.debug(message)
              return
            case LogLevel.Warning:
              console.warn(message)
              return
            default:
              console.log(message)
          }
        }
      }
    }
  }
}

export const apiScopes = [`api://${REACT_APP_MSAL_CLIENT_ID}/API`]

export const graphScopes = ["User.Read", "Files.ReadWrite.AppFolder", "Files.ReadWrite.All"]

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: apiScopes.concat(graphScopes)
}



/**
 * Msal instance configuration
 */
export let msalInstance = new PublicClientApplication(msalConfig)
const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }
})