import React, {useEffect, useState} from 'react'

import api from 'services/adminApiService'

import {Button, Card, PageHeader, Input, Divider, Table, Popconfirm, message} from 'antd'
import Icon from 'components/Icon'
import {downloadFile, isoDateToDateTime} from "../misc/utils";

const {Column} = Table


const ExportsAdfinity = () => {

  const [exports, setExports] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const [invoiceLimit, setInvoiceLimit] = useState("")

  const loadExports = () => {
    api.get('/reports/adfinity/exports-list', { loader: setIsLoading }).then(setExports).catch(console.error)
  }

  useEffect(loadExports, [])

  const exportAdfinity = () => {
    api.get(`/reports/adfinity/export?query=${JSON.stringify({ invoiceLimit })}`, { loader: setIsExporting, rawResponse: true })
      .then(data => downloadFile(data.body, data.response))
      .then(loadExports)
  }

  const deleteLastAdfinityExport = () => {
    api.call(`/reports/adfinity/export`, { method: 'DELETE', loader: setIsExporting })
      .then(() => {
        message.success('Dernier export Adfinity supprimé.')
        loadExports()
      })
  }

  const onRowHandlers = (record, rowIndex) => ({
    onClick: e => api.get(`/reports/adfinity/exports/${record.exportedCode}`, { loader: setIsLoading, rawResponse: true }).then(data => downloadFile(data.body, data.response))
  })

  return (
    <>
      <PageHeader title={<><Icon icon="file-export" fixedWidth /> Exports Adfinity</>} />
      <Card>
        Exporter jusqu'à la facture n° (comprise, facultatif) :
        <Input onChange={(e) => setInvoiceLimit(e.target.value)} allowClear style={{ marginLeft: 10, marginRight: 15, width: 130 }} />
        Si vous ne spécifiez pas de limite, toutes les factures non exportées seront exportées. La facture limite sera comprise dans l'export.
        <Popconfirm title="Êtes-vous sûr ?" onConfirm={exportAdfinity}>
          <Button type="primary" icon={<Icon icon="file-export" />} loading={isExporting} style={{marginLeft: 20}}>Exporter</Button>
        </Popconfirm>
        <Divider />
        <Table
          dataSource={exports}
          loading={isLoading}
          rowKey="exportedCode"
          rowClassName="row-clickable"
          size="small"
          onRow={onRowHandlers}
        >
          <Column title="N° export" dataIndex="exportedCode" />
          <Column title="Date d'export" dataIndex="exportedDate" render={isoDateToDateTime} />
          <Column title="Nb. factures" dataIndex="invoicesCount" />
          <Column title="Nb. entreprises" dataIndex="isBillingSpecificCount" />
          <Column title="Nb. participants" render={e => e.invoicesCount - e.isBillingSpecificCount} />
        </Table>
        <Popconfirm title="Êtes-vous sûr ?" onConfirm={deleteLastAdfinityExport}>
          <Button danger icon={<Icon icon="trash" />} loading={isExporting}>Supprimer le dernier export</Button>
        </Popconfirm>
      </Card>
    </>
  )

}

export default ExportsAdfinity