import {DateTime} from 'luxon'
import {isNil} from "lodash"
import moment from 'moment'
import {message} from 'antd'


export const sorters = {
  string: (key) => (a, b) => isNil(a[key]) ? -1 : isNil(b[key]) ? 1 : a[key].localeCompare(b[key])
}

export const filters = {
  string: (keys, value) => s => {
    for (let key of keys) {
      if (s[key]?.toLowerCase().includes(value.toLowerCase())) return true
    }
    return false
  }
}

export const fullname = user => user ? user.lastname + ' ' + user.firstname : '-'

export const address = (o) => {
  if (!o.number && !o.street && !o.zip && !o.city && !o.country) return '-'
  return `${o.number || ""} ${o.street || ""}, ${o.zip || ""} ${o.city || ""} ${o.country || ""}`
}

export const eventDateTime = (start, end) => {
  return DateTime.fromISO(start).toFormat('dd/MM/yy HH:mm') + '-' + DateTime.fromISO(end).toFormat('HH:mm')
}

export const trainingHours = events => {
  let hoursTotal = 0
  let CFHoursTotal = 0
  for (let event of events) {
    const duration = moment.duration(moment(event.end).diff(moment(event.start))).asHours() - (event.tableHours || 0)
    hoursTotal += duration
    CFHoursTotal += Math.min(duration, 7)
  }
  return {hoursTotal, CFHoursTotal}
}

export const isoDateToDateShort = (date) => {
  return date ? DateTime.fromISO(date).toFormat('dd/MM/yy') : ""
}

export const isoDateToDateTime = (date) => {
  return date ? DateTime.fromISO(date).toFormat('dd/MM/yy HH:mm') : ""
}

export const jsDateToHours = (date) => {
  return date ? DateTime.fromJSDate(date).toLocaleString(DateTime.TIME_24_SIMPLE) : ""
}

const _nfEur = new Intl.NumberFormat('fr-BE', {style: 'currency', currency: 'EUR'})
export const nfEur = amount => amount === null || amount === undefined ? '-' : _nfEur.format(amount)

const _nfPercent = new Intl.NumberFormat('fr-BE', {style: 'percent'})
export const nfPercent = number => number === null || number === undefined ? '-' : _nfPercent.format(number)


export const isUserProfileOK = (user) => {
  let ok = user.firstname && user.lastname && user.gender && user.birthDate && user.birthPlace && user.phone && user.email && user.street && user.number && user.zip && user.city && user.country
  if (user.isParticipant) {
    ok = ok && user.diplomas && user.diplomasInstitutions
  }
  if (user.isTrainer) {
    ok = ok && user.iban && (user.billing === "company" || user.billing === "personal")
  }
  if (user.isTrainer && user.billing === "company") {
    ok = ok && user.billingCompany && user.billingCompanyNumber && user.billingContact && user.billingEmail && user.billingPhone && user.billingStreet && user.billingNumber && user.billingZip && user.billingCity && user.billingCountry
  }
  if (user.isTrainer && user.billing === "personal") {
    ok = ok && user.trainerNISS
  }
  return ok
}

export const formatPaymentCom = pc => pc ? `+++${pc.substring(0, 3)}/${pc.substring(3, 7)}/${pc.substring(7, 12)}+++` : '-'

// Removes tab from pathname
export const cleanPathname = path => {
  let match = path.match(/^(\/(admin|compta)\/(training\/.+|user\/.+|trainings|refunds|sodexo-invoicing|invoices|registrations|analytic-codes))\//)
  if (match) return match[1]
  return path
}

// Get app path (compta or admin)
export const getAppPath = path => {
  let match = path.match(/^(\/(admin|compta)).*/)
  if (match) return match[1]
  return path
}

// Get colored balance
export const getColoredBalance = registration => {
  let color
  if (registration.isClosed && registration.balance === 0) color = 'unset'
  else {
    if (registration.isDepositPaid || registration.balance === 0) {
      if (registration.price + registration.balance >= registration.price) color = '#52c41a' // Green
      else color = '#fa8c16' // Orange
    } else color = '#f5222d' // Red
  }
  return <span style={{color}}>{nfEur(registration.balance)}</span>
}

export const filterOnSelectLabel = (inputValue, option) => option?.label?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1

export const copyToClipboard = (text, successMessage) => {
  navigator.clipboard.writeText(text)
  message.success(successMessage)
}

export const formatGender = gender => {
  switch (gender) {
    case 'm':
      return 'Homme'
    case 'f':
      return 'Femme'
    case 'o':
      return 'Autre'
    default:
      return ''
  }
}

export const formatNationality = nationality => {
  switch (nationality) {
    case 'belgian':
      return 'Belge'
    case 'ue':
      return 'Union Européenne'
    case 'out-ue':
      return 'Hors UE'
    default:
      return ''
  }
}

export const formatExperience = experience => {
  switch (experience) {
    case '<5':
      return '<5 ans'
    case '5-9':
      return '5-9 ans'
    case '10-14':
      return '10-14 ans'
    case '15-19':
      return '15-19 ans'
    case '>20':
      return '20 ans et plus'
    default:
      return ''
  }
}

const workStatuses = {
  independant: "Indépendant",
  employe: "Employé",
  cadre: "Cadre",
  ouvrier: "Ouvrier",
  interimaire: "Intérimaire",
  "conjoint-aidant": "Conjoint aidant",
}
export const formatWorkStatus = workStatus => {
  return JSON.parse(workStatus)?.map(ws => workStatuses[ws]).join(', ') || ''
}

const studyLevels = {
  univ: "Universitaire",
  "sup-non-univ": "Supérieur non universitaire",
  "sec-sup": "Secondaire supérieur",
  "exp-pro": "Expérience professionnelle"
}
export const formatStudyLevel = studyLevel => {
  return JSON.parse(studyLevel)?.map(sl => studyLevels[sl]).join(', ') || ''
}

export const isUserGestion = (msalInstance) => msalInstance?.getActiveAccount()?.idTokenClaims?.roles?.includes('cfc.gestion') || false
export const isUserCompta = (msalInstance) => msalInstance?.getActiveAccount()?.idTokenClaims?.roles?.includes('cfc.compta') || false

export const downloadFile = (body, response) => {
  const filename = response.headers.get('content-disposition').match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1] || 'file'
  const a = document.createElement('a')
  a.href = window.URL.createObjectURL(body)
  a.download = filename
  document.body.appendChild(a)
  a.click()
  a.remove()
}