import React, { useContext, useEffect, useMemo, useState } from 'react'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import RichTextEditor from 'components/RichTextEditor'
import SelectsContext from 'contexts/SelectsContext'
import { deserialize, EMPTY_VALUE, serialize } from 'misc/slateSerialize'
import DraggableModal from 'components/DraggableModal'

import { Form, Select } from 'antd'



const types = {
  'attendance-certificate': { email: 'attendance-certificate', doc: 'attendance-certificate' },
  'invoice': { email: 'invoice', doc: 'invoice' },
  'accept': { email: 'accept-registration', action: 'accept' },
  'reject': { email: 'reject-registration', action: 'reject' }
}



const SendFileMailModal = ({ registration, isVisible, onOK, onCancel, overwriteDoc = false, type }) => {

  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    if (isVisible) form.resetFields()
  }, [isVisible, form])

  const { getEmailByCode, signatures } = useContext(SelectsContext)
  const emailBody = useMemo(() => deserialize(getEmailByCode(types[type]?.email)?.html || EMPTY_VALUE), [getEmailByCode, type])
  const emailSignatureId = useMemo(() => getEmailByCode(types[type]?.email)?.signatureId, [getEmailByCode, type])

  const send = values => {
    values.emailBody = serialize(values.emailBody)
    const url = `/registrations/${registration.id}/` + (types[type]?.doc ? `file` : types[type]?.action)
    const data = types[type]?.doc ? { ...values, name: types[type]?.doc, action: 'pdf', overwriteDoc } : { ...values }
    api.post(url, data, { loader: setIsLoading })
      .then(onOK)
      .catch(console.error)
  }

  return (
    <DraggableModal
      title={<><Icon icon="envelope" fixedWidth /> Envoyer un email/document</>}
      open={isVisible}
      maskClosable={false}
      onCancel={onCancel}
      onOk={form.submit}
      okText="Envoyer"
      okButtonProps={{ icon: <Icon icon="paper-plane" />, loading: isLoading }}
      width={800}
    >

      <Form form={form} layout="vertical" onFinish={send}>
        <Form.Item name="emailBody" rules={[{ required: true }]} initialValue={emailBody}>
          <RichTextEditor />
        </Form.Item>
        <Form.Item name="emailSignatureId" label="Signature" rules={[{ required: true }]} initialValue={emailSignatureId}>
          <Select options={signatures?.map(item => ({ label: item.description, value: item.id }))} style={{ width: '100%' }} />
        </Form.Item>
      </Form>

    </DraggableModal >
  )

}

export default SendFileMailModal