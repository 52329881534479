import React, { useEffect, useState } from 'react'

import api from '../services/userApiService'



const UserAuthContext = React.createContext(null)

const UserAuthProvider = props => {

  const [auth, _setAuth] = useState({
    isAuthenticated: false,
    user: null,
    token: null,
    isLoading: false,
    isRecovering: false
  })

  const setAuth = newAuth => _setAuth(Object.assign({}, auth, newAuth))

  const login = (credentials) => {
    setAuth({ isLoading: true })
    return new Promise((resolve, reject) => {
      api.post('/auth/login', credentials)
        .then(data => {
          if (credentials.remember) {
            localStorage.setItem('token', data.token)
          } else {
            sessionStorage.setItem('token', data.token)
          }
          setAuth({ isAuthenticated: true, isLoading: false, token: data.token, user: data.user })
          resolve(data.user)
        })
        .catch(response => {
          setAuth({ isLoading: false })
          reject(response)
        })
    })
  }

  const logout = () => {
    setAuth({ isAuthenticated: false, token: null, user: null })
    localStorage.removeItem('token')
    sessionStorage.removeItem('token')
  }

  const recover = () => {
    // Check if token exists in localstorage
    let token = localStorage.getItem('token')
    // Check if token exists in sessionStorage
    if (!token) {
      token = sessionStorage.getItem('token')
    }
    // If no token, stop recovering
    if (!token) {
      return
    }
    // Get user from DB
    setAuth({ isLoading: true, isRecovering: true })
    api.get('/auth/user')
      .then(user => {
        setAuth({ isAuthenticated: true, isLoading: false, isRecovering: false, user })
      })
      .catch(response => {
        logout()
        setAuth({ isLoading: false, isRecovering: false })
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(recover, [])

  const exposedValue = {
    isAuthenticated: auth?.isAuthenticated,
    user: auth?.user,
    token: auth?.token,
    isLoading: auth?.isLoading,
    isRecovering: auth?.isRecovering,
    login,
    logout,
    setUser: user => setAuth({ user })
  }

  return (
    <UserAuthContext.Provider value={exposedValue}>
      {props.children}
    </UserAuthContext.Provider>
  )

}

export { UserAuthContext, UserAuthProvider }
export default UserAuthContext