import React, { useState } from 'react'

import api from 'services/adminApiService'
import { useMap } from 'react-use'
import { partial } from 'lodash'

import { Button, Card, PageHeader, DatePicker, Divider, Typography } from 'antd'
import Icon from 'components/Icon'
import {downloadFile} from "../misc/utils";



const Reports = () => {

  const [isLoading, { set }] = useMap({ clients: false, registrations: false, "cf-payments": false, 'invoices-billing-specific': false, 'trainings-calendar-quarters': false })
  const [range, setRange] = useState([])

  const download = (report) => {
    const params = { dates: [] }
    if (range?.[0]) params.dates[0] = range[0].toISOString()
    if (range?.[1]) params.dates[1] = range[1].toISOString()
    api.get(`/reports/${report}?query=${JSON.stringify(params)}`, { loader: partial(set, report), rawResponse: true }).then(data => downloadFile(data.body, data.response))
  }

  return (
    <>
      <PageHeader title={<><Icon icon="file-alt" fixedWidth /> Rapports</>} />
      <Card>
        Dates pour les rapports (facultatif) :
        <DatePicker.RangePicker allowClear allowEmpty={[true, true]} onChange={setRange} style={{ marginLeft: 10, marginRight: 15 }} />
        Si vous ne spécifiez pas de dates, toutes les données seront téléchargées.
        <Divider />
        <Button icon={<Icon icon="users" />} onClick={() => download('clients')} loading={isLoading.clients}>Participants</Button>
        <Typography.Text disabled italic style={{ marginLeft: 10 }}>Pas de filtre possible</Typography.Text>
        <br /><br />
        <Button icon={<Icon icon="file-signature" />} onClick={() => download('registrations')} loading={isLoading.registrations}>Inscriptions</Button>
        <Typography.Text disabled italic style={{ marginLeft: 10 }}>Filtrable selon la date d'inscription.</Typography.Text>
        <br /><br />
        <Button icon={<Icon icon="graduation-cap" />} onClick={() => download('trainings')} loading={isLoading.trainings}>Liste des formations</Button>
        <Typography.Text disabled italic style={{ marginLeft: 10 }}>Filtrable selon la date de début de la formation.</Typography.Text>
        <br /><br />
        <Button icon={<Icon icon="money-check-edit-alt" />} onClick={() => download('cf-payments')} loading={isLoading["cf-payments"]}>Paiements CF</Button>
        <Typography.Text disabled italic style={{ marginLeft: 10 }}>Filtrable selon la date de demande de paiement CF.</Typography.Text>
        <br /><br />
        <Button icon={<Icon icon="file-invoice-dollar" />} onClick={() => download('invoices-billing-specific')} loading={isLoading['invoices-billing-specific']}>Factures avec "facturé"</Button>
        <Typography.Text disabled italic style={{ marginLeft: 10 }}>Filtrable selon la date de facture.</Typography.Text>
        <br /><br />
        <Button icon={<Icon icon="calendar-day" />} onClick={() => download('trainings-calendar-quarters')} loading={isLoading['trainings-calendar-quarters']}>Répartition du temps de formation par trimestre</Button>
        <Typography.Text disabled italic style={{ marginLeft: 10 }}>Pas de filtre possible</Typography.Text>
      </Card>
    </>
  )

}

export default Reports