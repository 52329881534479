import React, { useContext, useState } from 'react'

import api from 'services/adminApiService'
import SelectsContext from 'contexts/SelectsContext'
import Icon from 'components/Icon'
import TableLink from 'components/TableLink'
import { filterOnSelectLabel } from 'misc/utils'
import TrainersOnTrainingsTable from 'components/TrainersOnTrainingsTable'
import DraggableModal from 'components/DraggableModal'

import { Button, Card, Form, Input, Row, Col, Table, Popconfirm, InputNumber, Select, message } from 'antd'

const { Column } = Table

const nf = new Intl.NumberFormat('fr-BE', { maximumFractionDigits: 2 })



const TrainersCard = ({ training, refreshTraining, style = {}, full = false }) => {

  // Modal
  const [modalStatus, setModalStatus] = useState({ visible: false, trainer: null })
  const [form] = Form.useForm()
  const encodedHourlyRate = Form.useWatch('hourlyRate', form)
  const hideModal = () => setModalStatus({ visible: false, trainer: null })

  // Get trainers for select
  const { trainers, getTrainersWithout } = useContext(SelectsContext)
  const trainersToAdd = getTrainersWithout(training?.trainers.map(t => t.user))

  const saveTrainer = () => {
    form.validateFields().then((values) => {
      if (modalStatus.trainer) {
        api.put(`/trainings/${training.id}/trainers`, modalStatus.trainer.userId, values).then(() => {
          message.success("Formateur modifié.")
          hideModal()
          refreshTraining()
        }).catch(console.error)
      } else {
        api.post(`/trainings/${training.id}/trainers`, values).then(() => {
          message.success("Formateur ajouté.")
          hideModal()
          refreshTraining()
        }).catch(console.error)
      }
    }).catch(() => { })
  }

  const deleteTrainer = () => {
    api.delete(`/trainings/${training.id}/trainers`, modalStatus.trainer.userId).then(() => {
      message.success("Formateur supprimé.")
      hideModal()
      refreshTraining()
    }).catch(console.error)
  }

  // On trainer click
  const onRowHandlers = (record, rowIndex) => ({
    onClick: e => {
      form.setFieldsValue(record)
      setModalStatus({ visible: true, trainer: record })
    }
  })

  return (
    <>
      <Card
        title={<><Icon icon="chalkboard-teacher" fixedWidth /> Formateurs</>}
        style={style}
        extra={<Button size="small" icon={<Icon icon="plus" />} onClick={() => setModalStatus({ visible: true, trainer: null })}>Ajouter</Button>}
      >
        {!full && <Table
          dataSource={training?.trainers}
          rowKey="userId"
          size="small"
          pagination={false}
          rowClassName="row-clickable"
          onRow={onRowHandlers}
        >
          <Column title="Nom" key="name" render={item => <TableLink.User user={item.user} />} />
          <Column title={<>Heures <Icon.Info tooltip="Les heures sont calculées hors heures de table." /></>} dataIndex="hours" render={value => value ? `${nf.format(value)} h` : '-'} />
        </Table>}
        {full && <TrainersOnTrainingsTable trainersOnTrainings={training?.trainers} refresh={refreshTraining} from="training" />}
      </Card>

      <DraggableModal
        title={<><Icon icon={"chalkboard-teacher"} /> Assigner un formateur</>}
        open={modalStatus.visible}
        closable
        maskClosable={false}
        destroyOnClose
        onCancel={hideModal}
        footer={<Row justify="space-between">
          <Col>
            <Popconfirm title={
              <>
                Êtes-vous sûr ?{modalStatus.trainer?.hours
                  ? <><br />Le formateur est programmé dans le planning.<br />Il sera supprimé de toutes les plages qui le concerne.</>
                  : null}
              </>
            } onConfirm={deleteTrainer}>
              <Button key="delete" danger hidden={!modalStatus.trainer}>Supprimer</Button>
            </Popconfirm>
          </Col>
          <Col>
            <Button key="cancel" onClick={hideModal}>Annuler</Button>
            {!modalStatus.trainer
              ? <Button key="ok" type="primary" icon={<Icon icon="plus" />} onClick={saveTrainer}>Ajouter</Button>
              : <Button key="ok" type="primary" icon={<Icon icon="save" />} onClick={saveTrainer}>Enregistrer</Button>}
          </Col>
        </Row>}
      >
        <Form form={form} layout="vertical" preserve={false}>
          <Form.Item label="Formateur" name="userId" rules={[{ required: true, message: 'Veuillez choisir un formateur.' }]}>
            <Select
              showSearch
              disabled={modalStatus.trainer}
              options={(modalStatus.trainer ? trainers : trainersToAdd).map(item => ({ label: `${item.firstname} ${item.lastname}`, value: item.id }))}
              style={{ width: '100%' }}
              filterOption={filterOnSelectLabel}
            />
          </Form.Item>
          <Form.Item label="Taux horaire" name="hourlyRate" rules={[{ required: true, message: 'Veuillez encoder le taux horaire.' }]}>
            <InputNumber min={0} precision={2} style={{ width: 100 }} className="input-number-euro" />
          </Form.Item>
          <div style={{ marginBottom: 20, color: '#006f91' }}>Montant calculé : {(modalStatus?.trainer?.hours * encodedHourlyRate) || 0} €</div>
          <Form.Item label={<>Montant total<Icon.Info tooltip="Ce montant sera repris sur la convention. S'il n'est pas encodé, le montant calculé sera repris." style={{ marginLeft: 7 }} /></>} name="totalRate">
            <InputNumber min={0} precision={2} style={{ width: 100 }} className="input-number-euro" />
          </Form.Item>
          <Form.Item label="Complément taux horaire (TTC, HT...)" name="hourlyRateClauses">
            <Input />
          </Form.Item>
          <Form.Item label="Clauses particulières" name="covenantClauses">
            <Input.TextArea placeholder="Clauses particulières qui seront reprises dans la convention" autoSize={{ minRows: 3 }} />
          </Form.Item>
        </Form>
      </DraggableModal>
    </>
  )

}

export default TrainersCard