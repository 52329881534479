import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { mapValues, forIn, values, keys } from 'lodash'

import Icon from 'components/Icon'
import { TrainingStates } from 'components/Tags'
import api from 'services/adminApiService'
import { isoDateToDateShort } from 'misc/utils'
import Badges from 'components/Badges'
import TableLink from 'components/TableLink'

import {Button, Card, Input, message, PageHeader, Space, Table, Tabs, Typography} from 'antd'
import {useMap} from "react-use";

const { Column } = Table
const { Text } = Typography



const TrainingsTable = ({ type }) => {

  const [trainings, setTrainings] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    defaultPageSize: 20,
    position: ['bottomRight', 'topRight'],
    total: 0,
    showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} éléments`,
    showSizeChanger: true
  })
  const [search, setSearch] = useState(null)

  const [anacadUpdates, {set : setAnacadUpdate, remove : removeAnacadUpdate, reset : resetAnacadUpdates}] = useMap()
  const [moduleUpdates, {set : setModuleUpdate, remove : removeModuleUpdate, reset : resetModuleUpdates}] = useMap()

  const [saving, setSaving] = useState(false)

  const loadTrainings = (pageSize = pagination.pageSize, current = pagination.current) => {
    api.trainings.get({ loader: setLoading, params: { results: pageSize, page: current, search, type } }).then(result => {
      setTrainings(result.data)
      setPagination({ ...pagination, pageSize, current, total: result.totalCount })
    }).catch(console.error)
  }

  const saveCodesUpdates = () => {
    const updates = mapValues(anacadUpdates, (v, k) => ({id: k, invoicingYearCode: v}))
    forIn(moduleUpdates,(v, k) => updates[k] = {...updates[k], id: k, invoicingTrainingCode: v})
    api.trainings.call({ method: "PUT", loader: setSaving, body: values(updates) }).then(data => {
      loadTrainings()
      resetAnacadUpdates()
      resetModuleUpdates()
      message.success("Modifications sauvegardées.")
    }).catch(console.error)
  }

  const saveOneCodesUpdate = (id, type) => {
    const body = type === 'module' ? {invoicingTrainingCode: moduleUpdates[id]} : {invoicingYearCode: anacadUpdates[id]}
    api.trainings.put(id, body,{ loader: setSaving }).then(data => {
      loadTrainings()
      if (type === 'module') removeModuleUpdate(id)
      else removeAnacadUpdate(id)
      message.success("Modification sauvegardée.")
    }).catch(console.error)
  }

  useEffect(() => {
    loadTrainings(pagination.pageSize, 1)
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  const onTableChange = (pagination, filters, sorter) => {
    loadTrainings(pagination.pageSize, pagination.current)
  }
  
  return (
    <>
      <div style={{ position: 'absolute', top: 32, zIndex: 10, display: 'flex', alignItems: 'center' }}>
        <Input.Search
          placeholder="Rechercher..."
          onSearch={setSearch}
          style={{ maxWidth: 250 }}
          enterButton
          allowClear
        />
      </div>
      <Table
        dataSource={trainings}
        loading={loading}
        rowKey="id"
        size="small"
        pagination={pagination}
        onChange={onTableChange}
        style={{ marginTop: trainings?.length ? 0 : 56 }}
      >
        <Column title="Code" render={t => <TableLink.Training training={t} hideDate newTab />} />
        <Column title="Nom" dataIndex="name" render={name => <Text ellipsis={{ tooltip: true }} style={{ maxWidth: 200 }}>{name}</Text>} />
        <Column title="Date (début/fin)" render={item => item.start ? isoDateToDateShort(item.start) + ' - ' + isoDateToDateShort(item.end) : '-'} />
        <Column title="Code module" render={item => {
          if (!item.invoicingTrainingCode || moduleUpdates[item.id] !== undefined) {
            return (
              <Space.Compact size="small">
                <Input placeholder="Code analytique - Module" autoFocus value={moduleUpdates[item.id]} onChange={e => setModuleUpdate(item.id, e.target.value)} />
                <Button type="primary" htmlType="submit" icon={<Icon icon="save" />} style={{width: 30}} onClick={() => saveOneCodesUpdate(item.id, "module")}></Button>
                <Button icon={<Icon icon="undo" />} style={{width: 30}} onClick={() => removeModuleUpdate(item.id)}></Button>
              </Space.Compact>
            )
          }
          return <><Button type="text" size="small" onClick={e => setModuleUpdate(item.id, item.invoicingTrainingCode)}>{item.invoicingTrainingCode}</Button></>
        }} />
        <Column title="Code année acad." render={item => {
          if (!item.invoicingYearCode || anacadUpdates[item.id] !== undefined) {
            return (
              <Space.Compact size="small">
                <Input placeholder="Code analytique - Année acad." autoFocus value={anacadUpdates[item.id]} onChange={e => setAnacadUpdate(item.id, e.target.value)} />
                <Button type="primary" htmlType="submit" icon={<Icon icon="save" />} style={{width: 30}} onClick={() => saveOneCodesUpdate(item.id, "anacad")}></Button>
                <Button icon={<Icon icon="undo" />} style={{width: 30}} onClick={() => removeAnacadUpdate(item.id)}></Button>
              </Space.Compact>
            )
          }
          return <><Button type="text" size="small" onClick={e => setAnacadUpdate(item.id, item.invoicingYearCode)}>{item.invoicingYearCode}</Button></>
        }} />
        <Column title="Inscri." key="inscriptions" render={(record) => <Badges.Registrations training={record} />} />
        <Column title="États" render={training => <TrainingStates training={training} />} />
      </Table>
      <Button type="primary" icon={<Icon icon="save" />} loading={saving} onClick={saveCodesUpdates} disabled={!(keys(anacadUpdates).length + keys(moduleUpdates).length)}>Sauvegarder tout</Button>
    </>
  )

}



const TrainingsAnalyticCodes = () => {

  // @ts-ignore
  const { tab = 'missing' } = useParams()

  const history = useHistory()

  return (
    <>
      <PageHeader title={<><Icon icon="chart-line" fixedWidth /> Codes analytiques des formations</>} />

      <Tabs defaultActiveKey="current" activeKey={tab} onTabClick={key => history.push(`/compta/analytic-codes/${key}`)} destroyInactiveTabPane={true}>

        <Tabs.TabPane tab={<><Icon icon="empty-set" />Formations avec codes manquants</>} key="missing">
          <Card>
            <TrainingsTable type="missing" />
          </Card>
        </Tabs.TabPane>

        <Tabs.TabPane tab={<><Icon icon="list" />Toutes les formations</>} key="all">
          <Card>
            <TrainingsTable type="all" />
          </Card>
        </Tabs.TabPane>

      </Tabs>
    </>
  )

}

export default TrainingsAnalyticCodes