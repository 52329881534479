import React from 'react'

import Icon from 'components/Icon'
import TrainersOnTrainingsTable from 'components/TrainersOnTrainingsTable'

import { Badge, Card } from 'antd'



const Trainings = ({ user, refreshUser }) => <>
  <Card title={<><Icon icon="graduation-cap" /> Formations <Badge count={user.trainings.length || 0} style={{ backgroundColor: "#1890ff" }} showZero /></>}>
    <TrainersOnTrainingsTable trainersOnTrainings={user.trainings} refresh={refreshUser} />
  </Card>
</>

export default Trainings


