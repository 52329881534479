import React, { useEffect, useState } from 'react'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import { isoDateToDateTime } from 'misc/utils'
import DraggableModal from 'components/DraggableModal'

import { Alert, Col, Descriptions, Form, Input, InputNumber, Radio, Row, Switch } from 'antd'



const EditRegistrationModal = ({ registration, isVisible, onOK, onCancel, from }) => {

  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    if (registration && isVisible) {
      form.setFieldsValue(registration)
    }
  }, [registration, isVisible, form])

  const save = values => {
    api.registrations.put(registration.id, values, { loader: setIsLoading })
      .then(onOK)
      .catch(console.error)
  }

  const onFormValuesChange = (changedValues, allValues) => {
    if (changedValues.hasReducedPrice !== undefined) {
      form.setFieldsValue({ price: changedValues.hasReducedPrice ? registration?.training.reducedPrice : registration?.training.price })
    }
  }

  return (
    <DraggableModal
      title={<><Icon icon="pencil" fixedWidth /> Modifier l'inscription</>}
      open={isVisible}
      maskClosable={false}
      onCancel={onCancel}
      onOk={form.submit}
      okText="Enregistrer"
      okButtonProps={{ icon: <Icon icon="save" />, loading: isLoading }}
      width={900}
    >

      <Alert type='warning' message="Une modification du prix ou des informations de facturation entrainera la création d'une note de crédit et d'une nouvelle facture (transmises automatiquement)." showIcon style={{ marginBottom: 20 }} />

      <Form form={form} layout="vertical" onFinish={save} onValuesChange={onFormValuesChange}>
        <Row gutter={16}>
          <Col span={10}>
            <Descriptions bordered size="small" column={1}>
              {from !== "user" && <Descriptions.Item label="Participant">{registration?.user.lastname} {registration?.user.firstname}</Descriptions.Item>}
              {from !== "training" && <Descriptions.Item label="Formation">{registration?.training.code}</Descriptions.Item>}
              <Descriptions.Item label="Date">{isoDateToDateTime(registration?.date)}</Descriptions.Item>
              <Descriptions.Item label="Prix réduit">
                <Form.Item name="hasReducedPrice" valuePropName="checked" noStyle>
                  <Switch checkedChildren="Oui" unCheckedChildren="Non" />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="Prix">
                <Form.Item name="price" rules={[{ required: true }]} className="form-item-no-explain">
                  <InputNumber min={0} precision={2} style={{ width: 100 }} className="input-number-euro" />
                </Form.Item>
              </Descriptions.Item>
              {registration?.training.isCEP && <Descriptions.Item label="Affilié CEP">
                <Form.Item name="isCEP" valuePropName="checked" noStyle>
                  <Switch checkedChildren="Oui" unCheckedChildren="Non" />
                </Form.Item>
              </Descriptions.Item>}
              {registration?.training.isPQK && <>
                <Descriptions.Item label="Affilié PQK">
                  <Form.Item name="isPQK" valuePropName="checked" noStyle>
                    <Switch checkedChildren="Oui" unCheckedChildren="Non" />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Numéro INAMI">
                  <Form.Item noStyle shouldUpdate={(prev, curr) => prev.isPQK !== curr.isPQK}>
                    {({ getFieldValue }) => getFieldValue('isPQK') ?
                      <Form.Item name="inamiNumber" rules={[
                        { required: true, message: "" },
                        { pattern: /^\d\/\d{5}\/\d\d\/\d{3}$/, message: "" }
                      ]} className="form-item-no-explain">
                        <Input style={{ maxWidth: 150 }} placeholder="0/00000/00/000" />
                      </Form.Item> : "-"
                    }
                  </Form.Item>
                </Descriptions.Item>
              </>}
              <Descriptions.Item label="Étudiant">
                <Form.Item name="isStudent" valuePropName="checked" noStyle>
                  <Switch checkedChildren="Oui" unCheckedChildren="Non" />
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={14}>
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label="Facturation">
                <Form.Item name="billing" noStyle rules={[{ required: true, message: 'Veuillez choisir une méthode de facturation' }]}>
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="personal">Participant</Radio.Button>
                    <Radio.Button value="company">Société</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>

            <Form.Item noStyle shouldUpdate={(prev, curr) => prev.billing !== curr.billing}>
              {({ getFieldValue }) => {
                if (getFieldValue('billing') === "personal") return <></>
                if (getFieldValue('billing') === "company") return <Descriptions bordered size="small" column={1}>
                  <Descriptions.Item label="Société/Institution">
                    <Form.Item name="billingName" noStyle rules={[{ required: true, message: 'Veuillez entrer le nom de la société/institution.' }]}>
                      <Input placeholder="Société/Institution" />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label="Numéro d'entreprise">
                    <Form.Item name="billingCompanyNumber" noStyle rules={[{ required: true, message: 'Veuillez entrer le numéro d\'entreprise.' }]}>
                      <Input placeholder="Numéro d'entreprise" />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label="Numéro de TVA">
                    <Form.Item name="billingVAT" noStyle>
                      <Input placeholder="Numéro de TVA" />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label="Personne de contact">
                    <Form.Item name="billingContact" noStyle rules={[{ required: true, message: 'Veuillez entrer la personne de contact.' }]}>
                      <Input placeholder="Personne de contact" />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label="Adresse e-mail">
                    <Form.Item name="billingEmail" noStyle rules={[{ required: true, message: 'Veuillez entrer l\' adresse e-mail.' }]}>
                      <Input prefix={<Icon icon="envelope" />} placeholder="E-mail" />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label="Numéro de téléphone">
                    <Form.Item name="billingPhone" noStyle rules={[{ required: true, message: 'Veuillez entrer le numéro de téléphone.' }]}>
                      <Input prefix={<Icon icon="phone" />} placeholder="Téléphone" />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label="Adresse">
                    <Form.Item noStyle required>
                      <Row gutter={6} style={{ marginBottom: 6 }}>
                        <Col flex="4">
                          <Form.Item name="billingStreet" noStyle rules={[{ required: true, message: "Veuillez entrer la rue" }]}>
                            <Input placeholder="Rue" />
                          </Form.Item>
                        </Col>
                        <Col flex="1">
                          <Form.Item name="billingNumber" noStyle rules={[{ required: true, message: "Veuillez entrer le numéro" }]}>
                            <Input placeholder="Numéro" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6} style={{ marginBottom: 6 }}>
                        <Col flex="2">
                          <Form.Item name="billingZip" noStyle rules={[{ required: true, message: "Veuillez entrer le code postal" }]}>
                            <Input placeholder="Code postal" />
                          </Form.Item>
                        </Col>
                        <Col flex="3">
                          <Form.Item name="billingCity" noStyle rules={[{ required: true, message: "Veuillez entrer la ville" }]}>
                            <Input placeholder="Ville" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col flex="auto">
                          <Form.Item name="billingCountry" noStyle rules={[{ required: true, message: "Veuillez entrer le pays" }]}>
                            <Input placeholder="Pays" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
              }}
            </Form.Item>
          </Col >
        </Row >

      </Form >

    </DraggableModal >
  )

}

export default EditRegistrationModal