import React, { useEffect } from 'react'

import moment from 'moment'

import Icon from 'components/Icon'
import DraggableModal from 'components/DraggableModal'

import { DatePicker, Form } from 'antd'



const SodexoPaidModal = ({ isVisible, onOK, onCancel }) => {

  const [form] = Form.useForm()

  useEffect(() => {
    if (isVisible) form.resetFields()
  }, [form, isVisible])

  return (
    <DraggableModal
      title={<><Icon icon="check" fixedWidth /> Paiement par Sodexo</>}
      open={isVisible}
      maskClosable={false}
      onCancel={onCancel}
      onOk={form.submit}
      okText="Valider"
      okButtonProps={{ icon: <Icon icon="save" /> }}
      destroyOnClose
    >
      <Form form={form} layout="vertical" onFinish={onOK}>
        <Form.Item label="Date de paiement" name="paymentDate" rules={[{ required: true }]} initialValue={moment()}>
          <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </DraggableModal >
  )

}

export default SodexoPaidModal