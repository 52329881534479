import React from 'react'

import api from 'services/adminApiService'

import { message } from 'antd'
import UserInfosForm from 'components/UserInfosForm'



const Profile = ({ user, setUser }) => {

  const onEdit = (values) => {
    return api.users.put(user.id, values)
      .then(user => {
        setUser(user)
        message.success("Modifications sauvegardés.")
      })
      .catch(console.error)
  }

  return <UserInfosForm user={user} saveText="Sauvegarder" onEdit={onEdit} adminMode={true} />

}

export default Profile


