import React, { useContext, useState, useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import Icon from 'components/Icon'
import RichTextEditor from 'components/RichTextEditor'
import api from 'services/adminApiService'
import { deserialize, serialize } from 'misc/slateSerialize'
import SelectsContext from 'contexts/SelectsContext'
import EmailTemplateFieldsCard from './Settings/EmailTemplateFieldsCard'

import { Button, Card, Col, Form, Input, message, PageHeader, Popconfirm, Row, Select } from 'antd'
import { UserType } from 'components/Tags'
import { filterOnSelectLabel } from 'misc/utils'



const SendMail = () => {

  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()

  const params = new URLSearchParams(useLocation().search)
  const trainingId = params.get("trainingId")
  const registrationStatus = params.get("status")
  const registration = JSON.parse(params.get("registration") || null)
  const training = JSON.parse(params.get("training") || null)

  const { getEmailById, signatures, trainings, emails } = useContext(SelectsContext)

  const [template, setTemplate] = useState(null)

  useEffect(() => {
    if (template) {
      form.setFieldsValue({
        html: deserialize(template.html),
        subject: template.subject,
        signatureId: template.signatureId
      })
    }
  }, [template, form])

  const onTemplateChange = id => setTemplate(getEmailById(id))

  const send = values => {
    if (training) {
      values.user = training.user.id
      values.training = training.training.id
    } else if (registration) {
      values.registration = registration.id
    }
    values.html = serialize(values.html)
    api.post('/sendmail', values, { loader: setIsLoading })
      .then(data => { message.success(`La demande à été enregistrée. Les e-mails seront envoyés sous-peu aux ${data.count} participants.`) })
      .catch(console.error)
  }

  return (
    <>
      <PageHeader title={<><Icon icon="paper-plane" fixedWidth /> Envoyer un e-mail</>} />

      <Form form={form} layout="vertical" onFinish={send}>

        <Row gutter={[16, 16]}>

          <Col span={24}>
            <Card title={<><Icon icon="users" fixedWidth /> Destinataire(s)</>}>
              <Row gutter={64}>
                <Col span={12}>
                  {registration || training
                    ? <Form.Item label="Utilisateur">
                      {training ? <>{training.user.name} <UserType.Trainer /></> : <>{registration.user.name} <UserType.Participant /></>}
                    </Form.Item>
                    : <Form.Item name="training" label="Formation" rules={[{ required: true }]} initialValue={trainingId ? Number(trainingId) : null}>
                      <Select
                        options={trainings?.map(item => ({ label: item.code, value: item.id }))}
                        style={{ width: '100%' }}
                        allowClear
                        showSearch
                        filterOption={filterOnSelectLabel}
                      />
                    </Form.Item>
                  }
                </Col>
                <Col span={12}>
                  {registration || training
                    ? <Form.Item label="Formation">
                      {training ? training.training.code : registration.training.code}
                    </Form.Item>
                    : <Form.Item name="registrationStatus" label="État de l'inscription" rules={[{ required: true }]} initialValue={registrationStatus ? [registrationStatus] : []}>
                      <Select style={{ width: '100%' }} mode="multiple">
                        <Select.Option value="validated">Inscrits</Select.Option>
                        <Select.Option value="waiting-list">Liste d'attente (sans les étudiants)</Select.Option>
                        <Select.Option value="waiting-list-student">Liste d'attente étudiant</Select.Option>
                        <Select.Option value="cancelled">Désistements</Select.Option>
                      </Select>
                    </Form.Item>
                  }

                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={16}>
            <Card title={<><Icon icon="envelope-open" fixedWidth /> E-mail</>}>
              <Form.Item label="Template d'e-mail">
                <Select options={emails?.map(item => ({ label: item.name, value: item.id }))} style={{ width: '100%' }} allowClear showSearch onChange={onTemplateChange} filterOption={filterOnSelectLabel} />
              </Form.Item>
              <Form.Item name="subject" label="Objet de l'e-mail" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="html" label="Contenu de l'e-mail" rules={[{ required: true }]}>
                <RichTextEditor />
              </Form.Item>
              <Form.Item name="signatureId" label="Signature" rules={[{ required: true }]}>
                <Select options={signatures?.map(item => ({ label: item.description, value: item.id }))} style={{ width: '100%' }} />
              </Form.Item>
              <Popconfirm title="Êtes-vous sûr ?" onConfirm={form.submit} >
                <Button type="primary" icon={<Icon icon="paper-plane" />} loading={isLoading}>Envoyer l'e-mail</Button>
              </Popconfirm>
            </Card>
          </Col>

          <Col span={8}>
            <EmailTemplateFieldsCard email={`generic-email-for-${training ? 'trainer' : 'registration'}`} />
          </Col>

        </Row>

      </Form>

    </>
  )

}

export default SendMail