import React, { useEffect, useState } from 'react'

import moment from 'moment'

import Icon from 'components/Icon'

import { EmailValidatedStatus } from 'components/Tags'

import { Button, Col, DatePicker, Divider, Form, Input, Radio, Row, Switch, Modal, Card, Space } from 'antd'
import { copyToClipboard } from 'misc/utils'



const UserInfosForm = ({ user, onEdit, saveText, adminMode = false }) => {

  const [isLoading, setIsLoading] = useState(false)
  const [isUpdatingEmail, setIsUpdatingEmail] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    const userData = Object.assign({}, user)
    if (userData.birthDate) userData.birthDate = moment(userData.birthDate)
    form.setFieldsValue(userData)
    setIsUpdatingEmail(false)
  }, [user, form])

  const onFinishForm = (values) => {
    setIsLoading(true)
    const promise = onEdit(values)
    if (promise) {
      promise.then(() => setIsLoading(false))
        .catch(e => {
          setIsLoading(false)
          console.error(e)
        })
    }
  }

  const enableEmailUpdate = () => {
    Modal.confirm({
      title: "Êtes-vous sûr ?",
      content: <>
        Modifier l'adresse e-mail d'un utilisateur lui enverra un e-mail pour valider sa nouvelle adresse e-mail. Tant qu'il n'a pas validé sa nouvelle adresse, il ne pourra pas se connecter.
      </>,
      maskClosable: true,
      closable: true,
      onOk: () => setIsUpdatingEmail(true)
    })

  }

  return (
    <>
      <Form form={form} onFinish={onFinishForm} layout="vertical">

        <Space direction="vertical" style={{ display: 'flex' }} size="large">

          <Card title={<><Icon icon="clipboard-user" /> Informations personnelles</>}>
            <Row gutter={24}>

              <Col flex="1 1 300px">
                <Form.Item name="firstname" label="Prénom" rules={[{ required: true, message: 'Veuillez entrer votre prénom.' }]}>
                  <Input placeholder="Prénom" />
                </Form.Item>
                <Form.Item name="lastname" label="Nom" rules={[{ required: true, message: 'Veuillez entrer votre nom.' }]}>
                  <Input placeholder="Nom" />
                </Form.Item>
                <Form.Item name="gender" label="Genre" className="horizontal-6" rules={!adminMode && [{ required: true, message: 'Veuillez entrer votre genre.' }]}>
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="m">Homme</Radio.Button>
                    <Radio.Button value="f">Femme</Radio.Button>
                    <Radio.Button value="o">Autre</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item name="birthDate" label="Date de naissance" rules={!adminMode && [{ required: true, message: 'Veuillez entrer votre date de naissance.' }]}>
                  <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item name="birthPlace" label="Lieu de naissance" rules={!adminMode && [{ required: true, message: 'Veuillez entrer votre lieu de naissance.' }]}>
                  <Input prefix={<Icon icon="map-marker-alt" />} placeholder="Lieu de naissance" />
                </Form.Item>
              </Col>

              <Col flex="31px"><Divider type="vertical" style={{ height: '100%' }} /></Col>

              <Col flex="1 1 300px">
                {adminMode && <Form.Item name="email" label={<>Adresse e-mail&nbsp;&nbsp;<EmailValidatedStatus status={user.isEmailValidated} /></>} >
                  <Input.Group compact style={{ display: 'flex' }}>
                    <Form.Item name="email" noStyle>
                      <Input prefix={<Icon icon="envelope" />} disabled={!isUpdatingEmail} />
                    </Form.Item>
                    <Button icon={<Icon icon="clipboard" />} onClick={() => copyToClipboard(user.email, 'Adresse e-mail copiée.')}>Copier</Button>
                    <Button icon={<Icon icon="lock-open" />} onClick={enableEmailUpdate} disabled={isUpdatingEmail}>Déverouiller</Button>
                  </Input.Group>
                </Form.Item>}
                {!adminMode && <Form.Item name="phone" label="Numéro de téléphone" rules={!adminMode && [{ required: true, message: 'Veuillez entrer votre numéro de téléphone.' }]}>
                  <Input prefix={<Icon icon="phone" />} placeholder="Téléphone" />
                </Form.Item>}
                {adminMode && <Form.Item name="phone" label="Numéro de téléphone" >
                  <Input.Group compact style={{ display: 'flex' }}>
                    <Form.Item name="phone" noStyle>
                      <Input prefix={<Icon icon="phone" />} placeholder="Téléphone" />
                    </Form.Item>
                    <Button icon={<Icon icon="clipboard" />} onClick={() => copyToClipboard(user.phone, 'Numéro de téléphone copié.')}>Copier</Button>
                    <a href={`tel:${user.phone}`}><Button icon={<Icon icon="phone" />} style={{ color: '#52c41a' }}>Appeler</Button></a>
                  </Input.Group>
                </Form.Item>}
                <Form.Item label="Adresse" required={!adminMode}>
                  <Row gutter={6} style={{ marginBottom: 6 }}>
                    <Col flex="4">
                      <Form.Item name="street" noStyle rules={!adminMode && [{ required: true, message: "Veuillez entrer la rue" }]}>
                        <Input placeholder="Rue" />
                      </Form.Item>
                    </Col>
                    <Col flex="1">
                      <Form.Item name="number" noStyle rules={!adminMode && [{ required: true, message: "Veuillez entrer le numéro" }]}>
                        <Input placeholder="Numéro" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={6} style={{ marginBottom: 6 }}>
                    <Col flex="2">
                      <Form.Item name="zip" noStyle rules={!adminMode && [{ required: true, message: "Veuillez entrer le code postal" }]}>
                        <Input placeholder="Code postal" />
                      </Form.Item>
                    </Col>
                    <Col flex="3">
                      <Form.Item name="city" noStyle rules={!adminMode && [{ required: true, message: "Veuillez entrer la ville" }]}>
                        <Input placeholder="Ville" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col flex="auto">
                      <Form.Item name="country" noStyle rules={!adminMode && [{ required: true, message: "Veuillez entrer le pays" }]}>
                        <Input placeholder="Pays" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>

            </Row>
          </Card>

          {user.isParticipant && <Card title={<><Icon icon="file-signature" /> Informations utiles pour les inscriptions</>}>
            <Row gutter={24}>
              <Col flex="1 1 300px">
                <Form.Item name="diplomas" label="Diplôme(s)" rules={!adminMode && [{ required: true, message: 'Veuillez mentionner vos diplômes ou noter explicitement "/"' }]}>
                  <Input.TextArea placeholder="Diplôme(s) et établissement de délivrance" autoSize={{ minRows: 2 }} />
                </Form.Item>
              </Col>
              <Col flex="31px"><Divider type="vertical" style={{ height: '100%' }} /></Col>
              <Col flex="1 1 300px">
                <Form.Item name="diplomasInstitutions" label="Etablissement(s) de délivrance (diplômes)" rules={!adminMode && [{ required: true, message: 'Veuillez mentionner le(s) établissement(s) de délivrance ou noter explicitement "/"' }]}>
                  <Input.TextArea placeholder="Etablissement(s) de délivrance (diplômes)" autoSize={{ minRows: 2 }} />
                </Form.Item>
              </Col>
            </Row>
          </Card>}

          {user.isTrainer && <Card title={<><Icon icon="chalkboard-teacher" /> Données formateur</>}>
            <Row gutter={24}>
              <Col flex="1 1 300px">
                <Form.Item name="billing" label="Facturation" rules={!adminMode && [{ required: true, message: 'Veuillez choisir une méthode de facturation' }]}>
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="personal">À mon nom</Radio.Button>
                    <Radio.Button value="company">À une société</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(prev, curr) => prev.billing !== curr.billing}>
                  {({ getFieldValue }) => {
                    if (getFieldValue('billing') === "personal") return <>
                      <Form.Item label="Numéro NISS" name="trainerNISS" rules={!adminMode && [{ required: true, message: 'Veuillez entrer le numéro NISS.' }]}>
                        <Input placeholder="Numéro NISS" />
                      </Form.Item>
                    </>
                    if (getFieldValue('billing') === "company") return <>
                      <Form.Item name="billingCompany" label="Société/Institution" rules={!adminMode && [{ required: true, message: 'Veuillez entrer le nom de la société/institution.' }]}>
                        <Input placeholder="Société/Institution" />
                      </Form.Item>
                      <Form.Item label="Numéro d'entreprise" name="billingCompanyNumber" rules={!adminMode && [{ required: true, message: 'Veuillez entrer le numéro d\'entreprise.' }]}>
                        <Input placeholder="Numéro d'entreprise" />
                      </Form.Item>
                      <Form.Item label="Numéro de TVA" name="billingVAT">
                        <Input placeholder="Numéro de TVA" />
                      </Form.Item>
                      <Form.Item name="billingContact" label="Personne de contact" rules={!adminMode && [{ required: true, message: 'Veuillez entrer la personne de contact.' }]}>
                        <Input placeholder="Personne de contact" />
                      </Form.Item>
                      <Form.Item name="billingEmail" label="Adresse e-mail" rules={!adminMode && [{ required: true, message: 'Veuillez entrer l\' adresse e-mail.' }]}>
                        <Input prefix={<Icon icon="envelope" />} placeholder="E-mail" />
                      </Form.Item>
                      <Form.Item name="billingPhone" label="Numéro de téléphone" rules={!adminMode && [{ required: true, message: 'Veuillez entrer le numéro de téléphone.' }]}>
                        <Input prefix={<Icon icon="phone" />} placeholder="Téléphone" />
                      </Form.Item>
                    </>
                  }}
                </Form.Item>
              </Col>

              <Col flex="31px"><Divider type="vertical" style={{ height: '100%' }} /></Col>

              <Col flex="1 1 300px">
                <Form.Item noStyle shouldUpdate={(prev, curr) => prev.billing !== curr.billing}>
                  {({ getFieldValue }) => getFieldValue('billing') === "company" && <>
                    <Form.Item label="Adresse" required={!adminMode}>
                      <Row gutter={6} style={{ marginBottom: 6 }}>
                        <Col flex="4">
                          <Form.Item name="billingStreet" noStyle rules={!adminMode && [{ required: true, message: "Veuillez entrer la rue" }]}>
                            <Input placeholder="Rue" />
                          </Form.Item>
                        </Col>
                        <Col flex="1">
                          <Form.Item name="billingNumber" noStyle rules={!adminMode && [{ required: true, message: "Veuillez entrer le numéro" }]}>
                            <Input placeholder="Numéro" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6} style={{ marginBottom: 6 }}>
                        <Col flex="2">
                          <Form.Item name="billingZip" noStyle rules={!adminMode && [{ required: true, message: "Veuillez entrer le code postal" }]}>
                            <Input placeholder="Code postal" />
                          </Form.Item>
                        </Col>
                        <Col flex="3">
                          <Form.Item name="billingCity" noStyle rules={!adminMode && [{ required: true, message: "Veuillez entrer la ville" }]}>
                            <Input placeholder="Ville" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col flex="auto">
                          <Form.Item name="billingCountry" noStyle rules={!adminMode && [{ required: true, message: "Veuillez entrer le pays" }]}>
                            <Input placeholder="Pays" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  </>
                  }
                </Form.Item>
                <Form.Item label="Numéro de compte de paiement" name="iban" rules={!adminMode && [{ required: true, message: "Veuillez entrer le numéro de compte de paiement" }]}>
                  <Input placeholder="Numéro de compte de paiement" />
                </Form.Item>
                {adminMode && <Form.Item label={<>Mini-CV pour le site web&nbsp;<Icon.Info tooltip="Il sera visible derrière le nom du formateur, dans la liste des intervenants de la formation." /></>} name="resume">
                  <Input.TextArea placeholder="Mini-CV pour le site web" autoSize={{ minRows: 3 }} />
                </Form.Item>}
              </Col>
            </Row>
          </Card>}

        </Space>

        <div style={{ marginTop: 25, marginBottom: 30 }}>
          <span>Je souhaite être tenu informé(e) des activités du Centre de Formation Continue Vinci.</span>
          <Form.Item name="keepInformed" valuePropName="checked" noStyle>
            <Switch checkedChildren="Oui" unCheckedChildren="Non" style={{ marginLeft: 10 }} />
          </Form.Item>
        </div>

        <Button loading={isLoading} type="primary" htmlType="submit" icon={<Icon icon="save" />}>{saveText}</Button>

      </Form>
    </>
  )

}

export default UserInfosForm