import React, { useContext, useState } from 'react'

import { cloneDeep } from 'lodash'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import SelectsContext from 'contexts/SelectsContext'
import Badges from 'components/Badges'

import { AutoComplete, Button, Card, Checkbox, Form, Input, InputNumber, Popconfirm, Table } from 'antd'
import { filterOnSelectLabel } from 'misc/utils'

const { Column } = Table



const Material = ({ training, setTraining }) => {

  const [editingMaterial, setEditingMaterial] = useState(null)

  const { materials } = useContext(SelectsContext)

  const [newForm] = Form.useForm()

  const addMaterial = (values) => {
    api.materials.post({ ...values, trainingId: training.id }).then(material => {
      const newTraining = Object.assign({}, training)
      newTraining.materials = newTraining.materials.concat(material)
      setTraining(newTraining)
      newForm.resetFields()
    }).catch(console.error)
  }

  const deleteMaterial = (id) => {
    api.materials.delete(id).then(material => {
      const newTraining = Object.assign({}, training)
      newTraining.materials = newTraining.materials.filter(m => m.id !== material.id)
      setTraining(newTraining)
    }).catch(console.error)
  }

  const updateMaterial = () => {
    api.materials.put(editingMaterial.id, editingMaterial).then(material => {
      const newTraining = Object.assign({}, training)
      newTraining.materials = newTraining.materials.filter(m => m.id !== material.id).concat(material)
      setTraining(newTraining)
      setEditingMaterial(null)
    }).catch(console.error)
  }

  const updateCheckBox = (material, isChecked) => {
    api.materials.put(material.id, { ...material, isChecked }).then(material => {
      const newTraining = Object.assign({}, training)
      newTraining.materials = newTraining.materials.filter(m => m.id !== material.id).concat(material)
      setTraining(newTraining)
    }).catch(console.error)
  }

  const sortedMaterials = training?.materials?.slice().sort((a, b) => a.name > b.name ? 1 : -1) // Call slice to create a new Array and prevent mutating it

  return (
    <>
      <Card title={<><Icon icon="tools" /> Liste du matériel nécessaire <Badges.Materials materials={training.materials} /></>}>
        <Table dataSource={sortedMaterials} showHeader={false} rowKey="id" size="middle" pagination={false}>
          <Column width={30} render={item => <Checkbox key={"checkbox" + item.id} checked={item.isChecked} onChange={e => updateCheckBox(item, e.target.checked)} />} />
          <Column width={80} align="right" render={item => editingMaterial?.id === item.id
            ? <InputNumber style={{ maxWidth: 60 }} size="small" defaultValue={editingMaterial.quantity} onChange={value => { setEditingMaterial({ ...editingMaterial, quantity: value }) }} />
            : item.quantity}
          />
          <Column render={item => editingMaterial?.id === item.id ? <Input defaultValue={editingMaterial.name} size="small" onChange={e => { setEditingMaterial({ ...editingMaterial, name: e.target.value }) }} /> : item.name} />
          <Column fixed="right" width={130} align="right" className="actions-column" render={item =>
            editingMaterial?.id !== item.id ? <>
              <Popconfirm onConfirm={() => deleteMaterial(item.id)} title="Êtes-vous sûr ?"><Button shape="circle" icon={<Icon icon="trash" />} size="small" danger /></Popconfirm>
              <Button shape="circle" onClick={() => setEditingMaterial(cloneDeep(item))} icon={<Icon icon="pencil" />} size="small" />
            </> : <>
              <Button onClick={updateMaterial} shape="circle" icon={<Icon icon="check" />} size="small" className="button-success" />
              <Button onClick={() => setEditingMaterial(null)} shape="circle" icon={<Icon icon="times" />} danger size="small" />
            </>
          } />
        </Table>
        <h3 style={{ marginTop: 25 }}>Ajouter un élément</h3>
        <Form onFinish={addMaterial} form={newForm}>
          <Input.Group compact>
            <Form.Item noStyle name="quantity" initialValue={1}>
              <InputNumber min={0} title={"Quantité"} />
            </Form.Item>
            <Form.Item noStyle name="name">
              <AutoComplete
                options={materials?.map(item => ({ value: item.name }))}
                style={{ width: '100%', maxWidth: 300 }}
                placeholder="Nom"
                filterOption={filterOnSelectLabel}
              />
            </Form.Item>
            <Button type="primary" htmlType="submit" icon={<Icon icon="plus" />}>Ajouter </Button>
          </Input.Group>
        </Form>
      </Card>
    </>
  )

}

export default Material


