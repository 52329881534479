import React, { useState } from 'react'

import Icon from 'components/Icon'
import api from 'services/userApiService'

import { Button, Col, Descriptions, Divider, Form, Input, Modal, Radio, Result, Row, Select, Steps } from 'antd'
import { formatPaymentCom } from 'misc/utils'



const CFPaymentModal = ({ registration, isVisible, onOK, onCancel }) => {

  const [step, setStep] = useState(0)
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const paymentCommunication = formatPaymentCom(registration.paymentCommunication)

  const prevStep = () => {
    setStep(step - 1 > 0 ? step - 1 : 0)
  }

  const nextStep = values => {
    setData(Object.assign({}, data, values))
    setStep(step + 1 < 4 ? step + 1 : 4)
  }

  const sendPayment = values => {
    const newData = Object.assign({}, data, values)
    setData(newData)
    api.post('/payments', { paymentCF: newData, registrationId: registration.id }, { loader: setIsLoading })
      .then(() => nextStep())
      .catch(console.error)
  }

  return (
    <Modal className="cf-payment-modal" title={<><Icon icon="money-check-alt" /> Paiement de la formation</>} open={isVisible} width="650px" footer={null} onCancel={onCancel} maskClosable={false}>
      <Steps progressDot size="small" current={step}>
        <Steps.Step title="Paiement de l'acompte" />
        <Steps.Step title="Renseignements travailleur" />
        <Steps.Step title="Renseignements entreprise" />
        <Steps.Step title="Renseignements chèques" />
      </Steps>
      <Divider />

      <div hidden={step !== 0}>
        <p>Le paiement de l'accompte est indispensable pour valider votre inscription. Il vous sera ensuite restitué à la fin de la formation.</p>
        <p>Veuillez effectuer le paiement sur le compte bancaire du Centre de Formation Continue.</p>
        <Descriptions column={1} bordered size="small">
          <Descriptions.Item label="Montant">{registration?.training?.deposit} €</Descriptions.Item>
          <Descriptions.Item label="Compte bancaire">BE04-0682-4057-9831</Descriptions.Item>
          <Descriptions.Item label="Destinataire">HE Vinci asbl</Descriptions.Item>
          <Descriptions.Item label="Communication structurée">{paymentCommunication}</Descriptions.Item>
        </Descriptions>
        <p style={{ marginTop: 20 }}>Une fois l'accompte payé, si vous êtes déjà en possession des informations pour vos chèques formation, vous pouvez les encoder maintenant. Dans le cas contraire, vous pourrez les encoder plus tard dans votre portail participant.</p>
        <p><Icon icon="exclamation-triangle" color="orange" /> Le paiement du solde (chèques formation ou non) doit être reçu au plus tard 20 jours avant le début de la formation.</p>
        <Divider />
        <Row justify="space-between">
          <Col><Button icon={<Icon icon="clock" />} onClick={onOK}>J'encoderai plus tard</Button></Col>
          <Col><Button type="primary" icon={<Icon icon="arrow-right" />} onClick={() => nextStep()}>J'encode mes chèques</Button></Col>
        </Row>
      </div>

      <Form hidden={step !== 1} onFinish={nextStep}>
        <Form.Item name="NRNumber" label="Numéro de registre national" rules={[
          { required: true },
          { pattern: /^\d\d\.\d\d\.\d\d-\d\d\d\.\d\d$/, message: "Le numéro doit être au format : 00.00.00-000.00 (avec les points et le tiret)" }
        ]}>
          <Input placeholder="00.00.00-000.00" />
        </Form.Item>
        <Form.Item name="nationality" label="Nationalité" rules={[{ required: true }]}>
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="belgian">Belge</Radio.Button>
            <Radio.Button value="ue">Union Européenne</Radio.Button>
            <Radio.Button value="out-ue">Hors UE</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="workStatus" label="Statut" rules={[{ required: true }]}>
          <Select mode="multiple">
            <Select.Option value="independant">Indépendant</Select.Option>
            <Select.Option value="employe">Employé</Select.Option>
            <Select.Option value="cadre">Cadre</Select.Option>
            <Select.Option value="ouvrier">Ouvrier</Select.Option>
            <Select.Option value="interimaire">Intérimaire</Select.Option>
            <Select.Option value="conjoint-aidant">Conjoint aidant</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="experience" label="Expérience professionnelle totale" rules={[{ required: true }]}>
          <Select>
            <Select.Option value="<5">&lt;5 ans</Select.Option>
            <Select.Option value="5-9">5-9 ans</Select.Option>
            <Select.Option value="10-14">10-14 ans</Select.Option>
            <Select.Option value="15-19">15-19 ans</Select.Option>
            <Select.Option value=">20">20 ans et plus</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="studyLevel" label="Niveau d'études" rules={[{ required: true }]}>
          <Select mode="multiple">
            <Select.Option value="univ">Universitaire</Select.Option>
            <Select.Option value="sup-non-univ">Supérieur non universitaire</Select.Option>
            <Select.Option value="sec-sup">Secondaire supérieur</Select.Option>
            <Select.Option value="exp-pro">Expérience professionnelle</Select.Option>
          </Select>
        </Form.Item>
        <Divider />
        <Row justify="space-between">
          <Col><Button icon={<Icon icon="arrow-left" />} onClick={prevStep}>Prédécent</Button></Col>
          <Col><Button type="primary" htmlType="submit" icon={<Icon icon="arrow-right" />}>Suivant</Button></Col>
        </Row>
      </Form>

      <Form hidden={step !== 2} onFinish={nextStep}>
        <Form.Item name="companyNumber" label="Numéro d'entreprise" rules={[
          { required: true },
          { pattern: /^\d\d\d\d\.\d\d\d\.\d\d\d$/, message: "Le numéro doit être au format : 1234.123.123 (avec les points)" }
        ]}>
          <Input placeholder="1234.123.123" />
        </Form.Item>
        <Form.Item name="companyName" label="Nom de l'entreprise" rules={[{ required: true }]}>
          <Input placeholder="Nom de l'entreprise" />
        </Form.Item>
        <Form.Item name="companyZip" label="Code postal" rules={[{ required: true }]}>
          <Input placeholder="Code postal de l'entreprise" />
        </Form.Item>
        <Form.Item name="companyCity" label="Localité" rules={[{ required: true }]}>
          <Input placeholder="Localité de l'entreprise" />
        </Form.Item>
        <Divider />
        <Row justify="space-between">
          <Col><Button icon={<Icon icon="arrow-left" />} onClick={prevStep}>Prédécent</Button></Col>
          <Col><Button type="primary" htmlType="submit" icon={<Icon icon="arrow-right" />}>Suivant</Button></Col>
        </Row>
      </Form>

      <Form hidden={step !== 3} onFinish={sendPayment}>
        <Form.Item name="authorizationNumber" label="N° d'autorisation client" rules={[
          { required: true },
          { pattern: /^\d\d\d\/\d\d\d\d\/\d\d\d\d\d$/, message: "Le numéro doit être au format : 123/1234/12345 (avec les /)" }
        ]}>
          <Input placeholder="123/1234/12345" />
        </Form.Item>
        <Form.Item name="usageCode" label="Code d'utilisation" rules={[
          { required: true },
          { pattern: /^\d{8}$/, message: "Le numéro doit être composé de 8 chiffres" }
        ]}>
          <Input placeholder="12345678" />
        </Form.Item>
        <Divider />
        <Row justify="space-between">
          <Col><Button icon={<Icon icon="arrow-left" />} onClick={prevStep}>Prédécent</Button></Col>
          <Col><Button loading={isLoading} type="primary" htmlType="submit" icon={<Icon icon="check" />}>Valider ce paiement</Button></Col>
        </Row>
      </Form>

      <div hidden={step !== 4}>
        <Result
          status="success"
          title="Nous avons bien reçu votre demande."
          subTitle={<>
            <p>Nous allons à présent bloquer les chèques formation auprès de Sodexo. Nous vous tiendrons informé dès que les chèques sont acceptés.</p>
          </>}
        />
        <Row justify="end">
          <Col><Button loading={isLoading} type="primary" htmlType="submit" icon={<Icon icon="check" />} onClick={onOK}>Terminé</Button></Col>
        </Row>
      </div>


    </Modal>
  )

}

export default CFPaymentModal