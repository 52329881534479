import React, { useEffect } from 'react'

import Icon from 'components/Icon'
import DraggableModal from 'components/DraggableModal'

import { Alert, Form, Input, InputNumber, } from 'antd'



const RefundModal = ({ amount, isVisible, onOK, onCancel, showInfo = true }) => {

  const [form] = Form.useForm()

  useEffect(() => {
    if (isVisible) {
      form.resetFields()
      form.setFieldsValue({ amount })
    }
  }, [amount, form, isVisible])

  const onFinish = values => {
    onOK({ note: values.note, amount: -values.amount })
  }

  return (
    <DraggableModal
      title={<><Icon icon="search" fixedWidth /> Encoder un remboursement</>}
      open={isVisible}
      maskClosable={false}
      onCancel={onCancel}
      onOk={form.submit}
      okText="Encoder"
      okButtonProps={{ icon: <Icon icon="save" /> }}
      destroyOnClose
    >
      {showInfo && <Alert
        style={{ marginBottom: 30 }}
        showIcon
        message="Les opérations pour lesquelles un remboursement est émis n'apparaitront plus dans les opérations à lettrer, même si elles n'ont pas été lettrées."
      />}
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label="Montant du remboursement" name="amount" rules={[{ required: true }]}>
          <InputNumber min={0.01} precision={2} style={{ width: 100 }} className="input-number-euro" />
        </Form.Item>
        <Form.Item label="Raison (publique)" name="note" rules={[{ required: true }]}>
          <Input.TextArea autoSize={{ minRows: 3 }} />
        </Form.Item>
      </Form>
    </DraggableModal >
  )

}

export default RefundModal