import React from 'react'


import Icon from 'components/Icon'
import {formatPaymentCom, getColoredBalance, isoDateToDateTime, nfEur} from 'misc/utils'
import TableLink from 'components/TableLink'
import { RegistrationStatus } from 'components/Tags'
import RegistrationView from './RegistrationView'

import { Table } from 'antd'

const { Column } = Table



/**
 * from can be training or user.
 */
const RegistrationsList = ({ registrations, refresh, from, loading = false, pagination = false, onChange = null, showIsClosed = true, showStatus = true }) => {

  return (
    <>
      <Table
        dataSource={registrations}
        rowKey="id"
        size="small"
        rowClassName="row-clickable"
        expandable={{
          expandRowByClick: true,
          expandIconColumnIndex: -1,
          expandedRowRender: r => <RegistrationView registration={r} refresh={refresh} />
        }}
        loading={loading}
        pagination={pagination}
        onChange={onChange}
      >
        <Column title="Numéro" render={item => <>{item.number}{item.user.hasImportantNote && <Icon.ImportantNote style={{ marginLeft: 7 }} />}</>} />
        {from !== 'user' && <Column title="Participant" render={item => <TableLink.User user={item.user} />} />}
        {from !== 'training' && <Column title="Formation" render={item => <TableLink.Training training={item.training} hideDate />} />}
        <Column title="Date" render={item => isoDateToDateTime(item.date)} />
        <Column title="Prix" render={item => nfEur(item.price)} />
        <Column title={<>Solde <Icon.Info popover={{
          title: "Codes couleur",
          content: <>
            <span style={{ color: '#52c41a' }}>Vert</span> : solde payé<br />
            <span style={{ color: '#fa8c16' }}>Orange</span> : acompte payé<br />
            <span style={{ color: '#f5222d' }}>Rouge</span> : acompte non payé
          </>
        }} /></>} render={getColoredBalance} />
        <Column title="Communic. struct." render={item => formatPaymentCom(item.paymentCommunication)} />
        {showStatus && <Column title="Statut" render={item => <RegistrationStatus registration={item} />} />}
        {showIsClosed && <Column title="Clôturée" render={item => <Icon.Boolean boolean={item.isClosed} />} />}
      </Table>
    </>
  )

}

export default RegistrationsList