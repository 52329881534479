import React, { useState, useEffect } from 'react'

import './ActionsTimeline.less'

import Icon from 'components/Icon'
import { flatten, orderBy } from 'lodash'
import { isoDateToDateShort, isoDateToDateTime } from 'misc/utils'
import Action from './Action'
import TableLink from 'components/TableLink'

import { Card, Select, Divider, Radio, Space, Empty, Table } from 'antd'

const { Column } = Table



const ActionsTimeline = ({ user, refreshUser }) => {


  const [filterRegistration, setFilterRegistration] = useState(null)
  const [filterTraining, setFilterTraining] = useState(null)
  const [filterUserType, setFilterUserType] = useState(user.isParticipant ? 'participant' : user.isTrainer ? 'trainer' : null)

  useEffect(() => {
    setFilterRegistration(null)
    setFilterTraining(null)
  }, [filterUserType])

  let actions = []
  if (filterUserType === 'participant') {
    let registrations = user.registrations
    if (filterRegistration) registrations = user.registrations.filter(r => r.id === filterRegistration)
    actions = flatten(registrations.map(r => r.actions.map(a => Object.assign({}, a, { registration: r }))))
    if (!filterRegistration) actions = actions.concat(user.actions)
  } else if (filterUserType === 'trainer') {
    let trainings = user.trainings
    if (filterTraining) trainings = user.trainings.filter(t => t.trainingId === filterTraining)
    actions = flatten(trainings.map(t => t.actions.map(a => Object.assign({}, a, { training: t }))))
    if (!filterTraining) actions = actions.concat(user.actions)
  }
  actions = orderBy(actions, ['doneDate'], ['desc'])

  return (
    <>
      <Card title={<><Icon icon="stream" />Timeline</>}>

        <Space size="middle">

          {<Radio.Group value={filterUserType} onChange={e => setFilterUserType(e.target.value)}>
            <Radio.Button value="participant" disabled={!user.isParticipant}>Participant</Radio.Button>
            <Radio.Button value="trainer" disabled={!user.isTrainer}>Formateur</Radio.Button>
          </Radio.Group>}

          {filterUserType === 'participant' && <Select style={{ width: 200 }} placeholder="Toutes les inscriptions" allowClear onChange={setFilterRegistration}>
            {user.registrations.map(r => <Select.Option key={r.id} value={r.id}>{r.training.code} <small>{isoDateToDateShort(r.training.start)}</small></Select.Option>)}
          </Select>}

          {filterUserType === 'trainer' && <Select style={{ width: 200 }} placeholder="Toutes les formations" allowClear onChange={setFilterTraining}>
            {user.trainings.map(t => <Select.Option key={t.trainingId} value={t.trainingId}>{t.training.code} <small>{isoDateToDateShort(t.training.start)}</small></Select.Option>)}
          </Select>}

          <Select style={{ width: 200 }} placeholder="Tous les événements" allowClear disabled>
            <Select.Option value="email">E-mails</Select.Option>
            <Select.Option value="invoice">Factures</Select.Option>
            <Select.Option value="refunds">Remboursements</Select.Option>
            <Select.Option value="notes">Notes</Select.Option>
            <Select.Option value="payments">Paiements</Select.Option>
            <Select.Option value="registrations">Inscriptions</Select.Option>
          </Select>

        </Space>

        <Divider />

        {!actions.length ? <Empty description="Aucun événement jusqu'à présent." />
          : <Table
            dataSource={actions}
            showHeader={false}
            size="small"
            pagination={{ pageSize: 20 }}
            className="timeline-table"
            rowKey="id"
            rowClassName="row-clickable"
            expandable={{
              expandRowByClick: true,
              expandIconColumnIndex: -1,
              expandedRowRender: action => <Action.Description action={action} refresh={refreshUser} user={user} />
            }}
          >
            <Column width={145} render={action => isoDateToDateTime(action.doneDate)} />
            <Column width={45} render={action => <Action.Icon action={action} />} className="action-icon" />
            {!filterRegistration && !filterTraining && <Column width={120} render={action => {
              if (action.registration) return <TableLink.Training training={action.registration.training} hideDate />
              if (action.training) return <TableLink.Training training={action.training.training} hideDate />
              return null
            }} />}
            <Column render={action => <Action action={action} user={user} />} />
          </Table>}
      </Card >
    </>
  )

}

export default ActionsTimeline


