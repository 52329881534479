import React, { useContext } from 'react'

import { useHistory } from 'react-router'

import Icon from 'components/Icon'
import UserAuthContext from 'contexts/UserAuthContext'
import api from 'services/userApiService'
import UserInfosForm from 'components/UserInfosForm'

import { Alert, Button, message, PageHeader } from 'antd'



const Profile = ({ forceFill = false }) => {

  const { user, setUser } = useContext(UserAuthContext)

  const history = useHistory()

  const onEdit = (values) => {
    return api.post('/auth/user', values)
      .then(user => {
        setUser(user)
        message.success("Votre profil est bien sauvegardé.")
      })
      .catch(console.error)
  }

  return (
    <>

      {forceFill
        ? <Alert
          className="fill-profile-alert"
          message={<>Vous y êtes presque !</>}
          description="Il semblerait que votre profil ne soit pas tout à fait complet. Merci de le compléter pour avoir accès au portail."
          type="info"
          showIcon
        />
        : <PageHeader
          title={<><Icon icon="user" /> Mon profil</>}
          extra={!forceFill && [
            <Button key="1" icon={<Icon icon="user-lock" />} onClick={() => history.push('/portal/credentials')}>Mes données de connexion</Button>
          ]}
        />
      }
      <p className="sub-title-light">Nous avons besoin de ces informations pour gérer au mieux votre dossier. Mais ne vous inquiétez pas, nous traitons vos données avec le plus grand soin conformément au Règlement Général de Protection des Données.</p>
      <UserInfosForm user={user} onEdit={onEdit} saveText="Sauvegarder mes données" />
      <p className="rgpd-infos" style={{marginTop: 35}}>L’ASBL HE Vinci est soucieuse de traiter vos données à caractère personnel conformément au Règlement Général de Protection des Données entré en vigueur ce 25 mai 2018. Nous vous informons traiter les données collectées par ce formulaire aux seules fins de l’administration de nos formations continues. Les traitements, en ce compris la collecte, sont effectués conformément aux dispositions légales applicables en matière de formation continue, mais aussi en vue de pouvoir réaliser nos obligations contractuelles ou précontractuelles à votre égard dans le cadre de nos prestations.</p>
      <p className="rgpd-infos">Vous avez un droit d’accès, de rectification sans frais, à l’effacement et à la limitation des traitements vous concernant. Par ailleurs, vous avez le droit de recevoir les données vous concernant dans un format structuré et/ou de les faire transmettre à une autre entreprise.</p>
      <p className="rgpd-infos">La Haute École reste à votre disposition pour toutes les questions, les remarques ou les plaintes à formuler concernant la protection de vos données personnelles. Si vous avez connaissance de l’existence d’une fuite de données ou si vous en suspectez une, nous vous demandons de nous la signaler immédiatement à l’adresse <a href="mailto:privacy@vinci.be">privacy@vinci.be</a></p>
      <p className="rgpd-infos">En outre, et conformément à l’article 37 du RGPD, la Haute École a procédé à la désignation d’une Data Protection Office ou délégué à la protection des données, en la personne de Madame Saba PARSA, avocate et DPO Certifiée. Vous pouvez la contacter à l’adresse mail suivante dpo@vinci.be. Enfin, vous disposez également du droit d’introduire une réclamation auprès de l’Autorité de Protection des Données (APD) à l’adresse suivante : rue de la Presse 35 - 1000 Bruxelles - téléphone 02/274.48.00 - ou via leur site internet : <a href="https://www.autoriteprotectiondonnees.be" target="_blank" rel="noreferrer">https://www.autoriteprotectiondonnees.be</a></p>
    </>
  )

}

export default Profile