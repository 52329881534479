import { msalInstance, graphScopes } from "../misc/authConfig"

// API_URL fetched from environment
export const GRAPH_API_ROOT = process.env.REACT_APP_GRAPH_API_URL



// Fetches an API response
export const callGraph = async (options) => {

	// Check if array of calls
	if (options instanceof Array) {
		return Promise.all(options.map((item) => callGraph(item)))
	}
	// Check endpoint
	const endpoint = options.endpoint;
	if (typeof endpoint !== 'string') {
		throw new Error('Specify a string endpoint URL.')
	}
	// Make full url
	const fullUrl = new URL((endpoint.indexOf(GRAPH_API_ROOT) === -1) ? GRAPH_API_ROOT + endpoint : endpoint)
	// Method
	options.method = options.method || 'GET'
	// Headers
	if (!options.headers) {
		options.headers = {}
	}
	// Body
	if (options.body) {
		options.body = JSON.stringify(options.body)
		options.headers['Content-Type'] = 'application/json'
	}
	// Token
	const { accessToken } = await msalInstance.acquireTokenSilent({ scopes: graphScopes })
	options.headers["Authorization"] = `Bearer ${accessToken}`
	// Add url parameters
	if (options.method.toUpperCase() === 'GET' && options.params) {
		Object.keys(options.params).forEach(key => fullUrl.searchParams.append(key, options.params[key]))
	}
	// Fetch
	return fetch(fullUrl.toString(), options)
		.then(response => {
			const contentType = response.headers.get("content-type");
			if (contentType && contentType.indexOf('application/json') !== -1)
				return response.json()
			return response.blob()
		})
		.catch(console.error)
}