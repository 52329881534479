import React, { useEffect, useState } from 'react'

import { Link, useHistory } from 'react-router-dom'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import { address, filters, sorters } from 'misc/utils'

import { PageHeader, Table, Button, Card, Input } from 'antd'

const { Column } = Table



const Sites = () => {

  const [sites, setSites] = useState([])
  const [filteredSites, setFilteredSites] = useState([])
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  useEffect(() => {
    api.sites.get({ loader: setLoading }).then(data => {
      setSites(data)
      setFilteredSites(data)
    }).catch(console.error)
  }, [])

  const onRowHandlers = (record, rowIndex) => ({
    onClick: e => history.push(`/admin/site/${record.id}`)
  })

  const onSearch = (e) => {
    if (e.target.value) {
      setFilteredSites(sites.filter(filters.string(['name'], e.target.value)))
    } else {
      setFilteredSites(sites)
    }
  }

  return (
    <>
      <PageHeader
        title={<><Icon icon="map-marker-alt" fixedWidth /> Lieux de formation</>}
        extra={[
          <Link to="/admin/site" key="1" ><Button icon={<Icon icon="plus" />}>Ajouter un site</Button></Link>,
        ]}
      />
      <Card>
        <Input.Search style={{ marginBottom: 15, maxWidth: 350 }} placeholder="Rechercher..." onChange={onSearch} allowClear />
        <Table dataSource={filteredSites} loading={loading} onRow={onRowHandlers} rowKey="id" rowClassName="row-clickable" size="small">
          <Column title="Nom" dataIndex="name" key="name" sorter={sorters.string('name')} />
          <Column title="Description" dataIndex="description" key="description" sorter={sorters.string('description')} />
          <Column title="Adresse" key="address" render={(t, site) => address(site)} />
        </Table>
      </Card>
    </>
  )

}

export default Sites