import React, { useEffect, useState } from 'react'

import './Trainings.less'

import { DateTime } from 'luxon'
import { useHistory } from 'react-router-dom'

import api from 'services/userApiService'
import Icon from 'components/Icon'
import Loader from 'components/Loader'
import Maintenance from 'user-app/Maintenance'
import { REGISTRATION_MAINTENANCE, } from 'env'
import { isoDateToDateShort } from 'misc/utils'
import { Layout, Row, Col, Card, Tag } from 'antd'

import logoFC from 'static/images/VINCI_FC_logo.png'
import logoVinci from 'static/images/VINCI_logo_white.png'
import logoNeoxis from 'static/images/NEOXIS_logo_inline_white.png'
import logo_background from 'static/images/VINCI_logo_background.svg'
import logo_background2 from 'static/images/VINCI_FC_background_logo.svg'

const { Content, Footer } = Layout

const { REACT_APP_VINCI_URL } = process.env



const Trainings = () => {

  const [trainings, setTrainings] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  useEffect(() => {
    api.get('/trainings', { loader: setIsLoading }).then(setTrainings).catch(console.error)
  }, [])

  // Maintenance mode
  if (REGISTRATION_MAINTENANCE) return <Maintenance />

  // Loading
  if (isLoading) return <Loader />

  // Registration screen
  return (
    <Layout className="trainings-layout">
      <div className="modal-layout-background" >
        <img src={logo_background} alt="Logo FC arrière plan" />
        <img src={logo_background2} alt="Logo FC arrière plan" />
      </div>
      <Layout>
        <div className="header">
          <Row align="middle" className="header-blue">
            <Col>
              <img src={logoFC} alt="Logo formation continue" />
            </Col>
            <Col flex="auto" >
              <h2>Catalogue des formations</h2>
              <h3>Choisissez une formation à laquelle vous inscrire</h3>
            </Col>
          </Row>
          <div className="header-border">
            <div style={{ backgroundColor: "#E7276D" }} />
            <div style={{ backgroundColor: "#F8AA00" }} />
            <div style={{ backgroundColor: "#009670" }} />
          </div>
        </div>

        <Content>
          <Row gutter={[16, 16]}>
            {trainings.map(training => <Col key={training.id}>
              <Card
                className="registration-card"
                actions={training.status === 'cancelled' || training.registrationsStatus !== 'open'
                  ? [<div onClick={() => window.open(`${REACT_APP_VINCI_URL}?t=${training.code}`, '_blank')}><Icon icon="eye" /><br />Détails</div>]
                  : [
                    <div onClick={() => history.push(`/register/${training.id}`)}><Icon icon="user-edit" /><br />Je m'inscris !</div>,
                    <div onClick={() => window.open(`${REACT_APP_VINCI_URL}?t=${training.code}`, '_blank')}><Icon icon="eye" /><br />Détails</div>
                  ]}
              >
                <Card.Meta
                  title={training.name}
                  description={<>
                    <p>
                      {training.code}<br />
                      {training.start ? `${isoDateToDateShort(training.start)} - ${isoDateToDateShort(training.end)}` : "Pas encore programmée."}<br />
                    </p>
                    {training.status === 'cancelled' && <p><Tag color="volcano">Formation Annulée !</Tag></p>}
                    {training.status !== 'cancelled'
                      && training.registrationsStatus === 'closed'
                      && <p><Tag color="gold">Inscriptions {DateTime.fromISO(training.start) < DateTime.now() ? 'terminées' : 'fermées'}</Tag></p>}
                    {training.status !== 'cancelled'
                      && training.registrationsStatus !== 'closed'
                      && training.registrationsStatus !== 'open'
                      && <p><Tag color="gold">Ouverture des inscriptions le {isoDateToDateShort(training.registrationsStatus)}</Tag></p>}
                  </>}
                />
              </Card>
            </Col>)}
          </Row>
        </Content>

        <Footer>
          <Row align="bottom">
            <Col className="logo-vinci" span={6}>
              <a href="https://www.vinci.be" target="_blank" rel="noreferrer"><img src={logoVinci} alt="Logo Vinci" /></a>
            </Col>
            <Col className="copyright" span={12}>
              &copy; {new Date().getFullYear()} - Développé par <a href="https://www.neoxis.be" target="_blank" rel="noreferrer"><img src={logoNeoxis} alt="Neoxis logo" /></a>
            </Col>
            <Col className="links" span={6}>
              <div>
                <a href="https://www.facebook.com/formationcontinuevinci/" target="_blank" rel="noreferrer"><Icon icon="fab facebook" /> Facebook</a>
                <a href="https://www.linkedin.com/showcase/centre-de-formation-continue-vinci/" target="_blank" rel="noreferrer"><Icon icon="fab linkedin" /> Linkedin</a>
              </div>
            </Col>
          </Row>
        </Footer>

      </Layout>
    </Layout>
  )
}

export default Trainings