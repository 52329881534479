import React, { useEffect, useState } from 'react'

import api from 'services/adminApiService'
import Icon from 'components/Icon'
import SessionsCard from './SessionsCard'

import { Button, Card, Form, Input, message, Row, Col, Descriptions, Space } from 'antd'
import {isoDateToDateShort, nfEur} from "../../misc/utils";
import StatusCard from "./StatusCard";



const Info = ({ training, setTraining }) => {

  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()

  // Put form values when refreshing training
  useEffect(() => {
    form.setFieldsValue(training)
  }, [training, form])

  const saveTraining = values => {
    api.trainings.put(training.id, values, { loader: setSaving }).then(data => {
      setTraining(data)
      message.success("Modifications sauvegardées.")
    }).catch(console.error)
  }

  return (
    <>
      <Row gutter={16}>

        <Col span={training?.sites && training?.trainers ? 16 : 24}>
          <Card title={<><Icon icon="info-circle" /> Informations générales</>}>
            <Form form={form} onFinish={saveTraining} layout="vertical">
              <Descriptions bordered size="small" column={1}>
                <Descriptions.Item label="Code">{training.code}</Descriptions.Item>
                <Descriptions.Item label="Nom">{training.name}</Descriptions.Item>
                <Descriptions.Item label="Date de début">{isoDateToDateShort(training.start)}</Descriptions.Item>
                <Descriptions.Item label="Date de fin">{isoDateToDateShort(training.end)}</Descriptions.Item>
                <Descriptions.Item label="Catégories">{training.categories?.map(c => c.name).join(', ')}</Descriptions.Item>
                <Descriptions.Item label="Acompte">{nfEur(training.deposit)}</Descriptions.Item>
                <Descriptions.Item label="Prix général">{nfEur(training.price)}</Descriptions.Item>
                <Descriptions.Item label="Prix réduit">{nfEur(training.reducedPrice)}</Descriptions.Item>
                <Descriptions.Item label="Code analytique - Module">
                  <Space.Compact size="small">
                    <Form.Item name="invoicingTrainingCode" noStyle>
                      <Input placeholder="Code analytique - Module" />
                    </Form.Item>
                    <Button loading={saving} type="primary" htmlType="submit" icon={<Icon icon="save" />} style={{width: 30}}></Button>
                  </Space.Compact>
                </Descriptions.Item>
                <Descriptions.Item label="Code analytique - Année académique">
                  <Space.Compact size="small">
                    <Form.Item name="invoicingYearCode" noStyle>
                      <Input placeholder="Code analytique - Année académique" />
                    </Form.Item>
                    <Button loading={saving} type="primary" htmlType="submit" icon={<Icon icon="save" />} style={{width: 30}}></Button>
                  </Space.Compact>
                </Descriptions.Item>
              </Descriptions>
            </Form>
          </Card>
        </Col>

        {training?.sites && training?.trainers &&
          <Col span={8}>
            <StatusCard training={training} />
            <SessionsCard training={training} style={{ marginTop: 16 }} />
          </Col>
        }

      </Row>

    </>
  )

}

export default Info