import React, { useState } from 'react'

import { useLocation } from 'react-router-dom'

import { DateTime } from "luxon"

import api from 'services/userApiService'
import Icon from 'components/Icon'

import { Button, Col, Descriptions, Divider, Form, Input, Row, Switch } from 'antd'



const Training = ({ training, registration, setRegistration, prev, next }) => {

  const [isLoading, setIsLoading] = useState(false)

  const code = new URLSearchParams(useLocation().search).get("code")

  const onFinishForm = (values) => {
    api.post('/registrations', { ...values, ...registration, trainingId: training.id, registerCode: code }, { loader: setIsLoading })
      .then(registration => {
        setRegistration(registration)
        next()
      })
      .catch(console.error)
  }

  const eventToString = event => (
    <span>{DateTime.fromISO(event.start).toFormat("ccc D '- De 'T")} à {DateTime.fromISO(event.end).toFormat("T")}</span>
  )

  const reducedPriceConditions = `Octroyé aux diplômés HE Vinci et membres de la communauté de la HE Vinci${training.reducedPriceConditions ? ", ainsi qu'aux " + training.reducedPriceConditions + '.' : '.'}`

  return (
    <>
      <h1>Votre inscription :</h1>
      <Descriptions bordered size="small" column={1}>
        <Descriptions.Item label="Formation">{training.name}</Descriptions.Item>
        <Descriptions.Item label="Lieu">{
          training.sites.length === 1
            ? training.sites[0].site.name
            : <ul style={{ margin: 0, paddingLeft: 15 }}>{training.sites.map(site => <li key={site.siteId}>{site.site.name}</li>)}</ul>
        }</Descriptions.Item>
        <Descriptions.Item label="Horaire">{
          training.events.length === 1
            ? eventToString(training.events[0])
            : <ul style={{ margin: 0, paddingLeft: 15 }}>{training.events.map(event =>
              <li key={event.id}>{eventToString(event)}</li>
            )}</ul>
        }</Descriptions.Item>
        <Descriptions.Item label="Conditions d'inscription">{training.conditions}</Descriptions.Item>
        <Descriptions.Item label="Matériel">{training.requirements}</Descriptions.Item>
        <Descriptions.Item label="Prix">
          Acompte : {training.deposit} € <span className="sub-title-light" style={{ marginLeft: 20 }}>(doit être payé pour valider l'inscription)</span>
          <br />
          Prix de la formation : {training.price} € <span className="sub-title-light" style={{ marginLeft: 20 }}>(à régler au plus tard 20 jours avant le début de la formation)</span>
          <br />
          Prix réduit : {training.reducedPrice} € <Icon.Info tooltip={reducedPriceConditions} />
        </Descriptions.Item>
      </Descriptions>
      <Form style={{ marginTop: 25 }} onFinish={onFinishForm} className="register-training-form" colon={false}>

        <Form.Item name="hasReducedPrice" label={<>Bénéficiez-vous du prix réduit ? {reducedPriceConditions}</>} valuePropName="checked">
          <Switch checkedChildren="Oui" unCheckedChildren="Non" />
        </Form.Item>
        {training.isCEP && <Form.Item name="isCEP" label="Cette formation est aggréée CEP. Êtes-vous bénéficiaire ?" valuePropName="checked">
          <Switch checkedChildren="Oui" unCheckedChildren="Non" />
        </Form.Item>}
        {training.isPQK && <>
          <Form.Item name="isPQK" label="Cette formation est aggréée PQK. Êtes-vous affilié ?" valuePropName="checked" >
            <Switch checkedChildren="Oui" unCheckedChildren="Non" />
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, curr) => prev.isPQK !== curr.isPQK}>
            {({ getFieldValue }) => getFieldValue('isPQK') &&
              <Form.Item name="inamiNumber" label="Votre numéro INAMI" rules={[
                { required: true, message: 'Veuillez entrer votre numéro INAMI.' },
                { pattern: /^\d\/\d{5}\/\d\d\/\d{3}$/, message: "Veuillez respecter le format 0/00000/00/000" }
              ]}>
                <Input style={{ marginLeft: 10, maxWidth: 150 }} />
              </Form.Item>
            }
          </Form.Item>
        </>}
        <Form.Item name="isStudent" label="Je serai encore étudiant au moment de la formation." valuePropName="checked">
          <Switch checkedChildren="Oui" unCheckedChildren="Non" />
        </Form.Item>
        <Form.Item
          name="confirmConditions"
          label="Je confirme que je respecte bien les conditions d'inscription décrites ci-dessus et peut en apporter la preuve."
          valuePropName="checked"
          rules={[{ validator: async (_, value) => { if (!value) throw new Error("Veuillez confirmer que vous respectez bien les conditions d'inscription.") } }]}
        >
          <Switch checkedChildren="Oui" unCheckedChildren="Non" />
        </Form.Item>

        <Divider />

        <p className="sub-title-light">En envoyant ce formulaire, votre inscription sera en attente de d'acceptation. Une fois acceptée, vous recevrez la facture et aurez la possibilité de payer l'acompte, ce qui validera votre inscription.</p>

        <Row justify="space-between" style={{ marginTop: 30 }}>
          <Col><Button icon={<Icon icon="arrow-left" />} onClick={prev}>Prédécent</Button></Col>
          <Col><Button loading={isLoading} type="primary" htmlType="submit" icon={<Icon icon="check" />}>Je m'inscris !</Button></Col>
        </Row>

      </Form>
    </>
  )

}

export default Training