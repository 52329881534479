import React, { useContext, useEffect, useMemo, useState } from 'react'

import { useParams } from 'react-router-dom'
import { sortBy, clone } from 'lodash'

import api from 'services/adminApiService'
import Icon from 'components/Icon'
import { UserTypes } from 'components/Tags'
import RegistrationsList from 'compta-app/RegistrationsList/RegistrationsList'
import Profile from './Profile'
import RecentsContext from 'contexts/RecentsContext'

import {Badge, Button, PageHeader, Spin, Card, Row, Col} from 'antd'




const User = () => {

  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(false)

  // Current user
  // @ts-ignore
  const { id} = useParams()

  const { addRecent } = useContext(RecentsContext)

  const loadUser = () => {
    if (id) api.users.getById(id, { loader: setLoading }).then(setUser).catch(console.error)
  }

  useEffect(loadUser, [id])

  useEffect(() => {
    if (user) addRecent(user, 'user')
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  const registrations = useMemo(() => {
    const registrations = sortBy(user?.registrations || [], 'start').map(r => clone(r))
    registrations.forEach(r => r.user = user)
    return registrations
  }, [user])

  // Training loading
  if (!user) {
    return <div><Spin size="large" /></div>
  }

  // Existing user view
  return (
    <>
      <PageHeader
        title={<><Icon icon="user" fixedWidth /> Utilisateur : {user.lastname} {user.firstname}</>}
        subTitle={user.clientNumber}
        tags={<UserTypes user={user} />}
        extra={<Button icon={<Icon icon="sync" spin={loading} />} onClick={loadUser} />}
      />

      <Spin delay={500} spinning={loading}>
        <Row gutter={16}>
          <Col span={24}>
            <Profile user={user} setUser={setUser} />
            <Card title={<><Icon icon="file-signature" />Inscriptions <Badge count={user.registrations.length || 0} style={{ backgroundColor: "#1890ff" }} showZero /></>} style={{marginTop: 16}}>
              <RegistrationsList
                registrations={registrations}
                refresh={loadUser}
                from='user'
              />
            </Card>
          </Col>
        </Row>
      </Spin>

    </>
  )

}

export default User


