import React, { useState, useEffect, useContext } from 'react'

import { Link, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { useMsal } from "@azure/msal-react"

import './App.less'

import Icon from 'components/Icon'

import { callGraph } from "services/graphService"

import ChecksToBlock from 'admin-app/Payments/ChecksToBlock'
import ImportPayments from 'admin-app/Payments/ImportPayments'
import Inbox from 'admin-app/Inbox'
import NotFound from 'components/NotFound'
import Participants from 'admin-app/Users/Participants'
import PaymentsToApply from 'admin-app/Payments/PaymentsToApply'
import Refunds from 'admin-app/Payments/Refunds'
import SendMail from 'admin-app/SendMail'
import Site from 'admin-app/Settings/Site'
import Sites from 'admin-app/Settings/Sites'
import SodexoInvoicing from 'admin-app/Payments/SodexoInvoicing'
import Tasks from 'admin-app/Tasks'
import Trainers from 'admin-app/Users/Trainers'
import TrainersPayments from 'admin-app/Payments/TrainersPayments'
import Trainings from 'admin-app/Trainings/Trainings'
import Training from 'admin-app/Training/Training'
import User from 'admin-app/User/User'
import EmailTemplates from 'admin-app/Settings/EmailTemplates'
import EmailTemplate from 'admin-app/Settings/EmailTemplate'
import Signatures from 'admin-app/Settings/Signatures'
import Users from 'admin-app/Users/Users'
import Categories from 'admin-app/Settings/Categories'
import InviteTrainer from 'admin-app/Users/InviteTrainer'
import Partners from 'admin-app/Settings/Partners'
import RecentsContext from 'contexts/RecentsContext'
import {cleanPathname, getAppPath, filterOnSelectLabel, isUserCompta, isUserGestion} from 'misc/utils'
import SelectsContext from 'contexts/SelectsContext'
import Registrations from 'admin-app/Registrations'
import CFPayments from 'admin-app/Payments/CFPayments'
import Reports from 'admin-app/Reports'
import Misc from 'admin-app/Settings/Misc'
import SodexoForemEncoding from 'admin-app/Payments/SodexoForemEncoding'
import Invoices from "admin-app/Invoices"

// Compta components
import TrainingsCompta from 'compta-app/Trainings'
import ReportsCompta from 'compta-app/Reports'
import TrainingCompta from 'compta-app/Training/Training'
import TrainingsAnalyticCodes from 'compta-app/TrainingsAnalyticCodes'
import RegistrationsCompta from 'compta-app/Registrations'
import ParticipantsCompta from 'compta-app/Participants'
import UserCompta from 'compta-app/User/User'
import InvoicesCompta from "compta-app/Invoices"
import Payments from "components/Payments"
import ExportsAdfinity from "compta-app/ExportsAdfinity"

import neoxisLogo from 'static/images/NEOXIS_logo.png'
import neoxisLogoInline from 'static/images/NEOXIS_logo_inline.png'
import vinciLogo from 'static/images/VINCI_FC_logo.png'

import { Avatar, Button, Dropdown, Layout, Menu, Modal, Select } from 'antd'

const { SubMenu } = Menu
const { Header, Content, Sider, Footer } = Layout

const {
  REACT_APP_VERSION,
  REACT_APP_CI_BRANCH_TAG,
  REACT_APP_CI_SHA,
  REACT_APP_CI_ENVIRONMENT,
  REACT_APP_API_URL
} = process.env



const App = () => {

  const [collapsed, setCollapsed] = useState(false)

  const { instance } = useMsal()

  const history = useHistory()
  const location = useLocation()
  const pathname = cleanPathname(location.pathname)
  const appPath = getAppPath(location.pathname)

  const { recents, clearRecents } = useContext(RecentsContext)

  const { globalSearchOptions } = useContext(SelectsContext)

  const handleUserMenuClick = e => {
    switch (e.key) {
      case 'logout':
        instance.logout()
        break
      default:
        console.warn('Unknown menu key: ' + e.key)
    }
  }

  const handleSiderMenuClick = e => history.push(e.key)

  const [user, setUser] = useState({})
  const [photo, setPhoto] = useState(null)

  useEffect(() => {
    callGraph({ endpoint: '/me' }).then(response => setUser(response))
    callGraph({ endpoint: '/me/photo/$value' }).then(response => setPhoto(URL.createObjectURL(response)))
  }, [])

  const info = () => {
    Modal.info({
      icon: <Icon icon="info-circle" />,
      title: 'A propos',
      closable: true,
      maskClosable: true,
      className: "info-modal",
      content: (
        <div>
          <p>Application dévelopée par</p>
          <a href="https://www.neoxis.be" target="_blank" rel="noreferrer"><img src={neoxisLogo} alt="Neoxis Logo" /></a>
          <p>pour le Centre de Formation Continue de la Haute Ecole Léonard de Vinci.</p>
          <p>
            Version : {REACT_APP_VERSION}
            <br />
            Build : {REACT_APP_CI_BRANCH_TAG + '-' + REACT_APP_CI_SHA + '-' + REACT_APP_CI_ENVIRONMENT}
            <br />
            API : {REACT_APP_API_URL}
          </p>
          <p>&copy; {new Date().getFullYear()}</p>
        </div>
      )
    })
  }

  const getRecentMenuItem = item => {
    switch (item.type) {
      case 'training': return <Menu.Item icon={<Icon icon="graduation-cap" fixedWidth />} key={`${appPath}/training/${item.id}`}>{item.code}</Menu.Item>
      case 'user': return <Menu.Item icon={<Icon icon="user" fixedWidth />} key={`${appPath}/user/${item.id}`}>{item.lastname} {item.firstname}</Menu.Item>
      default: return null
    }
  }

  // For searchbox
  const onSelect = value => {
    if (value.startsWith('P') || value.startsWith('F')) history.push(`${appPath}/user/${value.substring(1)}`)
    if (value.startsWith('T')) history.push(`${appPath}/training/${value.substring(1)}`)
  }

  return (
    <Layout className="app-layout">

      <Sider collapsed={collapsed}>

        <div className="sider-header">
          <div className="sider-collapse-btn" onClick={() => setCollapsed(!collapsed)}><Icon icon="bars" size="lg" /></div>
          <Link to={appPath}>
            <img src={vinciLogo} alt="Logo Vinci CFC" />
          </Link>
        </div>

        <div className="sider-content">
          <Menu mode="inline" theme="dark" onClick={handleSiderMenuClick} selectedKeys={[pathname]}>
            {recents.length ? <>
              <Menu.ItemGroup
                key="recents"
                title={<>Élements récents<Button icon={<Icon icon="times" />} size="small" type="link" onClick={clearRecents} /></>}
                className="recents-menu"
              >
                {recents.map(getRecentMenuItem)}
              </Menu.ItemGroup>
              <Menu.Divider className="recents-menu-divider" />
            </> : null}
            {isUserGestion(instance) && <>
              <Menu.Item icon={<Icon icon="book-open" fixedWidth />} key="/admin/trainings">Catalogue de formation</Menu.Item>
              <Menu.Item icon={<Icon icon="file-signature" fixedWidth />} key="/admin/registrations">Inscriptions</Menu.Item>
              <Menu.Item icon={<Icon icon="users-class" fixedWidth />} key="/admin/participants">Participants</Menu.Item>
              <Menu.Item icon={<Icon icon="chalkboard-teacher" fixedWidth />} key="/admin/trainers">Formateurs</Menu.Item>
              <Menu.Item icon={<Icon icon="paper-plane" fixedWidth />} key="/admin/send-mail">Envoyer un mail</Menu.Item>
              <SubMenu key="gestion-paiements" icon={<Icon icon="credit-card" fixedWidth />} title="Gestion des paiements">
                <Menu.Item icon={<Icon icon="list-alt" fixedWidth />} key="/admin/payments">Liste des paiements</Menu.Item>
                <Menu.Item icon={<Icon icon="money-check-edit-alt" fixedWidth />} key="/admin/refunds">Remboursements</Menu.Item>
                <Menu.Item icon={<Icon icon="file-import" fixedWidth />} key="/admin/import-payments">Importer des paiements</Menu.Item>
                <Menu.Item icon={<Icon icon="file-check" fixedWidth />} key="/admin/payments-to-apply">Paiements à lettrer</Menu.Item>
                <Menu.Item icon={<Icon icon="shield-check" fixedWidth />} key="/admin/checks-to-block">Chèques form. à bloquer</Menu.Item>
                <Menu.Item icon={<Icon icon="file-invoice-dollar" fixedWidth />} key="/admin/sodexo-invoicing">Facturation Sodexo</Menu.Item>
                <Menu.Item icon={<Icon icon="hand-holding-usd" fixedWidth />} key="/admin/trainers-payments">Paiement des formateurs</Menu.Item>
                <Menu.Item icon={<Icon icon="list" fixedWidth />} key="/admin/cf-payments">Paiement CF</Menu.Item>
                <Menu.Item icon={<Icon icon="edit" fixedWidth />} key="/admin/forem-encoding">Encodage Forem</Menu.Item>
              </SubMenu>
              <Menu.Item icon={<Icon icon="file-invoice-dollar" fixedWidth />} key="/admin/invoices">Factures et notes de crédit</Menu.Item>
              <Menu.Item icon={<Icon icon="file-alt" fixedWidth />} key="/admin/reports">Rapports</Menu.Item>
              <SubMenu key="configuration" icon={<Icon icon="cogs" fixedWidth />} title="Paramètres">
                <Menu.Item icon={<Icon icon="envelope-open-text" fixedWidth />} key="/admin/email-templates">Templates d'e-mails</Menu.Item>
                <Menu.Item icon={<Icon icon="signature" fixedWidth />} key="/admin/email-signatures">Signatures d'e-mails</Menu.Item>
                <Menu.Item icon={<Icon icon="map-marker-alt" fixedWidth />} key="/admin/sites">Lieux de formation</Menu.Item>
                <Menu.Item icon={<Icon icon="tags" fixedWidth />} key="/admin/training-categories">Catégories de formation</Menu.Item>
                <Menu.Item icon={<Icon icon="handshake" fixedWidth />} key="/admin/partners">Partenaires de formation</Menu.Item>
                <Menu.Item icon={<Icon icon="lightbulb-on" fixedWidth />} key="/admin/misc">Divers</Menu.Item>
              </SubMenu>
            </>}
            {isUserCompta(instance) && <>
              <Menu.Item icon={<Icon icon="book-open" fixedWidth />} key="/compta/trainings">Catalogue de formation</Menu.Item>
              <Menu.Item icon={<Icon icon="chart-line" fixedWidth />} key="/compta/analytic-codes">Code analytiques</Menu.Item>
              <Menu.Item icon={<Icon icon="file-signature" fixedWidth />} key="/compta/registrations">Inscriptions</Menu.Item>
              <Menu.Item icon={<Icon icon="users-class" fixedWidth />} key="/compta/participants">Participants</Menu.Item>
              <Menu.Item icon={<Icon icon="file-invoice-dollar" fixedWidth />} key="/compta/invoices">Factures et notes de crédit</Menu.Item>
              <Menu.Item icon={<Icon icon="credit-card" fixedWidth />} key="/compta/payments">Paiements</Menu.Item>
              <Menu.Item icon={<Icon icon="file-export" fixedWidth />} key="/compta/exports-adfinity">Exports Adfinity</Menu.Item>
              <Menu.Item icon={<Icon icon="file-alt" fixedWidth />} key="/compta/reports">Rapports</Menu.Item>
            </>}
          </Menu>
        </div>

      </Sider>

      <Layout>

        <Header className={REACT_APP_CI_ENVIRONMENT !== 'prod' ? 'test-env' : ''}>
          <div className="search-menu">
            <Icon icon="search" size="lg" />
            <Select
              showSearch={true}
              placeholder="Rechercher..."
              size="large"
              options={globalSearchOptions}
              popupClassName="search-menu-dropdown"
              filterOption={filterOnSelectLabel}
              onSelect={onSelect}
              value={null}
              showArrow={false}
            />
          </div>
          <span className='env-message'>Version de {REACT_APP_CI_ENVIRONMENT?.toUpperCase()} !</span>
          <Dropdown className="user-menu" trigger={['hover']} placement="bottomRight" arrow={true} menu={{ items: [{ key: 'logout', label: 'Se déconnecter' }], onClick: handleUserMenuClick }}>
            <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              <Avatar draggable={false} size={40} src={photo} icon={<Icon icon="user" fixedWidth />} />{user?.displayName} <Icon icon="chevron-down" size="sm" />
            </span>
          </Dropdown>
        </Header>

        <Content>
          <div className="main-content-wrapper">
            <Switch location={location}>
              {!isUserGestion(instance) && <Route path='/admin'><Redirect to='/compta' /></Route>}
              {!isUserCompta(instance) && <Route path='/compta'><Redirect to='/admin' /></Route>}
              <Route path='/admin' exact><Redirect to='/admin/trainings' /></Route>
              <Route path='/admin/trainings/:tab?'><Trainings /></Route>
              <Route path='/admin/training' exact><Training /></Route>
              <Route path='/admin/registrations/:tab?'><Registrations /></Route>
              <Route path='/admin/registrations' exact><Registrations /></Route>
              <Route path='/admin/training/:id/:tab?'><Training /></Route>
              <Route path='/admin/inbox'><Inbox /></Route>
              <Route path='/admin/send-mail'><SendMail /></Route>
              <Route path='/admin/sites' exact><Sites /></Route>
              <Route path='/admin/email-templates' exact><EmailTemplates /></Route>
              <Route path='/admin/email-template' exact><EmailTemplate /></Route>
              <Route path='/admin/email-template/:id' exact><EmailTemplate /></Route>
              <Route path='/admin/email-signatures' exact><Signatures /></Route>
              <Route path='/admin/partners' exact><Partners /></Route>
              <Route path='/admin/training-categories' exact><Categories /></Route>
              <Route path='/admin/site' exact><Site /></Route>
              <Route path='/admin/site/:id'><Site /></Route>
              <Route path='/admin/participants'><Participants /></Route>
              <Route path='/admin/trainers/invite'><InviteTrainer /></Route>
              <Route path='/admin/trainers'><Trainers /></Route>
              <Route path='/admin/users'><Users /></Route>
              <Route path='/admin/user/:id/:tab?'><User /></Route>
              <Route path='/admin/invoices'><Invoices /></Route>
              <Route path='/admin/payments'><Payments /></Route>
              <Route path='/admin/refunds/:tab?'><Refunds /></Route>
              <Route path='/admin/import-payments'><ImportPayments /></Route>
              <Route path='/admin/payments-to-apply'><PaymentsToApply /></Route>
              <Route path='/admin/checks-to-block'><ChecksToBlock /></Route>
              <Route path='/admin/cf-payments'><CFPayments /></Route>
              <Route path='/admin/forem-encoding/:tab?'><SodexoForemEncoding /></Route>
              <Route path='/admin/sodexo-invoicing/:tab?'><SodexoInvoicing /></Route>
              <Route path='/admin/trainers-payments'><TrainersPayments /></Route>
              <Route path='/admin/reports'><Reports /></Route>
              <Route path='/admin/tasks'><Tasks /></Route>
              <Route path='/admin/misc'><Misc /></Route>
              <Route path='/compta' exact><Redirect to='/compta/trainings' /></Route>
              <Route path='/compta/trainings/:tab?'><TrainingsCompta /></Route>
              <Route path='/compta/training/:id/:tab?'><TrainingCompta /></Route>
              <Route path='/compta/analytic-codes/:tab?'><TrainingsAnalyticCodes /></Route>
              <Route path='/compta/invoices/:tab?'><InvoicesCompta /></Route>
              <Route path='/compta/registrations/:tab?'><RegistrationsCompta /></Route>
              <Route path='/compta/participants'><ParticipantsCompta /></Route>
              <Route path='/compta/user/:id/:tab?'><UserCompta /></Route>
              <Route path='/compta/payments'><Payments /></Route>
              <Route path='/compta/exports-adfinity'><ExportsAdfinity /></Route>
              <Route path='/compta/reports'><ReportsCompta /></Route>
              <Route path='*'><NotFound /></Route>
            </Switch>
          </div>
          <Footer>
            &copy; {new Date().getFullYear()} - Application développée par <a href="https://www.neoxis.be" target="_blank" rel="noreferrer"><img src={neoxisLogoInline} alt="Neoxis logo" /></a>
            <Button shape="circle" icon={<Icon icon="info" />} size="small" style={{ float: 'right' }} onClick={info} />
          </Footer>
        </Content>

      </Layout>

    </Layout>
  )
}

export default App