import React, { useContext, useEffect, useState } from 'react'

import { useCounter } from 'react-use'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import { nfEur } from 'misc/utils'
import { RegistrationCancelStatus } from 'components/Tags'
import SelectsContext from 'contexts/SelectsContext'
import { deserialize, serialize } from 'misc/slateSerialize'
import RichTextEditor from 'components/RichTextEditor'
import DraggableModal from 'components/DraggableModal'

import { Alert, Button, Col, Descriptions, Divider, Form, Input, InputNumber, Row, Select, Steps } from 'antd'


const CancelRegistrationModal = ({ registration, isVisible, onOK, onCancel }) => {

  const [isLoading, setIsLoading] = useState(false)
  const [step, { inc: nextStep, dec: prevStep, reset: resetStep }] = useCounter(0, 2, 0)
  const [previewData, setPreviewData] = useState(null)
  const [refund, setRefund] = useState(null)

  const [form] = Form.useForm()

  const [template, setTemplate] = useState(null)

  const { getEmailByCode, signatures, emails } = useContext(SelectsContext)

  const getPreview = () => {
    api.get(`/registrations/${registration.id}/cancel-preview`, { loader: setIsLoading }).then(setPreviewData).catch(console.error)
  }

  useEffect(() => {
    if (registration && isVisible) {
      getPreview()
      resetStep()
    }
  }, [isVisible, registration]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (previewData) {
      form.setFieldsValue(previewData)
      setRefund(previewData.refund)
      setTemplate(getEmailByCode(previewData.email))
    }
  }, [previewData, form]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (template) {
      form.setFieldsValue({
        html: deserialize(template.html),
        subject: template.subject,
        signatureId: template.signatureId
      })
    }
  }, [template, form])

  const save = values => {
    values.html = serialize(values.html)
    api.registrations.delete(registration.id, { loader: setIsLoading, body: values })
      .then(onOK)
      .catch(console.error)
  }

  const onTemplateChange = code => setTemplate(getEmailByCode(code))

  const onHoldbackChange = value => setRefund(previewData.paid - value)

  return (
    <DraggableModal
      title={<><Icon icon="ban" fixedWidth /> Désistement du participant : {registration?.user.lastname} {registration?.user.firstname}</>}
      open={isVisible}
      maskClosable={false}
      onCancel={onCancel}
      footer={null}
      width={710}
      destroyOnClose
    >
      <Steps progressDot size="small" current={step}>
        <Steps.Step title="Avertissement" />
        <Steps.Step title="Proposition" />
        <Steps.Step title="Email" />
      </Steps>
      <Divider />

      <Form form={form} layout="vertical" onFinish={save}>

        {step === 0 && <>
          <Alert
            showIcon
            type="warning"
            message="Attention !"
            description={<>
              <p>Encoder un désistement est immédiat et irréversible. Ces opérations vont être effectuées :</p>
              <ul>
                <li>Les <strong>chèques formations bloqués</strong> seront marqués comme étant "à débloquer", et apparaitront dans la liste des remboursements.</li>
                <li>Les <strong>chèques formations encodés</strong> seront marqués comme "rejetés", avec une note signalant le désistement.</li>
                <li>Le <strong>montant payé sera marqué à rembourser</strong>, le cas échéant, avec une retenue que vous pourrez modifier manuellement si nécessaire.</li>
                <li>Un <strong>e-mail sera envoyé</strong> au participant pour lui signaler le désistement, et son portail sera mis à jour.</li>
              </ul>
            </>}
          />
          <Row justify="end" style={{ marginTop: 25 }}>
            <Col><Button type="primary" icon={<Icon icon="arrow-right" />} onClick={() => nextStep()}>Je comprends</Button></Col>
          </Row>
        </>}

        <div hidden={step !== 1}>
          <Descriptions bordered size="small" column={1}>
            <Descriptions.Item label="Solde global">{nfEur(registration?.balance)}</Descriptions.Item>
            <Descriptions.Item label="Solde Chèques Formation">{nfEur(previewData?.CFBalance)}</Descriptions.Item>
            <Descriptions.Item label="Accompte payé"><Icon.Boolean boolean={registration?.isDepositPaid} /></Descriptions.Item>
            <Descriptions.Item label="Montant payé (hors CF)">{nfEur(previewData?.paid)}</Descriptions.Item>
            <Descriptions.Item label="Situation du désistement"><RegistrationCancelStatus status={previewData?.email} /></Descriptions.Item>
            <Descriptions.Item label="Retenue">
              <Form.Item name="holdback" rules={[{ required: true }]} className="form-item-no-explain">
                <InputNumber min={0} max={previewData?.paid} precision={2} style={{ width: 150 }} className="input-number-euro" onChange={onHoldbackChange} />
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="Montant à rembourser">{nfEur(refund)}</Descriptions.Item>
          </Descriptions>
          <Row justify="space-between" style={{ marginTop: 25 }}>
            <Col><Button icon={<Icon icon="arrow-left" />} onClick={() => prevStep()}>Précédent</Button></Col>
            <Col><Button type="primary" icon={<Icon icon="arrow-right" />} onClick={() => nextStep()}>Continuer</Button></Col>
          </Row>
        </div>

        {step === 2 && <>
          <Form.Item name="email" label="Template d'e-mail">
            <Select options={emails?.map(item => ({ label: item.name, value: item.code }))} style={{ width: '100%' }} allowClear showSearch onChange={onTemplateChange} />
          </Form.Item>
          <Form.Item name="subject" label="Objet de l'e-mail" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="html" label="Contenu de l'e-mail" rules={[{ required: true }]}>
            <RichTextEditor />
          </Form.Item>
          <Form.Item name="signatureId" label="Signature" rules={[{ required: true }]}>
            <Select options={signatures?.map(item => ({ label: item.description, value: item.id }))} style={{ width: '100%' }} />
          </Form.Item>
          <Row justify="space-between" style={{ marginTop: 25 }}>
            <Col><Button icon={<Icon icon="arrow-left" />} onClick={() => prevStep()}>Précédent</Button></Col>
            <Col><Button type="primary" icon={<Icon icon="check" />} onClick={form.submit} loading={isLoading}>Valider le désistement</Button></Col>
          </Row>
        </>}

      </Form>

    </DraggableModal >
  )

}

export default CancelRegistrationModal