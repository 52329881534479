import React from 'react'

import Icon from 'components/Icon'
import { TrainingVisibility, RegistrationsStatus, TrainingStatus } from 'components/Tags'

import { Card, Table } from 'antd'

const { Column } = Table



const StatusCard = ({ training }) => {

  const status = [
    {
      id: 1,
      title: "Publiée sur le site",
      status: <TrainingVisibility training={training} />
    },
    {
      id: 2,
      title: "Inscriptions",
      status: <RegistrationsStatus training={training} />
    },
    {
      id: 3,
      title: "Organisation",
      status: <TrainingStatus training={training} />
    },
    {
      id: 4,
      title: "Facturation sodexo",
      status: <Icon.Boolean boolean={training.isSodexoInvoicingDone} />
    }
  ]

  return (
    <Card title={<><Icon icon="sliders-v" /> État</>}>
      <Table dataSource={status} rowKey="id" size="middle" pagination={false} showHeader={false}>
        <Column dataIndex="title" />
        <Column dataIndex="status" />
      </Table>
    </Card>
  )

}

export default StatusCard