import React, { useContext } from 'react'

import { Link, useHistory } from 'react-router-dom'

import ModalLayout from 'components/ModalLayout/ModalLayout'
import Icon from 'components/Icon'
import UserAuthContext from 'contexts/UserAuthContext'

import { Button, Checkbox, Form, Input } from 'antd'



const Login = () => {

  const history = useHistory()

  const { isLoading, login } = useContext(UserAuthContext)

  const onFinishForm = (values) => {
    login(values)
      .then(() => { history.push('/portal') })
      .catch(response => {
        if (response?.json?.error === "auth.email_not_validated") {
          history.push(`/validate-email?email=${values.email}`)
        }
      })
  }

  return (
    <ModalLayout title="Se connecter">
      <Form onFinish={onFinishForm}>
        <Form.Item name="email" rules={[{ required: true, message: 'Veuillez entrer votre adresse e-mail.' }]}>
          <Input prefix={<Icon icon="envelope" />} placeholder="E-mail" />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: 'Veuillez entrer votre mot de passe.' }]}>
          <Input.Password prefix={<Icon icon="lock" />} placeholder="Mot de passe" />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Se souvenir de moi</Checkbox>
          </Form.Item>
          <Link style={{ float: 'right' }} to="/forgot-password">Mot de passe oublié ?</Link>
        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
          <Button loading={isLoading} type="primary" htmlType="submit" icon={<Icon icon="sign-in" />}>Se connecter</Button>
        </Form.Item>
      </Form>
    </ModalLayout>
  )

}

export default Login