import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import TableButton from 'components/TableButton'
import TableLink from 'components/TableLink'
import { copyToClipboard, isoDateToDateTime } from 'misc/utils'
import { PaymentMethod, RegistrationStatus } from 'components/Tags'

import { Card, Descriptions, message, PageHeader, Space, Table, Tabs } from 'antd'

const { Column } = Table



const Refunds = () => {

  const { tab = 'todo' } = useParams()

  const history = useHistory()

  const [refunds, setRefunds] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const loadRefunds = () => {
    api.payments.get({ loader: setIsLoading, params: { type: `refunds-${tab}` } }).then(setRefunds).catch(console.error)
  }

  useEffect(loadRefunds, [tab])

  const markRefundAsDone = payment => {
    api.post(`/payments/${payment.id}/done`, {}, { loader: setIsLoading }).then(data => {
      message.success('Remboursement effecté.')
      loadRefunds()
    }).catch(console.error)
  }

  const undoneRefund = payment => {
    api.payments.put(payment.id, { isDone: false, paymentDate: null }, { loader: setIsLoading }).then(() => {
      message.success('Remboursement marqué comme "non effectué".')
      loadRefunds()
    }).catch(console.error)
  }

  const remove = payment => {
    api.payments.delete(payment.id, { loader: setIsLoading }).then(data => {
      message.success("Remboursement supprimé.")
      loadRefunds()
    }).catch(console.error)
  }

  const getCommunication = payment => {
    let communication = payment.registration.user?.invoicingClientNumber || payment.registration.user?.clientNumber || ''
    communication += ` Remb. ${payment.registration.training?.code}`
    switch (payment.registration.status) {
      case 'waiting-list': communication += " - liste d'attente"; break;
      case 'cancelled': communication += " - désistement"; break;
      default: break;
    }
    return communication
  }

  return (
    <>
      <PageHeader title={<><Icon icon="money-check-edit-alt" fixedWidth /> Remboursements</>} />

      <Tabs defaultActiveKey="current" activeKey={tab} onTabClick={key => history.push(`/admin/refunds/${key}`)} destroyInactiveTabPane={true}>

        <Tabs.TabPane tab={<><Icon icon="clipboard-list-check" />Remboursement à effectuer</>} key="todo">
          <Card>
            <Table dataSource={refunds} pagination={{ pageSize: 20 }} loading={isLoading} rowKey="id" size="small" className="row-clickable" expandable={{
              expandRowByClick: true,
              expandIconColumnIndex: -1,
              expandedRowRender: p => {
                const data = JSON.parse(p.rawBankData)
                return <>
                  <Descriptions bordered size="small">
                    <Descriptions.Item label="Raison" span={3}>{p.note}</Descriptions.Item>
                    {p.paymentCF && <>
                      <Descriptions.Item label="Numéro d'autorisation client" span={3}>{p.paymentCF.authorizationNumber}</Descriptions.Item>
                      <Descriptions.Item label="Code d'utilisation" span={3}>{p.paymentCF.usageCode}</Descriptions.Item>
                    </>}
                    {data && data.payment_date && <>
                      <Descriptions.Item label="N° d'extrait">{data.file_number}</Descriptions.Item>
                      <Descriptions.Item label="N° sur extrait">{data.number_on_file}</Descriptions.Item>
                      <Descriptions.Item label="IBAN">{data.iban}</Descriptions.Item>
                      <Descriptions.Item label="Date comptabilisation">{data.payment_date}</Descriptions.Item>
                      <Descriptions.Item label="IBAN Contrepartie">{data.counterpart_iban}</Descriptions.Item>
                      <Descriptions.Item label="Nom Contrepartie">{data.counterpart_name}</Descriptions.Item>
                      <Descriptions.Item label="Rue et numéro">{data.counterpart_street_number}</Descriptions.Item>
                      <Descriptions.Item label="CP et localité">{data.counterpart_zip_city}</Descriptions.Item>
                      <Descriptions.Item label="Communication">{data.communication}</Descriptions.Item>
                      <Descriptions.Item label="Date valeur">{data.date}</Descriptions.Item>
                      <Descriptions.Item label="Montant">{data.amount}</Descriptions.Item>
                      <Descriptions.Item label="Devise">{data.currency}</Descriptions.Item>
                      <Descriptions.Item label="Moyen">{data.payment_method}</Descriptions.Item>
                      <Descriptions.Item label="BIC">{data.bic}</Descriptions.Item>
                      <Descriptions.Item label="Pays">{data.country}</Descriptions.Item>
                      <Descriptions.Item label="Transaction">{data.transaction}</Descriptions.Item>
                    </>}
                  </Descriptions>
                </>
              }
            }}>
              <Column title="Date" render={payment => isoDateToDateTime(payment.date)} />
              <Column title="Participant" render={payment => payment.registration && <TableLink.User user={payment.registration.user} />} />
              <Column title="Formation" render={payment => payment.registration && <TableLink.Training training={payment.registration.training} />} />
              <Column title="Status d'inscription" render={payment => payment.registration && <RegistrationStatus registration={payment.registration} />} />
              <Column title="Moyen de paiement" render={payment => <PaymentMethod payment={payment} />} />
              <Column title="Montant" render={payment => `${-payment.amount} €`} />
              <Column title="N° de compte" render={payment => payment.iban || '-'} />
              <Column title="Actions" width={100} className="actions-column" render={payment => <Space>
                {!payment.paymentCF && <TableButton
                  icon="clipboard"
                  onClick={() => copyToClipboard(`${getCommunication(payment)}`, 'Communication copiée.')}
                  tooltip="Copier la communication du paiement"
                />}
                <TableButton icon="check" success onClick={() => markRefundAsDone(payment)} tooltip="Marquer comme &quot;effectué&quot;" confirmMessage={<>Êtes-vous sûr ?<br />Ceci validera le remboursement définitivement.</>} />
                <TableButton icon="trash" danger onClick={() => remove(payment)} tooltip="Supprimer le remboursement" confirmMessage={<>Êtes-vous sûr ?<br />Ceci supprimera le remboursement définitivement.</>} />
              </Space>} />
            </Table>
          </Card>
        </Tabs.TabPane>

        <Tabs.TabPane tab={<><Icon icon="check-double" />Remboursements terminés</>} key="done">
          <Card>
            <Table dataSource={refunds} loading={isLoading} rowKey="id" size="small" className="row-clickable" expandable={{
              expandRowByClick: true,
              expandIconColumnIndex: -1,
              expandedRowRender: p => <>
                <span><strong>Raison : </strong>{p.note}</span><br />
                {p.paymentCF && <><span><strong>Numéro d'autorisation client : </strong>{p.paymentCF.authorizationNumber}</span><br /></>}
                {p.paymentCF && <span><strong>Code d'utilisation : </strong>{p.paymentCF.usageCode}</span>}
              </>
            }}>
              <Column title="Date" render={payment => isoDateToDateTime(payment.date)} />
              <Column title="Participant" render={payment => payment.registration && <TableLink.User user={payment.registration.user} />} />
              <Column title="Formation" render={payment => payment.registration && <TableLink.Training training={payment.registration.training} />} />
              <Column title="Status d'inscription" render={payment => payment.registration && <RegistrationStatus registration={payment.registration} />} />
              <Column title="Moyen de paiement" render={payment => <PaymentMethod payment={payment} />} />
              <Column title="Montant" render={payment => `${-payment.amount} €`} />
              <Column title="N° de compte" render={payment => payment.iban || '-'} />
              <Column title="Actions" width={100} className="actions-column" render={payment => <Space>
                <TableButton icon="undo" onClick={() => undoneRefund(payment)} tooltip="Marquer comme &nbsp;à effectuer&nbsp;" confirmMessage={<>Êtes-vous sûr ?<br />Ceci déplacera le remboursement dans la liste des remboursements à effectuer.</>} />
              </Space>} />
            </Table>
          </Card>
        </Tabs.TabPane>

      </Tabs>
    </>
  )

}

export default Refunds