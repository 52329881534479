import React, { useContext, useState } from 'react'

import { flatten, uniqBy, groupBy } from "lodash"

import api from 'services/adminApiService'
import SelectsContext from 'contexts/SelectsContext'
import Icon from 'components/Icon'
import { filterOnSelectLabel } from 'misc/utils'
import DraggableModal from 'components/DraggableModal'

import { Button, Card, Form, Input, Row, Col, Table, Popconfirm, Select, message } from 'antd'


const { Column } = Table


const SitesCard = ({ training, refreshTraining, style = {} }) => {

  // Modal
  const [modalStatus, setModalStatus] = useState({ visible: false, site: null })
  const [form] = Form.useForm()
  const hideModal = () => setModalStatus({ visible: false, site: null })

  // Get sites for select
  const { sites, getSitesWithout } = useContext(SelectsContext)
  const sitesToAdd = getSitesWithout(training?.sites.map(t => t.site))

  // On site click
  const onRowHandlers = (record, rowIndex) => ({
    onClick: e => {
      form.setFieldsValue(record)
      setModalStatus({ visible: true, site: record })
    }
  })

  const saveSite = () => {
    form.validateFields().then((values) => {
      if (modalStatus.site) {
        api.put(`/trainings/${training.id}/sites`, modalStatus.site.siteId, values).then(() => {
          message.success("Site modifié.")
          hideModal()
          refreshTraining()
        }).catch(console.error)
      } else {
        api.post(`/trainings/${training.id}/sites`, values).then(() => {
          message.success("Site ajouté.")
          hideModal()
          refreshTraining()
        }).catch(console.error)
      }
    }).catch(() => { })
  }

  const deleteSite = () => {
    api.delete(`/trainings/${training.id}/sites`, modalStatus.site.siteId).then(() => {
      message.success("Site supprimé.")
      hideModal()
      refreshTraining()
    }).catch(console.error)
  }

  const classrooms = groupBy(uniqBy(flatten(training?.events.map(e => e.classrooms) || []), 'id'), 'siteId')

  return (
    <>
      <Card
        title={<><Icon icon="map-marker-alt" fixedWidth /> Lieux de formation</>}
        style={style}
        extra={<Button size="small" icon={<Icon icon="plus" />} onClick={() => setModalStatus({ visible: true, site: null })}>Ajouter</Button>}
      >
        <Table dataSource={training?.sites} rowKey="siteId" size="small" pagination={false} rowClassName="row-clickable" onRow={onRowHandlers}>
          <Column title="Nom" key="name" render={item => item.site.name} />
          <Column title="Locaux utilisés" render={item => classrooms[item.site.id]?.map(c => c.name).join(', ')} />
        </Table>
      </Card>

      <DraggableModal
        title={<><Icon icon={"map-marker-alt"} /> {modalStatus.site ? "Modifier le lieu" : "Ajouter un lieu"}</>}
        open={modalStatus.visible}
        closable
        maskClosable={false}
        destroyOnClose
        onCancel={hideModal}
        footer={<Row justify="space-between">
          <Col>
            <Popconfirm title={
              <>
                Êtes-vous sûr ?{classrooms[modalStatus.site?.siteId]?.length
                  ? <><br />Le lieu est programmé dans le planning.<br />Tous les locaux de ce site seront supprimés des plages concernées.</>
                  : null}
              </>
            } onConfirm={deleteSite}>
              <Button key="delete" danger hidden={!modalStatus.site}>Supprimer</Button>
            </Popconfirm>
          </Col>
          <Col>
            <Button key="cancel" onClick={hideModal}>Annuler</Button>
            {!modalStatus.site
              ? <Button key="ok" type="primary" icon={<Icon icon="plus" />} onClick={saveSite}>Ajouter</Button>
              : <Button key="ok" type="primary" icon={<Icon icon="save" />} onClick={saveSite}>Enregistrer</Button>}
          </Col>
        </Row>}
      >
        <Form form={form} layout="vertical" preserve={false}>
          <Form.Item label="Lieu de formation" name="siteId" rules={[{ required: true, message: 'Veuillez choisir un lieu.' }]}>
            <Select
              showSearch
              disabled={modalStatus.site}
              options={(modalStatus.site ? sites : sitesToAdd).map(item => ({ label: item.name, value: item.id }))}
              style={{ width: '100%' }}
              filterOption={filterOnSelectLabel}
            />
          </Form.Item>
          <Form.Item label="Notes" name="notes">
            <Input.TextArea placeholder="Ces notes seront transmises par mail aux participants." autoSize={{ minRows: 3 }} />
          </Form.Item>
        </Form>
      </DraggableModal>
    </>
  )

}

export default SitesCard