import React, { useState } from 'react'

import './Dashboard.less'

import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'

import api from 'services/adminApiService'
import Icon from 'components/Icon'
import StatusCard from './StatusCard'
import FileButton from 'components/FileButton'

import { Card, Row, Col, Steps, Alert, Divider, Button, Table, Modal, message, Space } from 'antd'

const { Step } = Steps
const { Column } = Table



const Dashboard = ({ training, setTraining, refreshTraining }) => {

  const [saving, setSaving] = useState(false)

  const computeCurrentStep = () => {
    if (training.status === 'confirmed' && DateTime.fromISO(training.end) < DateTime.now()) return 4
    if (training.registrationsStatus === 'closed' || DateTime.fromISO(training.registrationsStatus).isValid) {
      if (training.isPublished) return 1
      return 0
    }
    if (training.isPublished) {
      if (training.status === 'confirmed') return 3
      return 2
    }
    return -1
  }
  const currentStep = computeCurrentStep()

  const nextStep = () => {
    let title, content, body
    switch (currentStep) {
      case 0:
        title = "Brouillon -> Publicité"
        content = "Ceci aura pour effet de publier la formation sur le site internet Vinci."
        body = { isPublished: true }
        break;
      case 1:
        title = "Publicité -> Inscriptions"
        content = "Ceci aura pour effet d'ouvrir les inscriptions."
        body = { registrationsStatus: 'open' }
        break;
      case 2:
        title = "Inscriptions -> Confirmée"
        content = "Ceci aura pour effet de confirmer la formation. Un e-mail sera envoyé aux participants, 20J avant la formation, avec les informations pratiques."
        body = { status: 'confirmed' }
        break;
      default:
        return;
    }
    Modal.confirm({
      title,
      content,
      maskClosable: true,
      closable: true,
      onOk: () => {
        api.trainings.put(training.id, body, { loader: setSaving }).then(data => {
          setTraining(data)
          message.success("Passage à l'étape suivante terminée.")
        }).catch(console.error)
      }
    })
  }

  const updateTraining = values => {
    api.trainings.put(training.id, values, { loader: setSaving }).then(data => {
      setTraining(data)
      message.success("Modifications enregistrées.")
    }).catch(console.error)
  }

  const confirmAbsencesEncoded = () => {
    Modal.confirm({
      title: "Êtes-vous sûr ?",
      content: "Ceci enverra les attestations de présence aux participants qui ne l'ont pas encore reçue.",
      maskClosable: true,
      closable: true,
      onOk: () => updateTraining({ isAbsencesEncoded: true })
    })
  }

  const checklistBefore = [{
    title: "Encodage de l'ID FLink",
    status: <Icon.Boolean boolean={!!training.flinkID} />,
    actions: <Link to={`/admin/training/${training.id}/info`}><Button size="small" icon={<Icon icon="arrow-right" />}>L'encoder</Button></Link>
  }, {
    title: <>Matériel préparé <Icon.Info tooltip="Disponible à partir de 20J avant le début de la formation, s'il y a du matériel à préparer." /></>,
    status: <Icon.Boolean boolean={training.isMaterialPrepared} />,
    actions: <Link to={`/admin/training/${training.id}/material`}><Button size="small" icon={<Icon icon="arrow-right" />}>Vérifier</Button></Link>
  }, {
    title: <>Participants en ordre de paiement <Icon.Info tooltip="Disponible à partir de 20J avant le début de la formation, s'il y a des inscriptions validées." /></>,
    status: <Icon.Boolean boolean={training.isParticipantsBalanceOK} />,
    actions: <Link to={`/admin/training/${training.id}/participants`}><Button size="small" icon={<Icon icon="arrow-right" />}>Vérifier</Button></Link>
  }, {
    title: <>Chèques formation bloqués <Icon.Info tooltip="Disponible à partir de 20J avant le début de la formation, s'il y a des paiements CF." /></>,
    status: <Icon.Boolean boolean={training.isPaymentsCFBlocked} />,
    actions: <Link to={'/admin/checks-to-block'}><Button size="small" icon={<Icon icon="arrow-right" />}>Vérifier</Button></Link>
  }, {
    title: <>Reconnaissances de dette imprimées <Icon.Info tooltip="Disponible à partir de 7J avant le début de la formation, s'il y a des inscrits pas en ordre de paiement." /></>,
    status: <Icon.Boolean boolean={training.isAckOfDeptPrinted} />,
    actions: training.isAckOfDeptPrinted !== null && <Space>
      <Link to={`/admin/training/${training.id}/participants`}><Button size="small" icon={<Icon icon="arrow-right" />}>Y aller</Button></Link>
      {!training.isAckOfDeptPrinted && <Button size="small" icon={<Icon icon="check" />} onClick={() => updateTraining({ isAckOfDeptPrinted: true })}>C'est fait !</Button>}
      {training.isAckOfDeptPrinted && <Button size="small" icon={<Icon icon="times" />} onClick={() => updateTraining({ isAckOfDeptPrinted: false })}>Pas encore fait</Button>}
    </Space>
  }, {
    title: <>Liste de présence imprimée <Icon.Info tooltip="Disponible à partir de 14J avant le début de la formation, s'il y a des inscrits." /></>,
    status: <Icon.Boolean boolean={training.isTimesheetPrinted} />,
    actions: training.isTimesheetPrinted !== null && <Space>
      <FileButton.Download training={training} type="timesheet" shape={null} icon="arrow-to-bottom" tooltip={null}>Télécharger</FileButton.Download>
      {!training.isTimesheetPrinted && <Button size="small" icon={<Icon icon="check" />} onClick={() => updateTraining({ isTimesheetPrinted: true })}>C'est fait !</Button>}
      {training.isTimesheetPrinted && <Button size="small" icon={<Icon icon="times" />} onClick={() => updateTraining({ isTimesheetPrinted: false })}>Pas encore fait</Button>}
    </Space>
  }]
  checklistBefore.forEach((item, index) => { item.id = index + 1 })

  const checklistAfter = [{
    title: "Remboursement des participants en liste d'attente",
    status: <Icon.Boolean boolean={training.isWaitingListRefunded} />,
  }, {
    title: "Encodage des absences",
    status: <Icon.Boolean boolean={training.isAbsencesEncoded} />,
    actions: training.isAbsencesEncoded !== null && <Space>
      <Link to={`/admin/training/${training.id}/participants`}><Button size="small" icon={<Icon icon="arrow-right" />}>Y aller</Button></Link>
      {!training.isAbsencesEncoded && <Button size="small" icon={<Icon icon="check" />} onClick={() => confirmAbsencesEncoded()}>C'est fait !</Button>}
      {training.isAbsencesEncoded && <Button size="small" icon={<Icon icon="times" />} onClick={() => updateTraining({ isAbsencesEncoded: false })}>Pas encore fait</Button>}
    </Space>
  }, {
    title: "Facturation sodexo",
    status: <Icon.Boolean boolean={training.isSodexoInvoicingDone} />
  }, {
    title: "Envoi des attestations de suivi (automatique après encodage des absences)",
    status: <Icon.Boolean boolean={training.isAttendanceCertSent} />
  }, {
    title: "Paiement des formateurs",
    status: <Icon.Boolean boolean={training.isTrainersPaid} />
  }, {
    title: "Encodage des points PQK",
    status: <Icon.Boolean boolean={training.isPQKEncoded} />,
    actions: training.isPQKEncoded !== null && <Space>
      <Link to={`/admin/training/${training.id}/participants`}><Button size="small" icon={<Icon icon="arrow-right" />}>Y aller</Button></Link>
      {!training.isPQKEncoded && <Button size="small" icon={<Icon icon="check" />} onClick={() => updateTraining({ isPQKEncoded: true })}>C'est fait !</Button>}
      {training.isPQKEncoded && <Button size="small" icon={<Icon icon="times" />} onClick={() => updateTraining({ isPQKEncoded: false })}>Pas encore fait</Button>}
    </Space>
  }]
  checklistAfter.forEach((item, index) => { item.id = index + 1 })

  const getRowClassName = item => item.disabled ? 'row-disabled' : null

  return (
    <>
      <Row gutter={16}>

        <Col span={16}>
          <Card
            title={<><Icon icon="stream" /> Suivi de la formation</>}
            extra={currentStep >= 0 && currentStep <= 2 && <Button size="small" icon={<Icon icon="check" />} loading={saving} onClick={nextStep}>Passer à l'étape suivante</Button>}
          >
            {currentStep === -1 && <Alert type="warning" showIcon message="La formation ne suit pas le flux normal." style={{ marginBottom: 25 }} />}
            {training.status === 'cancelled'
              ? <Alert type="error" showIcon message="La formation est annulée." />
              : <Steps current={currentStep}>
                <Step title="Brouillon" icon={<Icon icon="edit" />} />
                <Step title="Publicité" icon={<Icon icon="bullhorn" />} />
                <Step title="Inscriptions" icon={<Icon icon="users" />} />
                <Step title="Confirmée" icon={<Icon icon="check" />} />
                <Step title="Terminée" icon={<Icon icon="flag-checkered" />} />
              </Steps>
            }
            <Divider />
            <Table dataSource={checklistBefore} rowKey="id" size="middle" pagination={false} showHeader={false} rowClassName={getRowClassName}>
              <Column width={30} render={item => !item.disabled && item.status} />
              <Column dataIndex="title" />
              <Column width={100} render={item => !item.disabled && item.actions} align="right" />
            </Table>
          </Card>
          <Card title={<><Icon icon="clipboard-list-check" /> Suivi après la formation</>} style={{ marginTop: 16 }}>
            {training.status !== 'confirmed' || DateTime.fromISO(training.start) > DateTime.now()
              ? <span>La formation n'est pas confirmée, ou n'a pas encore commencé <Icon icon="smile-beam" /></span>
              : <Table dataSource={checklistAfter} rowKey="id" size="middle" pagination={false} showHeader={false} rowClassName={getRowClassName}>
                <Column width={30} render={item => !item.disabled && item.status} />
                <Column dataIndex="title" />
                <Column width={100} render={item => !item.disabled && item.actions} align="right" />
              </Table>
            }

          </Card>
        </Col>

        <Col span={8}>
          <StatusCard training={training} setTraining={setTraining} />
        </Col>

      </Row>

    </>
  )

}

export default Dashboard