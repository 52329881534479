import React from 'react'

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import AdminAppRouter from 'components/AdminAppRouter'
import ForgotPassword from 'user-app/ForgotPassword'
import ResetPassword from 'user-app/ResetPassword'
import Register from 'user-app/Register/Register'
import Portal from 'user-app/Portal/Portal'
import ValidateEmail from 'user-app/ValidateEmail'
import { UserAuthProvider } from 'contexts/UserAuthContext'
import UserAuthRoute from './UserAuthRoute'
import UpdateEmail from 'user-app/UpdateEmail'
import RecoverAccount from 'user-app/RecoverAccount'
import Trainings from 'user-app/Register/Trainings'

// Ant design
import { ConfigProvider } from 'antd'
import frBE from 'antd/lib/locale/fr_BE'

// Luxon
import { Settings } from "luxon"

// Moment
import moment from 'moment';
import 'moment/locale/fr';

// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from 'fontawesome-pro-light-svg-icons'
import { faCircle } from 'fontawesome-pro-solid-svg-icons'
import { faMicrosoft, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons'
library.add(fal, faCircle, faMicrosoft, faFacebook, faLinkedin)

// Luxon config
Settings.defaultZoneName = "Europe/Brussels"
Settings.defaultLocale = "fr-be"

// Moment
moment.locale('fr')

// Ant design config
const validateMessages = {
  /* eslint-disable no-template-curly-in-string */
  required: "Veuillez compléter le ${label}"
}




const Root = () => {
  return (
    <ConfigProvider locale={frBE} form={{ validateMessages }}>
      <BrowserRouter>
        <Switch>
          <Route path="/admin"><AdminAppRouter /></Route>
          <Route path="/compta"><AdminAppRouter /></Route>
          <Route path="/forgot-password"><ForgotPassword /></Route>
          <Route path="/reset-password"><ResetPassword /></Route>
          <Route path="/recover-account"><RecoverAccount /></Route>
          <Route path="/validate-email"><ValidateEmail /></Route>
          <Route path="/update-email"><UpdateEmail /></Route>
          <Route path="/register" exact><Trainings /></Route>
          <Route path="/register/:id"><UserAuthProvider><Register /></UserAuthProvider></Route>
          <UserAuthRoute path="/portal"><Portal /></UserAuthRoute>
          <Route path="*"><Redirect to="/portal" /></Route>
        </Switch>
      </BrowserRouter>
    </ConfigProvider>
  )
}

export default Root