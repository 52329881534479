import React, { useEffect, useState } from 'react'

import { Link, useHistory } from 'react-router-dom'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import { filters, sorters } from 'misc/utils'

import { PageHeader, Table, Button, Card, Input } from 'antd'

const { Column } = Table



const EmailTemplates = () => {

  const [emails, setEmails] = useState([])
  const [filteredEmails, setFilteredEmails] = useState([])
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  useEffect(() => {
    api.emails.get({ loader: setLoading }).then(data => {
      setEmails(data)
      setFilteredEmails(data)
    }).catch(console.error)
  }, [])

  const onRowHandlers = (record, rowIndex) => ({
    onClick: e => history.push(`/admin/email-template/${record.id}`)
  })

  const onSearch = (e) => {
    if (e.target.value) {
      setFilteredEmails(emails.filter(filters.string(['name', 'code', 'from', 'subject'], e.target.value)))
    } else {
      setFilteredEmails(emails)
    }
  }

  return (
    <>
      <PageHeader
        title={<><Icon icon="envelope-open-text" fixedWidth /> Templates d'e-mails</>}
        extra={[
          <Link to="/admin/email-template" key="1" ><Button icon={<Icon icon="plus" />}>Ajouter un email</Button></Link>,
        ]}
      />
      <Card>
        <Input.Search style={{ marginBottom: 15, maxWidth: 350 }} placeholder="Rechercher..." onChange={onSearch} allowClear />
        <Table dataSource={filteredEmails} loading={loading} onRow={onRowHandlers} rowKey="id" rowClassName="row-clickable" size="small" pagination={{ pageSize: 20 }}>
          <Column title="Nom" dataIndex="name" sorter={sorters.string('name')} />
          <Column title="Signature" dataIndex={["signature", "description"]} sorter={sorters.string('from')} />
          <Column title="Objet" dataIndex="subject" sorter={sorters.string('subject')} />
          <Column title={<>Automatique <Icon.Info tooltip="Envoi automatique par l'application, non visible dans les envois manuels." /></>} dataIndex="code" render={code => <Icon.Boolean boolean={!!code} />} />
        </Table>
      </Card>
    </>
  )

}

export default EmailTemplates