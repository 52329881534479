import React, { useEffect, useState } from 'react'

import { Link, useLocation } from 'react-router-dom'

import api from 'services/userApiService'
import ModalLayout from 'components/ModalLayout/ModalLayout'
import Icon from 'components/Icon'
import Error500 from './Error500'

import { Button, Result } from 'antd'



const UpdateEmail = () => {

  const [isValidated, setIsValidated] = useState(false)
  const [error, setError] = useState(null)

  const params = new URLSearchParams(useLocation().search)
  const code = params.get("code")

  useEffect(() => {
    if (code) {
      api.post('/auth/update-email', { code })
        .then(() => { setTimeout(() => setIsValidated(true), 1000) })
        .catch(response => {
          if (response?.json?.error === "auth.invalid_or_expired_code") {
            setTimeout(() => setError("invalid_or_expired_code"), 1000)
          } else if (response?.json?.error === "auth.email_already_used") {
            setTimeout(() => setError("email_already_used"), 1000)
          } else {
            setTimeout(() => setError("unknown"), 1000)
          }
        })
    }
  }, [code])

  if (!code || error === "unknown") {
    return <Error500 />
  }

  return (
    <ModalLayout>

      {error === "invalid_or_expired_code" &&
        <Result
          title="Oups..."
          subTitle="Le lien que vous avez utilisé semble périmé ou déjà utilisé. Veuillez recommencer l'opération dans votre portail. Gardez à l'esprit que le lien n'est valable que 15 minutes."
          status="warning"
          style={{ paddingTop: 20 }}
          icon={<Icon icon="exclamation-triangle" />}
          extra={<Link to="/login"><Button type="primary" icon={<Icon icon="lock" />}>Aller à l'écran de connexion</Button></Link>} />
      }

      {error === "email_already_used" &&
        <Result
          title="Oups..."
          subTitle="Un compte existe déjà avec cette adresse e-mail. Est-ce que vous auriez 2 comptes ? N'hésitez pas à ré-initialiser le mot de passe de votre compte si vous ne vous en souvenez plus."
          status="warning"
          style={{ paddingTop: 20 }}
          icon={<Icon icon="exclamation-triangle" />}
          extra={<Link to="/login"><Button type="primary" icon={<Icon icon="lock" />}>Aller à l'écran de connexion</Button></Link>} />
      }

      {code && !error && (isValidated
        ? <Result
          title="L'adresse e-mail est changée !"
          subTitle="Vous pouvez maintenant vous connecter à votre portail avec votre nouvelle adresse e-mail !"
          status="success"
          style={{ paddingTop: 20 }}
          icon={<Icon icon="check-circle" />}
          extra={<Link to="/login"><Button type="primary" icon={<Icon icon="lock" />}>Aller à l'écran de connexion</Button></Link>} />

        : <Result
          title="Veuillez patienter..."
          subTitle="Nous vérifions que tout est en ordre..."
          status="info"
          style={{ paddingTop: 20 }}
          icon={<Icon icon="spinner-third" spin />} />
      )}

    </ModalLayout>
  )
}

export default UpdateEmail