import React, { useEffect, useState } from 'react'

import moment from 'moment'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import DraggableModal from 'components/DraggableModal'
import { filterOnSelectLabel } from 'misc/utils'
import { callGraph } from 'services/graphService'

import { Alert, Button, DatePicker, Form, Input, Select, Switch } from 'antd'



const AddFileModal = ({ user = null, isVisible, onOK, onCancel, file = null }) => {

  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()
  const hasRegistration = Form.useWatch('registrationId', form)
  const hasTraining = Form.useWatch('trainingId', form)

  useEffect(() => {
    if (isVisible) {
      if (file) {
        const values = { ...file }
        if (values.doneDate) values.doneDate = moment(values.doneDate)
        values.data = JSON.parse(values.data)
        form.setFieldsValue(values)
      }
      else form.resetFields()
    }
  }, [isVisible, form, file])

  const save = values => {
    const data = Object.assign({}, values)
    data.type = 'file'
    if (!data.registrationId) data.userId = user ? user.id : file.userId
    // Update
    if (file) {
      api.actions.put(file.id, data, { loader: setIsLoading })
        .then(onOK)
        .catch(console.error)
    }
    // Insert new
    else {
      api.actions.post(data, { loader: setIsLoading })
        .then(onOK)
        .catch(console.error)
    }
  }

  const openUserFolder = () => {
    callGraph({ endpoint: `/drive/special/approot:/Utilisateurs/${user.id}` }).then((data) => {
      window.open(data.webUrl, '_blank');
    }).catch(console.error)
  }

  return (
    <DraggableModal
      title={<><Icon icon="file-alt" fixedWidth /> {file ? "Modifier" : "Ajouter"} un fichier à la timeline</>}
      open={isVisible}
      maskClosable={false}
      onCancel={onCancel}
      onOk={form.submit}
      okText="Enregistrer"
      okButtonProps={{ icon: <Icon icon="save" />, loading: isLoading }}
    >
      <Alert
        message={<>Avant d'ajouter un fichier à la timeline, celui-ci doit exister dans le OneDrive de l'utilisateur. Une fois ajouté, entrez le nom <strong>exact</strong> du fichier ci-dessous.<br />Par exemple: "attestation-CEP.pdf"</>}
        showIcon
        action={<Button icon={<Icon icon="folder-open" />} onClick={openUserFolder}>Ouvrir le dossier</Button>}
        style={{ marginBottom: 25 }}
      />
      <Form form={form} layout="vertical" onFinish={save}>
        <Form.Item name={["data", "filename"]} label="Nom du fichier dans OneDrive" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name={["data", "title"]} label={<>Nom à afficher dans la timeline <small style={{ marginLeft: 10 }}>(et dans le portail le cas échéant)</small></>} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name={["data", "content"]} label={<>Note privée<small style={{ marginLeft: 10 }}>(non visible dans le portail)</small></>}>
          <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
        </Form.Item>
        <Form.Item name="doneDate" label="Date" rules={[{ required: true }]} initialValue={moment()}>
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>
        {user?.isParticipant && <Form.Item name="registrationId" label={<>Inscription participant <small style={{ marginLeft: 10 }}>(facultatif)</small></>}>
          <Select
            options={
              user?.registrations?.map(item => ({ label: `${item.number} - ${item.training.code}`, value: item.id }))
              || [{ label: `${file?.registration?.number} - ${file?.registration?.training?.code}`, value: file?.registration?.id }]
            }
            style={{ width: '100%' }}
            allowClear
            showSearch
            filterOption={filterOnSelectLabel}
            disabled={!!file || hasTraining}
          />
        </Form.Item>}
        {user?.isTrainer && <Form.Item name="trainingId" label={<>Formation formateur <small style={{ marginLeft: 10 }}>(facultatif)</small></>}>
          <Select
            options={
              user?.trainings?.map(item => ({ label: item.training.code, value: item.trainingId }))
              || [{ label: file?.registration?.training?.code, value: file?.trainingId }]
            }
            style={{ width: '100%' }}
            allowClear
            showSearch
            filterOption={filterOnSelectLabel}
            disabled={!!file || hasRegistration}
          />
        </Form.Item>}
        <Form.Item name={["data", "important"]} label={<>Point d'attention<Icon.Info tooltip="Une icône sera affichée dans la liste des inscriptions." style={{ marginLeft: 7 }} /></>} valuePropName="checked">
          <Switch checkedChildren="Oui" unCheckedChildren="Non" />
        </Form.Item>
        {(hasRegistration || hasTraining) &&
          <Form.Item name={["data", "showInPortal"]} label={<>Visible dans le portail<Icon.Info tooltip="Le fichier sera alors visible dans le portail de l'utilisateur, avec le nom défini précédemment." style={{ marginLeft: 7 }} /></>} valuePropName="checked">
            <Switch checkedChildren="Oui" unCheckedChildren="Non" />
          </Form.Item>
        }
      </Form>

    </DraggableModal >
  )

}

export default AddFileModal