import React, { useContext, useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import api from 'services/adminApiService'

import Icon from 'components/Icon'
import {TrainingStatus} from 'components/Tags'
import Info from './Info'
import WaitingList from './WaitingList'
import RecentsContext from 'contexts/RecentsContext'
import Badges from 'components/Badges'
import Registrations from './Registrations'

import { Button, PageHeader, Spin, Tabs } from 'antd'



const Training = () => {

  // Training states (training data, loading the training, saving the site training, form)
  const [training, setTraining] = useState(null)
  const [loading, setLoading] = useState(false)

  // Current site, if null it's a new one
  // @ts-ignore
  const { id, tab = 'info' } = useParams()

  const history = useHistory()

  const { addRecent } = useContext(RecentsContext)

  const loadTraining = () => {
    if (id) api.trainings.getById(id, { loader: setLoading }).then(setTraining).catch(console.error)
  }

  useEffect(loadTraining, [id])

  useEffect(() => {
    if (training && training.id) addRecent(training, 'training')
  }, [training]) // eslint-disable-line react-hooks/exhaustive-deps

  const onTabClick = key => {
    if (key === 'info') loadTraining()
    history.push(`/compta/training/${id}/${key}`)
  }

  // New training view
  if (!id) {
    return <>
      <PageHeader title={<><Icon icon="graduation-cap" fixedWidth /> Nouvelle {training?.parent ? "session de " + training.parent.code : "formation"}</>} />
      <Info training={training} setTraining={setTraining} refreshTraining={loadTraining} />
    </>
  }

  // Training loading
  if (!training || !training.registrations) {
    return <div><Spin size="large" /></div>
  }

  // Existing training view
  return (
    <>
      <PageHeader
        // @ts-ignore
        title={<><Icon icon="graduation-cap" fixedWidth /> Formation : {training.code}</>}
        tags={[<TrainingStatus key="status" training={training} />]}
        extra={<Button icon={<Icon icon="sync" spin={loading} />} onClick={loadTraining}></Button>}
      />

      <Spin delay={500} spinning={loading}>

        <Tabs defaultActiveKey="informations" activeKey={tab} onTabClick={onTabClick} destroyInactiveTabPane={true}>

          <Tabs.TabPane tab={<><Icon icon="info-circle" />Informations</>} key="info">
            <Info training={training} setTraining={setTraining} refreshTraining={loadTraining} />
          </Tabs.TabPane>

          <Tabs.TabPane tab={<><Icon icon="users" />Participants <Badges.Registrations training={training} /></>} key="participants">
            <Registrations training={training} refreshTraining={loadTraining} />
          </Tabs.TabPane>

          <Tabs.TabPane tab={<><Icon icon="user-clock" />Liste d'attente <Badges.WaitingList registrations={training.registrations} /></>} key="waiting">
            <WaitingList training={training} refreshTraining={loadTraining} />
          </Tabs.TabPane>

        </Tabs>

      </Spin>

    </>
  )

}

export default Training


