import React, { useEffect, useState } from 'react'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import { isoDateToDateShort, nfEur } from 'misc/utils'
import DraggableModal from 'components/DraggableModal'
import { RegistrationStatus } from 'components/Tags'

import { Col, Divider, Empty, Input, Row, Spin, Table } from 'antd'

const { Column } = Table



const defaultPagination = {
  current: 1,
  pageSize: 5,
  defaultPageSize: 5,
  total: 0,
  showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} éléments`,
  showSizeChanger: true
}



const SearchRegistrationModal = ({ isVisible, onOK, onCancel }) => {

  const [selection, setSelection] = useState()

  /**
   * Search
   */
  const [isSearching, setIsSearching] = useState(false)
  const [searchUserInput, setSearchUserInput] = useState("")
  const [searchTrainingInput, setSearchTrainingInput] = useState("")

  const [searchResults, setSearchResults] = useState([])
  const [pagination, setPagination] = useState(defaultPagination)

  const searchUser = (search, pageSize, current) => {
    setSearchUserInput(search)
    api.users.get({ loader: setIsSearching, params: { results: pageSize, page: current, search, type: 'participant', include: 'registration-user' } }).then(result => {
      setSearchResults(result.data)
      setPagination({ ...pagination, pageSize, current, total: result.totalCount })
    }).catch(console.error)
  }

  const searchTraining = (search, pageSize, current) => {
    setSearchTrainingInput(search)
    api.trainings.get({ loader: setIsSearching, params: { results: pageSize, page: current, search, include: 'registration-training' } }).then(result => {
      setSearchResults(result.data)
      setPagination({ ...pagination, pageSize, current, total: result.totalCount })
    }).catch(console.error)
  }

  const onSearchUser = search => searchUser(search, defaultPagination.pageSize, 1)

  const onSearchTraining = search => searchTraining(search, defaultPagination.pageSize, 1)

  const onUsersTableChange = (pagination, filters, sorter) => searchUser(searchUserInput, pagination.pageSize, pagination.current)

  const onTrainingsTableChange = (pagination, filters, sorter) => searchTraining(searchTrainingInput, pagination.pageSize, pagination.current)

  useEffect(() => {
    setSelection(null)
    setSearchResults([])
    setPagination(defaultPagination)
  }, [isVisible])

  return (
    <DraggableModal
      title={<><Icon icon="search" fixedWidth /> Rechercher une inscription</>}
      open={isVisible}
      maskClosable={false}
      onCancel={onCancel}
      onOk={() => onOK(selection)}
      okText="Choisir"
      okButtonProps={{ icon: <Icon icon="save" />, disabled: !selection }}
      width={700}
      destroyOnClose
    >
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Input.Search placeholder="Rechercher un utilisateur" prefix={<Icon icon="user" />} enterButton loading={isSearching} onSearch={onSearchUser} />
        </Col>
        <Col span={12}>
          <Input.Search placeholder="Rechercher une formation" prefix={<Icon icon="graduation-cap" />} enterButton loading={isSearching} onSearch={onSearchTraining} />
        </Col>
      </Row>
      <Divider />
      {searchResults[0]?.firstname && <>
        <Table
          dataSource={searchResults}
          rowKey="id"
          rowClassName="row-clickable"
          size="small"
          pagination={pagination}
          onChange={onUsersTableChange}
          expandable={{
            expandIconColumnIndex: -1,
            expandRowByClick: true,
            expandedRowRender: record => <Table
              rowKey="id"
              dataSource={record.registrations}
              size="small"
              pagination={false}
              rowSelection={{
                type: 'radio',
                selectedRowKeys: selection ? [selection.id] : [],
                onSelect: (record, selected) => setSelection(record)
              }}
            >
              <Column title="Formation" render={registration => registration.training.code} />
              <Column title="Date" render={registration => isoDateToDateShort(registration.date)} />
              <Column title="Prix" render={registration => nfEur(registration.price)} />
              <Column title="Solde" render={registration => nfEur(registration.balance)} />
              <Column title="Status" render={registration => <RegistrationStatus registration={registration} />} />
            </Table>
          }}
        >
          <Column title="Nom" render={user => `${user.lastname} ${user.firstname}`} />
          <Column title="Email" dataIndex="email" />
          <Column title="Pays" dataIndex="country" />
          <Column title="Dernière inscription" render={user =>
            <>{isoDateToDateShort(user.registrations[0].date)} ({user.registrations[0].training.code})</>
          } />
        </Table>
      </>}
      {searchResults[0]?.code && <>
        <Table
          dataSource={searchResults}
          rowKey="id"
          rowClassName="row-clickable"
          size="small"
          pagination={pagination}
          onChange={onTrainingsTableChange}
          expandable={{
            expandIconColumnIndex: -1,
            expandRowByClick: true,
            expandedRowRender: record => <Table
              rowKey="id"
              dataSource={record.registrations}
              size="small"
              pagination={false}
              rowSelection={{
                type: 'radio',
                selectedRowKeys: selection ? [selection.id] : [],
                onSelect: (record, selected) => setSelection(record)
              }}
            >
              <Column title="Participant" render={registration => registration.user.firstname + ' ' + registration.user.lastname} />
              <Column title="Date" render={registration => isoDateToDateShort(registration.date)} />
              <Column title="Prix" render={registration => nfEur(registration.price)} />
              <Column title="Solde" render={registration => nfEur(registration.balance)} />
              <Column title="Status" render={registration => <RegistrationStatus registration={registration} />} />
            </Table>
          }}
        >
          <Column title="Code" dataIndex="code" />
          <Column title="Nom" dataIndex="name" />
          <Column title="Date (début/fin)" render={item => item.start ? isoDateToDateShort(item.start) + ' - ' + isoDateToDateShort(item.end) : '-'} />
          <Column title="Lieu(x)" render={item => item.sites?.map(s => s.site.name).join(', ')} />
        </Table>
      </>}
      {
        !isSearching && !searchResults.length && <>
          <Empty description="Aucun résultats" imageStyle={{ maxHeight: 75 }} style={{ width: '100%' }} />
        </>
      }
      {isSearching && <Row justify="center"><Col><Spin size="large" /></Col></Row>}
    </DraggableModal >
  )

}

export default SearchRegistrationModal