import React, { useEffect, useState } from 'react'

import './Training.less'

import { useParams } from 'react-router-dom'
import { DateTime } from 'luxon'

import Icon from 'components/Icon'
import { address, isoDateToDateShort, } from 'misc/utils'
import api from 'services/userApiService'
import { TrainingStatus } from 'components/Tags'
import DocumentStatus from './DocumentStatus'

import { Card, Col, Table, Row, Spin, Descriptions, Alert, PageHeader } from 'antd'

const { Column } = Table



const Training = () => {

  const { id } = useParams()

  const [training, setTraining] = useState(null)
  const [isLoading, setIsLoading] = useState(null)

  const loadTraining = () => {
    api.getById('/trainer-trainings', id, { loader: setIsLoading }).then(data => {
      data.actions.forEach(a => a.data = JSON.parse(a.data))
      setTraining(data)
    }).catch(console.error)
  }
  useEffect(loadTraining, [id])

  if (!training || isLoading) {
    return <Spin size="large" />
  }

  const documents = training.actions.filter(a => a.type === 'file' && a.data.showInPortal)

  return (
    <>
      <PageHeader
        title={<><Icon icon="clipboard-list-check" /> Ma formation : {training.training.code}</>}
        tags={<TrainingStatus training={training.training} help={true} />}
      />

      <Row gutter={[16, 16]} style={{ marginTop: 30 }}>

        <Col span={24}>
          <Card title={<><Icon icon="info-circle" /> Informations générales</>}>
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label="Formation">{training.training.name}</Descriptions.Item>
              <Descriptions.Item label="Conditions d'inscription">{training.training.conditions}</Descriptions.Item>
              <Descriptions.Item label="Matériel">{training.training.requirements}</Descriptions.Item>
              <Descriptions.Item label="Formateurs"><ul className="trainers-list">{training.training.trainers.map(t => <li key={t.user.id}>{t.user.firstname} {t.user.lastname}</li>)}</ul></Descriptions.Item>
              <Descriptions.Item label="Statut de la formation"><TrainingStatus training={training.training} help={true} /></Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>

        <Col span={24}>
          <Card title={<><Icon icon="calendar-alt" /> Horaire</>}>
            {training.training.events.length
              ? <>
                <Alert message={<>Les plages où vous intervenez sont annotées avec un <Icon.Boolean boolean={true} /></>} style={{ marginBottom: 15 }} showIcon />
                <Table dataSource={training.training.events} size="small" rowKey="id" pagination={false}>
                  <Column title={<Icon.Info tooltip="Plages où vous intervenez" />} render={event => <Icon.Boolean noError boolean={!!event.trainers.filter(t => t.id === training.userId).length} />} />
                  <Column title="Date" render={event => DateTime.fromISO(event.start).toFormat("ccc D")} />
                  <Column title="Heure" render={event => `${DateTime.fromISO(event.start).toFormat("T")} - ${DateTime.fromISO(event.end).toFormat("T")} ${event.tableHours ? `(${event.tableHours}h de table)` : ''}`} />
                  <Column title="Lieu" render={event => event.classrooms.map(e => e.site.name + ' - Local ' + e.name).join(', ')} />
                  <Column title="Formateurs" render={event => event.trainers.map(t => `${t.firstname} ${t.lastname}`).join(', ')} />
                </Table>
              </>
              : <p>Informations à venir.</p>
            }
          </Card>
        </Col>

        <Col span={24}>
          <Card title={<><Icon icon="map-marker-alt" /> Lieu(x) de formation</>}>
            {training.training.sites.length
              ? <Row gutter={[16, 16]} justify="space-around">
                {training.training.sites.map(site =>
                  <Col key={site.site.id}>
                    <Card
                      className="site-card"
                      cover={<></>}
                      actions={[
                        <a key="website" href={site.site.url} target="_blank" rel="noopener noreferrer nofollow">
                          <div><Icon icon="globe" /><br />Informations pratiques</div>
                        </a>,
                        <a key="map" href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(address(site.site))}`} target="_blank" rel="noopener noreferrer nofollow">
                          <div><Icon icon="map-marked-alt" /><br />Plan</div>
                        </a>
                      ]}
                    >
                      <Card.Meta title={site.site.name} />
                      <Card.Meta
                        avatar={<Icon icon="map-marker-alt" />}
                        description={address(site.site)}
                      />
                      <Card.Meta
                        avatar={<Icon icon="sticky-note" />}
                        description={<>
                          {site.notes ? <>{site.notes}<br /></> : ''}
                          {site.site.notes ? site.site.notes : ''}
                        </>}
                      />
                    </Card>
                  </Col>
                )}
              </Row>
              : <p>Informations à venir.</p>
            }

          </Card>
        </Col>

        <Col span={24}>
          <Card title={<><Icon icon="file-alt" /> Mes documents</>}>
            {documents.length
              ? <Table dataSource={documents} size="small" rowKey="id" pagination={false}>
                <Column title="Date" render={item => isoDateToDateShort(item.doneDate || item.date)} />
                <Column title="Type" render={item => {
                  switch (item.type) {
                    case 'pro-forma-invoice': return 'Facture pro forma'
                    case 'attendance-certificate-sent': return 'Attestation de suivi'
                    case 'invoice-sent': return 'Facture'
                    case 'file': return item.data.title
                    default: return '-'
                  }
                }} />
                <Column title="Statut" render={item => <DocumentStatus document={item} />} />
              </Table>
              : <p>Aucun document disponible à ce jour.</p>
            }
          </Card>
        </Col>

      </Row>
    </>
  )

}

export default Training