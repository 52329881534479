import React, { useContext, useMemo, useState } from 'react'

import { useHistory } from 'react-router-dom'

import Icon from 'components/Icon'
import api from 'services/adminApiService'
import RichTextEditor from 'components/RichTextEditor'
import SelectsContext from 'contexts/SelectsContext'
import { serialize, deserialize, EMPTY_VALUE } from 'misc/slateSerialize'

import { Button, Card, Col, Form, Input, message, PageHeader, Row, Select } from 'antd'



const InviteTrainer = () => {

  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()

  const history = useHistory()

  const { getEmailByCode, signatures } = useContext(SelectsContext)
  const emailBody = useMemo(() => deserialize(getEmailByCode('invite-trainer')?.html) || EMPTY_VALUE, [getEmailByCode])
  const emailSignatureId = useMemo(() => getEmailByCode('invite-trainer')?.signatureId, [getEmailByCode])

  const send = values => {
    values.emailBody = serialize(values.emailBody)
    api.trainers.post(values, { loader: setIsLoading })
      .then(user => {
        message.success("Invitation envoyée.")
        history.push(`/admin/user/${user.id}`)
      })
      .catch(console.error)
  }

  return (
    <>
      <PageHeader title={<><Icon icon="paper-plane" fixedWidth /> Inviter un formateur</>} />
      <Form form={form} layout="vertical" onFinish={send}>
        <Row gutter={16}>

          <Col span={12}>
            <Card title={<><Icon icon="clipboard-user" fixedWidth /> informations</>}>
              <Form.Item name="firstname" label="Prénom" rules={[{ required: true }]}>
                <Input placeholder="Prénom" />
              </Form.Item>
              <Form.Item name="lastname" label="Nom" rules={[{ required: true }]}>
                <Input placeholder="Nom" />
              </Form.Item>
              <Form.Item name="email" label="Adresse e-mail" rules={[{ required: true }]}>
                <Input prefix={<Icon icon="envelope" />} placeholder="Adresse e-mail" />
              </Form.Item>
            </Card>
          </Col>

          <Col span={12}>
            <Card title={<><Icon icon="envelope" fixedWidth /> Email d'invitation</>}>
              <Form.Item name="emailBody" rules={[{ required: true }]} initialValue={emailBody}>
                <RichTextEditor />
              </Form.Item>
              <Form.Item label="Signature" name="emailSignatureId" rules={[{ required: true }]} initialValue={emailSignatureId}>
                <Select options={signatures?.map(item => ({ label: item.description, value: item.id }))} style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item >
                <Button type="primary" htmlType="submit" icon={<Icon icon="paper-plane" />} loading={isLoading}>Envoyer l'invitation</Button>
              </Form.Item>
            </Card>
          </Col>

        </Row>
      </Form>

    </>
  )

}

export default InviteTrainer